import { Divider, Stack, Typography, Box, Container } from '@mui/material'
import React from 'react'
import HeaderUser from '../components/HeaderUser'
import { GoHome } from 'react-icons/go';
import Footer from '../components/Footer';

const TermConditions = () => {

    return (
        <div>
            <HeaderUser user />
            <Stack
                direction={'row'}
                display={{ xs: 'none', md: 'flex' }}
                alignItems={'center'}
                spacing={1}
                px={18}
                py={1}
            >
                <GoHome size={'16px'} />
                <Typography sx={{ fontSize: '12px', fontWeight: '300' }}>
                    Home / Term & Conditions
                </Typography>
            </Stack>
            <Divider />
            <Box
                sx={{
                    width: {
                        md: "70%",
                        sm: "90%",
                        xs: "90%",
                    },
                    height: "auto",
                    margin: "50px auto",
                    boxShadow: 2,
                    borderRadius: '20px',
                    p: 4,
                }}
            >
                <Container>
                    <Typography textAlign="center" sx={{
                        fontSize: {
                            md: "2.125rem",
                            sm: "1.75rem",
                            xs: "1.75rem",
                        },
                    }}>
                        Terms and Conditions
                    </Typography>
                    <Typography variant="subtitle2" pt={3} style={{ fontWeight: 300 }}>
                        Please read these terms and conditions carefully before using our services. By using Kashmir Motors' services, you agree to abide by these terms and conditions
                    </Typography>
                    <Box>
                        <ul>
                            <li>
                                <Typography variant="body2" py={1}>
                                    <span style={{ fontWeight: "bold" }}>Vehicle Sales:</span>
                                    <span style={{ fontWeight: 300, paddingLeft: "10px" }}>
                                        All vehicle sales are final. Prices, specifications, and availability are subject to change without notice.
                                    </span>
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" py={1}>
                                    <span style={{ fontWeight: "bold" }}>Vehicle Inspection:</span>
                                    <span style={{ fontWeight: 300, paddingLeft: "10px" }}>
                                        Customers are encouraged to inspect vehicles thoroughly before purchase.
                                    </span>
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" py={1}>
                                    <span style={{ fontWeight: "bold" }}>Payment:</span>
                                    <span style={{ fontWeight: 300, paddingLeft: "10px" }}>
                                        Full payment is required before vehicle delivery.
                                    </span>
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" py={1}>
                                    <span style={{ fontWeight: "bold" }}>Delivery:</span>
                                    <span style={{ fontWeight: 300, paddingLeft: "10px" }}>
                                        We provide vehicle delivery services upon request, subject to additional charges.
                                    </span>
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" py={1}>
                                    <span style={{ fontWeight: "bold" }}>Privacy:</span>
                                    <span style={{ fontWeight: 300, paddingLeft: "10px" }}>
                                        We respect your privacy. Information provided to us will be used solely for transaction purposes and will not be shared without consent.
                                    </span>
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" py={1}>
                                    <span style={{ fontWeight: "bold" }}>Legal Compliance:</span>
                                    <span style={{ fontWeight: 300, paddingLeft: "10px" }}>
                                        Buyers are responsible for complying with all local, state, and federal laws related to vehicle ownership and operation.
                                    </span>
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" py={1}>
                                    <span style={{ fontWeight: "bold" }}>Dispute Resolution:</span>
                                    <span style={{ fontWeight: 300, paddingLeft: "10px" }}>
                                        Any disputes will be resolved through arbitration, as per the laws of the Civil Court.
                                    </span>
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" py={1}>
                                    <span style={{ fontWeight: "bold" }}>Changes to Terms:</span>
                                    <span style={{ fontWeight: 300, paddingLeft: "10px" }}>
                                        Kashmir Motors reserves the right to modify these terms and conditions at any time. Please review them periodically.
                                    </span>
                                </Typography>
                            </li>
                        </ul>
                    </Box>
                    <Box mt={2}>
                        <Typography variant="subtitle2" style={{ fontWeight: 300 }}>
                            By using our services, you acknowledge that you have read and understood these terms and conditions and agree to be bound by them. If you do not agree with any part of these terms, please refrain from using our services.
                        </Typography>
                    </Box>
                </Container>
            </Box>
            <Footer />
        </div>
    )
}

export default TermConditions;