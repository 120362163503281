import { Autocomplete, Avatar, Button, Divider, Drawer, Grid, List, ListItem, ListItemText, Menu, MenuItem, Modal, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react'
import { appImages } from '../assets/images';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { HiOutlineDotsVertical, HiOutlinePlusSm } from "react-icons/hi";
import { IoDocumentTextOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { Colors } from '../constants/colors';
import moment from 'moment';

const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 },
    {
        label: 'The Lord of the Rings: The Return of the King',
        year: 2003,
    },
    { label: 'The Good, the Bad and the Ugly', year: 1966 },
    { label: 'Fight Club', year: 1999 },
    {
        label: 'The Lord of the Rings: The Fellowship of the Ring',
        year: 2001,
    },
]

const detailData = [
    {
        title: 'Code (Lot No.)',
        detail: '315584'
    },
    {
        title: 'Maker',
        detail: 'TOYOTA'
    },
    {
        title: 'Car (Modal)',
        detail: 'A200A'
    },
    {
        title: 'Chassis No.',
        detail: 'A200A-0005853'
    },
    {
        title: 'Shipping Status',
        detail: '315584'
    },
    {
        title: 'ETA',
        detail: '12/10/2023'
    },
    {
        title: 'ETD',
        detail: '12/09/2023'
    },
    {
        title: 'Shipping From',
        detail: '315584'
    },
    {
        title: 'Shipper Name',
        detail: '315584'
    },
    {
        title: 'Auction House',
        detail: 'MIRIVE'
    },
    {
        title: 'Price',
        detail: '17K'
    },
    {
        title: 'Terms',
        detail: 'CAF'
    },
    {
        title: 'Status',
        detail: '315584'
    },
    {
        title: 'Documents Attached',
        detail: '315584'
    },



]

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 450,
    bgcolor: 'white',
    border: 'white',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px'
};

function UserBalance() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [modal, setModal] = useState(false);
    const handleModalOpen = () => {
        setModal(true)
    }
    const handleModalClose = () => setModal(false);

    const columns = [
        {
            field: 'codeNo',
            headerName: 'Code',
            flex: 1,
            editable: false,
            sortable: false,
            renderCell: (params) => (
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    {params?.row?.codeNo}
                </Box>
            ),
        },
        {
            field: 'doc',
            headerName: 'Doc',
            flex: 1,
            editable: false,
            sortable: false,
            renderCell: (params) => (
                <Box onClick={handleModalOpen} display={'flex'} gap={2} alignItems={'center'} sx={{ backgroundColor: Colors.Primary, p: '5px', borderRadius: '5px' }}>
                    <IoDocumentTextOutline size={20} style={{ cursor: 'pointer', color: 'white' }} />
                </Box>
            ),
        },
        {
            field: 'maker',
            headerName: 'Maker',
            flex: 1,
            editable: false,
            sortable: false,
            valueGetter: (params) =>
                `${params.row.maker}`,
        },
        {
            field: 'model',
            headerName: 'Model',
            flex: 1,
            editable: false,
            sortable: false,
            valueGetter: (params) =>
                `${params.row.model}`,
        },
        {
            field: 'eta',
            headerName: 'ETA',
            flex: 1,
            editable: false,
            sortable: false,
            valueGetter: (params) =>
                `${params.row.eta}`,
        },
        {
            field: 'etd',
            headerName: 'ETD',
            flex: 1,
            editable: false,
            sortable: false,
            valueGetter: (params) =>
                `${params.row.etd}`,
        },
        {
            field: 'location',
            headerName: 'Location',
            flex: 1,
            editable: false,
            sortable: false,
            valueGetter: (params) =>
                `${params.row.location}`,
        },
        {
            field: 'price',
            headerName: 'Price',
            sortable: false,
            flex: 1,
            valueGetter: (params) =>
                `${params.row.price}`,
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            flex: 1,
            valueGetter: (params) =>
                `${params.row.status}`,
        },
        // {
        //     field: 'action',
        //     headerName: 'Action',
        //     type: 'number',
        //     width: 110,
        //     editable: false,
        //     renderCell: (params) => (
        //         <Box display={'flex'} gap={2} alignItems={'center'}>
        //             <HiOutlineDotsVertical sx={{ width: 25, height: 25 }} />
        //         </Box>
        //     ),
        // },
    ];

    const rows = [
        { id: 1, etd: '2023/09/12', eta: '2023/09/12', model: 'A200A', maker: 'TOYOTA', location: 'OSAKA', codeNo: '315584', price: '1.752k', status: 'Paid', action: 35, },
        { id: 2, etd: '2023/09/12', eta: '2023/09/12', model: 'A200A', maker: 'HONDA', location: 'OSAKA', codeNo: '315583', price: '1.752k', status: 'UnPaid', action: 42, },
        { id: 3, etd: '2023/09/12', eta: '2023/09/12', model: 'A200A', maker: 'TOYOTA', location: 'OSAKA', codeNo: '315582', price: '1.752k', status: 'Paid', action: 45, },
        { id: 4, etd: '2023/09/12', eta: '2023/09/12', model: 'A200A', maker: 'HONDA', location: 'OSAKA', codeNo: '315581', price: '1.752k', status: 'Paid', action: 16, },
        { id: 5, etd: '2023/09/12', eta: '2023/09/12', model: 'A200A', maker: 'TOYOTA', location: 'OSAKA', codeNo: '315580', price: '1.752k', status: 'UnPaid', action: 26, },
        { id: 6, etd: '2023/09/12', eta: '2023/09/12', model: 'A200A', maker: 'HONDA', location: 'OSAKA', codeNo: '315579', price: '1.752k', status: 'Paid', action: 15, },
        { id: 7, etd: '2023/09/12', eta: '2023/09/12', model: 'A200A', maker: 'TOYOTA', location: 'OSAKA', codeNo: '315578', price: '1.752k', status: 'UnPaid', action: 44, },
    ];

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <>
            <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
                <List sx={{ width: '300px' }}>
                    <ListItem >
                        <ListItemText primary="Add User" /> <RxCross2 onClick={handleDrawerToggle} />
                    </ListItem>
                    <ListItem >
                        <TextField id="outlined-basic" label="First Name" variant="outlined" sx={{ width: '100%' }} />
                    </ListItem>
                    <ListItem >
                        <TextField id="outlined-basic" label="Last Name" variant="outlined" sx={{ width: '100%' }} />
                    </ListItem>
                    <ListItem >
                        <TextField id="outlined-basic" label="Email Address" variant="outlined" sx={{ width: '100%' }} />
                    </ListItem>
                    <ListItem >
                        <TextField id="outlined-basic" label="Phone" variant="outlined" sx={{ width: '100%' }} />
                    </ListItem>
                    <ListItem >
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={top100Films}
                            sx={{ width: '100%' }}
                            renderInput={(params) => <TextField {...params} label="User Role" />}
                            size={'small'}
                        />
                    </ListItem>
                    <ListItem >
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Button
                                id="basic-button"
                                aria-haspopup="true"
                                sx={{
                                    backgroundColor: Colors.Primary, '&:hover': {
                                        backgroundColor: Colors.Primary
                                    }
                                }}
                            >
                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Submit</Typography>
                            </Button>
                            <Button
                                id="basic-button"
                                aria-haspopup="true"
                                sx={{
                                    backgroundColor: '#E8E9ED', '&:hover': {
                                        backgroundColor: '#E8E9ED'
                                    }
                                }}
                                onClick={handleDrawerToggle}
                            >
                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'#878787'} >Cancel</Typography>
                            </Button>
                        </Stack>
                    </ListItem>
                </List>
            </Drawer>
            <Grid container md={12} >
                <Grid item xs={12} sm={11} md={12}>
                    {/* <Grid container md={12} gap={2} marginTop={2} justifyContent={{ xs: 'center' }} >
                        <Grid item xs={11} md={2.65} sx={{ boxShadow: 1, borderRadius: '5px', p: 2 }}  >
                            <Box>
                                <Box display={'flex'} justifyContent={'space-between'}>
                                    <Typography sx={{ fontSize: 18 }}>Total Users</Typography>
                                    <Avatar variant='square' src={appImages.tUser} sx={{ width: 22, height: 22 }} />
                                </Box>
                                <Typography sx={{ fontSize: 12, color: '#646464' }}>23,560 <span style={{ marginLeft: '10px', color: '#39B34A' }}>(+24%)</span> </Typography>
                                <Typography sx={{ fontSize: 16 }}>Last week anatylics</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={11} md={2.65} sx={{ boxShadow: 1, borderRadius: '5px', p: 2 }}  >
                            <Box>
                                <Box display={'flex'} justifyContent={'space-between'}>
                                    <Typography sx={{ fontSize: 18 }}>Total Users</Typography>
                                    <Avatar variant='square' src={appImages.aUser} sx={{ width: 22, height: 22 }} />
                                </Box>
                                <Typography sx={{ fontSize: 12, color: '#646464' }}>26,125 <span style={{ marginLeft: '10px', color: Colors.Primary }}>(+24%)</span> </Typography>
                                <Typography sx={{ fontSize: 16 }}>Last week anatylics</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={11} md={2.65} sx={{ boxShadow: 1, borderRadius: '5px', p: 2 }}  >
                            <Box>
                                <Box display={'flex'} justifyContent={'space-between'}>
                                    <Typography sx={{ fontSize: 18 }}>Total Users</Typography>
                                    <Avatar variant='square' src={appImages.pUser} sx={{ width: 22, height: 22 }} />
                                </Box>
                                <Typography sx={{ fontSize: 12, color: '#646464' }}>6,125 <span style={{ marginLeft: '10px', color: '#39B34A' }}>(+24%)</span> </Typography>
                                <Typography sx={{ fontSize: 16 }}>Last week anatylics</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={11} md={2.65} sx={{ boxShadow: 1, borderRadius: '5px', p: 2 }}  >
                            <Box>
                                <Box display={'flex'} justifyContent={'space-between'}>
                                    <Typography sx={{ fontSize: 18 }}>Total Users</Typography>
                                    <Avatar variant='square' src={appImages.penUser} sx={{ width: 22, height: 22 }} />
                                </Box>
                                <Typography sx={{ fontSize: 12, color: '#646464' }}>23,560 <span style={{ marginLeft: '10px', color: '#39B34A' }}>(+24%)</span> </Typography>
                                <Typography sx={{ fontSize: 16 }}>Last week anatylics</Typography>
                            </Box>
                        </Grid>
                    </Grid> */}

                    <Grid container md={12} marginTop={2} gap={2} justifyContent={'center'}>
                        <Grid item xs={11} md={11} p={2} boxShadow={1} borderRadius={'5px'}>
                            <Box sx={{ height: 'auto', width: '100%' }}>
                                {/* <Typography sx={{ fontSize: '18px', marginBottom: 2 }}>Search Filter</Typography>
                                <Stack direction={'row'} spacing={3} justifyContent={'space-between'} marginBottom={2} >
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={top100Films}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Select Role" />}
                                        size={'small'}
                                    />
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={top100Films}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Select Email" />}
                                        size={'small'}
                                    />
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={top100Films}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Select Balance" />}
                                        size={'small'}
                                    />
                                </Stack>
                                <Box sx={{ textAlign: 'right', my: '10px' }} >
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        sx={{
                                            backgroundColor: Colors.Primary, '&:hover': {
                                                backgroundColor: Colors.Primary, // Change the text color when hovered over
                                                // You can add other styles here for the hover state
                                            },
                                            alignItems: 'center',
                                            marginLeft: '5px'
                                        }}
                                        onClick={handleDrawerToggle}
                                    >
                                        <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} > <HiOutlinePlusSm /> Add User</Typography>
                                    </Button>
                                </Box> */}
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 7,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    // checkboxSelection
                                    disableRowSelectionOnClick
                                    slots={{ toolbar: GridToolbar }}
                                    slotProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                        },
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div>
                <Modal
                    open={modal}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={handleModalClose} style={{ cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Grid container md={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={5.8}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1 }}>
                                        <Avatar variant='square' src={appImages.otherCard} sx={{ width: '100%', height: '100%', borderRadius: '5px', '& .MuiAvatar-img': { objectFit: 'contain' } }} />
                                    </Box>
                                </Grid>
                                <Grid item md={5.8}>
                                    <Box sx={{ overflowX: 'hidden', maxHeight: 420, overflowY: 'scroll' }}>
                                        {detailData?.map((item, index) => {
                                            // const time = moment(item?.createdAt).format("ll");
                                            return (
                                                <>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                        <Typography sx={{ fontSize: '12px' }} >{item.title}</Typography>
                                                        <Typography sx={{ fontSize: '12px' }} >{item.detail}</Typography>
                                                    </Box>
                                                    <Divider/>
                                                </>
                                            )
                                        })}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    </Box>
                </Modal>
            </div>
        </>
    )
}

export default UserBalance