import { Typography, Box, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { Colors } from '../constants/colors';
import { getInvoicesData } from '../apis';
import moment from 'moment';
import { FiRefreshCcw } from 'react-icons/fi';
// import { downloadInvoice } from '../apis/adminIndex';
import { commaSeparated } from '../constants/GlobalConstants';

function Invoice() {
    const [invoiceData, setInvoiceData] = useState(null)
    const [loading, setLoading] = useState(null)

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <FiRefreshCcw style={{ fontSize: "20px", cursor: "pointer", paddingRight: "15px" }}
                    onClick={() => handleInvoicesList()}
                />
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        );
    }

    useEffect(() => {
        handleInvoicesList()
    }, []);

    // const handleDownloadInovice = async (id) => {
    //     const res = await downloadInvoice(id)
    // }

    const handleInvoicesList = async () => {
        setLoading(true)
        try {
            const res = await getInvoicesData()
            setInvoiceData(res?.data?.map((item) => {
                console.log("🚀 ~ file: Invoice.jsx:39 ~ setInvoiceData ~ item:", item)
                return {
                    id: item._id,
                    inNumber: item.invoiceNumber,
                    bank: item.bankName,
                    amount: commaSeparated(item.amount),
                    date: moment(item.createdAt).format("ll"),
                    dod: moment(item.paymentId.createdAt).format("ll"),
                    invID: item.file
                }
            })
            )
            setLoading(false)
        } catch (error) {
            console.log("🚀 ~ file: AdminBid.jsx:156 ~ handleBidList ~ error:", error)
        }
    }

    const columns = [
        {
            field: 'date',
            headerName: 'Invoice Date',
            // flex: 1,
            width: 150,
            sortable: false,
            renderCell: (params) => (
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        {params?.row?.date}
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'amount',
            headerName: 'Amount Deposited',
            // flex: 1,
            width: 150,
            renderCell: (params) => (
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        {params?.row?.amount}
                    </Typography>
                </Box>
            )
        },
        {
            field: 'bank',
            headerName: 'Bank Name',
            // flex: 1,
            width: 200,
            renderCell: (params) => (
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        {params?.row?.bank}
                    </Typography>
                </Box>
            )
        },
        {
            field: 'inNumber',
            headerName: 'Invoice No.',
            // flex: 1,
            width: 200,
            renderCell: (params) => (
                <Box display={'flex'} gap={2} alignItems={'center'} justifyContent={'center'}>
                    <a className='invText' href={process.env.REACT_APP_API_URL_INVOICE + params?.row?.invID} target='_blank' rel="noreferrer">
                        INV-{params?.row?.inNumber}
                    </a>
                </Box>
            ),
        },
        {
            field: 'dod',
            headerName: 'Date of Deposited',
            // flex: 1,
            width: 200,
            renderCell: (params) => (
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        {params?.row?.dod}
                    </Typography>
                </Box>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            // flex: 1,
            width: 150,
            renderCell: (params) => (
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        {params.row.status ?? 'Done'}
                    </Typography>
                </Box>
            )
        },
    ];

    return (
        <Box p={2}  borderRadius={'5px'}>
            <Box sx={{ height: 'auto', width: '100%', marginLeft:{xs:0, md:-5} }}>
                <Box sx={{ height: { xs: '600px', md: '500px' }, width: '100%' }}>
                    <DataGrid
                        rows={invoiceData || []}
                        columns={columns.map((column) => ({
                            ...column,
                            cellClassName: 'custom-cell-class',
                        }))}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        slots={{
                            loadingOverlay: LinearProgress,
                            toolbar: CustomToolbar,
                        }}
                        loading={loading}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                        }}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default Invoice