import { Box, Card, CardHeader, Divider, Grid, LinearProgress, List, ListItem, Typography } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { detailedUser } from '../../apis/adminIndex';
import { Colors } from '../../constants/colors';
import { commaSeparated } from '../../constants/GlobalConstants';
import { CarDetailFunc } from '../CarDetail';

const headerTypo = {
    fontSize: "1rem",
    fontWeight: 500,
}

const ListItemStyle = {
    color: Colors.lightgray,
}

const AdminCustomerDetail = () => {
    const { state } = useLocation();
    const userData = JSON.parse(localStorage.getItem("customerDetailData"))
    const [userID, setUserID] = useState(userData?.data);
    const [userInfo, setUserInfo] = useState([]);
    const [userTransaction, setUserTransaction] = useState([]);
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState({});
    const [open, setOpen] = useState(false);
    const [urlArray, setUrlArray] = useState([]);

    useEffect(() => {
        userDetail();
    }, []);

    const userDetail = async () => {
        setLoading(true);
        try {
            const res = await detailedUser(userID);
            const user = res?.data?.User;
            console.log("🚀 ~ userDetail ~ user:", user)

            // Extract necessary fields for user info
            const userData = [{
                id: user._id,
                name: `${user.FirstName} ${user.LastName}`,
                email: user.Email,
                balance: user.balance
            }];

            setUserInfo(user);

            // Extract necessary fields for user transactions and add unique id
            setUserTransaction(res?.data?.transactions.map((item, index) => ({
                id: item?._id || index, // Ensure each transaction has a unique id
                amount: commaSeparated(item?.amount),
                transDetail: item?.transactionDetail,
                type: item?.type,
                lotNumber: item?.auctionId?.LOT,
                date: moment(item?.createdAt).format('MMM, DD YY'),
                auction: item?.auctionId
            })));
            setLoading(false);
        } catch (error) {
            console.error("Error fetching user details:", error);
            setLoading(false);
        }
    };

    const userInfoColumns = [
        { field: 'name', headerName: 'Name', width: 400 },
        { field: 'email', headerName: 'Email', width: 400 },
        {
            field: 'balance', headerName: 'Balance', width: 150, renderCell: (params) => (
                <Box
                    component="span"
                    sx={{
                        color: params.value < 0 ? Colors.Primary : 'green',
                        fontWeight: 'bold'
                    }}
                >
                    {commaSeparated(params.value)}
                </Box>
            )
        }
    ];

    const transactionColumns = [
        {
            field: 'date',
            headerName: 'Date',
            // width: 120,
            flex: 1,
        },
        {
            field: 'lotNumber',
            headerName: 'Lot#',
            // width: 90,
            flex: 1,
            renderCell: (params) => (
                <Box>
                    <Typography onClick={() => {
                        setDetail(params?.row?.auction);
                        const urlString = params?.row?.auction?.IMAGES;
                        const urlParts = urlString ? urlString.split('#') : [];
                        const urlRegex = /(https?:\/\/[^\s]+)/g;
                        const urls = urlParts.flatMap(part => part.match(urlRegex));

                        if (urls) {
                            setUrlArray(urls);
                        }
                        setOpen(true);
                    }}
                        alignItems={'center'} sx={{ color: Colors.Primary, cursor: 'pointer', p: '5px', borderRadius: '5px', '&:hover': { textDecorationLine: 'underline' } }}>
                        {params.value}
                    </Typography>
                </Box>
            )
        },
        {
            field: 'transDetail',
            headerName: 'Transaction Detail',
            // width: 450,
            flex: 1,
            renderCell: (params) => (
                <Box>
                    <Typography sx={{ fontWeight: '300' }}>Amount charged</Typography>
                    {/* {`Transaction Against Auction of Amount ${commaSeparated(params.row.amount)}`} */}
                </Box>
            )
        },
        {
            field: 'amountIn',
            headerName: 'Debit',
            // width: 250,
            flex: 1,
            renderCell: (params) => (
                <Box
                    component="span"
                    sx={{
                        color: params.row.type === 'IN' ? 'green' : '',
                        fontWeight: 'bold',
                    }}
                >
                    {params.row.type === 'IN' ? params.row.amount : ""}
                </Box>
            )
        },
        {
            field: 'amountOut',
            headerName: 'Credit',
            // width: 150,
            flex: 1,
            renderCell: (params) => (
                <Box
                    component="span"
                    sx={{
                        color: params.row.type === 'OUT' ? Colors.Primary : '',
                        fontWeight: 'bold',
                    }}
                >
                    {params.row.type === 'OUT' ? params.row.amount : ""}
                </Box>
            )
        },
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        );
    }

    return (
        <Grid container>
            <Box sx={{ width: '95%', marginTop: 3, backgroundColor: 'white', borderRadius: '10px', marginLeft: { xs: 0, md: 5 } }}>
                <Card>
                    <CardHeader
                        sx={{
                            px: 2,
                            py: 1,
                            background: Colors.gray
                        }}
                        title={
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography style={headerTypo}>User Detail</Typography>
                            </Box>
                        }
                    />
                    <Divider />
                    <List
                        w-full="true"
                        dense
                        component="div"
                        role="list"
                    >
                        <ListItem
                            role="listitem"
                            sx={ListItemStyle}
                        >
                            <Grid container>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Name</Typography>
                                    <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{userInfo?.FirstName} {userInfo?.LastName}</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Email</Typography>
                                    <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{userInfo?.Email}</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Balance</Typography>
                                    <Typography sx={{ fontSize: "14px", fontWeight: 700, color: userInfo.balance <= 0 ? Colors.Primary : 'green' }}>{commaSeparated(userInfo?.balance)}</Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                    </List>
                </Card>
                {/* <DataGrid
                    sx={{ height: 400 }}
                    rows={userInfo || []}
                    columns={userInfoColumns.map((column) => ({
                        ...column,
                        cellClassName: 'custom-cell-class',
                    }))}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 20,
                            },
                        },
                    }}
                    pageSizeOptions={[20]}
                    disableRowSelectionOnClick
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar,
                    }}
                    loading={loading}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                /> */}
            </Box>
            <Box sx={{ height: 440, width: '92%', marginTop: 3, padding: '20px', backgroundColor: 'white', borderRadius: '10px', marginLeft: { xs: 0, md: 5 }, marginBottom: 3 }}>
                <Typography sx={{ marginBottom: '10px' }}>User Transactions</Typography>
                <DataGrid
                    sx={{ height: 400 }}
                    rows={userTransaction || []}
                    columns={transactionColumns.map((column) => ({
                        ...column,
                        cellClassName: 'custom-cell-class',
                    }))}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 20,
                            },
                        },
                    }}
                    pageSizeOptions={[20]}
                    rowsPerPageOptions={[200]}
                    disableRowSelectionOnClick
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar,
                    }}
                    loading={loading}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                />
            </Box>

            {
                (open && urlArray) && (
                    <CarDetailFunc detail={detail} open={open} setOpen={setOpen} urlArray={urlArray} adminNavigation={true} />
                )
            }
        </Grid>
    );
};

export default AdminCustomerDetail;
