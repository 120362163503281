import { Box, Button, CircularProgress, Divider, Grid, OutlinedInput, Avatar, Typography } from '@mui/material'
import React, { useState } from 'react'
import HeaderUser from '../components/HeaderUser'
import { GoHome } from 'react-icons/go';
import { FaUserAlt, FaFacebookF } from 'react-icons/fa';
import { IoKeyOutline } from 'react-icons/io5';
import { FcGoogle } from 'react-icons/fc';
import { TfiWorld } from 'react-icons/tfi';
import { AiTwotonePhone, AiOutlineMail } from 'react-icons/ai';
import { Colors } from '../constants/colors';
import Footer from '../components/Footer';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { signupValidationSchema } from '../constants/formikValidation';
import { registerUser } from '../apis';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { motion } from 'framer-motion'
import { appImages } from '../assets/images';

const SignUp = () => {
    const [passVisible, setPassVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()


    const handleSignUp = async (values) => {
        setLoading(true)
        try {
            const res = await registerUser(values)
            setLoading(false)
            navigate("/otp", { state: { token: res.data.token } })
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false)
        }

    }

    const initialValues = { FirstName: '', LastName: '', Email: '', Password: '', County: '', Phone: '', City: '', ZipCode: '', }

    return (
        <div className='backImgSignUp'>
            {/* <HeaderUser /> */}
            {/* <Stack
                direction={'row'}
                display={{ xs: 'none', md: 'flex' }}
                alignItems={'center'}
                spacing={1}
                px={18}

                py={1}
            >
                <GoHome size={'16px'} />
                <Typography sx={{ fontSize: '12px', fontWeight: '300' }}>
                    Home / Sign Up
                </Typography>
            </Stack>
            <Divider /> */}

            <Grid container px={{ xs: 2, md: 5 }} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={12} md={6} mt={{ xs: 4, md: 4 }} sx={{ backgroundColor: Colors.white, padding: '20px', borderRadius: '10px' }}>
                    <Box sx={{ textAlign: '-webkit-center', width: '100%' }}>
                        <Avatar
                            variant="square"
                            src={appImages.kmLogo1}
                            sx={{
                                width: 120,
                                height: 120,
                                '& .MuiAvatar-img': { objectFit: 'contain' },
                                alignSelf: 'center',

                            }} />
                    </Box>
                    <Typography
                        sx={{ fontSize: '36px', color: Colors.Primary }}>
                        Sign
                        <span style={{ color: 'black', fontWeight: '300' }}> Up</span>
                    </Typography>
                    {/* <Typography sx={{ fontSize: '14px' }} >Please sign up to your account.</Typography> */}

                    <Formik
                        initialValues={initialValues}
                        validationSchema={signupValidationSchema}
                        onSubmit={handleSignUp}
                    >
                        {({ errors, touched, validateForm, handleSubmit, setFieldValue, }) => (
                            <Form>
                                <Box sx={{ display: { xs: "block", md: 'block' }, }} >
                                    <Box sx={{ display: "flex", flexDirection: "column", width: { xs: "100%", md: "100%" } }} >
                                        <Field name="FirstName">
                                            {({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    sx={{ backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' }, marginTop: { xs: 1, md: 0 }, }}
                                                    placeholder="First Name"
                                                    id="outlined-adornment-weight"
                                                    endAdornment={<FaUserAlt color="#ADADAD" style={{ cursor: 'pointer' }} />}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="FirstName">
                                            {(msg) => (
                                                <Typography variant="caption" color="error">
                                                    {msg}
                                                </Typography>
                                            )}
                                        </ErrorMessage>
                                    </Box>

                                    <Box sx={{
                                        display: "flex",
                                        width: { xs: '100%', md: '100%' },
                                        marginLeft: { xs: 0, md: 0 },
                                        marginTop: { xs: 0, md: 0 },
                                    }} >
                                        <Field name="LastName">
                                            {({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    sx={{ backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' }, marginTop: { xs: 1, md: 2 }, }}
                                                    placeholder="Last Name"
                                                    id="outlined-adornment-weight"
                                                    endAdornment={<FaUserAlt color="#ADADAD" style={{ cursor: 'pointer' }} />}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="LastName">
                                            {(msg) => (
                                                <Typography variant="caption" color="error">
                                                    {msg}
                                                </Typography>
                                            )}
                                        </ErrorMessage>
                                    </Box>
                                </Box>

                                <Box sx={{ display: { xs: "block", md: 'block' } }} >
                                    <Box sx={{ display: "flex", flexDirection: "column", width: { xs: "100%", md: "100%" } }} >
                                        <Field name="Email">
                                            {({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    sx={{ backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' }, marginTop: { xs: 1, md: 2 }, }}
                                                    placeholder="Email"
                                                    id="outlined-adornment-weight"
                                                    endAdornment={<AiOutlineMail color="#ADADAD" style={{ cursor: 'pointer' }} />}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="Email">
                                            {(msg) => (
                                                <Typography variant="caption" color="error">
                                                    {msg}
                                                </Typography>
                                            )}
                                        </ErrorMessage>
                                    </Box>

                                    <Box sx={{
                                        display: "flex",
                                        width: { xs: '100%', md: '100%' },
                                        marginLeft: { xs: 0, md: 0 },
                                        marginTop: { xs: -1, md: 0 },
                                    }} >
                                        <PhoneInput
                                            country={'jp'}
                                            value={initialValues.Phone}
                                            placeholder={'Phone Number'}
                                            onChange={(val) => setFieldValue("Phone", val)}
                                            inputProps={{
                                                name: 'Phone',
                                                required: true,
                                                autoFocus: true
                                            }}
                                            containerStyle={{ backgroundColor: '#F6F6F6', marginTop: '17px', height: '55px' }}
                                            inputStyle={{ width: '100%', height: '55px', backgroundColor: '#F6F6F6' }}
                                            buttonStyle={{ backgroundColor: 'rgba(173, 173, 173, 0.5)' }}
                                        />
                                        {/* <Field name="Phone">
                                            {({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    sx={{ backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' }, marginTop: { xs: 1, md: 2 }, }}
                                                    placeholder="Phone"
                                                    id="outlined-adornment-weight"
                                                    endAdornment={<AiTwotonePhone color="#ADADAD" style={{ cursor: 'pointer' }} />}
                                                />
                                            )}
                                        </Field> */}
                                        <ErrorMessage name="Phone">
                                            {(msg) => (
                                                <Typography variant="caption" color="error">
                                                    {msg}
                                                </Typography>
                                            )}
                                        </ErrorMessage>
                                    </Box>
                                </Box>

                                <Box sx={{ display: { xs: "block", md: 'block' }, flexDirection: "row" }} >
                                    <Box sx={{ display: "flex", flexDirection: "column", width: { xs: "100%", md: "100%" } }} >
                                        <Field name="Password">
                                            {({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    sx={{ backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' }, marginTop: { xs: 1, md: 2 }, }}
                                                    placeholder="Strong Password"
                                                    id="outlined-adornment-weight"
                                                    endAdornment={<IoKeyOutline color="#ADADAD" style={{ cursor: 'pointer' }} onClick={() => setPassVisible(!passVisible)} />}
                                                    type={passVisible ? '' : 'password'}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="Password">
                                            {(msg) => (
                                                <Typography variant="caption" color="error">
                                                    {msg}
                                                </Typography>
                                            )}
                                        </ErrorMessage>
                                    </Box>

                                    <Box sx={{
                                        display: "flex", flexDirection: "column",
                                        width: { xs: '100%', md: '100%' },
                                        marginLeft: { xs: 0, md: 0 },
                                        marginTop: { xs: 0, md: 0 },
                                    }} >
                                        <Field name="County">
                                            {({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    sx={{ backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' }, marginTop: { xs: 1, md: 2 }, }}
                                                    placeholder="Country"
                                                    id="outlined-adornment-weight"
                                                    endAdornment={<TfiWorld color="#ADADAD" style={{ cursor: 'pointer' }} />}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="County">
                                            {(msg) => (
                                                <Typography variant="caption" color="error">
                                                    {msg}
                                                </Typography>
                                            )}
                                        </ErrorMessage>
                                    </Box>
                                </Box>

                                <Box sx={{ display: { xs: "block", md: 'block' }, flexDirection: "row" }} >
                                    <Box sx={{ display: "block", flexDirection: "column", width: { xs: "100%", md: "100%" } }} >
                                        <Field name="City">
                                            {({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    sx={{ backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' }, marginTop: { xs: 1, md: 2 }, }}
                                                    placeholder="City"
                                                    id="outlined-adornment-weight"
                                                    endAdornment={<TfiWorld color="#ADADAD" style={{ cursor: 'pointer' }} />}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="City">
                                            {(msg) => (
                                                <Typography variant="caption" color="error">
                                                    {msg}
                                                </Typography>
                                            )}
                                        </ErrorMessage>
                                    </Box>

                                    <Box sx={{
                                        display: { xs: "block", md: 'flex' },
                                        width: { xs: '100%', md: '100%' },
                                        marginLeft: { xs: 0, md: 0 },
                                        marginTop: { xs: 0, md: 0 },
                                    }} >
                                        <Field name="ZipCode">
                                            {({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    sx={{ backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' }, marginTop: { xs: 1, md: 2 }, }}
                                                    placeholder="Zip Code"
                                                    id="outlined-adornment-weight"
                                                    endAdornment={<FaUserAlt color="#ADADAD" style={{ cursor: 'pointer' }} />}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="ZipCode">
                                            {(msg) => (
                                                <Typography variant="caption" color="error">
                                                    {msg}
                                                </Typography>
                                            )}
                                        </ErrorMessage>
                                    </Box>
                                </Box>
                                <motion.div whileHover={{ scale: 1.02 }}>
                                    <Button
                                        onClick={loading ? '' : handleSubmit}
                                        id="basic-button"
                                        aria-haspopup="true"
                                        sx={{
                                            marginTop: '15px',
                                            backgroundColor: loading ? 'white' : Colors.Primary, '&:hover': {
                                                backgroundColor: loading ? 'white' : Colors.Primary,
                                            },
                                            textTransform: 'capitalize',
                                            width: { xs: '100%', md: '100%' }
                                        }}
                                    >
                                        {loading ? <CircularProgress size={18} color={'warning'} /> : <Typography fontSize={'18px'} fontFamily={"Roboto"} color={'white'} >Send OTP</Typography>}
                                    </Button>
                                </motion.div>
                            </Form>
                        )}
                    </Formik>
                    <Divider sx={{ display: { xs: 'none', md: 'block' }, width: '100%', marginTop: 1, marginLeft: -0.8 }} orientation="horizontal" variant="middle" flexItem />
                    <Typography fontSize={'14px'} fontFamily={"Roboto"} color={'#333333'} mt={'10px'} onClick={() => navigate('/login')} >
                        Already have an account?
                        <span style={{
                            marginLeft: '5px',
                            color: Colors.Primary, textDecorationLine: 'underline',
                            cursor: 'pointer',
                            fontSize: '16px',
                            fontWeight: '500'
                        }}
                        >
                            Sign In
                        </span>
                    </Typography>
                    {/* <Divider sx={{ marginTop: 5 }} /> */}
                </Grid>
                {/* <Grid item xs={12} md={12} >
                    <Typography pt={3} >Login via social media</Typography>
                    <Button
                        id="basic-button"
                        aria-haspopup="true"
                        sx={{
                            marginTop: '15px',
                            backgroundColor: Colors.fbColor, '&:hover': {
                                backgroundColor: Colors.fbColor,
                            },
                            textTransform: 'capitalize',
                            paddingLeft: 2,
                            width: '250px'
                        }}
                    >
                        <FaFacebookF color='white' size={'20px'} />
                        <Typography
                            width={'250px'}
                            fontSize={'12px'}
                            fontFamily={"Roboto"}
                            color={'white'}
                            textAlign={'left'}
                            marginLeft={1}
                        >Sign in with Facebook</Typography>
                    </Button>
                    <Button
                        variant='outlined'
                        id="basic-button"
                        aria-haspopup="true"
                        sx={{
                            marginTop: '15px',
                            backgroundColor: Colors.white,
                            '&:hover': {
                                backgroundColor: Colors.white,
                                borderColor: Colors.borderColor,
                            },
                            textTransform: 'capitalize',
                            paddingLeft: 2,
                            width: '250px',
                            borderColor: Colors.borderColor,
                            marginLeft: { xs: '0px', md: '10px' }
                        }}
                    >
                        <FcGoogle color='white' size={'20px'} />
                        <Typography
                            width={'250px'}
                            fontSize={'12px'}
                            fontFamily={"Roboto"}
                            color={Colors.black}
                            textAlign={'left'}
                            marginLeft={1}
                        >Sign in with Gmail</Typography>
                    </Button>
                </Grid> */}

            </Grid>
            {/* <Footer /> */}
            <ToastContainer />
        </div >
    )
}

export default SignUp