import { Box, Button, CircularProgress, Divider, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import HeaderUser from '../components/HeaderUser'
import { GoHome } from 'react-icons/go';
import { FaFacebookF } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { Colors } from '../constants/colors';
import Footer from '../components/Footer';
import OtpInput from 'react-otp-input';
import { resendOTP, verificationOTP } from '../apis';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OTPView = () => {
    const { state } = useLocation();

    const [otp, setOtp] = useState('');
    const [countdown, setCountdown] = useState(60);
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [loader, setLoader] = useState(false);


    useEffect(() => {
        let timer;

        if (countdown > 0 && isResendDisabled) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else {
            setIsResendDisabled(false);
        }

        return () => clearInterval(timer);
    }, [countdown, isResendDisabled]);

    const navigate = useNavigate();

    const handleOTP = async () => {
        setLoader(true)
        try {
            const res = await verificationOTP(otp)
            if (res?.message == "Invalid OTP") {
                toast.error(res.message, {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                // alert(res?.message)
                setLoader(false)
            } else {
                setLoader(false)
                toast.success(res.message, {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setTimeout(() => {
                    // navigate('/newuserpayment', { state: { token: state.token } })
                    navigate('/login')
                }, 2500);
            }


        } catch (error) {
            console.log("🚀 ~ file: SignUp.jsx:27 ~ handleSignUp ~ error:", error.res)
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            // alert(error?.response?.data?.message)
            setLoader(false)
        }
    }
    const handleResendOTP = async () => {
        try {
            const res = await resendOTP(state.token)
            setCountdown(60);
            setIsResendDisabled(true);
            console.log("🚀 ~ file: SignUp.jsx:22 ~ handleSignUp ~ res:", res)
            if (res) {
                toast.success(res.message, {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                // alert(res.message)
            }
        } catch (error) {
            console.log("🚀 ~ file: SignUp.jsx:27 ~ handleSignUp ~ error:", error.res)
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            // alert(error?.response?.data?.message)
        }
    }
    return (
        <div>
            <HeaderUser />
            <Stack
                direction={'row'}
                display={{ xs: 'none', md: 'flex' }}
                alignItems={'center'}
                spacing={1}
                px={18}
                py={1}
            >
                <GoHome size={'16px'} />
                <Typography sx={{ fontSize: '12px', fontWeight: '300' }}>
                    Home / Sign Up
                </Typography>
            </Stack>
            <Divider />

            <Grid container px={{ xs: 2, md: 18 }} sx={{ marginTop: '10px', justifyContent: 'space-between' }}>
                <Grid item xs={12} md={12} mt={4}>
                    <Typography
                        sx={{ color: 'black', fontWeight: '300', fontSize: '36px', }} >
                        Enter
                        <span style={{ color: Colors.Primary, fontWeight: '700' }}> OTP</span>
                    </Typography>
                    <Typography sx={{ fontSize: '14px' }} >We've sent the code to your Phone Number please enter the code</Typography>

                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        // onChange={(e) => console.log(e)}
                        numInputs={6}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={{
                            backgroundColor: otp.length < 1 ? '#F5F5F5' : Colors.Primary,
                            marginRight: 14,
                            border: 'none',
                            width: "50px",
                            height: '50px',
                            borderRadius: '5px',
                            color: otp.length < 1 ? Colors.black : Colors.white,
                            fontSize: '18px',
                            fontFamily: 'Roboto',
                            fontWeight: '700',
                            outlineWidth: 0
                        }}
                        containerStyle={{ marginTop: 5 }}
                    />

                    <Typography fontSize={'12px'} fontWeight={'300'} fontFamily={"Roboto"} color={'#333333'} mt={5} >
                        Code expires in :
                        <span style={{
                            marginLeft: '5px',
                            color: Colors.Primary, textDecorationLine: 'underline',
                            cursor: 'pointer',
                            fontWeight: '500'
                        }}
                        >
                            00 : {countdown < 10 ? `0${countdown}` : countdown}
                        </span>
                    </Typography>

                    <Typography fontSize={'12px'} fontWeight={'300'} fontFamily={"Roboto"} color={'#333333'} mt={'10px'} >

                        Didn’t receive code?
                        <Button
                            onClick={handleResendOTP}
                            disabled={isResendDisabled}
                        >
                            <span style={{
                                marginLeft: '5px',
                                color: Colors.Primary, textDecorationLine: 'underline',
                                cursor: 'pointer',
                                fontWeight: '500',
                                textTransform: 'capitalize'
                            }}
                            >
                                Resend Code
                            </span>
                        </Button>
                    </Typography>
                    <Button
                        disabled={otp.length < 6 ? true : false}
                        onClick={loader ? '' : handleOTP}
                        id="basic-button"
                        aria-haspopup="true"
                        sx={{
                            marginTop: '15px',
                            backgroundColor: otp.length < 6 ? 'rgba(204, 7, 26, 0.2)' : loader ? "white" : Colors.Primary, '&:hover': {
                                backgroundColor: loader ? "white" : Colors.Primary,
                            },
                            textTransform: 'capitalize',
                            width: { xs: '100%', md: 100 }
                        }}
                    >
                        {loader ? <CircularProgress size={12} color={'warning'} /> : <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Submit</Typography>}
                    </Button>

                    <Divider sx={{ marginTop: 5 }} />
                </Grid>
                <Grid item xs={12} md={12} >
                    <Typography pt={3} >Sign up via social media</Typography>
                    <Button
                        id="basic-button"
                        aria-haspopup="true"
                        sx={{
                            marginTop: '15px',
                            backgroundColor: Colors.fbColor, '&:hover': {
                                backgroundColor: Colors.fbColor,
                            },
                            textTransform: 'capitalize',
                            paddingLeft: 2,
                            width: '250px'
                        }}
                    >
                        <FaFacebookF color='white' size={'20px'} />
                        <Typography
                            width={'250px'}
                            fontSize={'12px'}
                            fontFamily={"Roboto"}
                            color={'white'}
                            textAlign={'left'}
                            marginLeft={1}
                        >Sign in with Facebook</Typography>
                    </Button>
                    <Button
                        variant='outlined'
                        id="basic-button"
                        aria-haspopup="true"
                        sx={{
                            marginTop: '15px',
                            backgroundColor: Colors.white, '&:hover': {
                                backgroundColor: Colors.white,
                            },
                            textTransform: 'capitalize',
                            paddingLeft: 2,
                            width: '250px',
                            borderColor: Colors.borderColor, // Set the border color to black
                            '&:hover': {
                                borderColor: Colors.borderColor, // Change the border color on hover
                            },
                            marginLeft: { xs: '0px', md: '10px' }
                        }}
                    >
                        <FcGoogle color='white' size={'20px'} />
                        <Typography
                            width={'250px'}
                            fontSize={'12px'}
                            fontFamily={"Roboto"}
                            color={Colors.black}
                            textAlign={'left'}
                            marginLeft={1}
                        >Sign in with Gmail</Typography>
                    </Button>
                </Grid>
                <Divider sx={{ display: { xs: 'none', md: 'block' }, width: '100%', marginTop: 5 }} orientation="horizontal" variant="middle" flexItem />
                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'#333333'} mt={'10px'} >
                    Dont’t have an Account?
                    <span style={{
                        marginLeft: '5px',
                        color: Colors.Primary, textDecorationLine: 'underline',
                        cursor: 'pointer',
                    }}
                    >
                        Sign Up
                    </span>
                </Typography>
            </Grid>
            <Footer />
            <ToastContainer />
        </div >
    )
}

export default OTPView