import { Typography, Button, Box, LinearProgress, Menu, MenuItem, Modal, CircularProgress, Divider, Grid, } from '@mui/material'
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { AllFavorite, DeleteFvrt } from '../apis/AuctionApis'
import CountdownTimer, { commaSeparated, DateDifference } from '../constants/GlobalConstants'
import { FiRefreshCcw } from 'react-icons/fi';
import { HiOutlineDotsVertical } from "react-icons/hi";
import { CarDetailFunc } from './CarDetail'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Colors } from '../constants/colors'
import { RxCross2 } from 'react-icons/rx'

const stylePaymentModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 350, md: 400 },
    bgcolor: 'white',
    border: 'white',
    boxShadow: 24,
    p: { xs: 2, md: 4 },
    borderRadius: '10px'
};

const MyFavorite = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [fvrtData, setFvrtData] = useState(null)
    const [date, setDate] = useState(new Date())
    const [open, setOpen] = useState(false)
    const [detailOpen, setDetailOpen] = useState(false)
    const [show, setShow] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [detail, setDetail] = useState({})
    const [urlArray, setUrlArray] = useState()
    const [loading, setLoading] = useState(null)
    const [bidStatus, setBidStatus] = useState(false);
    const [spinner, setSpinner] = useState(false)
    const [deletedId, setDeletedId] = useState(null)
    const menuOpen = Boolean(anchorEl);
    const handleClick = (event, rowData) => {
        setDeletedId(rowData.row.auctionId)
        setDetail(rowData.row.action)
        setAnchorEl(event.currentTarget);
    };

    const handleDeletedModal = () => {
        handleClose()
        setDeleteModal(true)
        // setDeletedId(row.id)
    }

    const handleClose = () => {
        setAnchorEl(null);
        setShow(false)
    };

    useEffect(() => {
        if (bidStatus) {
            toast.success('Your bid has been submitted successfully', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            setBidStatus(false)
            setOpen(false)
        }
    }, [bidStatus]);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <FiRefreshCcw style={{ fontSize: "20px", cursor: "pointer", paddingRight: "15px" }}
                // onClick={() => handleInvoicesList()}
                />
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        );
    }

    useEffect(() => {
        handleAllFvrt()
    }, [])


    const handleAllFvrt = async () => {
        setLoading(true)
        try {
            const res = await AllFavorite()
            setFvrtData(res?.data?.map((item) => {
                return {
                    id: item?._id,
                    auctionId: item?.auction?.ID,
                    lot: item?.auction?.LOT,
                    location: item?.auction?.AUCTION,
                    rate: item?.auction?.RATE,
                    modelName: item?.auction?.MARKA_NAME + ' ' + item?.auction?.MODEL_NAME,
                    date: moment(item.auction?.AUCTION_DATE).format('ll'),
                    action: item.auction,
                    images: item.auction.IMAGES
                }
            })
            )
            setLoading(false)
        } catch (error) {
            console.log("🚀 ~ handleAllFvrt ~ error:", error)
            setLoading(false)
        }
    }

    const handleDeleteFvrt = async () => {
        setSpinner(true)
        try {
            const res = await DeleteFvrt(deletedId)
            toast.success(res?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setSpinner(false)
            handleAllFvrt()
            setDeleteModal(false)
        } catch (error) {
            console.log("🚀 ~ handleDeleteFvrt ~ error:", error)
            setSpinner(false)
            setDeleteModal(false)
        }
    }

    const columns = [
        {
            field: 'lot',
            headerName: 'Lot #.',
            // flex: 1,
            width: 150,
            renderCell: (params) => (
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    <Typography
                        onClick={(event) => {
                            setDetail(params.row.action)
                            const urlString = params.row.images;
                            const urlParts = urlString.split('#');
                            const urlRegex = /(https?:\/\/[^\s]+)/g;
                            const urls = urlParts.flatMap(part => part.match(urlRegex));

                            if (urls) {
                                setUrlArray(urls);
                            }
                            setDetailOpen(true)
                        }}
                        alignItems={'center'} sx={{ color: Colors.Primary, cursor: 'pointer', p: '5px', borderRadius: '5px', '&:hover': { textDecorationLine: 'underline' } }}>
                        {`${params?.row?.lot}`}
                    </Typography>
                </Box>
            )
        },

        {
            field: 'auctionHouse',
            headerName: 'Auction House',
            // flex: 1,
            width: 180,
            renderCell: (params) => (
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        {params?.row?.location}
                    </Typography>
                </Box>
            )
        },
        {
            field: 'modelName',
            headerName: 'Make/Model',
            // flex: 1,
            width: 180,
            renderCell: (params) => (
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        {params?.row?.modelName}
                    </Typography>
                </Box>
            )
        },
        {
            field: 'date',
            headerName: 'Auction Date',
            // flex: 1,
            width: 180,
            sortable: false,
            renderCell: (params) => (
                <Box display={'flex'} gap={0.5} alignItems={'center'}>
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        {params.row.date}
                    </Typography>
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        <CountdownTimer targetTime={params.row.date} />
                    </Typography>
                </Box>
            ),
        },

        {
            field: 'auctionStatus',
            headerName: 'Status',
            // flex: 1,
            width: 180,
            sortable: false,
            renderCell: (params) => {
                const today = new Date();
                const givenDate = new Date(moment(params.row.date).format("YYYY-MM-DD"))
                const activeStatus = today < givenDate;
                return (
                    <Box display={'flex'} gap={0.5} alignItems={'center'}>
                        {activeStatus ?
                            <Typography sx={{ fontWeight: '500', fontSize: '14px', backgroundColor: 'red', px: '5px', borderRadius: '5px', color: 'white' }}>
                                Active
                            </Typography>
                            :
                            <Typography sx={{ fontWeight: '500', fontSize: '14px', backgroundColor: 'lightgrey', px: '5px', borderRadius: '5px', color: 'darkgrey' }}>
                                Expire
                            </Typography>
                        }
                    </Box>
                )
            },
        },


        {
            field: 'action',
            headerName: 'Action',
            // type: 'number',
            width: 100,
            editable: false,
            sortable: false,
            renderCell: (rowData) => (
                <>
                    <HiOutlineDotsVertical onClick={(event) => {
                        const today = new Date();
                        const givenDate = new Date(moment(rowData.row.date).format("YYYY-MM-DD"))
                        if (today < givenDate) {
                            setShow(true);
                        }
                        console.log("Clicked:179", rowData);
                        handleClick(event, rowData)
                    }} style={{ width: 15, height: 15, cursor: 'pointer' }} />
                    <Menu
                        sx={{
                            boxShadow: 'none', '& .MuiPaper-elevation': {
                                boxShadow: '.2px .2px .2px .2px'
                            }
                        }}
                        id={rowData.id}
                        anchorEl={anchorEl}
                        open={menuOpen}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {show &&
                            <MenuItem
                                onClick={() => {
                                    const urlString = rowData.row.images;
                                    const urlParts = urlString.split('#');
                                    const urlRegex = /(https?:\/\/[^\s]+)/g;
                                    const urls = urlParts.flatMap(part => part.match(urlRegex));

                                    if (urls) {
                                        setUrlArray(urls);
                                    }
                                    setOpen(true)
                                    handleClose()
                                }} >
                                Bid
                            </MenuItem>
                        }
                        <MenuItem
                            onClick={handleDeletedModal}
                        > Delete </MenuItem>
                    </Menu>
                </>
            ),
        },
    ];

    return (
        <div>
            <Box p={2} borderRadius={'5px'}>
                <Box sx={{ height: 'auto', width: '100%', marginLeft: { xs: 0, md: -5 } }}>
                    <Box sx={{ height: { xs: '600px', md: '500px' }, width: '100%' }}>
                        <DataGrid
                            rows={fvrtData || []}
                            columns={columns.map((column) => ({
                                ...column,
                                cellClassName: 'custom-cell-class',
                            }))}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            disableRowSelectionOnClick
                            slots={{
                                loadingOverlay: LinearProgress,
                                toolbar: CustomToolbar,
                            }}
                            loading={loading}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                },
                            }}
                        />
                    </Box>
                </Box>
            </Box>

            <div>
                <Modal
                    open={deleteModal}
                    onClose={() => { setDeleteModal(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={stylePaymentModal}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={() => { setDeleteModal(false) }} style={{ cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Grid container md={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={12}>
                                    <Typography sx={{ textAlign: 'center', marginBottom: 5 }}>Are you sure you want to delete?</Typography>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        {spinner ?
                                            <Box sx={{ width: '90%', display: 'flex', justifyContent: 'center', height: 50, alignItems: 'center' }} >
                                                <CircularProgress size={26} color={'warning'} />
                                            </Box>
                                            :
                                            <Button
                                                onClick={handleDeleteFvrt}
                                                id="basic-button"
                                                sx={{
                                                    backgroundColor: '#232628', '&:hover': {
                                                        backgroundColor: '#232628',
                                                    },
                                                    width: '90%',
                                                    my: 1
                                                }}
                                            >
                                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Yes</Typography>
                                            </Button>
                                        }
                                        <Button
                                            onClick={() => { setDeleteModal(false) }}
                                            id="basic-button"
                                            sx={{
                                                backgroundColor: Colors.Primary, '&:hover': {
                                                    backgroundColor: Colors.Primary
                                                },
                                                width: '90%',
                                                my: 1
                                            }}
                                        >
                                            <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >No</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    </Box>
                </Modal>
            </div>
            {open && (
                <CarDetailFunc detail={detail} open={open} setOpen={setOpen} urlArray={urlArray} setBidStatus={setBidStatus} />
            )}

            {(detailOpen && urlArray.length > 0 && detail) && (
                <CarDetailFunc adminNavigation={true} detail={detail} open={detailOpen} setOpen={setDetailOpen} urlArray={urlArray} />
            )}
            <ToastContainer />
        </div>
    )
}

export default MyFavorite