import { AppBar, Avatar, Box, Button, Container, Drawer, IconButton, List, ListItem, ListItemText, Menu, MenuItem, OutlinedInput, Stack, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import { appImages } from '../assets/images';
import { BiMenu } from 'react-icons/bi';
import { BsPhone } from 'react-icons/bs';
import { HiOutlineChatBubbleLeft } from 'react-icons/hi2';
import { TbWorld } from 'react-icons/tb';
import { LiaQuestionSolid } from 'react-icons/lia';
import { Colors } from '../constants/colors';
import { Link, useNavigate } from 'react-router-dom';
import { userDetails } from '../apis';
import { motion } from "framer-motion"

const HeaderUser = (props) => {
    const matches = useMediaQuery('(max-width:820px)');

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const open = Boolean(anchorEl);

    const navigate = useNavigate()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };


    return (
        <>
            {matches ? (
                <>
                    <AppBar position="fixed">
                        <Toolbar sx={{ backgroundColor: Colors.white, justifyContent: 'space-between' }}>
                            {matches && (
                                <IconButton
                                    sx={{ width: '50px' }}
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerToggle}
                                    edge="start"
                                >
                                    <BiMenu color={Colors.Primary} />
                                </IconButton>
                            )}

                            <Avatar
                                variant="square"
                                src={appImages.kmLogo1}
                                sx={{
                                    width: 50,
                                    height: 50,
                                    '& .MuiAvatar-img': { objectFit: 'contain' },
                                    alignSelf: 'center'
                                }} />
                            <Box sx={{ width: '50px' }}>

                            </Box>
                        </Toolbar>
                    </AppBar>

                    <Drawer open={drawerOpen} onClose={handleDrawerToggle}>
                        <List sx={{ width: '50vw' }}>
                            <ListItem button onClick={handleDrawerToggle}>
                                <ListItemText primary="Stock" />
                            </ListItem>
                            <ListItem button onClick={handleDrawerToggle}>
                                <ListItemText primary="Auction" />
                            </ListItem>
                            <ListItem button onClick={handleDrawerToggle}>
                                <ListItemText primary="About Us" />
                            </ListItem>
                            <ListItem button onClick={handleDrawerToggle}>
                                <ListItemText primary="How To Buy?" />
                            </ListItem>
                            <ListItem button onClick={handleDrawerToggle}>
                                <ListItemText primary="Contact Us" />
                            </ListItem>
                            <ListItem button onClick={handleDrawerToggle}>
                                <Stack width={'100%'} direction={'row'} alignItems={'center'} spacing={1}>
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={() => navigate('/login')}
                                        sx={{
                                            backgroundColor: '#232628', '&:hover': {
                                                backgroundColor: '#232628', // Change the text color when hovered over
                                                // You can add other styles here for the hover state
                                            },
                                            width: '100%'
                                        }}
                                    >
                                        <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Login</Typography>
                                    </Button>
                                    {/* <Button
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={() => navigate('/signup')}
                                        sx={{
                                            backgroundColor: Colors.Primary, '&:hover': {
                                                backgroundColor: Colors.Primary
                                            },
                                            width: '50%'
                                        }}
                                    >
                                        <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Sign up</Typography>
                                    </Button> */}
                                </Stack>
                            </ListItem>
                            <ListItem button onClick={handleDrawerToggle}>
                                <Button
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={() => navigate('/adminlogin')}
                                    sx={{
                                        backgroundColor: Colors.Primary, '&:hover': {
                                            backgroundColor: Colors.Primary, // Change the text color when hovered over
                                            // You can add other styles here for the hover state
                                        },
                                        width: '100%'
                                    }}
                                >
                                    <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Admin Login</Typography>
                                </Button>
                            </ListItem>
                        </List>
                    </Drawer>
                </>
            ) :
                <>
                    {/* <Box sx={{ backgroundColor: '#F2F2F2', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} py={1} px={{ xs: 2, md: 18 }} >
                        <Box gap={5} sx={{ backgroundColor: '#F2F2F2', display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ backgroundColor: '#F2F2F2', display: 'flex', alignItems: 'center' }}>
                                <BsPhone color={Colors.Primary} />
                                <Typography sx={{ fontSize: '12px', marginLeft: '5px', fontFamily: 'Roboto', fontWeight: '300' }} >+81-3-1234-6789</Typography>
                            </Box>
                        </Box>
                        <Box gap={3} sx={{ backgroundColor: '#F2F2F2', display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ backgroundColor: '#F2F2F2', display: 'flex', alignItems: 'center' }}>
                                <LiaQuestionSolid color={Colors.Primary} />
                                <Typography sx={{ fontSize: '12px', marginLeft: '5px', fontFamily: 'Roboto', fontWeight: '300' }} >Help</Typography>
                            </Box>
                        </Box>
                    </Box> */}

                    <Stack borderBottom={'2px solid #37a647'} px={{ xs: 2, md: 10 }}>
                        {/* <Container> */}
                            <Stack
                                height={'65px'}
                                direction={"row"}
                                display={'flex'}
                                justifyContent={localStorage.getItem('AUTH_TOKEN') ? '' : 'space-between'}
                                alignItems={"center"}
                            >
                                <Link
                                to={'/'}
                                >
                                <Avatar
                                    variant="square"
                                    src={appImages.kmLogo1}
                                    sx={{
                                        width: 100,
                                        height: 100,
                                        '& .MuiAvatar-img': { objectFit: 'contain' },
                                        cursor:'pointer'
                                    }} />
                                    </Link>
                                {!localStorage.getItem("AUTH_TOKEN") &&
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'right'} spacing={1} width={'50%'} >
                                        {!props.admin ?
                                            <Stack direction={{ md: 'row', xs: 'column' }} display={{ md: 'flex', xs: 'block' }} justifyContent={'space-between'} alignItems={"center"} sx={{ width: '50%', padding: '10px' }} marginLeft={localStorage.getItem('AUTH_TOKEN') ? '14%' : ''} >
                                                <Typography sx={{
                                                    '&:hover': {
                                                        cursor: 'pointer', // Change the text color when hovered over
                                                        // You can add other styles here for the hover state
                                                    },
                                                }}>Home</Typography>
                                                <Typography sx={{
                                                    '&:hover': {
                                                        cursor: 'pointer', // Change the text color when hovered over
                                                        // You can add other styles here for the hover state
                                                    },
                                                }}>How it works</Typography>
                                                <Typography sx={{
                                                    '&:hover': {
                                                        cursor: 'pointer', // Change the text color when hovered over
                                                        // You can add other styles here for the hover state
                                                    },
                                                }}>Contact Us</Typography>
                                            </Stack>
                                            :
                                            ""
                                        }

                                        <motion.div whileHover={{ scale: 1.2 }}>
                                            <Button
                                                id="basic-button"
                                                aria-controls={open ? 'basic-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                                onClick={() => navigate('/adminlogin')}
                                                sx={{
                                                    backgroundColor: Colors.Primary, '&:hover': {
                                                        backgroundColor: Colors.Primary, // Change the text color when hovered over
                                                        // You can add other styles here for the hover state
                                                    }
                                                }}
                                            >
                                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Admin Login</Typography>
                                            </Button>
                                        </motion.div>
                                    </Stack>
                                }
                            </Stack>
                        {/* </Container> */}
                    </Stack>
                </>
            }
        </>
    )
}

export default HeaderUser