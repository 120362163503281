import { Box, Typography, Grid, Container } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Colors } from '../constants/colors'

const Footer = () => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                borderTop: 2,
                borderTopColor: Colors.Primary,
                backgroundColor: '#F2F2F2',
                py: 1.5,
                marginTop:{xs:"10px", md:"30px"}
            }}>
            <Container>
                <Grid container>
                    <Grid item md={6} sm={6} xs={6}>
                        <Typography sx={{ fontWeight: 300 }}>
                            @ Copyright 2024-2025 kashmirmotors.com
                        </Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={6}>
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Typography onClick={() => navigate('/term-and-conditions')} sx={{ fontWeight: 300, paddingRight: "25px", textDecoration: "underline", cursor: "pointer" }}>
                                Term & Conditions
                            </Typography>
                            <Typography onClick={() => navigate('/privacy-policy')} sx={{ fontWeight: 300, textDecoration: "underline", cursor: "pointer" }}>
                                Privacy Policy
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Footer