import { Avatar, Box, Button, Card, CardContent, CardMedia, Container, Divider, Grid, OutlinedInput, TextareaAutosize, Typography } from '@mui/material'
import React from 'react'
import HeaderUser from '../components/HeaderUser'
import Footer from '../components/Footer';
import { appImages } from '../assets/images';
import { Colors } from '../constants/colors';
import { BiYen } from 'react-icons/bi'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { contactUsValidationSchema } from '../constants/formikValidation';
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom';

const HomeView = () => {
    const navigate = useNavigate()

    const data = [
        {
            img: appImages.homeBack,
            heading: 'MAZDA DEMIO 2013/7',
            description: 'Car servicing is the regular maintenance and inspection of a vehicle to ensure.',
            km: '117,141km',
            date: '2013/7',
            type: 'AUTOMATIC'
        },
        {
            img: appImages.homeBack,
            heading: 'MAZDA DEMIO 2013/7',
            description: 'Car servicing is the regular maintenance and inspection of a vehicle to ensure.',
            km: '117,141km',
            date: '2013/7',
            type: 'AUTOMATIC'
        },
        {
            img: appImages.homeBack,
            heading: 'MAZDA DEMIO 2013/7',
            description: 'Car servicing is the regular maintenance and inspection of a vehicle to ensure.',
            km: '117,141km',
            date: '2013/7',
            type: 'AUTOMATIC'
        },
        {
            img: appImages.homeBack,
            heading: 'MAZDA DEMIO 2013/7',
            description: 'Car servicing is the regular maintenance and inspection of a vehicle to ensure.',
            km: '117,141km',
            date: '2013/7',
            type: 'AUTOMATIC'
        }
    ]

    const latestBlog = [
        {
            img: appImages.homeBack,
            heading: 'The Car Enthusiast: Exploring the World of Cars and Driving.',
            title: 'Mr. Morris Mannu',
            date: 'Posted on - 15 Oct, 2023'
        },
        {
            img: appImages.homeBack,
            heading: 'The Car Enthusiast: Exploring the World of Cars and Driving.',
            title: 'Mr. Morris Mannu',
            date: 'Posted on - 15 Oct, 2023'
        },
        {
            img: appImages.homeBack,
            heading: 'The Car Enthusiast: Exploring the World of Cars and Driving.',
            title: 'Mr. Morris Mannu',
            date: 'Posted on - 15 Oct, 2023'
        }
    ]

    const aboutCardsData = [
        {
            key: 1,
            img: appImages.map,
            heading: 'Choose Any where',
            description: 'Car servicing is the regular maintenance and inspection of a vehicle to ensure.',
        },
        {
            key: 2,
            img: appImages.cUS,
            heading: 'Contact With Us',
            description: 'Car servicing is the regular maintenance and inspection of a vehicle to ensure.',
        },
        {
            key: 3,
            img: appImages.pCar,
            heading: 'Pay For The Car',
            description: 'Car servicing is the regular maintenance and inspection of a vehicle to ensure.',
        },
        {
            key: 4,
            img: appImages.rCar,
            heading: 'Recieve The Car',
            description: 'Car servicing is the regular maintenance and inspection of a vehicle to ensure.',
        },
    ];

    const chooseCar = [
        {
            key: 1,
            img: appImages.adorablePrice,
            heading: 'Affordable',
            subHeading: 'Price',
            description: 'An affordable price for a luxury car may be significantly higher than an affordable price for a budget car...',
        },
        {
            key: 2,
            img: appImages.payBack,
            heading: 'Money Back',
            subHeading: 'Guarantee',
            description: 'An affordable price for a luxury car may be significantly higher than an affordable price for a budget car...',
        },
        {
            key: 3,
            img: appImages.CusSatis,
            heading: 'Customer',
            subHeading: 'Satisfaction',
            description: 'An affordable price for a luxury car may be significantly higher than an affordable price for a budget car...',
        },
    ];

    const initialValues = { email: '', phone: '', fName: '', lName: '', message: '' }

    const handleContactUs = (values) => {
        console.log("🚀 ~ file: SignUp.jsx:19 ~ handleSignUp ~ values:", values)
    }

    return (
        <div>
            <HeaderUser />
            <Box>
                <Avatar variant='square' src={appImages.homeBack} sx={{ width: '100%', height: { xs: '370px', md: '520px' }, position: 'relative', marginTop: { xs: 6, md: 0 } }} />
                <Box sx={{ position: 'absolute', left: '0%', right: '50%', top: { xs: '10%', md: '45%' }, bottom: '50%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ alignItems: 'center', marginTop: { xs: '5%', md: '10%' }, width: '100%' }}>
                        <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{ pt: { md: 0, xs: 3 }, pb: { md: 0, xs: 5 } }}>
                            <Grid item xs={12} md={9}>
                                <Box>
                                    <Typography
                                        variant="h1"
                                        sx={{
                                            fontSize: { xs: '1.2rem', sm: '2rem', md: '2.4375rem' },
                                            fontFamily: "Roboto",
                                            fontWeight: 700,
                                            lineHeight: 1.2,
                                            color: 'white',
                                            textAlign: 'center',
                                            marginTop: '-15%'
                                        }}
                                    >
                                        Kashmir Motors Co, LTD
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        sx={{
                                            fontSize: { xs: '1.2rem', sm: '2rem', md: '2.4375rem' },
                                            fontFamily: "Roboto",
                                            fontWeight: 700,
                                            lineHeight: 1.2,
                                            color: 'white',
                                            textAlign: 'center',
                                            // marginTop: '-5%'
                                        }}
                                    >
                                        Japan
                                    </Typography>
                                </Box>
                                <Grid container spacing={0} sx={{ textAlign: 'center' }}>
                                    <Grid item xs={12}>
                                        <motion.div
                                            initial={{ opacity: 0, translateY: 500 }}
                                            animate={{ opacity: 1, translateY: 0 }}
                                            transition={{
                                                type: 'spring',
                                                stiffness: 150,
                                                damping: 30
                                            }}
                                        >
                                            <Typography
                                                variant="h1"
                                                sx={{
                                                    fontSize: { xs: '1.2rem', sm: '2rem', md: '3.4375rem' },
                                                    fontFamily: "Roboto",
                                                    fontWeight: 700,
                                                    lineHeight: 1.2,
                                                    color: 'white',
                                                    marginTop: '5%',
                                                }}
                                            >
                                                <span>Get Your Dream </span>
                                                <Box
                                                    component="span"
                                                    sx={{
                                                        background: 'linear-gradient(90deg, rgb(37, 161, 244), rgb(249, 31, 169), rgb(37, 161, 244)) 0 0 / 400% 100%',
                                                        color: 'transparent',
                                                        WebkitBackgroundClip: 'text',
                                                        backgroundClip: 'text',
                                                        animation: 'move-bg 24s infinite linear',
                                                        '@keyframes move-bg': {
                                                            '100%': {
                                                                backgroundPosition: '400% 0'
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <span>Car </span>
                                                </Box>
                                                <span> Directly From Japan</span>
                                            </Typography>
                                        </motion.div>
                                    </Grid>
                                    <Grid container justifyContent="center" item xs={12}>
                                        <Grid item xs={8}>
                                            <motion.div
                                                initial={{ opacity: 0, translateY: 550 }}
                                                animate={{ opacity: 1, translateY: 0 }}
                                                transition={{
                                                    type: 'spring',
                                                    stiffness: 150,
                                                    damping: 30,
                                                    delay: 0.2
                                                }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    component="div"
                                                    sx={{
                                                        fontSize: { xs: '0.875rem', md: '1rem', lg: '1.5rem' },
                                                        fontFamily: "Roboto",
                                                        fontWeight: 400,
                                                        lineHeight: { xs: 1.4, md: 1.4 },
                                                        color: 'white',
                                                    }}
                                                >
                                                    Hessel free Japanese car auction service.
                                                    quick and reliable
                                                </Typography>
                                            </motion.div>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <motion.div
                                            initial={{ opacity: 0, translateY: 550 }}
                                            animate={{ opacity: 1, translateY: 0 }}
                                            transition={{
                                                type: 'spring',
                                                stiffness: 150,
                                                damping: 30,
                                                delay: 0.4
                                            }}
                                        >
                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item gap={2}>

                                                    {/* <motion.div whileHover={{ scale: 1.2 }}>
                                                        <Button
                                                            id="basic-button"
                                                            // aria-controls={open ? 'basic-menu' : undefined}
                                                            aria-haspopup="true"
                                                            // aria-expanded={open ? 'true' : undefined}
                                                            onClick={() => navigate('/signup')}
                                                            sx={{
                                                                width: '200px',
                                                                backgroundColor: Colors.Primary, '&:hover': {
                                                                    backgroundColor: Colors.Primary
                                                                }
                                                            }}
                                                        >
                                                            <Typography fontSize={'18px'} fontFamily={"Roboto"} color={'white'} >Sign up</Typography>
                                                        </Button>
                                                    </motion.div> */}
                                                    <motion.div whileHover={{ scale: 1.2 }}>
                                                        <Button
                                                            id="basic-button"
                                                            // aria-controls={open ? 'basic-menu' : undefined}
                                                            aria-haspopup="true"
                                                            // aria-expanded={open ? 'true' : undefined}
                                                            onClick={() => navigate('/login')}
                                                            sx={{
                                                                width: '200px',
                                                                marginTop: 1,
                                                                // backgroundColor: '#232628', '&:hover': {
                                                                //     backgroundColor: '#232628', 
                                                                // }
                                                                backgroundColor: Colors.Primary, '&:hover': {
                                                                    backgroundColor: Colors.Primary
                                                                }
                                                            }}
                                                        >
                                                            <Typography fontSize={'18px'} fontFamily={"Roboto"} color={'white'} >Sign In</Typography>
                                                        </Button>
                                                    </motion.div>
                                                </Grid>
                                            </Grid>
                                        </motion.div>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>


                    </Box>

                </Box>
                <Box
                    sx={{
                        // display: 'flex',
                        // position: 'relative',
                        // bottom: { xs: 0, sm: -120 },
                        // marginTop: '15%',
                        left: 0,
                        right: 0,
                        // alignItems: 'center',
                        // justifyContent: 'center',
                        background: "white",
                        // borderTop: `1px solid ${Colors.lightgray}`,
                        // borderBottom: `1px solid ${Colors.lightgray}}`,
                    }}
                >
                    <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        sx={{
                            '& > .MuiGrid-item': {
                                // borderRight: `1px solid ${Colors.lightgray}`,
                                // '&:first-of-type': {
                                //     borderLeft: `1px solid ${Colors.lightgray}`
                                // },
                                '& img': {
                                    // padding: 1.3
                                }
                            }
                        }}
                    >
                        <Grid item>
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 0.3
                                }}
                            >
                                <Avatar
                                    variant="square"
                                    src={appImages.toyota}
                                    sx={{
                                        width: {xs:110, sm: 110, md: 100 },
                                        height: {xs:110, sm: 110, md: 90 },
                                        "& .MuiAvatar-img": { objectFit: "contain" },
                                        alignSelf: "center",
                                    }}
                                />
                            </motion.div>
                        </Grid>
                        <Grid item>
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 0.5
                                }}
                            >
                                <Avatar
                                    variant="square"
                                    src={appImages.honda}
                                    sx={{
                                        width: {xs:110, sm: 110, md: 100 },
                                        height: {xs:110, sm: 110, md: 90 },
                                        "& .MuiAvatar-img": { objectFit: "contain" },
                                        alignSelf: "center",
                                    }}
                                />
                            </motion.div>
                        </Grid>
                        <Grid item>
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 0.6
                                }}
                            >
                                <Avatar
                                    variant="square"
                                    src={appImages.nissan}
                                    sx={{
                                        width: {xs:110, sm: 110, md: 100 },
                                        height: {xs:110, sm: 110, md: 90 },
                                        "& .MuiAvatar-img": { objectFit: "contain" },
                                        alignSelf: "center",
                                    }}
                                />
                            </motion.div>
                        </Grid>
                        <Grid item>
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 0.7
                                }}
                            >
                                <Avatar
                                    variant="square"
                                    src={appImages.lexus}
                                    sx={{
                                        width: {xs:110, sm: 110, md: 100 },
                                        height: {xs:110, sm: 110, md: 90 },
                                        "& .MuiAvatar-img": { objectFit: "contain" },
                                        alignSelf: "center",
                                    }}
                                />
                            </motion.div>
                        </Grid>
                        <Grid item>
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 0.8
                                }}
                            >
                                <Avatar
                                    variant="square"
                                    src={appImages.mitsubishi}
                                    sx={{
                                        width: {xs:110, sm: 110, md: 100 },
                                        height: {xs:110, sm: 110, md: 90 },
                                        "& .MuiAvatar-img": { objectFit: "contain" },
                                        alignSelf: "center",
                                    }}
                                />
                            </motion.div>
                        </Grid>
                        <Grid item>
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 0.9
                                }}
                            >
                                <Avatar
                                    variant="square"
                                    src={appImages.suzuki}
                                    sx={{
                                        width: {xs:110, sm: 110, md: 100 },
                                        height: {xs:110, sm: 110, md: 90 },
                                        "& .MuiAvatar-img": { objectFit: "contain" },
                                        alignSelf: "center",
                                    }}
                                />
                            </motion.div>
                        </Grid>
                        <Grid item >
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 1.0
                                }}
                            >
                                <Avatar
                                    variant="square"
                                    src={appImages.daihatsu}
                                    sx={{
                                        width: {xs:110, sm: 110, md: 100 },
                                        height: {xs:110, sm: 110, md: 90 },
                                        "& .MuiAvatar-img": { objectFit: "contain" },
                                        alignSelf: "center",
                                    }}
                                />
                            </motion.div>
                        </Grid>
                        <Grid item >
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 1.1
                                }}
                            >
                                <Avatar
                                    variant="square"
                                    src={appImages.subaru}
                                    sx={{
                                        width: {xs:110, sm: 110, md: 100 },
                                        height: {xs:110, sm: 110, md: 90 },
                                        "& .MuiAvatar-img": { objectFit: "contain" },
                                        alignSelf: "center",
                                    }}
                                />
                            </motion.div>
                        </Grid>
                        <Grid item>
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 1.2
                                }}
                            >
                                <Avatar
                                    variant="square"
                                    src={appImages.mercedes}
                                    sx={{
                                        width: {xs:110, sm: 110, md: 100 },
                                        height: {xs:110, sm: 110, md: 90 },
                                        "& .MuiAvatar-img": { objectFit: "contain" },
                                        alignSelf: "center",
                                    }}
                                />
                            </motion.div>
                        </Grid>
                        <Grid item >
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 1.3
                                }}
                            >
                                <Avatar
                                    variant="square"
                                    src={appImages.bmw}
                                    sx={{
                                        width: {xs:110, sm: 110, md: 100 },
                                        height: {xs:110, sm: 110, md: 90 },
                                        "& .MuiAvatar-img": { objectFit: "contain" },
                                        alignSelf: "center",
                                    }}
                                />
                            </motion.div>
                        </Grid>
                        <Grid item>
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 1.4
                                }}
                            >
                                <Avatar
                                    variant="square"
                                    src={appImages.audi}
                                    sx={{
                                        width: {xs:110, sm: 110, md: 100 },
                                        height: {xs:110, sm: 110, md: 90 },
                                        "& .MuiAvatar-img": { objectFit: "contain" },
                                        alignSelf: "center",
                                    }}
                                />
                            </motion.div>
                        </Grid>
                        <Grid item>
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 1.5
                                }}
                            >
                                <Avatar
                                    variant="square"
                                    src={appImages.volkswagon}
                                    sx={{
                                        width: {xs:110, sm: 110, md: 100 },
                                        height: {xs:110, sm: 110, md: 90 },
                                        "& .MuiAvatar-img": { objectFit: "contain" },
                                        alignSelf: "center",
                                    }}
                                />
                            </motion.div>
                        </Grid>
                        <Grid item>
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 1.6
                                }}
                            >
                                <Avatar
                                    variant="square"
                                    src={appImages.japan}
                                    sx={{
                                        width: {xs:110, sm: 110, md: 100 },
                                        height: {xs:110, sm: 110, md: 90 },
                                        "& .MuiAvatar-img": { objectFit: "contain" },
                                        alignSelf: "center",
                                    }}
                                />
                            </motion.div>
                        </Grid>

                    </Grid>
                </Box>
            </Box>

            {/* <Grid container px={{ xs: 2, md: 18 }} sx={{ marginTop: '10px', justifyContent: 'space-between', backgroundColor: 'white', my: 5, py: 5 }}>
                <Grid item md={5} >
                    <Avatar variant='square' src={appImages.carImg} sx={{ width: '100%', height: '100%' }} />
                </Grid>
                <Grid item md={6} py={12} gap={2} >
                    <Typography sx={{ fontSize: 28, fontFamily: 'Roboto', color: Colors.Primary, textAlign: 'left' }} >About <span style={{ color: Colors.black }} >Us</span></Typography>
                    <Typography sx={{ fontSize: 16, fontFamily: 'Roboto', fontWeight: '300' }} >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sed est sed orcit elit auctor ullamcorper et imperdiet lectus. Vivamus gravida metus vitae nunc sempe lacinia est pulvinar. Ut sit amet lacus luctus, iaculis turpis sit amet.
                    </Typography>
                    <Typography sx={{ fontSize: 16, fontFamily: 'Roboto', fontWeight: '300', mt: 2 }}>
                        Maecenas eros mi, lacinia eu ultricies vel, elementum et justo. Ut at tortor a odio vestib suscipit non sit amet dolor. Morbi molestie magna nec metus facilisis, at iaculis adipiscing. Praesent ac diam velit.
                    </Typography>
                    <Typography sx={{ fontSize: 16, fontFamily: 'Roboto', fontWeight: '300', mt: 2 }}>
                        Curabitur lacinia tristique velit ut laoreet. Nam pretium id risuse fermentum. Aenean eu euismod justo.
                    </Typography>
                </Grid>
            </Grid> */}
            {/* <Box sx={{ backgroundColor: '#F8F8F8' }}>
                <Grid container px={{ xs: 2, md: 18 }} sx={{ justifyContent: 'space-between', my: 5, py: 5, }}>
                    <Grid item md={12} >
                        <Typography sx={{ fontSize: 42, fontFamily: 'Roboto', color: Colors.black }} >How Its <span style={{ color: Colors.Primary }} >Works?</span></Typography>
                        <Typography sx={{ fontSize: 16, fontFamily: 'Roboto', fontWeight: '300' }} >
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Phasellus sed est sed orcit elit auctor ullamcorper et imperdiet lectus.
                            Vivamus gravida metus vitae nunc sempe lacinia est pulvinar.
                            Ut sit amet lacus luctus, iaculis turpis sit amet.Maecenas eros mi,
                            lacinia eu ultricies vel, elementum et justo.
                            Ut at tortor a odio vestib suscipit non sit amet dolor.
                            Morbi molestie magna nec metus facilisis, at iaculis adipiscing.
                            Praesent ac diam velit.
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ width: { xs: '80%', md: '100%' }, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                    <Divider sx={{ width: '15%' }} />
                    <Box sx={{
                        width: 35,
                        height: 35,
                        border: 1,
                        borderColor: Colors.Primary,
                        borderRadius: '50%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Typography sx={{ textAlign: 'center', margin: '7px', color: Colors.Primary, fontWeight: '300', fontSize: '14px' }}>01</Typography>
                    </Box>
                    <Divider sx={{ width: '15%' }} />
                    <Box sx={{
                        width: 35,
                        height: 35,
                        border: 1,
                        borderColor: Colors.Primary,
                        borderRadius: '50%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Typography sx={{ textAlign: 'center', margin: '7px', color: Colors.Primary, fontWeight: '300', fontSize: '14px' }}>02</Typography>
                    </Box>
                    <Divider sx={{ width: '15%' }} />
                    <Box sx={{
                        width: 35,
                        height: 35,
                        border: 1,
                        borderColor: Colors.Primary,
                        borderRadius: '50%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Typography sx={{ textAlign: 'center', margin: '7px', color: Colors.Primary, fontWeight: '300', fontSize: '14px' }}>03</Typography>
                    </Box>
                    <Divider sx={{ width: '15%' }} />
                    <Box sx={{
                        width: 35,
                        height: 35,
                        border: 1,
                        borderColor: Colors.Primary,
                        borderRadius: '50%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Typography sx={{ textAlign: 'center', margin: '7px', color: Colors.Primary, fontWeight: '300', fontSize: '14px' }}>04</Typography>
                    </Box>
                    <Divider sx={{ width: '15%' }} />
                </Box>
                <Box mt={2} display={'flex'} flexWrap={'wrap'} pl={{ xs: '60px', md: 20 }} justifyContent={'space-between'} width={{ xs: '75%', md: '78%' }}>
                    {aboutCardsData.map((item, index) => {
                        return (
                            <div key={item.key} style={{ maxWidth: 200, borderRadius: 2, marginRight: '10px', textAlign: '-webkit-center' }}>
                                <Box
                                    sx={{
                                        boxShadow: 1,
                                        width: 80,
                                        height: 80,
                                        m: 1,
                                        borderRadius: '50%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: item.key === 1 ? Colors.Primary : 'white',
                                        display: 'flex'
                                    }}>
                                    <Avatar
                                        variant='square'
                                        sx={{ width: 40, height: 40 }}
                                        src={item.img}
                                    />
                                </Box>
                                <CardContent>
                                    <Box >
                                        <Typography gutterBottom sx={{ fontSize: '14px', textAlign: 'center', color: Colors.Primary }} component="div">
                                            {item.heading}
                                        </Typography>
                                    </Box>
                                    <Typography fontWeight={'300'} fontSize={'12px'} color="text.secondary">
                                        {item.description}
                                    </Typography>
                                </CardContent>
                            </div>
                        )
                    }
                    )}
                </Box>
            </Box>


            <Grid
                container
                px={{ xs: 2, md: 18 }}
                sx={{
                    marginTop: '10px',
                    justifyContent: 'space-between',
                    my: 5,
                    py: 5,
                    backgroundColor: 'white'
                }}>
                <Grid item md={12} >
                    <Typography sx={{ fontSize: 42, fontFamily: 'Roboto', color: Colors.black }} >Why Choose <span style={{ color: Colors.Primary }} >KASHMIR MOTORS?</span></Typography>
                    <Typography sx={{ fontSize: 16, fontFamily: 'Roboto', fontWeight: '300' }} >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sed est sed orcit elit auctor ullamcorper et imperdiet lectus. Vivamus gravida metus vitae nunc sempe lacinia est pulvinar. Ut sit amet lacus luctus, iaculis turpis sit amet.
                    </Typography>
                    <Box mt={2} display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'} width={'100%'}>
                        {chooseCar.map((item, index) => {
                            return (
                                <Card
                                    key={item.key}
                                    sx={{
                                        maxWidth: { xs: '100%', md: 300 },
                                        borderRadius: 2,
                                        marginRight: '10px',
                                        my: { xs: 1, md: 0 }
                                    }}>
                                    <Box
                                        sx={{
                                            boxShadow: 1,
                                            width: 80,
                                            height: 80,
                                            m: 2,
                                            borderRadius: '50%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: 'white',
                                            display: 'flex'
                                        }}>
                                        <Avatar
                                            variant='square'
                                            sx={{ width: 40, height: 40 }}
                                            src={item.img}
                                        />
                                    </Box>
                                    <CardContent>
                                        <Box>
                                            <Typography
                                                gutterBottom
                                                sx={{
                                                    fontSize: '14px',
                                                    textAlign: 'left',
                                                    color: Colors.Primary
                                                }}
                                                component="div"
                                            >
                                                {item.heading}<span style={{ marginLeft: '5px', color: Colors.black }}>{item.subHeading}</span>
                                            </Typography>
                                        </Box>
                                        <Typography fontWeight={'300'} fontSize={'12px'} color="text.secondary">
                                            {item.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            )
                        }
                        )}
                    </Box>
                    <Divider sx={{ marginTop: 3 }} />
                    <Box sx={{ alignItems: 'center', display: { xs: 'block', md: 'flex' }, margin: 4, justifyContent: 'space-evenly' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', my: { xs: 2, md: 0 } }}>
                            <Avatar variant='square' sx={{ width: 50, height: 50 }} src={appImages.sold} />
                            <Box ml={'10px'} >
                                <Typography>Business</Typography>
                                <Typography sx={{ color: Colors.Primary }}>6000k+</Typography>
                            </Box>
                        </Box>
                        <Divider sx={{ marginTop: 3, display: { xs: 'block', md: 'none' } }} />
                        <Divider sx={{ marginTop: 3, height: 80, backgroundColor: '#DFDFDF', display: { xs: 'none', md: 'block' } }} orientation={'vertical'} />
                        <Box sx={{ display: 'flex', alignItems: 'center', my: { xs: 2, md: 0 } }}>
                            <Avatar variant='square' sx={{ width: 50, height: 50 }} src={appImages.auction} />
                            <Box ml={'10px'}>
                                <Typography>Auction</Typography>
                                <Typography sx={{ color: Colors.Primary }}>8534</Typography>
                            </Box>
                        </Box>
                        <Divider sx={{ marginTop: 3, display: { xs: 'block', md: 'none' } }} />
                        <Divider sx={{ marginTop: 3, height: 80, backgroundColor: '#DFDFDF', display: { xs: 'none', md: 'block' } }} orientation={'vertical'} />
                        <Box sx={{ display: 'flex', alignItems: 'center', my: { xs: 2, md: 0 } }}>
                            <Avatar variant='square' sx={{ width: 50, height: 50 }} src={appImages.carAvail} />
                            <Box ml={'10px'}>
                                <Typography>Car Available</Typography>
                                <Typography sx={{ color: Colors.Primary }}>450k+</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Divider sx={{ marginTop: 3 }} />
                </Grid>
            </Grid>
            <Grid container px={{ xs: 2, md: 18 }} sx={{ marginTop: '10px', justifyContent: 'space-between', my: 5, py: 5, backgroundColor: '#F8F8F8' }}>
                <Grid item md={12} >
                    <Typography sx={{ fontSize: 42, fontFamily: 'Roboto', color: Colors.black }} >Read Our  <span style={{ color: Colors.Primary }} >Latest Blog</span></Typography>
                    <Typography sx={{ fontSize: 16, fontFamily: 'Roboto', fontWeight: '300' }} >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sed est sed orcit elit auctor ullamcorper et imperdiet lectus. Vivamus gravida metus vitae nunc sempe lacinia est pulvinar. Ut sit amet lacus luctus, iaculis turpis sit amet.
                    </Typography>
                    <Box mt={2} display={'flex'} flexWrap={'wrap'}>
                        {latestBlog.map((item, index) => {
                            return (
                                <Card sx={{ maxWidth: 350, borderRadius: 2, m: '10px' }}>
                                    <CardMedia
                                        sx={{ height: 250, m: 1, borderRadius: 2 }}
                                        image={appImages.homeBack}
                                    />
                                    <CardContent>
                                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                                            <Typography gutterBottom sx={{ fontSize: '14px' }} component="div">
                                                {item.heading}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                    <Divider sx={{ borderStyle: 'dashed', }} />
                                    <Box sx={{ display: 'flex', m: 3 }}>
                                        <Avatar />
                                        <Box sx={{ alignItems: 'center', marginLeft: 1 }}>
                                            <Typography sx={{ fontSize: '16px' }}>{item.title}</Typography>
                                            <Typography sx={{ fontSize: '14px', color: Colors.Primary }}>{item.date}</Typography>
                                        </Box>
                                    </Box>
                                </Card>
                            )
                        }
                        )}
                    </Box>
                </Grid>
            </Grid>
            <Container>
                <Box
                    sx={{
                        backgroundImage: `url(${appImages.wMap})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: '100%',
                        height: 'auto',
                        width: '100%',
                        textAlign: '-webkit-center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '10px'
                    }}
                >
                    <Grid container px={{ xs: 2, md: 18 }} sx={{ marginTop: '10px', justifyContent: 'space-between', my: 1, py: 1 }}>
                        <Grid item md={5} >
                            <Avatar variant='square' src={appImages.contact}
                                sx={{ width: '80%', height: '45%', '.css-1pqm26d-MuiAvatar-img': { objectFit: 'contain' } }} />
                            <Typography sx={{ fontSize: 24, fontFamily: 'Roboto', color: Colors.black, textAlign: 'left' }} >Contact <span style={{ color: Colors.Primary }} >Us</span></Typography>
                            <Typography sx={{ fontSize: '13px', fontFamily: 'Roboto', fontWeight: '300', textAlign: 'left' }} >
                                If you want to submit a print job or request a quote, the more details you can provide, the more efficient the process will be! Here’s some of the information we’ll need;
                            </Typography>
                            <Typography sx={{ fontSize: '13px', fontFamily: 'Roboto', fontWeight: '300', mt: 2, textAlign: 'left' }}>
                                For most common printing jobs, like business cards, postcards, basic documents, posters, plan prints, etc, we’ll need to know the paperweight (in ‘gsm’ or grams per meter), paper type (matt, gloss or silk/satin), whether it’s in color or B&W, the size of the print (which should match your artwork) and whether it’s single or double-sided.
                            </Typography>
                        </Grid>
                        <Grid item md={6} py={12} gap={2} >
                            <Box
                                boxShadow={1}
                                height={'100%'}
                                bgcolor={Colors.white}
                                p={'20px'}
                                marginTop={'-50px'}
                                borderRadius={'5px'}
                                width={{ xs: '100%' }}
                            >
                                <Typography>Quick Inquiry</Typography>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={contactUsValidationSchema}
                                    onSubmit={handleContactUs}
                                >
                                    {({ errors, touched, validateForm, handleSubmit }) => (
                                        <Form>
                                            <Box sx={{ display: { xs: 'block', md: "flex" }, flexDirection: "row", mt: 1 }} >
                                                <Box sx={{ display: { xs: 'block', md: "flex" }, flexDirection: "column", width: { xs: '100%', md: "47%" } }} >
                                                    <Field name="fName">
                                                        {({ field }) => (
                                                            <>
                                                                <Typography sx={{ textAlign: 'left', fontSize: '14px', color: '#6E6E6E' }}>
                                                                    First Name
                                                                </Typography>
                                                                <OutlinedInput
                                                                    {...field}
                                                                    sx={{
                                                                        backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' },
                                                                        marginTop: { xs: 1, md: 0 },
                                                                    }}
                                                                    id="outlined-adornment-weight"
                                                                />
                                                            </>
                                                        )}
                                                    </Field>
                                                    <ErrorMessage name="fName" float={'left'}>
                                                        {(msg) => (
                                                            <Typography variant="caption" color="error" marginRight={'auto'} >
                                                                {msg}
                                                            </Typography>
                                                        )}
                                                    </ErrorMessage>
                                                </Box>

                                                <Box sx={{
                                                    display: "flex", flexDirection: "column",
                                                    width: { xs: '100%', md: '47%' },
                                                    marginLeft: { xs: 0, md: 2 },
                                                    marginTop: { xs: 1, md: 0 },
                                                }} >
                                                    <Field name="lName">
                                                        {({ field }) => (
                                                            <>
                                                                <Typography sx={{ textAlign: 'left', fontSize: '14px', color: '#6E6E6E' }}>
                                                                    Last Name
                                                                </Typography>
                                                                <OutlinedInput
                                                                    {...field}
                                                                    sx={{ backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' }, marginTop: { xs: 1, md: 0 }, }}
                                                                    id="outlined-adornment-weight"
                                                                />
                                                            </>
                                                        )}
                                                    </Field>
                                                    <ErrorMessage name="lName">
                                                        {(msg) => (
                                                            <Typography variant="caption" color="error" marginRight={'auto'}>
                                                                {msg}
                                                            </Typography>
                                                        )}
                                                    </ErrorMessage>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }} >
                                                <Field name="email">
                                                    {({ field }) => (
                                                        <>
                                                            <Typography sx={{ textAlign: 'left', fontSize: '14px', color: '#6E6E6E', mt: 1 }}>
                                                                Email
                                                            </Typography>
                                                            <OutlinedInput
                                                                {...field}
                                                                sx={{
                                                                    backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' },
                                                                    marginTop: { xs: 1, md: 0 },
                                                                }}
                                                                id="outlined-adornment-weight"
                                                            />
                                                        </>
                                                    )}
                                                </Field>
                                                <ErrorMessage name="email">
                                                    {(msg) => (
                                                        <Typography variant="caption" color="error" marginRight={'auto'}>
                                                            {msg}
                                                        </Typography>
                                                    )}
                                                </ErrorMessage>
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }} >
                                                <Field name="phone">
                                                    {({ field }) => (
                                                        <>
                                                            <Typography sx={{ textAlign: 'left', fontSize: '14px', color: '#6E6E6E', mt: 1 }}>
                                                                Phone
                                                            </Typography>
                                                            <OutlinedInput
                                                                {...field}
                                                                sx={{
                                                                    backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' },
                                                                    marginTop: { xs: 1, md: 0 },
                                                                }}
                                                                id="outlined-adornment-weight"
                                                            />
                                                        </>
                                                    )}
                                                </Field>
                                                <ErrorMessage name="phone">
                                                    {(msg) => (
                                                        <Typography variant="caption" color="error" marginRight={'auto'}>
                                                            {msg}
                                                        </Typography>
                                                    )}
                                                </ErrorMessage>
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }} >
                                                <Field name="message">
                                                    {({ field }) => (
                                                        <>
                                                            <Typography sx={{ textAlign: 'left', fontSize: '14px', color: '#6E6E6E', mt: 1 }}>
                                                                Your Message
                                                            </Typography>
                                                            <TextareaAutosize
                                                                {...field}
                                                                sx={{
                                                                    backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' },
                                                                    marginTop: { xs: 1, md: 0 },
                                                                }}
                                                                minRows={5}
                                                                id="outlined-adornment-weight"
                                                            />
                                                        </>
                                                    )}
                                                </Field>
                                                <ErrorMessage name="message">
                                                    {(msg) => (
                                                        <Typography variant="caption" color="error" marginRight={'auto'}>
                                                            {msg}
                                                        </Typography>
                                                    )}
                                                </ErrorMessage>
                                            </Box>
                                            <Button
                                                onClick={handleSubmit}
                                                id="basic-button"
                                                aria-haspopup="true"
                                                sx={{
                                                    my: '15px',
                                                    backgroundColor: Colors.Primary, '&:hover': {
                                                        backgroundColor: Colors.Primary,
                                                    },
                                                    textTransform: 'capitalize',
                                                    width: { xs: '100%', md: 100 }
                                                }}
                                            >
                                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Submit</Typography>
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container> */}
            <Footer />
        </div>
    )
}

export default HomeView