import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Grid, Box, Button, LinearProgress, Typography, Tabs, Tab, Modal, Divider, CircularProgress } from '@mui/material';
import {
    DataGrid,
    GridToolbarQuickFilter,
    GridToolbarContainer,
} from '@mui/x-data-grid';
import { commaSeparated, DateDifference, isWithinJapanTimeRange } from '../constants/GlobalConstants';

// Icons
import { BsFillTriangleFill } from "react-icons/bs";
import { TbTriangleInvertedFilled } from "react-icons/tb";

// Api
import { getCancelBidbyUser, GetUserBidList } from "../apis/AuctionApis"

import { CarDetailFunc } from "./CarDetail"

import moment from "moment"
import japanTime from 'moment-timezone'

//  Toast
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FiRefreshCcw } from "react-icons/fi";
import { RxCross2 } from 'react-icons/rx';
import { Colors } from '../constants/colors';

const stylePaymentModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 350, md: 450 },
    bgcolor: 'white',
    border: 'white',
    boxShadow: 24,
    p: { xs: 2, md: 4 },
    borderRadius: '10px'
};

function UserBid() {
    const [open, setOpen] = useState(false);
    const [bidData, setBidData] = useState([]);
    const [urlArray, setUrlArray] = useState([]);
    const [detail, setDetail] = useState({});
    const [highestBid, setHighestBid] = useState({});
    const [userBid, setUserBid] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [bidStatus, setBidStatus] = useState(false);
    const [value, setValue] = useState(0);
    const [dateChange, setDateChange] = useState(moment().format("YYYY-MM-DD"));
    const [cancelBid, setCancelBid] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [auctionBidID, setAuctionBidID] = useState()

    //  Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const dateChangeRef = useRef(dateChange);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <FiRefreshCcw style={{ fontSize: "20px", cursor: "pointer", paddingRight: "15px" }} onClick={() => allBids()} />
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        );
    }

    const allBids = useCallback(() => {
        (async () => {
            setIsLoading(true)
            const query = "?date=" + dateChangeRef.current + "&page=" + currentPage + "&limit=" + pageSize;
            const response = await GetUserBidList(query);
            setTotalRecords(response.data.totalRecords);
            setBidData(response.data.result)
            setIsLoading(false)
        })();
    }, [currentPage, pageSize, dateChange]);

    useEffect(() => {
        allBids();
    }, [allBids]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            allBids();
        }, 10000);

        return () => clearInterval(intervalId);
    }, [allBids]);

    const handleTabClick = (newDate) => {
        setDateChange(newDate);
        dateChangeRef.current = newDate;
    };

    useEffect(() => {
        if (bidStatus) {
            toast.success('Your bid has been submitted successfully', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            allBids();
            setBidStatus(false)
        }
    }, [bidStatus, allBids]);

    const getRowId = (bidData) => bidData.auction.ID;

    const handleCancelBidModalClose = () => {
        setCancelBid(false)
    }

    const handleCancelBidModalOpen = (data) => {
        setAuctionBidID(data.ID)
        setCancelBid(true)
    }

    const handleCancleUserBid = async () => {
        setSpinner(true)
        try {
            const formData = new FormData()
            formData.append("auctionId", auctionBidID)
            const res = await getCancelBidbyUser(formData)
            console.log("🚀 ~ handleCancleUserBid ~ res:", res)
            toast.success(res.message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            setSpinner(false)
            setCancelBid(false)
        } catch (error) {
            console.log("🚀 ~ handleCancleUserBid ~ error:", error)
            setSpinner(false)
        }

    }

    // const isWithinJapanTimeRange = (auctionDate) => {
    //     const auctionMoment = japanTime(auctionDate).tz('Asia/Tokyo');
    //     const currentMoment = japanTime().tz('Asia/Tokyo');

    //     const isSameDay = currentMoment.isSame(auctionMoment, 'day');

    //     if (isSameDay) {
    //         const startOfAuctionDay = auctionMoment.clone().startOf('day').add(8, 'hours');
    //         const endOfAuctionDay = auctionMoment.clone().startOf('day').add(24, 'hours');

    //         const isWithinTimeRange = currentMoment.isBetween(startOfAuctionDay, endOfAuctionDay, null, '[)');

    //         return isWithinTimeRange;
    //     } else {

    //         return false;
    //     }
    // };

    const columns = [
        {
            field: 'MARKA_NAME',
            headerName: 'Car Name',
            width: 140,
            renderCell: ({ row }) => {
                return (
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        {row?.auction.MARKA_NAME + ' ' + row?.auction.MODEL_NAME + ' ' + row?.auction.YEAR ?? ""}
                    </Typography>
                )
            },
        },
        {
            field: 'LOT',
            headerName: 'Lot No',
            width: 60,
            renderCell: ({ row }) => {
                return (
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        {row?.auction.LOT ?? ""}
                    </Typography>
                )

            },
        },
        {
            field: 'AUCTION',
            headerName: 'Auction',
            width: 90,
            renderCell: ({ row }) => {
                return (
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        {row?.auction.AUCTION ?? ""}
                    </Typography>
                )
            },
        },
        // {
        //     field: 'AUCTION_DATE',
        //     headerName: 'Auction Date',
        //     width: 100,
        //     valueGetter: ({ row }) => {
        //         return moment(row?.auction.AUCTION_DATE).format("DD MMM, YYYY hh:mm A") ?? "";
        //     },
        // },
        {
            field: 'START',
            headerName: 'Start Price',
            width: 100,
            renderCell: ({ row }) => {
                return (
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                        {commaSeparated(row?.auction.START) ?? ""}
                    </Typography>
                )
            },
        },
        {
            field: 'AVG_PRICE',
            headerName: 'Avg. Price',
            width: 100,
            renderCell: ({ row }) => {
                return (
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                        {commaSeparated(row?.auction.AVG_PRICE) ?? ""}
                    </Typography>
                )
            },
        },
        {
            field: 'FINISH',
            headerName: 'End. Price',
            width: 100,
            renderCell: ({ row }) => {
                return (
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                        {commaSeparated(row?.auction.FINISH) ?? ""}
                    </Typography>
                )
            },
        },
        {
            field: 'amount',
            headerName: 'User Bid',
            width: 150,
            renderCell: ({ row }) => {
                let AmountStatus = false;
                if (row.highestBid && row.userBid) {
                    if (row.highestBid?.userId?._id === row.userBid?.userId) {
                        if (row.userBid.amount >= row.highestBid.amount) {
                            AmountStatus = true
                        }
                        else {
                            AmountStatus = false
                        }
                    }
                }
                return (
                    <Box sx={{
                        background: AmountStatus ? " #C9F7EA" : "#F7C9CD",
                        padding: "7px",
                        borderRadius: "7px",
                        textAlign: "center",
                        width: "85%",
                        margin: "0 auto",
                        fontWeight: "bold"
                    }}>
                        {AmountStatus ? (
                            <BsFillTriangleFill size={12} />
                        ) : (
                            <TbTriangleInvertedFilled size={12} />
                        )}
                        <span style={{ paddingLeft: "5px" }}>
                            {commaSeparated(row?.userBid.amount)}
                        </span>
                    </Box>
                );
            },
        },
        {
            field: 'GROUP',
            headerName: 'Group',
            width: 40,
            renderCell: ({ row }) => {
                return (
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        {row?.userBid.bidGroup ?? ""}
                    </Typography>
                )
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 80,
            renderCell: ({ row }) => {
                return (
                    <>
                        {row.auction?.status === 'Pending' && (
                            <Typography sx={{ cursor: 'pointer', fontSize: '12px', backgroundColor: 'rgba(253, 181, 40, 0.2)', py: 0.2, px: 1, borderRadius: '5px', color: '#FDB528' }}>
                                Pending
                            </Typography>
                        )}
                        {row.auction?.status === 'Active' && (
                            <Typography sx={{ cursor: 'pointer', fontSize: '12px', backgroundColor: 'rgba(103, 187, 47, 0.2)', py: 0.2, px: 1, borderRadius: '5px', color: '#67BB2F' }}>
                                Active
                            </Typography>
                        )}
                        {row.auction?.status === "InProgress" && (
                            <Typography sx={{ cursor: 'pointer', fontSize: '12px', backgroundColor: '#D5EBFE', py: 0.2, px: 1, borderRadius: '5px', color: '#56AEF7' }}>
                                In Biding
                            </Typography>
                        )}
                    </>
                );
            },
        },
        {
            field: 'bidStatus',
            headerName: 'Bid Status',
            width: 80,
            renderCell: ({ row }) => {
                return (
                    <Typography sx={{ fontWeight: '500', fontSize: '14px', cursor: 'pointer', backgroundColor: "lightgrey", py: 0.2, px: 1, borderRadius: '5px', color: "grey", textTransform: 'capitalize' }}>
                        {row?.userBid.status ?? ""}
                    </Typography>
                )
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 120,
            renderCell: ({ row }) => {
                const statusVal = DateDifference(row?.auction.AUCTION_DATE)
                console.log("🚀 ~ UserBid ~ statusVal:", statusVal)
                const hideCancelButton = isWithinJapanTimeRange(row?.auction.AUCTION_DATE);
                console.log("🚀 ~ UserBid ~ hideCancelButton:", hideCancelButton)
                return (
                    <>
                        {row.userBid?.status !== 'Rejected' && row.userBid?.status !== 'cancelled' &&
                            <>
                                {statusVal && (
                                    <Button variant="contained"
                                        sx={{
                                            background: "#000",
                                            '&:hover': {
                                                background: "#000",
                                            },
                                        }}
                                        onClick={() => {
                                            const urlString = row?.auction.IMAGES;
                                            const urlParts = urlString.split('#');
                                            const urlRegex = /(https?:\/\/[^\s]+)/g;
                                            const urls = urlParts.flatMap(part => part.match(urlRegex));

                                            if (urls) {
                                                setUrlArray(urls);
                                            }
                                            setHighestBid(row?.highestBid)
                                            setUserBid(row?.userBid)
                                            setDetail(row?.auction)
                                            setOpen(true)
                                        }}
                                    >
                                        Update
                                    </Button>
                                )}
                                {!hideCancelButton && statusVal &&(
                                    <Button variant="contained"
                                        sx={{
                                            background: "red",
                                            '&:hover': {
                                                background: "red",
                                            },
                                            marginLeft: '5px'
                                        }}
                                        onClick={() => { handleCancelBidModalOpen(row.auction) }}
                                    >
                                        Cancel
                                    </Button>
                                )}
                            </>
                        }
                    </>
                );
            },
        },
    ];

    const handlePageChange = (params) => {
        setCurrentPage(params + 1);
    };

    const handlePageSizeChange = (params) => {
        setPageSize(params);
    };

    return (
        <>
            <Grid container marginLeft={{ xs: 0, md: -3 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={12} sm={8} md={4} mb={2}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab label={moment().format('dddd')} sx={{ textTransform: "capitalize" }}
                                    onClick={() => handleTabClick(moment().format('YYYY-MM-DD'))}
                                />
                                <Tab label={moment().add(1, 'days').format('dddd')} sx={{ textTransform: "capitalize" }}
                                    onClick={() => handleTabClick(moment().add(1, 'days').format('YYYY-MM-DD'))}
                                />
                                <Tab label={moment().add(2, 'days').format('dddd')} sx={{ textTransform: "capitalize" }}
                                    onClick={() => handleTabClick(moment().add(2, 'days').format('YYYY-MM-DD'))}
                                />
                            </Tabs>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} boxShadow={1}>
                    <Box sx={{ height: 600, width: '100%', overflowX: 'auto' }}>
                        <DataGrid
                            rows={bidData}
                            columns={columns.map((column) => ({
                                ...column,
                                cellClassName: 'custom-cell-class',
                            }))}
                            pagination
                            pageSize={pageSize}
                            rowsPerPageOptions={[10, 25, 50]}
                            rowCount={totalRecords}
                            paginationMode="server"
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            getRowHeight={() => 'auto'}
                            slots={{
                                loadingOverlay: LinearProgress,
                                toolbar: CustomToolbar,
                            }}
                            loading={isLoading}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                },
                            }}
                            getRowId={getRowId}
                        />
                    </Box>
                </Grid>
            </Grid>

            <div>
                <Modal
                    open={cancelBid}
                    onClose={handleCancelBidModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={stylePaymentModal}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={handleCancelBidModalClose} style={{ cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Grid container md={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={12}>
                                    <Typography sx={{ textAlign: 'center', marginBottom: 5 }}>Are you sure you want to cancel this bid?</Typography>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        {spinner ?
                                            <Box sx={{ width: '90%', display: 'flex', justifyContent: 'center', height: 50, alignItems: 'center' }} >
                                                <CircularProgress size={26} color={'warning'} />
                                            </Box>
                                            :
                                            <Button
                                                onClick={handleCancleUserBid}
                                                id="basic-button"
                                                sx={{
                                                    backgroundColor: '#232628', '&:hover': {
                                                        backgroundColor: '#232628',
                                                    },
                                                    width: '90%',
                                                    my: 1
                                                }}
                                            >
                                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Yes</Typography>
                                            </Button>
                                        }
                                        <Button
                                            onClick={handleCancelBidModalClose}
                                            id="basic-button"
                                            sx={{
                                                backgroundColor: Colors.Primary, '&:hover': {
                                                    backgroundColor: Colors.Primary
                                                },
                                                width: '90%',
                                                my: 1
                                            }}
                                        >
                                            <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >No</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    </Box>
                </Modal>
            </div>
            {(open && urlArray.length > 0 && detail) && (
                <CarDetailFunc detail={detail} open={open} setOpen={setOpen} urlArray={urlArray} navigation="userbidPage" highestBid={highestBid} userBid={userBid} setBidStatus={setBidStatus} />
            )}
            <ToastContainer />
        </>
    )
}

export default UserBid