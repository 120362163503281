import moment from "moment";
import logo from "../../assets/images/kmLogo.png";

const UserPDF = (data) => {
  // Format the date with time in AM/PM format
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  let tableRows = "";
  const currentDate = moment().tz("Asia/Tokyo").format("DD-MM-YYYY hh:mm A");

  let serialNumber = 1;

  data.forEach((item) => {
    console.log(item);
    const date = moment.utc(item.AUCTION_DATE);
    const formattedDate = date.format("Do MMM hh:mma");
    const formattedNumber = numberWithCommas(parseInt(item.bids[0].amount));
    let note = "";
    if (item?.bids[0]?.bidNote) {
      note = item?.bids[0]?.bidNote;
    }
    let group = "";
    if (item.bids[0]?.bidGroup) {
      group = "Yes";
    } else {
      group = "No";
    }
    const limitedStr = note.substring(0, 10);
    tableRows += `
            <tr>
                <td>${serialNumber}</td>
                <td>${formattedDate}</td>
                <td>${item.AUCTION}</td>
                <td>${item.LOT}</td> 
                <td>${item.MARKA_NAME}</td>
                <td>${item.MODEL_NAME}</td>
                <td>${item.YEAR}</td>
                <td>
                    ${item.bids[0].userId.FirstName} ${item.bids[0].userId.LastName} <br/>
                    <span style="font-size:9px!important">${note}</span>
                </td>
                <td>${group}</td>
                <td>¥${formattedNumber}</td>
                <td></td>
            </tr>
        `;
    serialNumber++; // Increment serial number
  });

  return `
    <!DOCTYPE html>
    <html lang="en">

    <head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
        body {
        font-family: Arial, Helvetica, sans-serif;
        margin: 0;
        font-size:10px!important;
        }

        .info-container {
        display: flex;
        justify-content: space-between;
        }

        .box {
        width: 33%;
        }

        .boxDivs {
        padding: 3px 0px;
        }

        table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
        }

        body{
            margin-top:30px;
        }

        .tHeadColor, th{
            background-color: #CC071A;
            color: #fff
        }

        th,
        td {
        border: 1px solid #ccc;
        padding: 10px;
        text-align: left;
        }

        
    </style>
    </head>

    <body>
    <div class="info-container" style="margin-bottom: 30px;">
        <div style="width: 50%;">
        <h1 style="color: #ccc; font-size: 48px">Users Bid List</h1>
        </div>
        <div style="width: 30%">
        <div style="width: 90%; height: 100px">
        <img
        src=${logo}
        alt="Company Logo" width="100%" height="100%">
        </div>
        </div>
    </div>
    <div style="border-bottom: 2px solid #CC071A; padding: 10px 0px; text-align: left; width:100%; bottom: 0">
        <p style="margin: 5px; font-size:small;">
        Publish Date ${currentDate}
        </p>
    </div>
    <table>
        <thead style="background: #CC071A;color: #fff;">
        <tr>
            <th style="text-align: left;">Sr.no</th>
            <th style="text-align: left;">Auction Date</th>
            <th style="text-align: left;">Auction</th>
            <th style="text-align: left;">Lot No</th>
            <th style="text-align: left;">Name</th>
            <th style="text-align: left;">Model</th>
            <th style="text-align: left;">Year</th>
            <th style="text-align: left;">Bider</th>
            <th style="text-align: left;">Grouped</th>
            <th style="text-align: left;">Bid</th>
            <th style="text-align: left;text-decoration: underline;">Result</th>
        </tr>
        </thead>
        <tbody>
        ${tableRows}
        </tbody>
    </table>
    
    
    </body>
    </html>`;
};
export default UserPDF;
