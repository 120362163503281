import { Avatar, Box, Button, Grid, LinearProgress, Typography } from '@mui/material'
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { AiOutlinePrinter } from 'react-icons/ai';
import { FiRefreshCcw } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { getReportData } from '../../apis/adminIndex'
import { Colors } from '../../constants/colors';
import { commaSeparated } from '../../constants/GlobalConstants';
import UserPDF from './UserPDF';
import logo from "../../assets/images/kmLogo.png";

const ViewReport = () => {
    const { state } = useLocation();
    console.log("🚀 ~ ViewReport ~ state:", state)
    const [reportData, setReportData] = useState()
    const [loading, setLoading] = useState(false)
    const [pdfReport, setPdfReport] = useState()

    const componentRef = useRef();

    useEffect(() => {
        handleReportData()
    }, [])

    const handleReportData = async () => {
        setLoading(true)
        try {
            const res = await getReportData(state?.date)
            console.log("🚀 ~ res:", res)
            setPdfReport(res.data)
            setReportData(res?.data?.map((item) => {
                return {
                    id: item._id,
                    auctionDate: moment(item.AUCTION_DATE).format("Do MMMM hh:mma"),
                    auction: item.AUCTION,
                    lot: item.LOT,
                    name: item.bids[0].userId.FirstName + ' ' + item.bids[0].userId.LastName,
                    model: item.MODEL_NAME,
                    modelName: item.MARKA_NAME,
                    year: item.YEAR,
                    bidAmount: commaSeparated(item.bids[0].amount)
                }
            })
            )

            setLoading(false)
        } catch (error) {
            console.log("🚀 ~ error:", error)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (reportData) {
            handlePrint();
        }
    }, [reportData]);


    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <FiRefreshCcw style={{ fontSize: "20px", cursor: "pointer", paddingRight: "15px" }}
                    onClick={() => { }}
                />
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        );
    }

    const columns = [
        {
            field: 'auctionDate',
            headerName: 'Auction Date',
            width: 150,
            editable: false,
            sortable: false,
            valueGetter: (params) =>
                `${params.row.auctionDate}`,
        },
        {
            field: 'auction',
            headerName: 'Auction',
            width: 110,
            editable: false,
            sortable: false,
            valueGetter: (params) =>
                `${params.row.auction}`,
        },
        {
            field: 'lot',
            headerName: 'Lot No',
            width: 80,
            editable: false,
            sortable: false,
            valueGetter: (params) =>
                `${params.row.lot}`,
        },
        {
            field: 'modelName',
            headerName: 'Name',
            width: 80,
            editable: false,
            sortable: false,
            valueGetter: (params) =>
                `${params.row.modelName}`,
        },
        {
            field: 'model',
            headerName: 'Model',
            width: 130,
            editable: false,
            sortable: false,
            valueGetter: (params) =>
                `${params.row.model}`,
        },
        {
            field: 'year',
            headerName: 'Year',
            width: 70,
            editable: false,
            sortable: false,
            valueGetter: (params) =>
                `${params.row.year}`,
        },
        {
            field: 'name',
            headerName: 'Bider',
            width: 150,
            editable: false,
            sortable: false,
            valueGetter: (params) =>
                `${params.row.name}`,
        },
        {
            field: 'group',
            headerName: 'Grouped',
            width: 80,
            editable: false,
            sortable: false,
            renderCell: ({ row }) => (
                <Typography sx={{ fontWeight: '300' }}>No</Typography>
            ),
        },
        {
            field: 'bidAmount',
            headerName: 'Bid',
            width: 100,
            editable: false,
            sortable: false,
            valueGetter: (params) =>
                `${params.row.bidAmount}`,
        },

        {
            field: 'result',
            headerName: 'Result',
            width: 100,
            editable: false,
            sortable: false,
            renderCell: ({ row }) => (
                <Typography></Typography>
            ),
        },
    ];

    // const handlePrint = () => {
    //     const content = UserPDF(pdfReport);
    //     const iframe = document.createElement('iframe');
    //     document.body.appendChild(iframe);
    //     iframe.style.position = 'absolute';
    //     iframe.style.width = '0px';
    //     iframe.style.height = '0px';
    //     iframe.style.border = 'none';
    //     const doc = iframe.contentWindow.document;
    //     doc.open();
    //     doc.write(content);
    //     doc.close();
    //     iframe.contentWindow.focus();
    //     iframe.contentWindow.print();
    //     document.body.removeChild(iframe);
    // };

    const handlePrint = () => {
        const content = UserPDF(pdfReport);
        const iframe = document.createElement('iframe');
        document.body.appendChild(iframe);
        iframe.style.position = 'absolute';
        iframe.style.width = '0px';
        iframe.style.height = '0px';
        iframe.style.border = 'none';

        // Wait for the logo to load before writing the content
        const logoImg = new Image();
        logoImg.onload = () => {
            const doc = iframe.contentWindow.document;
            doc.open();
            doc.write(content);
            doc.close();
            iframe.contentWindow.focus();
            iframe.contentWindow.print();
            document.body.removeChild(iframe);
        };
        logoImg.src = logo; // Ensure logo is fully loaded

        // In case the logo fails to load, you can still proceed with printing the content
    };


    return (
        <>
            {/* <ReactToPrint
                trigger={() => <button>Print</button>}
                content={() => componentRef.current}
            /> */}
            {/* <button onClick={handlePrint}>Print</button> */}
            <Grid container md={12} marginTop={2} justifyContent={'center'}>
                <Grid item xs={11} md={11} p={2} borderRadius={'5px'}>
                    {/* <Button sx={{
                        backgroundColor: Colors.Primary, '&:hover': {
                            backgroundColor: Colors.Primary
                        },
                        color: 'white',
                        width: { xs: '200px', md: '170px' },
                        marginLeft: '2%',
                        height: '50px'
                    }} onClick={handlePrint} >Print Report</Button> */}

                    <Button sx={{
                        backgroundColor: Colors.Primary, '&:hover': {
                            backgroundColor: Colors.Primary
                        },
                        color: 'white',
                        width: { xs: '200px', md: '250px' },
                        marginLeft: '2%',
                        height: '50px'
                    }} onClick={handlePrint} ><AiOutlinePrinter color='white' size={25} style={{ marginRight: '5px' }} /> Print Report</Button>
                    <Box ref={componentRef} sx={{
                        height: { xs: '600px', md: '550px' },
                        width: '100%',
                        padding: '20px'
                    }}>
                        <DataGrid
                            rows={reportData || []}
                            // columns={columns}
                            columns={columns.map((column) => ({
                                ...column,
                                cellClassName: 'custom-cell-class',
                            }))}
                            autoHeight
                            pageSize={reportData ? reportData.length : 10}
                            // initialState={{
                            //     pagination: {
                            //         paginationModel: {
                            //             pageSize: 20,
                            //         },
                            //     },
                            // }}
                            // pageSizeOptions={[20]}
                            // checkboxSelection
                            disableRowSelectionOnClick
                            slots={{
                                loadingOverlay: LinearProgress,
                                toolbar: CustomToolbar,
                            }}
                            loading={loading}
                            // slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                },
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default ViewReport