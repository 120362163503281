import { Avatar, Box, Drawer, Grid, LinearProgress, List, ListItem, ListItemText, Typography, Card, CardHeader, Divider } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { FaFileAlt } from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';
import { RxCross2 } from 'react-icons/rx';
import { Colors } from '../constants/colors';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoDownload } from "react-icons/go";
import { IoMdEye } from "react-icons/io";
import { CarDetailFunc } from './CarDetail';
import ThumbnailSlider from '../components/ThumbnailSlider';
import { getUserTransitData } from '../apis';
import { commaSeparated } from '../constants/GlobalConstants';



const Transit = () => {
    const [transitData, setTransitData] = useState()
    const [loading, setLoading] = useState()
    const [detail, setDetail] = useState({})
    const [open, setOpen] = useState(false);
    const [urlArray, setUrlArray] = useState([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [bidCompleteData, setBidCompleteData] = useState(new Date());
    const [bidCompleteURl, setbidCompleteURl] = useState([]);


    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <FiRefreshCcw style={{ fontSize: "20px", cursor: "pointer", paddingRight: "15px" }}
                    onClick={() => handleTransitList()}
                />
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        );
    }

    const handleDrawerToggle = (row) => {
        const urlString = row?.car;
        const urlParts = urlString.split('#');
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const urls = urlParts.flatMap(part => part.match(urlRegex));

        if (urls) {
            setbidCompleteURl(urls);
        }
        setBidCompleteData(row)
        setDrawerOpen(true);
    };

    const columns = [
        {
            field: 'car',
            headerName: 'Photo',
            width: 70,
            renderCell: (params) => (
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    <Avatar variant='square' sx={{ borderRadius: '5px' }} src={params?.row?.car} />
                </Box>
            ),
        },
        {
            field: 'lotNo',
            headerName: 'Lot No.',
            width: 80,
            renderCell: ({ row }) => (
                <Typography
                    onClick={(event) => {
                        setDetail(row.item)
                        const urlString = row.car;
                        const urlParts = urlString.split('#');
                        const urlRegex = /(https?:\/\/[^\s]+)/g;
                        const urls = urlParts.flatMap(part => part.match(urlRegex));

                        if (urls) {
                            setUrlArray(urls);
                        }
                        setOpen(true)
                    }}
                    alignItems={'center'} sx={{ color: Colors.Primary, cursor: 'pointer', p: '5px', borderRadius: '5px', '&:hover': { textDecorationLine: 'underline' } }}>
                    {`${row?.lotNo}`}
                </Typography>
            ),
        },
        {
            field: 'makeModel',
            headerName: 'Make / Modal',
            width: 120,
            valueGetter: (params) =>
                `${params.row.makeModel}`,
        },
        {
            field: 'eta',
            headerName: 'ETA',
            width: 100,
            valueGetter: (params) =>
                `${params.row.eta}`,
        },
        {
            field: 'etd',
            headerName: 'ETD',
            width: 100,
            valueGetter: (params) =>
                `${params.row.etd}`,
        },
        {
            field: 'fullName',
            headerName: 'Full Name',
            width: 120,
            renderCell: ({ row }) => (
                <Typography
                    alignItems={'center'}
                    sx={{
                        // color: Colors.Primary, 
                        // cursor: 'pointer',
                        p: '5px',
                        borderRadius: '5px',
                        // '&:hover': { textDecorationLine: 'underline' },
                        fontWeight: '300'
                    }}>
                    {`${row?.fullName}`}
                </Typography>
            ),
        },
        {
            field: 'vessel',
            headerName: 'Vessel',
            width: 100,
            valueGetter: (params) =>
                `${params.row.vessel}`,
        },
        {
            field: 'dop',
            headerName: 'Date of Purchase',
            width: 140,
            valueGetter: (params) =>
                `${params.row.dop}`,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 90,
            renderCell: (params) => {
                return (
                    <>
                        {params.row.status === 'Pending' &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#DC8686',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                Pending
                            </Typography>}
                        {params.row.status === "Delivered" &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#9BB8CD',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                Delivered
                            </Typography>
                        }
                        {params.row.status === "En Route" &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#d797b1',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                En Route
                            </Typography>
                        }
                        {params.row.status === "Arrived" &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#7C9D96',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                Arrived
                            </Typography>
                        }
                        {params.row.status === "Shipped" &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#E9B384',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                Shipped
                            </Typography>
                        }
                        {params.row.status === "Cancelled" &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#9f9752',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                Cancelled
                            </Typography>
                        }
                        {params.row.status === "Closed" &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#c4bab6',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                Closed
                            </Typography>
                        }
                    </>
                )
            },
        },

        {
            field: 'pStatus',
            headerName: 'Payment Status',
            width: 100,
            valueGetter: (params) =>
                `${params.row.pStatus}`,
        },
        {
            field: 'edit',
            headerName: 'View',
            width: 50,
            renderCell: ({ row }) => (
                <Box onClick={() => handleDrawerToggle(row)} sx={{ backgroundColor: Colors.Primary, p: 1, borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                    <IoMdEye
                        style={{ cursor: 'pointer' }} size={22} color={'white'} />
                </Box>
            ),
        },
    ];


    useEffect(() => {
        handleTransitList()
    }, [])

    const handleTransitList = async () => {
        setLoading(true)
        try {
            const res = await getUserTransitData()
            setTransitData(res?.data?.map((item) => {
                return {
                    id: item._id,
                    car: item.IMAGES,
                    auctionDate: moment(item.AUCTION_DATE).format('ll'),
                    lotNo: item.LOT,
                    makeModel: item.MARKA_NAME + ' ' + item.MODEL_NAME,
                    eta: moment(item?.ETA).format('ll'),
                    etd: moment(item?.EDT).format('ll'),
                    fullName: item.SoldTo?.FirstName + ' ' + item.SoldTo?.LastName,
                    vessel: item.VessalName,
                    dop: moment(item.ConfirmDate).format('ll'),
                    status: item.DeliveryStatus,
                    pStatus: 'Paid',
                    soldPrice: item?.SoldPrice,
                    note: item.Note,
                    purchaseAt: item.PurchaseAt,
                    item: item
                }
            })
            )
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }

    }

    const handleCloseDrawer = () => {
        setDrawerOpen(false)
    }

    const ListItemStyle = {
        color: Colors.lightgray,
        borderBottom: "1px solid #f3f3f3",
    }

    const headerTypo = {
        fontSize: "1rem",
        fontWeight: 500,
    }

    return (
        <>
            <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer}>
                <List sx={{ width: { xs: '300px', md: '450px' }, height: '100%', overflowX: { xs: 'hidden' }, overflowY: 'scroll', marginBottom: 2 }}>
                    <ListItem>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                            <ListItemText primary="Update Transit Detail" />
                            {/* <Typography sx={{ color: Colors.Primary, fontSize: '20px', fontFamily: 'Roboto' }}>Congratulations! You have won this bid</Typography> */}
                            <RxCross2 style={{ cursor: 'pointer' }} onClick={handleCloseDrawer} />
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ width: '100%' }}>
                            <ThumbnailSlider imageData={bidCompleteURl} />
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box
                            // boxShadow={1}
                            bgcolor={Colors.white}
                            borderRadius={'20px'}
                            width={{ md: '95%' }}
                        >
                            {bidCompleteData?.item?.Files.length > 0 &&
                                <Box sx={{ backgroundColor: 'white', display: 'flex', gap: '10px', overflowX: 'scroll', py: 1 }}>
                                    {console.log("Ksakjdlasdkjj", bidCompleteData)}
                                    {bidCompleteData?.item?.Files?.map((item, index) => (
                                        <Box key={index} sx={{ width: '100px', borderRadius: '5px', border: '0.1px solid grey', position: 'relative' }} >
                                            <Avatar
                                                variant='square'
                                                src={process.env.REACT_APP_API_IMAGE_URL + item.src}
                                                sx={{ backgroundColor: '#f3f3f3', width: '100px', height: '100px', borderRadius: '5px', position: 'relative', '& .MuiAvatar-img': { objectFit: 'contain' } }} >
                                                <FaFileAlt size={44} color={'gray'} />
                                            </Avatar>
                                            <a style={{ color: 'black' }} href={process.env.REACT_APP_API_IMAGE_URL + item.src} target="_blank" download >
                                                <GoDownload
                                                    style={{
                                                        cursor: 'pointer',
                                                        top: '0px',
                                                        right: '0px',
                                                        position: 'absolute'
                                                    }}
                                                    onClick={() => { }} />
                                            </a>
                                        </Box>
                                    ))}
                                </Box>
                            }
                        </Box>
                    </ListItem>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{
                            width: {
                                md: "90%",
                                sm: "100%",
                                xs: "100%"
                            }, margin: "0 auto"
                        }}>
                            <Card>
                                <CardHeader
                                    sx={{
                                        px: 2,
                                        py: 1,
                                        background: Colors.gray
                                    }}
                                    title={<Typography style={headerTypo}>Car Detail</Typography>}
                                />
                                <Divider />
                                <List
                                    w-full="true"
                                    dense
                                    component="div"
                                    role="list"
                                >
                                    <ListItem
                                        role="listitem"
                                        sx={ListItemStyle}
                                    >
                                        <Grid container>
                                            <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Lot No</Typography>
                                            </Grid>
                                            <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{bidCompleteData.lotNo}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    <ListItem
                                        role="listitem"
                                        sx={ListItemStyle}
                                    >
                                        <Grid container>
                                            <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Brand Car</Typography>
                                            </Grid>
                                            <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{bidCompleteData.makeModel}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    <ListItem
                                        role="listitem"
                                        sx={ListItemStyle}
                                    >
                                        <Grid container>
                                            <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>ETA</Typography>
                                            </Grid>
                                            <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{bidCompleteData.eta}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    <ListItem
                                        role="listitem"
                                        sx={ListItemStyle}
                                    >
                                        <Grid container>
                                            <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>ETD</Typography>
                                            </Grid>
                                            <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{bidCompleteData.etd}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    <ListItem
                                        role="listitem"
                                        sx={ListItemStyle}
                                    >
                                        <Grid container>
                                            <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Purchase At</Typography>
                                            </Grid>
                                            <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{commaSeparated(bidCompleteData.purchaseAt)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    <ListItem
                                        role="listitem"
                                        sx={ListItemStyle}
                                    >
                                        <Grid container>
                                            <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Vessel Name</Typography>
                                            </Grid>
                                            <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{bidCompleteData.vessel}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    <ListItem
                                        role="listitem"
                                        sx={ListItemStyle}
                                    >
                                        <Grid container>
                                            <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Note</Typography>
                                            </Grid>
                                            <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{bidCompleteData.note}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    {/* <ListItem
                                        role="listitem"
                                        sx={ListItemStyle}
                                    >
                                        <Grid container>
                                            <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Purchase At</Typography>
                                            </Grid>
                                            <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{commaSeparated(bidCompleteData.purchaseAt)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem> */}
                                </List>
                            </Card>
                        </Box>
                    </Grid>
                    {/* <ListItem>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography>Lot Number:</Typography>
                            <Typography>{bidCompleteData.lotNo}</Typography>
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography>Brand Car:</Typography>
                            <Typography>{bidCompleteData.makeModel}</Typography>
                        </Box>
                    </ListItem>

                    <ListItem >
                        <Box sx={{ width: '100%' }} >
                            <Typography>ETA: {bidCompleteData.eta}</Typography>
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Typography>ETD: {bidCompleteData.etd}</Typography>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ width: '100%' }}>
                            <Typography>Vessel Name : {bidCompleteData.vessel}</Typography>
                        </Box>
                    </ListItem> */}
                </List>
            </Drawer>
            {/* <Box p={2} boxShadow={1} borderRadius={'5px'}> */}
            <Box sx={{ height: { xs: '600px', md: '600px' }, width: '100%', marginLeft: { xs: 0, md: -5 } }}>
                <DataGrid
                    rows={transitData || []}
                    // columns={columns}
                    columns={columns.map((column) => ({
                        ...column,
                        cellClassName: 'custom-cell-class',
                    }))}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 20,
                            },
                        },
                    }}
                    pageSizeOptions={[20]}
                    // checkboxSelection
                    disableRowSelectionOnClick
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar,
                    }}
                    loading={loading}
                    // slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                />
            </Box>
            {/* </Box> */}

            {
                (open && urlArray) && (
                    <CarDetailFunc detail={detail} open={open} setOpen={setOpen} urlArray={urlArray} adminNavigation={true} />
                )
            }
            <ToastContainer />
        </>
    )
}

export default Transit