import React, { useEffect, useState } from 'react'

import { Avatar, Grid, LinearProgress, Typography, Box } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';

import { appImages } from '../../assets/images';
import { getAllAdminDashboardData, getLastWeekData } from '../../apis';

// Chart
import {
    AreaChart, Area, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, LineChart, Line, ResponsiveContainer
} from 'recharts';

// components
import { commaSeparated } from '../../constants/GlobalConstants';
import { Colors } from '../../constants/colors';
import { TbCircleArrowUp, TbCircleArrowDown } from 'react-icons/tb';
import moment from 'moment';
import { CarDetailFunc } from '../CarDetail';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarQuickFilter />
        </GridToolbarContainer>
    );
}

function AdminDashboard() {
    const [data, setData] = useState()
    const [chartData, setChartData] = useState()
    const [monthWiseChart, setMonthWiseChart] = useState()
    const [tableData, setTableData] = useState()
    const [lastWeekData, setLastWeekData] = useState()
    const [lastWeekUsers, setLastWeekUsers] = useState()
    const [isLoading, setIsLoading] = useState()
    const [open, setOpen] = useState(false);
    const [urlArray, setUrlArray] = useState([]);
    const [detail, setDetail] = useState({})

    useEffect(() => {
        handleAllAdminDashboardData()
        handleLastWeekDataData()
    }, [])

    const handleAllAdminDashboardData = async () => {
        setIsLoading(true)
        try {
            const res = await getAllAdminDashboardData()
            setData(res?.data)
            const chartData = [{
                name: 'Arrived',
                amount: res?.data?.transit?.dataOfActuionArrived,
            },
            {
                name: 'Cancelled',
                amount: res?.data?.transit?.dataOfActuionCancelled,
            },
            {
                name: 'En Route',
                amount: res?.data?.transit?.dataOfActuionEnRoute,
            }]
            setChartData(chartData)
            let entries = Object.entries(res.data?.lastMonthSales);
            let data = entries.map(([key, val]) => ({
                name: key,
                amount: val,
            }));
            setMonthWiseChart(data)
            let lastWeekEntries = Object.entries(res.data?.lastWeekUsers);
            let lastWeekData = lastWeekEntries.map(([key, val]) => ({
                name: key,
                amount: val,
            }));
            setLastWeekUsers(lastWeekData)
            setTableData(res?.data?.lastWeekTransactions.map((item) => {
                return {
                    id: item?.userID?._id,
                    fullName: item?.userID?.FirstName + ' ' + item?.userID?.LastName,
                    txnDetail: item?.transactionDetail,
                    type: item?.type,
                    txnAmount: commaSeparated(item.amount)
                }
            }))
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    const handleLastWeekDataData = async () => {
        setIsLoading(true)
        try {
            const res = await getLastWeekData()
            console.log("🚀 ~ handleLastWeekDataData ~ res:", res)
            setLastWeekData(res.data.map((item) => {
                return {
                    id: item?._id,
                    lotNo: item.LOT,
                    auctionHouse: item.AUCTION,
                    makeModel: item.MARKA_NAME + ' ' + item.MODEL_NAME,
                    purchaseDate: moment(item.purchaseDate).format('ll'),
                    purchasePrice: commaSeparated(item.PurchaseAt),
                    item: item
                }
            }))
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    const columns = [
        {
            field: 'type',
            headerName: 'Type',
            sortable: false,
            // flex: 1,
            width: 100,
            renderCell: (params) => (
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    {params?.row?.type !== "OUT" ?
                        <Box sx={{ p: 2, justifyContent: 'center', display: 'flex', alignItems: 'center', borderRadius: '5px' }}>
                            <TbCircleArrowUp color='#39B34A' size={25} />
                        </Box>
                        :
                        <Box sx={{ p: 2, justifyContent: 'center', display: 'flex', alignItems: 'center', borderRadius: '5px' }}>
                            <TbCircleArrowDown color={Colors.Primary} size={25} />
                        </Box>

                    }
                </Box>
            ),
        },
        {
            field: 'fullName',
            headerName: 'User',
            // flex: 1,
            width: 200,
            editable: false,
            renderCell: (params) => (
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    {params?.row?.fullName}
                </Box>
            ),
        },
        {
            field: 'txnDetail',
            headerName: 'TXN Detail',
            width: 220,
            editable: false,
        },
        {
            field: 'txnAmount',
            headerName: 'TXN Amount',
            sortable: false,
            // flex: 1,
            width: 150,
            renderCell: (params) => (
                <Box display={'flex'} gap={2} alignItems={'center'} width={100} justifyContent={'right'}>
                    {params.row.txnAmount}
                </Box>
            ),

        },
    ];

    const lastWeekColumns = [
        {
            field: 'lotNo',
            headerName: 'Lot No.',
            // flex: 1,
            width: 200,
            editable: false,
            renderCell: ({ row }) => (
                <Typography
                    onClick={(event) => {
                        console.log("Select Row:", row);
                        setDetail(row.item)
                        const urlString = row.item.IMAGES;
                        const urlParts = urlString.split('#');
                        const urlRegex = /(https?:\/\/[^\s]+)/g;
                        const urls = urlParts.flatMap(part => part.match(urlRegex));

                        if (urls) {
                            setUrlArray(urls);
                        }
                        setOpen(true)
                    }}
                    alignItems={'center'} sx={{ color: Colors.Primary, cursor: 'pointer', p: '5px', borderRadius: '5px', '&:hover': { textDecorationLine: 'underline' } }}>
                    {`${row?.lotNo}`}
                </Typography>
            ),
        },
        {
            field: 'makeModel',
            headerName: 'Make / Modal',
            width: 200,
            valueGetter: (params) =>
                `${params.row.makeModel}`,
        },
        {
            field: 'purchaseDate',
            headerName: 'Purchased Date',
            width: 200,
            valueGetter: (params) =>
                `${params.row.purchaseDate}`,
        },
        {
            field: 'purchaseAt',
            headerName: 'Purchased Price',
            width: 200,
            valueGetter: (params) =>
                `${params.row.purchasePrice}`,
        },
        {
            field: 'auctionHouse',
            headerName: 'Auction House',
            width: 200,
            valueGetter: (params) =>
                `${params.row.auctionHouse}`,
        },
    ];

    return (
        <div>
            <Grid container md={12} mt={{ xs: 7, md: 0 }} mb={{ xs: 0, md: 1 }} >
                <Grid item xs={12} sm={11} md={12}>
                    <Grid container md={12} gap={2} marginTop={2} justifyContent={{ xs: 'center' }} >
                        <Grid item xs={11.5} md={3.6} sx={{ borderRadius: '5px', p: 2, py: 4, borderLeft: '2px solid #A04F56', borderLeftColor: '#A04F56', backgroundColor: 'rgb(160, 79, 86, 0.1)' }}  >
                            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} >
                                <Box marginLeft={'5px'}>
                                    <Typography sx={{ fontSize: 20 }}>All Customers</Typography>
                                    <Typography sx={{ fontSize: 27, fontWeight: '500' }}>{data?.getAllNewCustomers}</Typography>
                                </Box>
                                {/* <Avatar variant='square' sx={{width:80, height:80}} src={appImages.customers} /> */}
                                <Avatar
                                    variant="square"
                                    src={appImages.customers}
                                    sx={{
                                        width: 100,
                                        height: 100,
                                        opacity: 0.5,
                                        '& .MuiAvatar-img': { objectFit: 'contain' }
                                    }} />

                            </Box>
                        </Grid>
                        <Grid item xs={11.5} md={3.6} sx={{ borderRadius: '5px', p: 2, py: 4, borderLeft: '2px solid #FCB223', borderLeftColor: '#FCB223', backgroundColor: 'rgb(251, 178, 35, 0.1)' }}  >
                            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} >

                                <Box marginLeft={'5px'}>
                                    <Typography sx={{ fontSize: 20 }}>Total Sale</Typography>
                                    <Typography sx={{ fontSize: 27, fontWeight: '500' }}>{commaSeparated(data?.totalSale)}</Typography>
                                </Box>
                                <Avatar
                                    variant="square"
                                    src={appImages.graph}
                                    sx={{
                                        width: 100,
                                        height: 100,
                                        opacity: 0.5,
                                        '& .MuiAvatar-img': { objectFit: 'contain' }
                                    }} />
                            </Box>
                        </Grid>
                        <Grid item xs={11.5} md={3.6} sx={{ borderRadius: '5px', p: 2, py: 4, borderLeft: '2px solid #34ceff', borderLeftColor: '#34ceff', backgroundColor: 'rgb(53, 206, 255, 0.1)' }}  >
                            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} >

                                <Box marginLeft={'5px'}>
                                    <Typography sx={{ fontSize: 20 }}>All Transactions</Typography>
                                    <Typography sx={{ fontSize: 27, fontWeight: '500' }}>{commaSeparated(data?.getAllTansactionAmontData)}</Typography>
                                </Box>
                                <Avatar
                                    variant="square"
                                    src={appImages.briefcase}
                                    sx={{
                                        width: 100,
                                        height: 100,
                                        opacity: 0.5,
                                        '& .MuiAvatar-img': { objectFit: 'contain' }
                                    }} />
                            </Box>
                        </Grid>
                        {/* <Grid item xs={12} md={3.6} sx={{ boxShadow: 1, borderRadius: '5px', p: 2, borderLeft: '2px solid #FCB223', borderLeftColor: '#FCB223' }}  >
                            <Box display={'flex'} >
                                <Avatar variant='square' src={appImages.profit} />
                                <Box marginLeft={'5px'}>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Typography sx={{ fontSize: 16 }}>{commaSeparated(data?.totalSale)}</Typography>
                                    </Box>
                                    <Typography sx={{ fontSize: 12 }}>Total Sale</Typography>
                                </Box>
                            </Box>
                        </Grid> */}
                        {/* <Grid item xs={12} md={3.6} sx={{ boxShadow: 1, borderRadius: '5px', p: 2, borderLeft: '2px solid #34ceff', borderLeftColor: '#34ceff' }}  >
                            <Box display={'flex'}>
                                <Avatar variant='square' src={appImages.tansaction} />
                                <Box marginLeft={'5px'}>
                                    <Typography sx={{ fontSize: 16 }}>{commaSeparated(data?.getAllTansactionAmontData)}</Typography>
                                    <Typography sx={{ fontSize: 12 }}>All Transactions</Typography>
                                </Box>
                            </Box>
                        </Grid> */}
                        {/* <Box>
                                <Typography sx={{ fontSize: 18 }}>Sales Overview</Typography>
                                <Box marginTop={3} display={'flex'} justifyContent={'space-between'}>
                                    <Box display={'flex'} >
                                        <Avatar variant='square' src={appImages.members} />
                                        <Box marginLeft={'5px'}>
                                            <Typography sx={{ fontSize: 16 }}>{data?.getAllNewCustomers}</Typography>
                                            <Typography sx={{ fontSize: 12 }}>All Customers</Typography>
                                        </Box>
                                    </Box>
                                    <Box display={'flex'} >
                                        <Avatar variant='square' src={appImages.profit} />
                                        <Box marginLeft={'5px'}>
                                            <Box display={'flex'} alignItems={'center'}>
                                                <Typography sx={{ fontSize: 16 }}>{commaSeparated(data?.totalSale)}</Typography>
                                            </Box>
                                            <Typography sx={{ fontSize: 12 }}>Total Sale</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display={'flex'} marginTop={4} >
                                    <Avatar variant='square' src={appImages.tansaction} />
                                    <Box marginLeft={'5px'}>
                                        <Typography sx={{ fontSize: 16 }}>{commaSeparated(data?.getAllTansactionAmontData)}</Typography>
                                        <Typography sx={{ fontSize: 12 }}>All Transactions</Typography>
                                    </Box>
                                </Box>
                            </Box> */}

                        {/* <Grid item xs={11} md={5.5} sx={{ boxShadow: 1, borderRadius: '5px', p: 2, backgroundColor: 'rgb(227, 225, 218, 0.4)' }} >
                            <Box>
                                <Typography sx={{ fontSize: 18, mb: 1 }}>Sales This Month</Typography>
                            </Box>
                            <ResponsiveContainer width="100%" height={150}>
                                <LineChart
                                    width={500}
                                    height={150}
                                    data={monthWiseChart}
                                    syncId="anyId"
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" style={{ fontSize: '12px' }} />
                                    <YAxis style={{ fontSize: '12px' }} />
                                    <Tooltip />
                                    <Line type="monotone" dataKey="amount" stroke={Colors.Primary} fill={Colors.Primary} />
                                </LineChart>
                            </ResponsiveContainer>
                        </Grid> */}

                        <Grid item xs={11} md={11.1} sx={{ borderRadius: '5px' }} >
                            <Box>
                                <Typography sx={{ fontSize: 18, marginBottom: '10px' }}>Last Week Data</Typography>
                            </Box>
                            <Box sx={{ height: '300px', width: '100%' }}>
                                <DataGrid
                                    rows={lastWeekData || []}
                                    // columns={columns}
                                    columns={lastWeekColumns.map((column) => ({
                                        ...column,
                                        cellClassName: 'custom-cell-class',
                                    }))}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 20,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[20]}
                                    // checkboxSelection
                                    disableRowSelectionOnClick
                                    slots={{
                                        loadingOverlay: LinearProgress,
                                        toolbar: CustomToolbar,
                                    }}
                                    loading={isLoading}
                                    // slots={{ toolbar: GridToolbar }}
                                    slotProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                        },
                                    }}
                                />
                            </Box>
                            {/* <ResponsiveContainer width="100%" height={150}>
                                <AreaChart
                                    width={500}
                                    height={150}
                                    data={lastWeekUsers}
                                    syncId="weekId"
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" style={{ fontSize: '12px' }} />
                                    <YAxis style={{ fontSize: '12px' }} />
                                    <Tooltip />
                                    <Area type="monotone" dataKey="amount" stroke={Colors.Primary} fill={Colors.Primary} />
                                </AreaChart>
                            </ResponsiveContainer> */}
                        </Grid>
                    </Grid>

                    <Grid container marginTop={2} gap={2} justifyContent={'center'}>
                        <Grid item xs={11} md={8}>
                            <Box sx={{ height: '400px', width: '100%' }}>
                                <DataGrid
                                    rows={tableData || []}
                                    // columns={columns}
                                    columns={columns.map((column) => ({
                                        ...column,
                                        cellClassName: 'custom-cell-class',
                                    }))}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 20,
                                            },
                                        },
                                    }}
                                    loading={isLoading}
                                    slots={{
                                        loadingOverlay: LinearProgress,
                                        toolbar: CustomToolbar,
                                    }}
                                    pageSizeOptions={[20]}
                                    // checkboxSelection
                                    disableRowSelectionOnClick
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={11} md={3} sx={{ boxShadow: 1, borderRadius: '5px', p: 2 }} >
                            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} >
                                <Typography sx={{ fontSize: 18, mb: 3 }}>Total Transits</Typography>
                            </Box>
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart margin={{ left: -40 }} data={chartData && chartData}>
                                    <XAxis dataKey="name" style={{ fontSize: '12px' }} />
                                    <YAxis style={{ fontSize: '12px' }} />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <Bar dataKey="amount" fill={Colors.Primary} />
                                </BarChart>
                            </ResponsiveContainer>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            {(open && urlArray.length > 0 && detail) && (
                <CarDetailFunc adminNavigation={true} detail={detail} open={open} setOpen={setOpen} urlArray={urlArray} />
            )}
        </div>
    )
}

export default AdminDashboard