import { Autocomplete, Avatar, Button, CircularProgress, Divider, Drawer, Grid, LinearProgress, List, ListItem, ListItemText, Modal, OutlinedInput, Stack, Tab, Tabs, TextareaAutosize, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState, useRef } from 'react'

import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineCancel } from "react-icons/md";
import { Colors } from '../../constants/colors';
import moment from 'moment';
import { bidCompleteAPI, getActivetoPending, getAdminBidList, getMarkBidInLose, getMarkBidInProcess, getSingleBidDetail } from '../../apis/adminIndex';
import { useEffect } from 'react';
import { commaSeparated, DateDifference } from '../../constants/GlobalConstants';
import ThumbnailSlider from '../../components/ThumbnailSlider';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { IoArrowRedoSharp, IoCloudUploadOutline } from 'react-icons/io5'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaFileAlt, FaInfoCircle } from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';
import { CarDetailFunc } from '../CarDetail';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    // height: 450,
    bgcolor: 'white',
    border: 'white',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px'
};

const stylePaymentModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 350, md: 400 },
    bgcolor: 'white',
    border: 'white',
    boxShadow: 24,
    p: { xs: 2, md: 4 },
    borderRadius: '10px'
};
const styleInfoModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'white',
    border: 'white',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px'
};

const styleBidderModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: 'white',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px'
};



function AdminBid() {
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [bidListData, setBidListData] = useState([]);
    const [allBidListData, setAllBidListData] = useState()
    const [userSelectedId, setUserSelectedId] = useState(null)
    const [detail, setDetail] = useState(null)

    const [pendingStatusId, setPendingStatusId] = useState(null)
    const [selectedUserDetail, setSelectedUserDetail] = useState()
    const [urlArray, setUrlArray] = useState([]);
    const [bidCompleteURl, setbidCompleteURl] = useState([]);

    const [open, setOpen] = useState(false);
    const [bidderModal, setBidderModal] = useState(false);
    const [progressModal, setProgressModal] = useState(false);
    const [pendingModal, setPendingModal] = useState(false);
    const [pendingData, setPendingData] = useState(false);
    const [completeBidingModal, setCompleteBidingModal] = useState(false);
    const [loseBidingModal, setLoseBidingModal] = useState(false);
    const [addUserLoader, setAddUserLoader] = useState(false)
    const [bidCompleteData, setBidCompleteData] = useState(new Date());
    const [paymentProof, setPaymentProof] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [eta, setETA] = useState('');
    const [etd, setETD] = useState('');
    const [note, setNote] = useState('');
    const [vesselName, setVesselName] = useState('');
    const [defaultDate, setDefaultDate] = useState(moment().format('YYYY-MM-DD'));
    const [value, setValue] = useState(0);
    const [status, setStatus] = useState();

    const dateChangeRef = useRef(defaultDate);

    const data = JSON.parse(localStorage.getItem("loggedUser"))

    const Status = [
        { id: 1, label: 'Pending' },
        { id: 2, label: 'Shipped' },
        { id: 3, label: 'En Route' },
        { id: 4, label: 'Arrived' },
        { id: 5, label: 'Delivered' },
        { id: 6, label: 'Closed' },
        { id: 7, label: 'Cancelled' },
    ]

    function CustomToolbar() {
        const [infoModal, setInfoModal] = useState(false);

        const handleInfoModal = () => {
            setInfoModal(true)
        }

        const handleInfoModalClose = () => {
            setInfoModal(false)
        }
        return (
            <>
                <GridToolbarContainer>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: { xs: 'column-reverse', md: 'row' } }} >
                        <Box sx={{ width: '100%' }} >
                            <Grid container gap={1.5}>
                                <Grid item xs={12} md={3.5} boxShadow={1} p={1}>
                                    <Box sx={{ width: '100%' }}>
                                        <Typography sx={{ fontSize: '16px', fontFamily: 'Roboto' }}>
                                            Total cars for auction today
                                        </Typography>
                                        <Typography sx={{ fontSize: '22px', fontFamily: 'Roboto' }}>
                                            {allBidListData?.countTodayBids}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3.5} boxShadow={1} p={1}>
                                    <Box sx={{ width: '100%' }}>
                                        <Typography sx={{ fontSize: '16px', fontFamily: 'Roboto' }}>
                                            Total won today
                                        </Typography>
                                        <Typography sx={{ fontSize: '22px', fontFamily: 'Roboto' }}>
                                            {allBidListData?.countWinToday}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3.5} boxShadow={1} p={1}>
                                    <Box sx={{ width: '100%' }}>
                                        <Typography sx={{ fontSize: '16px', fontFamily: 'Roboto' }}>
                                            Total lost today
                                        </Typography>
                                        <Typography sx={{ fontSize: '22px', fontFamily: 'Roboto' }}>
                                            {allBidListData?.countLoseToday}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} >
                            <FiRefreshCcw style={{ fontSize: "20px", cursor: "pointer", paddingRight: "15px" }}
                                onClick={() => handleBidList()}
                            />
                            <FaInfoCircle size={22} color={Colors.Primary} style={{ cursor: 'pointer' }} onClick={handleInfoModal} />
                            <GridToolbarQuickFilter />
                        </Box>
                    </Box>
                </GridToolbarContainer>
                <div>
                    <Modal
                        open={infoModal}
                        onClose={handleInfoModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >

                        <Box sx={styleInfoModal}>
                            <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                                <RxCross2 onClick={handleInfoModalClose} style={{ cursor: 'pointer' }} />
                            </Box>
                            <Grid container gap={3} my={1.5}>
                                <Grid item md={1.5}>
                                    <Typography sx={{
                                        fontSize: '12px',
                                        py: 1,
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        color: '#FDB528',
                                        backgroundColor: 'rgba(253, 181, 40, 0.2)'
                                    }}>
                                        Active
                                    </Typography>
                                </Grid>
                                <Grid item md={9}>
                                    <Typography sx={{
                                        fontSize: '12px',
                                        py: 0.2,
                                        borderRadius: '5px',
                                        color: Colors.lightgray,
                                    }}>
                                        The local bidding for this car is still open. It will automatically close 4 hours before the bidding date/time
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container gap={3} my={1.5}>
                                <Grid item md={1.5}>
                                    <Typography sx={{
                                        fontSize: '12px',
                                        py: 1,
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        color: Colors.Primary,
                                        backgroundColor: 'rgba( 204, 7, 26, 0.2)',
                                    }}>
                                        Pending
                                    </Typography>
                                </Grid>
                                <Grid item md={9}>
                                    <Typography sx={{
                                        fontSize: '12px',
                                        borderRadius: '5px',
                                        color: Colors.lightgray,
                                    }}>
                                        The local bidding time is over for this car but it has not been started bidding on 3rd party platform.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container gap={3} >
                                <Grid item md={1.5}>
                                    <Typography sx={{
                                        fontSize: '12px',
                                        py: 1,
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        // color: '#FDB528',
                                        backgroundColor: '#D5EBFE',
                                        color: '#56AEF7'
                                    }}>
                                        In Biding
                                    </Typography>
                                </Grid>
                                <Grid item md={9}>
                                    <Typography sx={{
                                        fontSize: '12px',
                                        py: 0.2,
                                        borderRadius: '5px',
                                        color: Colors.lightgray,
                                    }}>
                                        The admin has started bidding this car on 3rd party platform. This car is ready for either win or loose from the other platform.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Modal>
                </div>
            </>
        );
    }


    const handleModalClose = () => {
        setOpen(false)
    }

    const handleBidderModalClose = () => {
        setBidderModal(false)
    }
    const handleProgressModalClose = () => {
        setProgressModal(false)
    }
    const handleActiveToPendingModalClose = () => {
        setPendingModal(false)
    }
    const handleCompleteModalClose = () => {
        setCompleteBidingModal(false)
    }

    const handleLoseModalClose = () => {
        setLoseBidingModal(false)
    }

    useEffect(() => {
        handleBidList()
    }, [])

    useEffect(() => {
        handleSingleBidDetail(userSelectedId)
    }, [userSelectedId]);

    const handleBidList = async () => {
        setLoading(true)
        try {
            const res = await getAdminBidList(`?date=${dateChangeRef.current}`)
            console.log("🚀 ~ file: AdminBid.jsx:303 ~ handleBidList ~ res:", res)
            setAllBidListData(res.data)
            setBidListData(res?.data?.result.map((item) => {
                return {
                    id: item._id,
                    car: item.IMAGES,
                    auctionDate: moment(item.AUCTION_DATE).format('DD/MM/YYYY'),
                    lotNo: item.LOT,
                    makeModel: item.MARKA_NAME + ' ' + item.MODEL_NAME,
                    balance: commaSeparated(item?.bids?.userId?.balance),
                    currentBid: commaSeparated(item?.bids?.amount),
                    bidderName: item.bids?.userId?.FirstName + ' ' + item.bids?.userId?.LastName,
                    auctionName: item.AUCTION,
                    status: item.status,
                    date: DateDifference(item.AUCTION_DATE),
                    amount: commaSeparated(item?.bids?.amount),
                    soldPrice: item?.bids?.amount,
                    item: item
                }
            }))
            setLoading(false)
        } catch (error) {
            console.log("🚀 ~ file: AdminBid.jsx:156 ~ handleBidList ~ error:", error)
            setLoading(false)
        }

    }

    // useEffect(() => {
    //     // setInterval(() => {
    //     //     handleBidList()
    //     // }, 10000);
    //     const intervalId = setInterval(() => {
    //         handleBidList();
    //     }, 10000);

    //     return () => clearInterval(intervalId);
    // }, [])
    useEffect(() => {
        const intervalId = setInterval(() => {
            // Use the current value of dateChange from the ref
            handleBidList();
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);

    const handleTabClick = (newDate) => {
        setDefaultDate(newDate);
        dateChangeRef.current = newDate; // Update the ref with the new value
    };



    const handleSingleBidDetail = async (id) => {
        setLoader(true)
        try {
            const res = await getSingleBidDetail(id)
            console.log("🚀 ~ handleSingleBidDetail ~ res:", res)
            var data = res?.data

            setSelectedUserDetail(data)
            const urlString = data?.auctionData?.IMAGES;
            const urlParts = urlString.split('#');
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            const urls = urlParts.flatMap(part => part.match(urlRegex));

            if (urls) {
                setUrlArray(urls);
            }
            setLoader(false)
        } catch (error) {
            console.log("🚀 ~ file: AdminBid.jsx:173 ~ handleSingleBidDetail ~ error:", error)
        }
    }

    const handleProgressModal = (id) => {
        setPendingStatusId(id)
        setProgressModal(true)
    }

    const handleInProgressLoseModal = (row) => {
        console.log("🚀 ~ file: AdminBid.jsx:388 ~ handleInProgressLoseModal ~ id:", row)
        setPendingStatusId(row.id)
        setLoseBidingModal(true)
    }

    const handleActiveToPendingModal = (row) => {
        setPendingData(row)
        setPendingModal(true)
    }

    const handleChangeStatus = async () => {
        setSpinner(true)
        try {
            const res = await getMarkBidInProcess(pendingStatusId)
            console.log("🚀 ~ file: AdminBid.jsx:171 ~ handleChangeStatus ~ res:", res)
            handleBidList()
            setSpinner(false)
            setProgressModal(false)
        } catch (error) {
            console.log("🚀 ~ file: AdminBid.jsx:177 ~ handleChangeStatus ~ error:", error)
            setSpinner(false)
        }

    }

    const handleLoseStatus = async () => {
        setSpinner(true)
        try {
            const res = await getMarkBidInLose(pendingStatusId)
            handleBidList()
            setSpinner(false)
            setLoseBidingModal(false)
        } catch (error) {
            console.log("🚀 ~ file: AdminBid.jsx:177 ~ handleChangeStatus ~ error:", error)
            setSpinner(false)
        }

    }


    const handleChangeStatusActive = async () => {
        setSpinner(true)
        try {
            const res = await getActivetoPending(pendingData.id)
            console.log("🚀 ~ file: AdminBid.jsx:171 ~ handleChangeStatus ~ res:", res)
            handleBidList()
            setSpinner(false)
            setPendingModal(false)
        } catch (error) {
            console.log("🚀 ~ file: AdminBid.jsx:177 ~ handleChangeStatus ~ error:", error)
            setSpinner(false)
        }

    }

    const handleDrawerToggle = (row) => {
        const urlString = row?.car;
        const urlParts = urlString.split('#');
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const urls = urlParts.flatMap(part => part.match(urlRegex));

        if (urls) {
            setbidCompleteURl(urls);
        }
        console.log("🚀 ~ file: AdminBid.jsx:185 ~ handleDrawerToggle ~ row:", row)
        setBidCompleteData(row)
        setDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false)
    }


    const handleBidComplete = async () => {
        setAddUserLoader(true)
        try {
            let formData = new FormData();
            formData.append("AuctionId", bidCompleteData?.id);
            formData.append("ConfirmDate", moment().format('YYYY-MM-DD'));
            formData.append("Status", 'Completed');
            formData.append("DeliveryStatus", status);
            formData.append("ETA", moment(eta).format('YYYY-MM-DD'));
            formData.append("EDT", moment(etd).format('YYYY-MM-DD'));
            formData.append("Note", note);
            formData.append("SoldPrice", Number(bidCompleteData?.soldPrice));
            formData.append("VessalName", vesselName);
            selectedFile.forEach(file => {
                formData.append("DocFiles", file);
            });
            const res = await bidCompleteAPI(data.token, formData)
            console.log("🚀 ~ file: AdminBid.jsx:381 ~ handleBidComplete ~ res:", res)
            handleBidList()
            // toast.success(res?.message, {
            //         position: "top-right",
            //     autoClose: 2500,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            // });
            setAddUserLoader(false)
            setSelectedFile(null)
            setDrawerOpen(false);
        } catch (error) {
            console.log("🚀 ~ file: AdminBid.jsx:193 ~ handleAddUser ~ error:", error)
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setAddUserLoader(false)
        }
    }

    const columns = [
        // { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'car',
            headerName: 'Car Picture',
            // flex: 1,
            width: 100,
            editable: false,
            sortable: false,
            renderCell: (params) => (
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    <Avatar variant='square' sx={{ borderRadius: '5px' }} src={params?.row?.car} />
                </Box>
            ),
        },
        {
            field: 'lotNo',
            headerName: 'Lot No.',
            // flex: 1,
            width: 100,
            editable: false,
            sortable: false,
            renderCell: ({ row }) => (
                <Typography
                    onClick={(event) => {
                        setDetail(row.item)
                        const urlString = row.car;
                        const urlParts = urlString.split('#');
                        const urlRegex = /(https?:\/\/[^\s]+)/g;
                        const urls = urlParts.flatMap(part => part.match(urlRegex));

                        if (urls) {
                            setUrlArray(urls);
                        }
                        setOpen(true)
                    }}
                    // onClick={(event) => {
                    //     setUserSelectedId(row.id)
                    //     setModal(true)
                    // }}
                    alignItems={'center'} sx={{ color: Colors.Primary, cursor: 'pointer', p: '5px', borderRadius: '5px', '&:hover': { textDecorationLine: 'underline' } }}>
                    {`${row?.lotNo}`}
                </Typography>
            ),
        },
        {
            field: 'makeModel',
            headerName: 'Make / Modal',
            // flex: 1,
            width: 170,
            editable: false,
            sortable: false,
            valueGetter: (params) =>
                `${params.row.makeModel}`,
        },
        {
            field: 'balance',
            headerName: 'Balance',
            // flex: 1,
            width: 120,
            editable: false,
            sortable: false,
            valueGetter: (params) =>
                `${params.row.balance}`,
        },
        {
            field: 'currentBid',
            headerName: 'Current Bid Amount',
            // flex: 1,
            width: 150,
            editable: false,
            sortable: false,
            valueGetter: (params) =>
                `${params.row.currentBid}`,
        },
        {
            field: 'bidderName',
            headerName: 'Bidder Name',
            // flex: 1,
            width: 150,
            editable: false,
            sortable: false,
            renderCell: ({ row }) => (
                <Typography onClick={(event) => {
                    console.log("UserSelectedId", row);
                    setUserSelectedId(row.id)
                    setBidderModal(true)
                }}
                    alignItems={'center'} sx={{ color: Colors.Primary, cursor: 'pointer', p: '5px', borderRadius: '5px', '&:hover': { textDecorationLine: 'underline' } }}>
                    {`${row?.bidderName}`}
                </Typography>
            ),
        },
        {
            field: 'auctionName',
            headerName: 'Auction Name',
            sortable: false,
            // flex: 1,
            width: 150,
            valueGetter: (params) =>
                `${params.row.auctionName}`,
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            // flex: 1,
            width: 200,
            renderCell: ({ row }) => (
                <>
                    {row.status === 'InProgress' ?
                        <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                            <Typography sx={{ cursor: 'pointer', fontSize: '12px', backgroundColor: '#D5EBFE', py: 0.2, px: 1, borderRadius: '5px', color: '#56AEF7' }}>
                                In Biding
                            </Typography>
                            <Box
                                onClick={() => handleDrawerToggle(row)}
                                sx={{
                                    backgroundColor: '#24CF36',
                                    padding: '3px',
                                    borderRadius: '6px',
                                    cursor: 'pointer',
                                    width: '45px',
                                    height: '35px',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                <FaThumbsUp color={'white'} size={20} />
                            </Box>
                            <Box onClick={() => handleInProgressLoseModal(row)} sx={{ backgroundColor: '#CF246F', padding: '3px', borderRadius: '6px', cursor: 'pointer', width: '45px', height: '35px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                <FaThumbsDown color={'white'} size={20} />
                            </Box>
                        </Box>
                        :
                        <Box
                            onClick={() => {
                                if (!row.date) {
                                    handleProgressModal(row.id)
                                }
                            }}
                            sx={{ display: 'flex', gap: 1 }}
                        >
                            <Typography sx={{
                                cursor: 'pointer',
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: !row.date ? 'rgba( 204, 7, 26, 0.2)' : 'rgba(253, 181, 40, 0.2)',
                                color: !row.date ? Colors.Primary : '#FDB528',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                {row.date ? 'Active' : 'Pending'}
                            </Typography>
                            {row.date ?
                                <Box
                                    onClick={() => handleActiveToPendingModal(row)}
                                    sx={{
                                        backgroundColor: '#e07619',
                                        padding: '3px',
                                        borderRadius: '6px',
                                        cursor: 'pointer',
                                        width: '45px',
                                        height: '35px',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                    <IoArrowRedoSharp color={'white'} size={20} />
                                </Box>
                                :
                                <></>}
                        </Box>
                    }
                </>


            ),
            // valueGetter: (params) => 
            //     `${params.row.status}`,
        },
    ];

    const today = moment();
    console.log("🚀 ~ file: AdminBid.jsx:554 ~ AdminBid ~ today:", moment().format('DD-MM-YYYY'))

    const handleFileChange = (event) => {
        const allowedFileTypes = ["image/jpeg", "image/png", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf", "application/zip"];

        const files = event.target.files;
        console.log("🚀 ~ file: AdminBid.jsx:373 ~ handleFileChange ~ file:", files);
        let prevArray = [];
        let filesArray = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (allowedFileTypes.includes(file.type)) {
                filesArray.push(file);

                // Set the image preview
                const previewURL = file ? URL.createObjectURL(file) : '';

                if (previewURL) {
                    prevArray.push(previewURL);
                }
            } else {
                // File type not allowed, you can handle this case (e.g., show an error message)
                toast.error('File type not allowed', {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                console.log(`File type not allowed: ${file.type}`);
            }

        }
        setSelectedFile(filesArray.length > 0 ? filesArray : null);
        // Set the image preview
        setPaymentProof(prevArray);
    };

    const handleDeleteImage = (index) => {
        const newPaymentProof = [...paymentProof];
        newPaymentProof.splice(index, 1);

        setPaymentProof(newPaymentProof);
        if (newPaymentProof.length === 0) {
            setSelectedFile(null);
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (defaultDate) {
            handleBidList()
        }
    }, [defaultDate]);

    return (
        <div>
            <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer}>
                <List sx={{ width: '450px', height: '100%', overflowY: 'scroll', marginBottom: 2 }}>
                    <ListItem>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                            <Typography sx={{ color: Colors.Primary, fontSize: '20px', fontFamily: 'Roboto' }}>Congratulations! You have won this bid</Typography>
                            <RxCross2 style={{ cursor: 'pointer' }} onClick={handleCloseDrawer} />
                        </Box>
                    </ListItem>
                    <ListItem><ListItemText primary="Car Delivery Status" /></ListItem>
                    <ListItem>
                        <Box sx={{ width: '100%' }}>
                            <ThumbnailSlider imageData={bidCompleteURl} />
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography>Lot Number:</Typography>
                            <Typography>{bidCompleteData.lotNo}</Typography>
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography>Brand Car:</Typography>
                            <Typography>{bidCompleteData.makeModel}</Typography>
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box
                            boxShadow={1}
                            bgcolor={Colors.white}
                            borderRadius={'20px'}
                            width={{ md: '95%' }}
                        >
                            {!selectedFile ?
                                <label htmlFor="fileInput">
                                    <Box sx={{ backgroundColor: '#F6F6F6', border: 1, width: '100%', px: '10px', py: '40px', borderRadius: '5px', borderStyle: 'dashed', cursor: 'pointer', textAlign: 'center' }}>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            onChange={handleFileChange}
                                            multiple
                                            style={{ display: 'none' }}
                                        />
                                        <IoCloudUploadOutline color={'#6E6E6E'} size={30} />
                                        <Typography sx={{ color: '#6E6E6E', fontSize: '12px' }}>Browse file to Upload</Typography>
                                    </Box>
                                </label>
                                :
                                <Box sx={{ backgroundColor: 'white', display: 'flex', gap: '10px', overflowX: 'scroll', py: 1 }}>
                                    {paymentProof.map((item, index) => (
                                        <Box sx={{ width: '100px', borderRadius: '5px', boxShadow: 1, border: '0.1px solid grey', position: 'relative' }} >
                                            {/* <img key={index} src={item} style={{ width: '100px', height: '100px', position: 'relative' }} /> */}
                                            <Avatar variant='square' key={index} src={item} sx={{ backgroundColor: '#f3f3f3', width: '100px', height: '100px', position: 'relative', '& .MuiAvatar-img': { objectFit: 'contain' } }} >
                                                <FaFileAlt size={44} color={'gray'} />
                                            </Avatar>
                                            <MdOutlineCancel
                                                style={{
                                                    cursor: 'pointer',
                                                    top: '0px',
                                                    right: '0px',
                                                    position: 'absolute'
                                                }}
                                                onClick={() => handleDeleteImage(index)} />
                                        </Box>
                                    ))}
                                </Box>
                            }
                        </Box>
                    </ListItem>
                    <ListItem >

                        <Box sx={{ width: '100%' }} >

                            <Typography>ETA</Typography>
                            <DatePicker

                                minDate={today}
                                selected={eta && new Date(eta)}
                                onChange={(e) => setETA(e)}
                                className='etdInput'
                                dateFormat={'dd/MM/yyyy'}
                            // views={['day', 'month', 'year',]}
                            />
                        </Box>
                    </ListItem>
                    <ListItem >
                        <Box sx={{ width: '100%' }} >
                            <Typography>ETD</Typography>
                            <DatePicker
                                minDate={today}
                                selected={etd && new Date(etd)}
                                onSelect={(e) => setETD(e)}
                                className='etdInput'
                                dateFormat={'dd/MM/yyyy'}
                            />
                            {/* <DatePicker
                                            sx={{ backgroundColor: '#F6F6F6' }}
                                            minDate={today}
                                            format="DD-MM-YYYY"
                                            onChange={(e) => setETD(e)}
                                        // views={['day', 'month', 'year',]} 
                                        /> */}
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ width: '100%' }}>
                            <Typography>Vessel Name</Typography>
                            <OutlinedInput
                                onChange={(e) => setVesselName(e.target.value)}
                                sx={{ width: '100%', backgroundColor: '#F6F6F6' }} placeholder="Enter Vessel Name" />
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ width: '100%' }}>
                            <Typography>Status</Typography>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={Status}
                                disableClearable
                                sx={{ width: '100%', backgroundColor: '#F6F6F6', marginTop: '16px' }}
                                onChange={(e, v) => setStatus(v?.label)}
                                renderInput={(params) => <TextField {...params} variant="outlined" placeholder={"Enter Status"} />}
                            />
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ width: '100%' }}>
                            <Typography>Note</Typography>
                            <TextareaAutosize
                                onChange={(e) => setNote(e.target.value)}
                                style={{
                                    width: '95%',
                                    borderColor: 'GrayText',
                                    borderRadius: '5px',
                                    backgroundColor: '#F6F6F6',
                                    padding: '10px',
                                    fontSize: '14px',
                                    fontFamily: 'Roboto',
                                    fontWeight: '500'
                                }}
                                minRows={4}
                            />
                        </Box>
                    </ListItem>
                    <ListItem >
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Button
                                onClick={handleBidComplete}
                                id="basic-button"
                                aria-haspopup="true"
                                sx={{
                                    backgroundColor: addUserLoader ? 'white' : Colors.Primary, '&:hover': {
                                        backgroundColor: addUserLoader ? 'white' : Colors.Primary
                                    },
                                    width: '100px'
                                }}
                            >
                                {addUserLoader ? <CircularProgress size={14} color={'warning'} /> : <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Submit</Typography>}
                            </Button>
                            <Button
                                id="basic-button"
                                aria-haspopup="true"
                                sx={{
                                    backgroundColor: '#E8E9ED', '&:hover': {
                                        backgroundColor: '#E8E9ED'
                                    },
                                    width: '100px'
                                }}
                                onClick={handleCloseDrawer}
                            >
                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'#878787'} >Cancel</Typography>
                            </Button>
                        </Stack>
                    </ListItem>
                </List>
            </Drawer>
            <Grid container marginTop={{ xs: 7, md: 2 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={12} sm={8} md={8}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab label={moment().format('dddd')} sx={{ textTransform: "capitalize" }}
                                    // onClick={() => {
                                    //     const date = moment().format('YYYY-MM-DD');
                                    //     setDefaultDate(date)
                                    // }}
                                    onClick={() => handleTabClick(moment().format('YYYY-MM-DD'))}
                                />
                                <Tab label={moment().add(1, 'days').format('dddd')} sx={{ textTransform: "capitalize" }}
                                    // onClick={() => {
                                    //     const date = moment().add(1, 'days').format('YYYY-MM-DD');
                                    //     setDefaultDate(date)
                                    // }} 
                                    onClick={() => handleTabClick(moment().add(1, 'days').format('YYYY-MM-DD'))}
                                />
                                <Tab label={moment().add(2, 'days').format('dddd')} sx={{ textTransform: "capitalize" }}
                                    // onClick={() => {
                                    //     const date = moment().add(2, 'days').format('YYYY-MM-DD');
                                    //     setDefaultDate(date)
                                    // }}
                                    onClick={() => handleTabClick(moment().add(2, 'days').format('YYYY-MM-DD'))}
                                />
                            </Tabs>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container md={12} >
                <Grid container md={12} marginTop={2} gap={2} justifyContent={'center'}>
                    <Grid item xs={11} md={11} p={2} boxShadow={1} borderRadius={'5px'}>
                        <Box sx={{ height: { xs: '600px', md: '600px' }, width: '100%' }}>
                            <DataGrid
                                rows={bidListData || []}
                                // columns={columns}
                                columns={columns.map((column) => ({
                                    ...column,
                                    cellClassName: 'custom-cell-class',
                                }))}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 20,
                                        },
                                    },
                                }}
                                pageSizeOptions={[20]}
                                // checkboxSelection
                                disableRowSelectionOnClick
                                slots={{
                                    loadingOverlay: LinearProgress,
                                    toolbar: CustomToolbar,
                                }}
                                loading={loading}
                                // slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            {/* <div>
                <Modal
                    open={modal}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={style}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={handleModalClose} style={{ cursor: 'pointer' }} />
                        </Box>
                        {loader ?
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', height: 400, alignItems: 'center' }} >
                                <CircularProgress size={26} color={'warning'} />
                            </Box>
                            :
                            <Box sx={{ width: '100%', display: 'flex' }}>
                                <Grid container md={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                    <Grid item md={5.8}>
                                        <ThumbnailSlider imageData={urlArray} />
                                    </Grid>
                                    <Grid item md={5.8}>
                                        <Box sx={{ overflowX: 'hidden', maxHeight: 420, overflowY: 'scroll' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Auction</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.auctionData.AUCTION}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Auction Date</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{moment(selectedUserDetail?.auctionData.AUCTION_DATE).format('DD/MM/YYYY')}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Year</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.auctionData.YEAR}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Marka</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.auctionData.MARKA_NAME}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Model</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.auctionData.MODEL_NAME}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Engine_V</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.auctionData.ENG_V}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Equip</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.auctionData.EQUIP}</Typography>
                                            </Box>
                                            <Divider />

                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Grade</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.auctionData.GRADE}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Rate</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.auctionData.RATE}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Milage</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.auctionData.MILEAGE} KM</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Status</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.auctionData.status}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Market Value</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Average Price</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{commaSeparated(selectedUserDetail?.auctionData.AVG_PRICE)}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Start Price</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{commaSeparated(selectedUserDetail?.auctionData.START)}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >End Price</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{commaSeparated(selectedUserDetail?.auctionData.FINISH)}</Typography>
                                            </Box>
                                            <Divider />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Box>}

                    </Box>
                </Modal>
            </div> */}
            <div>
                <Modal
                    open={bidderModal}
                    onClose={handleBidderModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={styleBidderModal}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={handleBidderModalClose} style={{ cursor: 'pointer' }} />
                        </Box>
                        {loader ?
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', height: 400, alignItems: 'center' }} >
                                <CircularProgress size={26} color={'warning'} />
                            </Box>
                            :
                            <Box sx={{ width: '100%', display: 'flex' }}>
                                <Grid container md={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                    <Grid item md={12}>
                                        <Box sx={{ overflowX: 'hidden', maxHeight: 420 }}>
                                            {/* {selectedUserDetail && Object.entries(selectedUserDetail?.auctionData).map(([key, value]) => {
                                                return (
                                                    <>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                            <Typography sx={{ fontSize: '12px' }} >{key}</Typography>
                                                            <Typography sx={{ fontSize: '12px' }} >{value}</Typography>
                                                        </Box>
                                                        <Divider />
                                                    </>
                                                )
                                            })
                                            } */}
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Name</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.higestBidUser?.userId?.FirstName + ' ' + selectedUserDetail?.higestBidUser?.userId?.LastName}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Email</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.higestBidUser?.userId?.Email}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Country</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.higestBidUser?.userId?.County}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Phone</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.higestBidUser?.userId?.Phone}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Zip Code</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.higestBidUser?.userId?.ZipCode}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Status</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.higestBidUser?.status}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Balance</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{commaSeparated(selectedUserDetail?.higestBidUser?.userId?.balance)}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Bid Amount</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{commaSeparated(selectedUserDetail?.higestBidUser?.amount)}</Typography>
                                            </Box>
                                            <Divider />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Box>
                        }
                    </Box>
                </Modal>
            </div>
            <div>
                <Modal
                    open={progressModal}
                    onClose={handleProgressModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={stylePaymentModal}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={handleProgressModalClose} style={{ cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Grid container md={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={12}>
                                    <Typography sx={{ textAlign: 'center', marginBottom: 5 }}>Are you sure you want change the status ?</Typography>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        {spinner ?
                                            <Box sx={{ width: '90%', display: 'flex', justifyContent: 'center', height: 50, alignItems: 'center' }} >
                                                <CircularProgress size={26} color={'warning'} />
                                            </Box>
                                            :
                                            <Button
                                                onClick={handleChangeStatus}
                                                id="basic-button"
                                                sx={{
                                                    backgroundColor: '#232628', '&:hover': {
                                                        backgroundColor: '#232628',
                                                    },
                                                    width: '90%',
                                                    my: 1
                                                }}
                                            >
                                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Yes</Typography>
                                            </Button>
                                        }
                                        <Button
                                            onClick={handleProgressModalClose}
                                            id="basic-button"
                                            sx={{
                                                backgroundColor: Colors.Primary, '&:hover': {
                                                    backgroundColor: Colors.Primary
                                                },
                                                width: '90%',
                                                my: 1
                                            }}
                                        >
                                            <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >No</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    </Box>
                </Modal>
            </div>

            <div>
                <Modal
                    open={pendingModal}
                    onClose={handleActiveToPendingModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={stylePaymentModal}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={handleActiveToPendingModalClose} style={{ cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Grid container md={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={12}>
                                    <Typography sx={{ textAlign: 'center', marginBottom: 5 }}>Are you sure you want change the status ?</Typography>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        {spinner ?
                                            <Box sx={{ width: '90%', display: 'flex', justifyContent: 'center', height: 50, alignItems: 'center' }} >
                                                <CircularProgress size={26} color={'warning'} />
                                            </Box>
                                            :
                                            <Button
                                                onClick={handleChangeStatusActive}
                                                id="basic-button"
                                                sx={{
                                                    backgroundColor: '#232628', '&:hover': {
                                                        backgroundColor: '#232628',
                                                    },
                                                    width: '90%',
                                                    my: 1
                                                }}
                                            >
                                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Yes</Typography>
                                            </Button>
                                        }
                                        <Button
                                            onClick={handleProgressModalClose}
                                            id="basic-button"
                                            sx={{
                                                backgroundColor: Colors.Primary, '&:hover': {
                                                    backgroundColor: Colors.Primary
                                                },
                                                width: '90%',
                                                my: 1
                                            }}
                                        >
                                            <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >No</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    </Box>
                </Modal>
            </div>

            <div>
                <Modal
                    open={completeBidingModal}
                    onClose={handleCompleteModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={stylePaymentModal}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={handleCompleteModalClose} style={{ cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Grid container md={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={12}>
                                    <Typography sx={{ textAlign: 'center', marginBottom: 5 }}>Are you sure you want change the status ?</Typography>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        {spinner ?
                                            <Box sx={{ width: '90%', display: 'flex', justifyContent: 'center', height: 50, alignItems: 'center' }} >
                                                <CircularProgress size={26} color={'warning'} />
                                            </Box>
                                            :
                                            <Button
                                                onClick={handleChangeStatus}
                                                id="basic-button"
                                                sx={{
                                                    backgroundColor: '#232628', '&:hover': {
                                                        backgroundColor: '#232628',
                                                    },
                                                    width: '90%',
                                                    my: 1
                                                }}
                                            >
                                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Yes</Typography>
                                            </Button>
                                        }
                                        <Button
                                            onClick={handleCompleteModalClose}
                                            id="basic-button"
                                            sx={{
                                                backgroundColor: Colors.Primary, '&:hover': {
                                                    backgroundColor: Colors.Primary
                                                },
                                                width: '90%',
                                                my: 1
                                            }}
                                        >
                                            <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >No</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    </Box>
                </Modal>
            </div>

            <div>
                <Modal
                    open={loseBidingModal}
                    onClose={handleLoseModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={stylePaymentModal}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={handleLoseModalClose} style={{ cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Grid container md={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={12}>
                                    <Typography sx={{ textAlign: 'center', marginBottom: 5 }}>Are you sure you want to mark this bid as lost ?</Typography>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        {spinner ?
                                            <Box sx={{ width: '90%', display: 'flex', justifyContent: 'center', height: 50, alignItems: 'center' }} >
                                                <CircularProgress size={26} color={'warning'} />
                                            </Box>
                                            :
                                            <Button
                                                onClick={handleLoseStatus}
                                                id="basic-button"
                                                sx={{
                                                    backgroundColor: '#232628', '&:hover': {
                                                        backgroundColor: '#232628',
                                                    },
                                                    width: '90%',
                                                    my: 1
                                                }}
                                            >
                                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Yes</Typography>
                                            </Button>
                                        }
                                        <Button
                                            onClick={handleLoseModalClose}
                                            id="basic-button"
                                            sx={{
                                                backgroundColor: Colors.Primary, '&:hover': {
                                                    backgroundColor: Colors.Primary
                                                },
                                                width: '90%',
                                                my: 1
                                            }}
                                        >
                                            <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >No</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    </Box>
                </Modal>
            </div>
            {
                (open && urlArray) && (
                    <CarDetailFunc detail={detail} open={open} setOpen={setOpen} urlArray={urlArray} adminNavigation={true} />
                )
            }
            <ToastContainer />
        </div>
    )
}

export default AdminBid