import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().required('Password is required').min(8, 'Password contains atleast 8 characters'),
});

export const UpdatePassValidationSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old Password is required'),
    newPassword: Yup.string().required('New Password is required').min(8, 'Password contains atleast 8 characters'),
    confirmedPdassword: Yup
        .string()
        .required("Confirm Password required")
        .oneOf([Yup.ref("newPassword")], "Password does not match"),
});

export const signupValidationSchema = Yup.object().shape({
    FirstName: Yup.string().required('First name is required'),
    LastName: Yup.string().required('Last name is required'),
    Email: Yup.string().email('Invalid email address').required('Email is required'),
    Password: Yup.string().required('Password is required').min(8, 'Password contain atleast 8 characters'),
    Phone: Yup.string().required('Phone is required'),
    County: Yup.string().required('Country is required'),
    City: Yup.string().required('City is required'),
    ZipCode: Yup.string().required('Zip code is required'),
});

export const contactUsValidationSchema = Yup.object().shape({
    fName: Yup.string().required('First name is required'),
    lName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.string().required('Phone is required'),
    message: Yup.string().required('Message is required'),
});

export const paymentValidationSchema = Yup.object().shape({
    fName: Yup.string().required('First name is required'),
    lName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.string().required('Phone is required'),
    message: Yup.string().required('Message is required'),
});

export const forgotValidationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
});

export const updatePasswordSchema = Yup.object({
    ResetCode: Yup.string().required('ResetCode Required'),
    Password: Yup.string().required('Password is required').min(8, 'Password contain atleast 8 characters'),
    ConfirmedPassword: Yup.string().required('ConfirmedPassword is required').min(8, 'Password contain atleast 8 characters'),
});

export const paymentSchema = Yup.object({
    payment: Yup.string().required('Payment Required'),
    bankName: Yup.string().required('Bank Required'),
});

export const balanceSchema = Yup.object({
    payment: Yup.string().required('Amount Required'),
    bankName: Yup.string().required('Bank Required'),
});

export const addUserValidationSchema = Yup.object().shape({
    FirstName: Yup.string().required('First name is required'),
    LastName: Yup.string().required('Last name is required'),
    Email: Yup.string().email('Invalid email address').required('Email is required'),
    Phone: Yup.string().required('Phone is required'),
    UserRole: Yup.string().required('Role is required'),
});

export const bidCompleteValidationSchema = Yup.object().shape({
    ETD: Yup.string().required('ETD is required'),
    ETA: Yup.string().required('ETA is required'),
});