import {
    Box, CircularProgress, Typography
} from '@mui/material';

import { Colors } from './colors';
import moment from 'moment';
import japanTime from 'moment-timezone'
import { useEffect, useState } from 'react';

const CountdownTimer = ({ targetTime }) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            // setTimeLeft(calculateTimeLeft());
            const timeLeft = calculateTimeLeft();
            if (timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0) {
                clearInterval(timer);
            } else {
                setTimeLeft(timeLeft);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    function calculateTimeLeft() {
        const japanTime = new Date().toLocaleString('en-US', { timeZone: 'Asia/Tokyo' });
        const difference = +new Date(targetTime) - +new Date(japanTime);
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    }

    return (
        <div>
            {Object.keys(timeLeft).length === 0 ? '' :
                <>
                    {/* {timeLeft.days < 10 ? `0${timeLeft.days}` : timeLeft.days}:{timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours}:{timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes} */}
                    {timeLeft.days < 10 ? `0${timeLeft.days}` : timeLeft.days}:{timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours}:{timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes}:{timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}
                    {/* {timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours}:{timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes}:{timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds} */}
                </>
            }
        </div>
    );
};

export default CountdownTimer;

export const DateDifferenceCalculator = ({ targetDateTime }) => {
    const [timeDifference, setTimeDifference] = useState('');

    const calculateTimeDifference = (givenDateTime) => {
        setTimeDifference('')
        const givenDate = new Date(givenDateTime);
        const japanTime = givenDate.toLocaleString('en-US', {
            timeZone: 'Asia/Tokyo'
        });

        const japanDate = new Date(japanTime);
        const currentTime = new Date();

        if (japanDate > currentTime) {
            const differenceInMilliseconds = japanDate - currentTime;

            const daysDifference = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
            const hoursDifference = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutesDifference = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

            if (daysDifference === 0 && hoursDifference === 0) {
                setTimeDifference(``);
            } else if (hoursDifference === 0) {
                setTimeDifference(`${daysDifference < 10 ? `0${daysDifference}` : daysDifference} days`);
            } else {
                setTimeDifference(`${daysDifference < 10 ? `0${daysDifference}` : daysDifference} days, ${hoursDifference < 10 ? `0${hoursDifference}` : hoursDifference} hours`);
            }

        } else {
            setTimeDifference(null); // Hide the message if the time has passed
        }
    };

    useEffect(() => {
        calculateTimeDifference(targetDateTime);
    }, [targetDateTime]);

    return (
        <Typography sx={{ fontSize: "14px", fontWeight: 700 }} >
            {timeDifference && <p>{timeDifference}</p>}
        </Typography>
    );
};

export const ProgressBar = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: "center", marginTop: "20px" }}>
            <CircularProgress style={{ color: Colors.Primary }} />
        </Box>
    );
}

export const commaSeparated = (value) => {
    const numericValue = Number(value);

    if (isNaN(numericValue)) {
        return '';
    }

    return numericValue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'JPY',
        currencyDisplay: 'symbol',
    });
}

export const OnlycommaSeparated = (value) => {
    const numericValue = Number(value);

    if (isNaN(numericValue)) {
        return '';
    }

    return numericValue.toLocaleString('en-US');
}

const price = [];

for (let val = 10; val <= 1000; val += 10) {
    price.push({ label: val.toString() + 'k', value: val.toString() + '000' });
}

export const allPrice = price;


export const DateDifference = (date) => {

    const currentDate = moment();
    const modifiedDate = currentDate.subtract(4, 'hours');
    const anotherDate = moment(date, 'YYYY-MM-DD HH:mm:ss');
    const differenceInHours = anotherDate.diff(modifiedDate, 'hours');

    if (differenceInHours > 0) {
        return true
    }
    else {
        return false
    }
}

export const removeTrailingTime = (value) => {
    if (value?.endsWith(" 00:00:00")) {
        return value.slice(0, -9); // Removes " 00:00:00" from the end
    }
    return value;
}

export const parse_ = (value) => {
    var doc = new DOMParser().parseFromString(value, "text/html");
    var links = doc.querySelectorAll("span");
    console.log(links)
    links.forEach(function (i, v) {

        console.log(i.innerText);

    })
}

export const ScoreArray = [
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "3.5", value: "3.5" },
    { label: "4", value: "4" },
    { label: "4.5", value: "4.5" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "R", value: "R" },
    { label: "N", value: "N" },
    { label: "99", value: "99" },
];

const currentYear = new Date().getFullYear();

const years = []

for (let year = currentYear; year >= 1980; year--) {
    years.push({ label: year.toString(), id: year })
}

export const allYears = years;

const mileage = [];

for (let miles = 0; miles <= 1000000; miles += 10000) {
    mileage.push({ label: (OnlycommaSeparated(miles)).toString() + ` KM`, id: miles });
}

export const allMileage = mileage;

const engine = [];

for (let eng = 0; eng <= 7000; eng += 500) {
    engine.push({ label: eng.toString() + ` CC`, id: eng });
}

export const allEngineRange = engine;

const prices = [];

for (let pri = 0; pri <= 10000000; pri += 10000) {
    prices.push({ label: (commaSeparated(pri)).toString(), id: pri });
}

export const allPrices = prices;

export const isWithinJapanTimeRange = (date) => {
    const auctionMoment = japanTime(date).tz('Asia/Tokyo');
    const currentMoment = japanTime().tz('Asia/Tokyo');

    const isSameDay = currentMoment.isSame(auctionMoment, 'day');

    if (isSameDay) {
        const startOfAuctionDay = auctionMoment.clone().startOf('day').add(8, 'hours');
        const endOfAuctionDay = auctionMoment.clone().startOf('day').add(24, 'hours');

        const isWithinTimeRange = currentMoment.isBetween(startOfAuctionDay, endOfAuctionDay, null, '[)');

        return isWithinTimeRange;
    } else {

        return false;
    }
};