import { Box, Button, Checkbox, CircularProgress, Avatar, Grid, InputAdornment, OutlinedInput, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { GoHome } from 'react-icons/go';
import { FaUserAlt, FaFacebookF } from 'react-icons/fa';
import { IoKeyOutline } from 'react-icons/io5';
import { FcGoogle } from 'react-icons/fc';
import { useState } from 'react';


import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer';
import { Colors } from '../../constants/colors';
import { loginValidationSchema } from '../../constants/formikValidation';
import { loginUser } from '../../apis/adminIndex';
import HeaderUser from '../../components/HeaderUser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { appImages } from '../../assets/images';
import { motion } from 'framer-motion'

const AdminLogin = (props) => {
    const [passVisible, setPassVisible] = useState('')
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();

    const handleAdminLogin = async (values) => {
        setLoading(true)
        try {
            const res = await loginUser(values)
            // toast.success(res.message, {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            // });
            setTimeout(() => {
                document.location.reload(navigate("/admin/dashboard"));
            }, 200);
            setLoading(false)
        } catch (error) {
            console.log("🚀 ~ file: Login.jsx:30 ~ handleLogin ~ error", error)
            toast.error('Email or Password is invalid', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false)
        }
    }
    const initialValues = { email: '', password: '' }
    return (
        <div className='adminBackground'>
            {/* <HeaderUser admin /> */}
            {/* <Stack
                direction={'row'}
                display={{ xs: 'none', md: 'flex' }}
                alignItems={'center'}
                spacing={1}
                px={18}
                py={1}
            >
                <GoHome size={'16px'} />
                <Typography sx={{ fontSize: '12px', fontWeight: '300' }}>
                    Home / Login
                </Typography>
            </Stack>
            <Divider /> */}

            <Grid container spacing={2} px={{ xs: 2, md: 18 }} sx={{
                justifyContent: 'space-between',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                alignContent: 'center'
            }}>
                <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'} >

                    <Box sx={{ width: { sm: '80%', lg: '50%' }, backgroundColor: 'white', px: 2, py: 4, borderRadius: '10px', boxShadow: 1 }}>
                        <Box sx={{ textAlign: '-webkit-center', width: '100%' }}>
                            <Avatar
                                variant="square"
                                src={appImages.kmLogo1}
                                sx={{
                                    width: 120,
                                    height: 120,
                                    '& .MuiAvatar-img': { objectFit: 'contain' },
                                    alignSelf: 'center',

                                }} />
                        </Box>
                        <Typography
                            sx={{ fontSize: '36px', color: Colors.Primary }}>
                            Sign
                            <span style={{ color: 'black', fontWeight: '300' }}> In</span>
                        </Typography>
                        <Typography sx={{ fontSize: '14px' }} >Please sign in with your admin account.</Typography>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={loginValidationSchema}
                            onSubmit={handleAdminLogin}
                        >
                            {({ errors, touched, validateForm, handleSubmit }) => (
                                <Form>
                                    <Stack mt={4}>
                                        <Field name="email">
                                            {({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    sx={{ backgroundColor: '#F6F6F6', }}
                                                    placeholder="Email"
                                                    id="outlined-adornment-weight"
                                                    endAdornment={<FaUserAlt color="#ADADAD" style={{ cursor: 'pointer' }} />}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="email">
                                            {(msg) => (
                                                <Typography variant="caption" color="error">
                                                    {msg}
                                                </Typography>
                                            )}
                                        </ErrorMessage>

                                        <Field name="password">
                                            {({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    sx={{ marginTop: 2, backgroundColor: '#F6F6F6' }}
                                                    placeholder="Password"
                                                    id="outlined-adornment-weight"
                                                    endAdornment={<IoKeyOutline color="#ADADAD" size={20} style={{ cursor: 'pointer' }} onClick={() => setPassVisible(!passVisible)} />}
                                                    type={passVisible ? "" : "password"}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="password">
                                            {(msg) => (
                                                <Typography variant="caption" color="error">
                                                    {msg}
                                                </Typography>
                                            )}
                                        </ErrorMessage>
                                    </Stack>

                                    <Stack direction={'row'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} >
                                        <Stack direction={'row'} display={'flex'} alignItems={'center'} >
                                            <Checkbox
                                                sx={{
                                                    marginLeft: '-10px',
                                                    alignSelf: 'flex-start',
                                                    color: Colors.Primary,
                                                    '&.Mui-checked': {
                                                        color: Colors.Primary,
                                                    },
                                                }}
                                                defaultChecked
                                            />
                                            <span
                                                style={{ color: 'black', fontFamily: 'Roboto', fontSize: '12px' }}
                                            >
                                                Remember me
                                            </span>
                                        </Stack>
                                        <Typography sx={{
                                            fontSize: '12px',
                                            '&:hover': {
                                                cursor: 'pointer',
                                            },
                                            textDecorationLine: 'underline',
                                            color: 'red',
                                            fontWeight: '300'
                                        }} onClick={() => navigate('/forgotpassword')}>Forgot Password?</Typography>
                                    </Stack>

                                    <motion.div whileHover={{ scale: 1.02 }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                            <Button
                                                onClick={loading ? '' : handleSubmit}
                                                id="basic-button"
                                                aria-haspopup="true"
                                                sx={{
                                                    width: '100%',
                                                    marginTop: '15px',
                                                    py: 1,
                                                    backgroundColor: loading ? 'white' : Colors.Primary, '&:hover': {
                                                        backgroundColor: loading ? 'white' : Colors.Primary,
                                                    },
                                                    // width: '200px',
                                                    textTransform: 'capitalize'
                                                }}
                                                type={'submit'}
                                            >
                                                {loading ? <CircularProgress size={18} color={'warning'} /> : <Typography fontSize={'18px'} fontFamily={"Roboto"} color={'white'} >Login</Typography>}

                                            </Button>
                                        </Box>
                                    </motion.div>
                                </Form>
                            )}
                        </Formik>
                        {/* <Typography fontWeight={'300'} fontSize={'12px'} fontFamily={"Roboto"} color={'#B0B0B0'} mt={'10px'} >
                            By signing in to your profile, you agree to our
                            <span style={{
                                marginLeft: '5px',
                                color: Colors.Primary, textDecorationLine: 'underline',
                                cursor: 'pointer'
                            }}
                            >
                                Term’s of Services
                            </span>
                        </Typography> */}
                    </Box>
                </Grid>
                {/* <Divider sx={{ display: { xs: 'none', md: 'block' }, height: '50vh', marginTop: 5 }} orientation="vertical" variant="middle" flexItem />
                <Grid item xs={12} md={5} px={5} >
                    <Typography pt={3} >Login via social media</Typography>
                    <Button
                        id="basic-button"
                        aria-haspopup="true"
                        sx={{
                            marginTop: '15px',
                            backgroundColor: Colors.fbColor, '&:hover': {
                                backgroundColor: Colors.fbColor,
                            },
                            textTransform: 'capitalize',
                            paddingLeft: 2,
                            width: '250px'
                        }}
                    >
                        <FaFacebookF color='white' size={'20px'} />
                        <Typography
                            width={'250px'}
                            fontSize={'12px'}
                            fontFamily={"Roboto"}
                            color={'white'}
                            textAlign={'left'}
                            marginLeft={1}
                        >Sign in with Facebook</Typography>
                    </Button>
                    <br />
                    <Button
                        variant='outlined'
                        id="basic-button"
                        aria-haspopup="true"
                        sx={{
                            marginTop: '15px',
                            backgroundColor: Colors.white, '&:hover': {
                                backgroundColor: Colors.white,
                            },
                            textTransform: 'capitalize',
                            paddingLeft: 2,
                            width: '250px',
                            borderColor: Colors.borderColor, // Set the border color to black
                            '&:hover': {
                                borderColor: Colors.borderColor, // Change the border color on hover
                            },
                        }}
                    >
                        <FcGoogle color='white' size={'20px'} />
                        <Typography
                            width={'250px'}
                            fontSize={'12px'}
                            fontFamily={"Roboto"}
                            color={Colors.black}
                            textAlign={'left'}
                            marginLeft={1}
                        >Sign in with Gmail</Typography>
                    </Button>
                </Grid> */}
            </Grid>
            {/* <Footer /> */}
            <ToastContainer />
        </div >
    )
}

export default AdminLogin