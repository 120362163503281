import React, { useState } from 'react'
import { Typography, Box, CircularProgress, Button, OutlinedInput, Stack } from '@mui/material';
import { Colors } from '../constants/colors';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { UpdatePassValidationSchema } from '../constants/formikValidation';
import { IoKeyOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { updateUserPassword } from '../apis';

// Toast
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Settings() {
    const navigate = useNavigate()
    const [oldPassVisible, setOldPassVisible] = useState(false)
    const [passVisible, setPassVisible] = useState(false)
    const [confirmPassVisible, setConfirmPassVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const user = JSON.parse(localStorage.getItem("loggedUser"));
    console.log("🚀 ~ Settings ~ user:", user)

    const initialValues = { oldPassword: '', newPassword: '', confirmedPdassword: '' };
    const handleUpdatePassword = async (values) => {
        setLoading(true)
        try {
            await updateUserPassword(values)
            toast.success('Password Changed Successfully', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setTimeout(() => {
                localStorage.removeItem('loggedUser')
                document.location.reload(navigate("/"))
            }, 2000);
            setLoading(false)
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false)
        }
    }

    return (
        <>
            <Box sx={{
                boxShadow: 1, borderRadius: '5px', p: 2,
                width: { lg: "50%", md: "60%", sm: "90%", xs: "90%" },
                margin: "5% auto"
            }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography sx={{
                        fontSize: "1.1rem",
                        fontWeight: 500,
                        color: Colors.Primary,
                    }}>
                        Update Password
                    </Typography>
                    {user.user.Role != "Admin" &&
                        <Button
                            onClick={() => navigate('/user/payment')}
                            sx={{
                                backgroundColor: Colors.Primary, '&:hover': {
                                    backgroundColor: Colors.Primary,
                                },
                                textTransform: 'capitalize'
                            }}
                        >
                            <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Add Payment</Typography>
                        </Button>
                    }
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={UpdatePassValidationSchema}
                    onSubmit={handleUpdatePassword}
                >
                    {({ handleSubmit }) => (
                        <Form>
                            <Stack mt={1}>
                                <Field name="oldPassword">
                                    {({ field }) => (
                                        <OutlinedInput
                                            {...field}
                                            sx={{ marginTop: 2, backgroundColor: '#F6F6F6' }}
                                            placeholder="Old Password"
                                            endAdornment={<IoKeyOutline color="#ADADAD" size={20} style={{ cursor: 'pointer' }} onClick={() => setOldPassVisible(!oldPassVisible)} />}
                                            type={oldPassVisible ? "" : "password"}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage name="oldPassword">
                                    {(msg) => (
                                        <Typography variant="caption" color="error">
                                            {msg}
                                        </Typography>
                                    )}
                                </ErrorMessage>
                                <Field name="newPassword">
                                    {({ field }) => (
                                        <OutlinedInput
                                            {...field}
                                            sx={{ marginTop: 2, backgroundColor: '#F6F6F6' }}
                                            placeholder="New Password"
                                            endAdornment={<IoKeyOutline color="#ADADAD" size={20} style={{ cursor: 'pointer' }} onClick={() => setPassVisible(!passVisible)} />}
                                            type={passVisible ? "" : "password"}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage name="newPassword">
                                    {(msg) => (
                                        <Typography variant="caption" color="error">
                                            {msg}
                                        </Typography>
                                    )}
                                </ErrorMessage>
                                <Field name="confirmedPdassword">
                                    {({ field }) => (
                                        <OutlinedInput
                                            {...field}
                                            sx={{ marginTop: 2, backgroundColor: '#F6F6F6' }}
                                            placeholder="Confirm Password"
                                            endAdornment={<IoKeyOutline color="#ADADAD" size={20} style={{ cursor: 'pointer' }} onClick={() => setConfirmPassVisible(!confirmPassVisible)} />}
                                            type={confirmPassVisible ? "" : "password"}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage name="confirmedPdassword">
                                    {(msg) => (
                                        <Typography variant="caption" color="error">
                                            {msg}
                                        </Typography>
                                    )}
                                </ErrorMessage>
                            </Stack>
                            <Button
                                onClick={handleSubmit}
                                id="basic-button"
                                aria-haspopup="true"
                                sx={{
                                    marginTop: '15px',
                                    backgroundColor: loading ? 'white' : Colors.Primary, '&:hover': {
                                        backgroundColor: loading ? 'white' : Colors.Primary,
                                    },
                                    textTransform: 'capitalize'
                                }}
                                type={'submit'}
                            >
                                {loading ? <CircularProgress size={12} color={'warning'} /> : <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Update</Typography>}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Box>
            <ToastContainer />
        </>
    )
}

export default Settings