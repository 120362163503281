import { Button, CircularProgress, Divider, Grid, OutlinedInput, Stack, Typography, Box, Avatar } from '@mui/material'
import React, { useState } from 'react'
import HeaderUser from '../components/HeaderUser'
import { GoHome } from 'react-icons/go';
import { FaUserAlt, FaFacebookF } from 'react-icons/fa';
import { IoKeyOutline } from 'react-icons/io5';
import { FcGoogle } from 'react-icons/fc';
import { Colors } from '../constants/colors';
import Footer from '../components/Footer';
import { updatePassword } from '../apis';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { updatePasswordSchema } from '../constants/formikValidation';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { appImages } from '../assets/images';
import { motion } from 'framer-motion'

const UpdatePassword = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [passVisible, setPassVisible] = useState(false)
    const [confirmPassVisible, setConfirmPassVisible] = useState(false)

    const handleUpdatePassword = async (values) => {
        setLoading(true)
        if (values.Password == values.ConfirmedPassword) {
            try {
                const res = await updatePassword(values.ResetCode, values.Password, values.ConfirmedPassword)
                toast.success(res.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                console.log("🚀 ~ file: UpdatePassword.jsx:20 ~ handleUpdatePassword ~ res:", res)
                setLoading(false)
                setTimeout(() => {
                    navigate('/login')
                }, 2500);
            } catch (error) {
                console.log("🚀 ~ file: UpdatePassword.jsx:23 ~ handleUpdatePassword ~ error:", error)
                toast.error(error?.response?.data?.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false)
            }
        } else {
            toast.error('Password not matched', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false)
        }

    }

    const initialValues = { ResetCode: '', Password: '', ConfirmedPassword: '' }

    return (
        <div className='backImg'>
            {/* <HeaderUser />
            <Stack
                direction={'row'}
                display={{ xs: 'none', md: 'flex' }}
                alignItems={'center'}
                spacing={1}
                px={18}
                py={1}
            >
                <GoHome size={'16px'} />
                <Typography sx={{ fontSize: '12px', fontWeight: '300' }}>
                    Home / Forgot Password
                </Typography>
            </Stack>
            <Divider /> */}

            <Grid container spacing={2} px={{ xs: 2, md: 18 }} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={12} md={5} ml={{xs:'20px', md:0}} mt={{ xs: '45%', md: '7%' }} sx={{ backgroundColor: Colors.white, borderRadius: '10px', padding: '20px' }}>
                    <Box sx={{ textAlign: '-webkit-center', width: '100%' }}>
                        <Avatar
                            variant="square"
                            src={appImages.kmLogo1}
                            sx={{
                                width: 120,
                                height: 120,
                                '& .MuiAvatar-img': { objectFit: 'contain' },
                                alignSelf: 'center',

                            }} />
                    </Box>
                    <Typography
                        sx={{ fontSize: '36px', color: Colors.Primary }}>
                        Update
                        <span style={{ color: 'black', fontWeight: '300' }}> Password?</span>
                    </Typography>
                    {/* <Typography sx={{ fontSize: '14px' }} >Enter the email associated with your account and we'll send an email with instructions to reset your password.</Typography> */}

                    <>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={updatePasswordSchema}
                            onSubmit={handleUpdatePassword}
                        >
                            {({ errors, touched, validateForm, handleSubmit }) => (
                                <Form>
                                    {/* Use Field component for form fields */}
                                    <Stack mt={'5px'}>
                                        <Field name="ResetCode">
                                            {({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    sx={{ backgroundColor: '#F6F6F6' }}
                                                    placeholder="Reset Code"
                                                    id="outlined-adornment-weight"
                                                // endAdornment={<FaUserAlt color="#ADADAD" style={{ cursor: 'pointer' }} />}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="ResetCode">
                                            {(msg) => (
                                                <Typography variant="caption" color="error">
                                                    {msg}
                                                </Typography>
                                            )}
                                        </ErrorMessage>
                                    </Stack>

                                    <Stack mt={2}>
                                        <Field name="Password">
                                            {({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    sx={{ backgroundColor: '#F6F6F6' }}
                                                    placeholder="Password"
                                                    id="outlined-adornment-weight"
                                                    endAdornment={<IoKeyOutline color="#ADADAD" size={20} style={{ cursor: 'pointer' }} onClick={() => setPassVisible(!passVisible)} />}
                                                    type={passVisible ? "" : "password"}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="Password">
                                            {(msg) => (
                                                <Typography variant="caption" color="error">
                                                    {msg}
                                                </Typography>
                                            )}
                                        </ErrorMessage>
                                    </Stack>

                                    <Stack mt={2}>
                                        <Field name="ConfirmedPassword">
                                            {({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    sx={{ backgroundColor: '#F6F6F6' }}
                                                    placeholder="Confirm Password"
                                                    id="outlined-adornment-weight"
                                                    endAdornment={<IoKeyOutline color="#ADADAD" size={20} style={{ cursor: 'pointer' }} onClick={() => setConfirmPassVisible(!confirmPassVisible)} />}
                                                    type={confirmPassVisible ? "" : "password"}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="ConfirmedPassword">
                                            {(msg) => (
                                                <Typography variant="caption" color="error">
                                                    {msg}
                                                </Typography>
                                            )}
                                        </ErrorMessage>
                                    </Stack>
                                    <motion.div whileHover={{ scale: 1.02 }}>
                                        <Button
                                            onClick={loading ? '' : handleSubmit}
                                            type="submit"
                                            id="basic-button"
                                            aria-haspopup="true"
                                            sx={{
                                                width: '100%',
                                                marginTop: '15px',
                                                backgroundColor: loading ? 'white' : Colors.Primary, '&:hover': {
                                                    backgroundColor: loading ? 'white' : Colors.Primary,
                                                },
                                                textTransform: 'capitalize'
                                            }}
                                        >
                                            {loading ? <CircularProgress size={18} color={'warning'} /> : <Typography fontSize={'18px'} fontFamily={"Roboto"} color={'white'} >Change Password</Typography>}
                                        </Button>
                                    </motion.div>
                                </Form>
                            )}
                        </Formik>
                        {/* <Divider sx={{ marginTop: 5 }} />
                        <Typography onClick={() => navigate('/signup')} fontSize={'14px'} fontFamily={"Roboto"} color={'#333333'} mt={'10px'} >
                            Dont’t have an Account?
                            <span style={{
                                marginLeft: '5px',
                                color: Colors.Primary, textDecorationLine: 'underline',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: '500'
                            }}
                            >
                                Sign Up
                            </span>
                        </Typography> */}
                    </>
                </Grid>
                {/* <Divider sx={{ display: { xs: 'none', md: 'block' }, height: '50vh', marginTop: 5 }} orientation="vertical" variant="middle" flexItem />
                <Grid item xs={12} md={5} px={5} >
                    <Typography pt={5} >Login via social media</Typography>
                    <Button
                        id="basic-button"
                        aria-haspopup="true"
                        sx={{
                            marginTop: '15px',
                            backgroundColor: Colors.fbColor, '&:hover': {
                                backgroundColor: Colors.fbColor,
                            },
                            textTransform: 'capitalize',
                            paddingLeft: 2,
                            width: '250px'
                        }}
                    >
                        <FaFacebookF color='white' size={'20px'} />
                        <Typography
                            width={'250px'}
                            fontSize={'12px'}
                            fontFamily={"Roboto"}
                            color={'white'}
                            textAlign={'left'}
                            marginLeft={1}
                        >Sign in with Facebook</Typography>
                    </Button>
                    <br />
                    <Button
                        variant='outlined'
                        id="basic-button"
                        aria-haspopup="true"
                        sx={{
                            marginTop: '15px',
                            backgroundColor: Colors.white, '&:hover': {
                                backgroundColor: Colors.white,
                            },
                            textTransform: 'capitalize',
                            paddingLeft: 2,
                            width: '250px',
                            borderColor: Colors.borderColor, // Set the border color to black
                            '&:hover': {
                                borderColor: Colors.borderColor, // Change the border color on hover
                            },
                        }}
                    >
                        <FcGoogle color='white' size={'20px'} />
                        <Typography
                            width={'250px'}
                            fontSize={'12px'}
                            fontFamily={"Roboto"}
                            color={Colors.black}
                            textAlign={'left'}
                            marginLeft={1}
                        >Sign in with Gmail</Typography>
                    </Button>
                </Grid> */}
            </Grid>
            {/* <Footer /> */}
            <ToastContainer />
        </div>
    )
}

export default UpdatePassword