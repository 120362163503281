import { AxiosInstance } from './AxiosInstance';

export const loginUser = async (credentials) => {
    const response = await AxiosInstance.post('/PortalLogin', { ...credentials });
    localStorage.setItem("loggedUser", JSON.stringify(response.data.data));
    return response.data;
};

export const detailedUser = async (_id) => {
    const response = await AxiosInstance.get('/DetailedUser/' + _id);
    return response.data;
};

export const blockedUser = async (_id) => {
    const response = await AxiosInstance.get('/blackUserByAdmin/' + _id);
    return response.data;
};

export const getSingleBidDetail = async (_id) => {
    const response = await AxiosInstance.get('/GetSingleBid/' + _id);
    return response.data;
};

export const getMarkBidInProcess = async (_id, userID) => {
    const response = await AxiosInstance.get('/MarkBidInProcess/' + `${_id}/` + `${userID}`);
    return response.data;
};
export const getMarkBidInLose = async (_id) => {
    const response = await AxiosInstance.get('/MarkBidToLose/' + _id);
    return response.data;
};
export const getCancelUserBid = async (_id) => {
    const response = await AxiosInstance.get('/cancelUserBid/' + _id);
    return response.data;
};

export const downloadPrintPdf = async (date) => {
    const response = await AxiosInstance.get('/downloadPDFUserBid/' + date);
    return response.data;
};
export const getDeleteUser = async (_id) => {
    const response = await AxiosInstance.get('/DeleteUser/' + _id);
    return response.data;
};

export const getUserBidStatus = async (data) => {
    const response = await AxiosInstance.post(`/UserBidStatus`, data);
    return response.data;
};

export const getActivetoPending = async (_id,userID) => {
    const response = await AxiosInstance.get('/MarkBidInProcess/' + `${_id}/` + `${userID}`);
    return response.data;
};

export const downloadInvoice = async (id) => {
    const response = await AxiosInstance.get('/DownloadInvoice/' + id);
    return response.data;
};

export const verifyUser = async (_id) => {
    const response = await AxiosInstance.get('/VerifyUser/' + _id);
    return response.data;
};

export const GetAllUsers = async () => {
    const response = await AxiosInstance.get(`/GetAllUsers`);
    return response.data;
};
export const GetOneMonthReport = async (data) => {
    const response = await AxiosInstance.post(`/oneMonthReport`, data);
    return response.data;
};

export const totalUsersWidget = async () => {
    const response = await AxiosInstance.get(`/TotalUserWidget`);
    return response.data;
};
export const activeUsersWidget = async () => {
    const response = await AxiosInstance.get(`/ActiveUserWidget`);
    return response.data;
};
export const paidUsersWidget = async () => {
    const response = await AxiosInstance.get(`/PaidUserWidget`);
    return response.data;
};
export const pendingUsersWidget = async () => {
    const response = await AxiosInstance.get(`/PendingUserWidget`);
    return response.data;
};
export const getAdminBidList = async (date) => {
    const response = await AxiosInstance.get(`/AdminBidList` + date);
    return response.data;
};

export const getTransitData = async () => {
    const response = await AxiosInstance.get(`/Admintransit`);
    return response.data;
};

export const getReportData = async (date) => {
    const response = await AxiosInstance.get(`/viewDownloadUserBid?date=` + date);
    return response.data;
};

export const bidCompleteAPI = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    headers["Content-Type"] = "multipart/form-data";
    const response = await AxiosInstance.post('/BidComplete', formData);
    return response.data;
};
export const bidUpdateData = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    headers["Content-Type"] = "multipart/form-data";
    const response = await AxiosInstance.post('/BidUpdate', formData);
    return response.data;
};
