import { AxiosInstance } from './AxiosInstance';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const loginUser = async (credentials) => {
    const response = await AxiosInstance.post('/Login', { ...credentials });
    localStorage.removeItem("loggedUser");
    localStorage.setItem("loggedUser", JSON.stringify(response.data.data));
    return response.data;
};

export const registerUser = async (credentials) => {
    const response = await AxiosInstance.post('/Register', { ...credentials });
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${response.data.data.token}`;
    // localStorage.setItem("loggedUser", JSON.stringify(response.data.data));
    return response.data;
};

export const addUser = async (FirstName, LastName, Email, Phone, Role) => {
    const response = await AxiosInstance.post('/AddUser', { FirstName, LastName, Email, Phone, Role });
    // const headers = AxiosInstance.defaults.headers;
    // headers['Authorization'] = `Bearer ${response.data.data.token}`;
    // localStorage.setItem("loggedUser", JSON.stringify(response.data.data));
    return response.data;
};

export const verificationOTP = async (otp) => {
    const response = await AxiosInstance.get('/VerifyOTP/' + otp);
    return response.data;
};
export const getUserBalance = async () => {
    const response = await AxiosInstance.get('/GetUsersBalance');
    return response.data;
};

export const getAllUserTransactionList = async () => {
    const response = await AxiosInstance.get('/GetAllUsersTransaction');
    return response.data;
};

export const getAllAdminDashboardData = async () => {
    const response = await AxiosInstance.get('/AdminDashBoard');
    return response.data;
};

export const getLastWeekData = async () => {
    const response = await AxiosInstance.get('/get7daysSales');
    return response.data;
};

export const getInvoicesData = async () => {
    const response = await AxiosInstance.get(`/GetAllInvoices`);
    return response.data;
};

export const getUserTransitData = async () => {
    const response = await AxiosInstance.get(`/Usertransit`);
    return response.data;
};
export const getBidsHistoryData = async (query) => {
    const response = await AxiosInstance.get(`/UserBidHistory` + query);
    return response.data;
};

export const getUserDashBoardData = async () => {
    const response = await AxiosInstance.get('/UserDashBoard');
    return response.data;
};

export const getAllNotifications = async () => {
    const response = await AxiosInstance.get('/getAllNotifications');
    return response.data;
};

export const getUserLastBidStatus = async () => {
    const response = await AxiosInstance.get('/getLastBidStatus');
    return response.data;
};

export const getLastWeekSpending = async () => {
    const response = await AxiosInstance.get('/GetLastWeekSpending');
    return response.data;
};

export const resetPassword = async (email) => {
    const response = await AxiosInstance.get('/ResetPassword/' + email);
    return response.data;
};
export const readNotification = async (id) => {
    const response = await AxiosInstance.get('/markReadNotification/' + id);
    return response.data;
};

export const updatePassword = async (ResetCode, Password, ConfirmedPassword) => {
    const response = await AxiosInstance.post('/UpdatePassword', { ResetCode, Password, ConfirmedPassword });
    return response.data;
};

export const updateUserPassword = async (data) => {
    const response = await AxiosInstance.post('/passwordUpdate', data);
    return response.data;
};

export const verifyingPayment = async (userId, paymentId, amount, bankName) => {
    const response = await AxiosInstance.post('/VerifyPayment', { userId, paymentId, amount, bankName });
    return response.data;
};

export const addBalancePayment = async (userId, payment, bank) => {
    const response = await AxiosInstance.post('/CreateUsserPayment', { userId, payment, bank });
    return response.data;
};

export const resendOTP = async (token) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post('/ResendOTP');

    return response.data;
};

export const payment = async (token, paymentProof) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    headers["Content-Type"] = "multipart/form-data";
    const response = await AxiosInstance.post('/AddPaymentProof', paymentProof);
    return response.data;
};

export const fetchUserDetail = createAsyncThunk(
    "auth/fetchUserDetail",
    async () => {
        try {
            const response = await AxiosInstance.get('/UserDetail');
            return response.data;
        } catch (error) {
            console.log("🚀 ~ error:", error)
            throw error;
        }
    }
);

const user = JSON.parse(localStorage.getItem("loggedUser"));
const loggedUserInfo = {};

const initialState = user
    ? { isLoggedIn: true, loggedUserInfo }
    : { isLoggedIn: false, loggedUserInfo: null };



const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserDetail.fulfilled, (state, action) => {
                state.isLoggedIn = true;
                state.loggedUserInfo = action.payload.data;
            })
            .addCase(fetchUserDetail.rejected, (state) => {
                state.isLoggedIn = false;
                state.loggedUserInfo = null;
            });
    },
});

export default authSlice.reducer;