import React, { useEffect, useCallback } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// Components
import Login from "./pages/Login";
import TermConditions from "./pages/TermConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ForgotPassword from "./pages/ForgotPassword";
import SignUp from "./pages/SignUp";
import OTPView from "./pages/OTPView";
import AuthView from "./pages/AuthView";
import Dashboard from "./pages/Dashboard";
import Auction from "./pages/Auction";
import { CarDetail } from "./pages/CarDetail";
import UserBalance from "./pages/UserBalance";
import UserBid from "./pages/UserBid";
import Invoice from "./pages/Invoice";
import Payment from "./pages/Payment";
import Settings from "./pages/Settings";
import UpdatePassword from "./pages/UpdatePassword";
import AdminLogin from "./pages/Admin/AdminLogin";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import AdminUserBalance from "./pages/Admin/AdminUserBalance";
import AdminInvoice from "./pages/Admin/AdminInvoice";

//  Layout
import {
  UserLayoutStructure,
  AdminLayoutStructure,
} from "./Layouts/LayoutStructures";

//  Redux
import { useDispatch, useSelector } from "react-redux";

// Api
import { fetchUserDetail } from "./apis/index";
import {
  fetchAllMakes,
  fetchAllColors,
  fetchAllAuctionHouses,
} from "./apis/AuctionApis";
import AdminBid from "./pages/Admin/AdminBid";
import SignUpPayment from "./pages/SignUpPayment";
import ThankYou from "./pages/ThankYou";
import AdminTransit from "./pages/Admin/AdminTransit";
import Transit from "./pages/Transit";
import BidHistory from "./pages/BidHistory";
import AdminBid2 from "./pages/Admin/AdminBid2";
import MyFavorite from "./pages/MyFavorite";
import PrintPage2 from "./pages/Admin/PrintPagew";
import ReportPage from "./pages/Admin/ReportPage";
import { Box, Typography } from "@mui/material";
import ViewReport from "./pages/Admin/ViewReport";
import AdminCustomerDetail from "./pages/Admin/AdminCustomerDetail";
import UserPDF from "./pages/Admin/UserPDF";

export const RoleBasedRoutes = ({ RoleName, user }) => {
  if (RoleName === "User" && user) {
    return (
      <Routes>
        {RoleName === "User" && user && (
          <Route index path="/*" element={<Navigate to="/user/dashboard" />} />
        )}
        <Route path="/user" element={<UserLayoutStructure />}>
          <Route index path="dashboard" element={<Dashboard />} />
          <Route path="auction" element={<Auction />} />
          <Route path="car-detail/:code?" element={<CarDetail />} />
          <Route path="userbalance" element={<UserBalance />} />
          <Route path="invoice" element={<Invoice />} />
          <Route path="transit" element={<Transit />} />
          <Route path="payment" element={<Payment />} />
          <Route path="bids" element={<UserBid />} />
          <Route path="favorite" element={<MyFavorite />} />
          <Route path="settings" element={<Settings />} />
          <Route path="thankyou" element={<ThankYou />} />
          <Route path="bidshistory" element={<BidHistory />} />
        </Route>
      </Routes>
    );
  } else if (RoleName === "Admin" && user) {
    return (
      <Routes>
        {RoleName === "Admin" && user && (
          <Route index path="/*" element={<Navigate to="/admin/dashboard" />} />
        )}
        <Route path="/admin" element={<AdminLayoutStructure />}>
          <Route index path="dashboard" element={<AdminDashboard />} />
          <Route path="userbalance" element={<AdminUserBalance />} />
          <Route path="invoice" element={<AdminInvoice />} />
          <Route path="bid2" element={<AdminBid />} />
          <Route path="bid" element={<AdminBid2 />} />
          <Route path="report" element={<ReportPage />} />
          <Route path="transit" element={<AdminTransit />} />
          <Route path="settings" element={<Settings />} />
          <Route path="view-reports" element={<ViewReport />} />
          <Route path="print" element={<UserPDF />} />
          <Route path="customer-detail" element={<AdminCustomerDetail />} />
        </Route>
      </Routes>
    );
  } else if (user === null) {
    return (
      <Routes>
        {user === null && (
          <Route index path="/*" element={<Navigate to="/" />} />
        )}
        <Route index path="/" element={<AuthView />} />
        <Route path="/login" element={<Login />} />
        <Route path="/term-and-conditions" element={<TermConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/adminlogin" element={<AdminLogin />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/otp" element={<OTPView />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/newuserpayment" element={<SignUpPayment />} />
        <Route path="/updatepassword" element={<UpdatePassword />} />
        <Route path="/thankyou" element={<ThankYou />} />
      </Routes>
    );
  }
};

export function AllRoutes() {
  const dispatch = useDispatch();
  const { isLoggedIn, loggedUserInfo } = useSelector((state) => state.auth);
  const { allAuctionHouses } = useSelector((state) => state.auction);

  const getDetailedData = useCallback(() => {
    dispatch(fetchUserDetail()).unwrap();
    dispatch(fetchAllMakes()).unwrap();
    dispatch(fetchAllColors()).unwrap();
    dispatch(fetchAllAuctionHouses()).unwrap();
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      getDetailedData();
    } else {
      // If not logged in, remove the localStorage
      localStorage.removeItem("loggedUser");
    }
  }, [isLoggedIn, getDetailedData]);

  if (allAuctionHouses == null && isLoggedIn) {
    return (
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          display: "flex",
          width: "100%",
        }}
      >
        <Box>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "44px",
              fontWeight: "700",
              fontFamily: "Roboto",
            }}
          >
            404
          </Typography>
          <Typography>
            Your session has been expire please reload and login again.
          </Typography>
        </Box>
      </Box>
    );
  }

  const user = JSON.parse(localStorage.getItem("loggedUser"));
  const RoleName = user ? user.user?.Role : "";

  return (
    <BrowserRouter>
      <RoleBasedRoutes RoleName={RoleName} user={user} />
    </BrowserRouter>
  );
}
