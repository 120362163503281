import { Divider, Stack, Typography, Box, Container } from '@mui/material'
import React from 'react'
import HeaderUser from '../components/HeaderUser'
import { GoHome } from 'react-icons/go';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {

    return (
        <div>
            <HeaderUser user />
            <Stack
                direction={'row'}
                display={{ xs: 'none', md: 'flex' }}
                alignItems={'center'}
                spacing={1}
                px={18}
                py={1}
            >
                <GoHome size={'16px'} />
                <Typography sx={{ fontSize: '12px', fontWeight: '300' }}>
                    Home / Privacy Policy
                </Typography>
            </Stack>
            <Divider />
            <Box
                sx={{
                    width: {
                        md: "70%",
                        sm: "90%",
                        xs: "90%",
                    },
                    height: "auto",
                    margin: "50px auto",
                    boxShadow: 2,
                    borderRadius: '20px',
                    p: 4,
                }}
            >
                <Container>
                    <Typography textAlign="center" sx={{
                        fontSize: {
                            md: "2.125rem",
                            sm: "1.75rem",
                            xs: "1.75rem"
                        }
                    }}>
                        Privacy Policy
                    </Typography>
                    <Typography variant="subtitle2" pt={3} style={{ fontWeight: 300 }}>
                        At Kashmir Motors, we are committed to safeguarding your privacy. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you interact with our website, services, and vehicles. By accessing or using our services, you consent to the practices outlined in this policy.
                    </Typography>
                    <Box mt={2}>
                        <Typography variant="h6" mb={2}>
                            Information We Collect
                        </Typography>
                        <ul>
                            <li>
                                <Typography variant="body2" py={1}>
                                    <span style={{ fontWeight: "bold" }}>Personal Information:</span>
                                    <span style={{ fontWeight: 300, paddingLeft: "10px" }}>
                                        We may collect personal information, including your name, contact details, address, and financial information, when you purchase a vehicle or use our services.
                                    </span>
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" py={1}>
                                    <span style={{ fontWeight: "bold" }}>Vehicle Data:</span>
                                    <span style={{ fontWeight: 300, paddingLeft: "10px" }}>
                                        We collect information related to the vehicle, including make, model, mileage, and maintenance records.
                                    </span>
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" py={1}>
                                    <span style={{ fontWeight: "bold" }}>Website Data:</span>
                                    <span style={{ fontWeight: 300, paddingLeft: "10px" }}>
                                        We may collect data such as IP addresses, browser type, pages visited, and session duration when you visit our website.
                                    </span>
                                </Typography>
                            </li>
                        </ul>
                    </Box>
                    <Box mt={2}>
                        <Typography variant="h6">
                            How We Use Your Information
                        </Typography>
                        <ul>
                            <li>
                                <Typography variant="body2" py={1}>
                                    <span style={{ fontWeight: "bold" }}>Vehicle Transactions:</span>
                                    <span style={{ fontWeight: 300, paddingLeft: "10px" }}>
                                        We use your personal information to facilitate vehicle sales, financing, and delivery.
                                    </span>
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" py={1}>
                                    <span style={{ fontWeight: "bold" }}>Communication:</span>
                                    <span style={{ fontWeight: 300, paddingLeft: "10px" }}>
                                        We may contact you for service updates, promotions, or feedback related to your vehicle purchase.
                                    </span>
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" py={1}>
                                    <span style={{ fontWeight: "bold" }}>Improvement:</span>
                                    <span style={{ fontWeight: 300, paddingLeft: "10px" }}>
                                        We analyze data to improve our services, website, and customer experience.
                                    </span>
                                </Typography>
                            </li>
                        </ul>
                    </Box>
                    <Box mt={2}>
                        <Typography variant="h6">
                            Information Sharing
                        </Typography>
                        <ul>
                            <li>
                                <Typography variant="body2" py={1}>
                                    <span style={{ fontWeight: "bold" }}>Service Providers:</span>
                                    <span style={{ fontWeight: 300, paddingLeft: "10px" }}>
                                        We may share your information with service providers who assist us in delivering our services, such as lenders, insurers, and delivery companies.
                                    </span>
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" py={1}>
                                    <span style={{ fontWeight: "bold" }}>Legal Compliance:</span>
                                    <span style={{ fontWeight: 300, paddingLeft: "10px" }}>
                                        We may share your information to comply with legal obligations or respond to lawful requests.
                                    </span>
                                </Typography>
                            </li>
                        </ul>
                    </Box>
                    <Box mt={2}>
                        <Typography variant="h6">
                            Security
                        </Typography>
                        <Typography variant="subtitle2" pt={2} style={{ fontWeight: 300 }}>
                            We employ industry-standard security measures to protect your data. However, no online transmission or data storage is completely secure; therefore, we cannot guarantee absolute security.
                        </Typography>
                    </Box>
                    <Box mt={2}>
                        <Typography variant="h6">
                            Your Choices
                        </Typography>
                        <Typography variant="subtitle2" pt={2} style={{ fontWeight: 300 }}>
                            You have the right to access, correct, or delete your personal information. You can also choose to opt out of marketing communications.
                        </Typography>
                    </Box>
                    <Box mt={2}>
                        <Typography variant="h6">
                            Changes to Privacy Policy
                        </Typography>
                        <Typography variant="subtitle2" pt={2} style={{ fontWeight: 300 }}>
                            We may update this Privacy Policy periodically to reflect changes in our practices. We will post the revised policy on our website.
                        </Typography>
                    </Box>
                    <Box mt={2}>
                        <Typography variant="h6">
                            Contact Us
                        </Typography>
                        <Typography variant="subtitle2" pt={2} style={{ fontWeight: 300 }}>
                            If you have questions or concerns regarding our Privacy Policy or the handling of your data, please contact us.
                        </Typography>
                    </Box>
                </Container>
            </Box>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy;