import { Avatar, Box, Button, Checkbox, CircularProgress, Divider, Grid, OutlinedInput, Stack, Typography } from '@mui/material'
import React from 'react'
import HeaderUser from '../components/HeaderUser'
import { GoHome } from 'react-icons/go';
import { FaUserAlt, FaFacebookF } from 'react-icons/fa';
import { IoKeyOutline } from 'react-icons/io5';
import { FcGoogle } from 'react-icons/fc';
import { Colors } from '../constants/colors';
import Footer from '../components/Footer';
import { useState } from 'react';


import { Formik, Form, Field, ErrorMessage } from 'formik';
import { loginValidationSchema } from '../constants/formikValidation';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../apis';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { appImages } from '../assets/images';
import { motion } from 'framer-motion'

const Login = ({ navigation }) => {
    const [passVisible, setPassVisible] = useState('')
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();

    const handleLogin = async (values) => {
        setLoading(true)
        try {
            const res = await loginUser(values)
            // toast.success(res.message, {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            // });
            setTimeout(() => {
                document.location.reload(navigate("/user/dashboard"));
            }, 200);
            setLoading(false)
        } catch (error) {
            console.log("🚀 ~ file: Login.jsx:30 ~ handleLogin ~ error:", error)
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false)
        }
    }
    const initialValues = { email: '', password: '' }
    return (
        <div className="backImg">
            {/* <HeaderUser user /> */}
            {/* <Stack
                direction={'row'}
                display={{ xs: 'none', md: 'flex' }}
                alignItems={'center'}
                spacing={1}
                px={18}
                py={1}
            >
                <GoHome size={'16px'} />
                <Typography sx={{ fontSize: '12px', fontWeight: '300' }}>
                    Home / Login
                </Typography>
            </Stack>
            <Divider /> */}

            <Grid container spacing={0} px={{ xs: 2, md: 18 }} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                <Grid item xs={12} md={5} mt={{xs:'45%', md:'7%'}} sx={{ backgroundColor: Colors.white, borderRadius: '10px', padding: '20px' }}>
                    <Box sx={{ textAlign: '-webkit-center', width: '100%' }}>
                        <Avatar
                            variant="square"
                            src={appImages.kmLogo1}
                            sx={{
                                width: 120,
                                height: 120,
                                '& .MuiAvatar-img': { objectFit: 'contain' },
                                alignSelf: 'center',

                            }} />
                    </Box>
                    <Typography
                        sx={{ fontSize: '36px', color: Colors.Primary }}>
                        Sign
                        <span style={{ color: 'black', fontWeight: '300' }}> In</span>
                    </Typography>
                    {/* <Typography sx={{ fontSize: '14px' }} >Please sign in to your account.</Typography> */}

                    <Formik
                        initialValues={initialValues}
                        validationSchema={loginValidationSchema}
                        onSubmit={handleLogin}
                    >
                        {({ errors, touched, validateForm, handleSubmit }) => (
                            <Form>
                                <Stack mt={0} sx={{ width: '100%' }}>
                                    <Field name="email">
                                        {({ field }) => (
                                            <OutlinedInput
                                                {...field}
                                                sx={{ backgroundColor: '#F6F6F6' }}
                                                placeholder="Email"
                                                endAdornment={<FaUserAlt color="#ADADAD" style={{ cursor: 'pointer' }} />}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="email">
                                        {(msg) => (
                                            <Typography variant="caption" color="error">
                                                {msg}
                                            </Typography>
                                        )}
                                    </ErrorMessage>

                                    <Field name="password">
                                        {({ field }) => (
                                            <OutlinedInput
                                                {...field}
                                                sx={{ marginTop: 2, backgroundColor: '#F6F6F6' }}
                                                placeholder="Password"
                                                endAdornment={<IoKeyOutline color="#ADADAD" size={20} style={{ cursor: 'pointer' }} onClick={() => setPassVisible(!passVisible)} />}
                                                type={passVisible ? "" : "password"}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="password">
                                        {(msg) => (
                                            <Typography variant="caption" color="error">
                                                {msg}
                                            </Typography>
                                        )}
                                    </ErrorMessage>
                                </Stack>

                                <Stack direction={'row'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} >
                                    <Stack direction={'row'} display={'flex'} alignItems={'center'} >
                                        <Checkbox
                                            sx={{
                                                marginLeft: '-10px',
                                                alignSelf: 'flex-start',
                                                color: Colors.Primary,
                                                '&.Mui-checked': {
                                                    color: Colors.Primary,
                                                },
                                            }}
                                            defaultChecked
                                        />
                                        <span
                                            style={{ color: 'black', fontFamily: 'Roboto', fontSize: '12px' }}
                                        >
                                            Remember me
                                        </span>
                                    </Stack>
                                    <Typography sx={{
                                        fontSize: '12px',
                                        '&:hover': {
                                            cursor: 'pointer',
                                        },
                                        textDecorationLine: 'underline',
                                        color: '#ED2929',
                                        fontWeight: '300'
                                    }} onClick={() => navigate('/forgotpassword')}>Forgot Password?</Typography>
                                </Stack>

                                <motion.div whileHover={{ scale: 1.02 }}>
                                    <Button
                                        onClick={handleSubmit}
                                        id="basic-button"
                                        aria-haspopup="true"
                                        sx={{
                                            width: '100%',
                                            marginTop: '15px',
                                            backgroundColor: loading ? 'white' : Colors.Primary, '&:hover': {
                                                backgroundColor: loading ? 'white' : Colors.Primary,
                                            },
                                            textTransform: 'capitalize'
                                        }}
                                        type={'submit'}
                                    >
                                        {loading ? <CircularProgress size={18} color={'warning'} /> : <Typography fontSize={'18px'} fontFamily={"Roboto"} color={'white'} >Login</Typography>}
                                    </Button>
                                </motion.div>
                                <motion.div whileHover={{ scale: 1.02 }}>
                                    <Button
                                        onClick={() => navigate('/')}
                                        id="basic-button"
                                        aria-haspopup="true"
                                        sx={{
                                            width: '100%',
                                            marginTop: '15px',
                                            backgroundColor: Colors.Primary, '&:hover': {
                                                backgroundColor: Colors.Primary,
                                            },
                                            textTransform: 'capitalize'
                                        }}
                                    >
                                        <Typography fontSize={'18px'} fontFamily={"Roboto"} color={'white'} >Back to Home</Typography>
                                    </Button>
                                </motion.div>
                            </Form>
                        )}
                    </Formik>
                    <Typography fontWeight={'300'} fontSize={'14px'} fontFamily={"Roboto"} color={Colors.black} mt={'10px'} >
                        By signing in to your profile, you agree to our
                        <span style={{
                            marginLeft: '5px',
                            color: Colors.Primary, textDecorationLine: 'underline',
                            cursor: 'pointer',
                            fontSize: '16px',
                            fontWeight: '500'
                        }}
                        >
                            Term’s of Services
                        </span>
                    </Typography>
                    {/* <Divider sx={{ marginTop: 5 }} />
                    <Typography fontSize={'14px'} fontFamily={"Roboto"} color={'#333333'} mt={'10px'} onClick={() => navigate('/signup')}>
                        Dont’t have an Account?
                        <span style={{
                            marginLeft: '5px',
                            color: Colors.Primary, textDecorationLine: 'underline',
                            cursor: 'pointer',
                            fontSize:'16px',
                            fontWeight:'500'
                        }}
                        >
                            Sign Up
                        </span>
                    </Typography> */}
                </Grid>
                {/* <Divider sx={{ display: { xs: 'none', md: 'block' }, height: '50vh', marginTop: 5 }} orientation="vertical" variant="middle" flexItem />
                <Grid item xs={12} md={5} >
                    <Box sx={{
                        width: '100%', height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                    }} >

                        <Avatar variant='square' src={appImages.cover} sx={{ overflow: 'hidden', width: '100%', height: "auto", borderRadius: '20px', '& .MuiAvatar-img': { objectFit: 'contain', borderRadius: '20px', overflow: 'hidden' }, }} />
                    </Box>
                </Grid> */}
            </Grid>
            {/* <Footer /> */}
            <ToastContainer />
        </div>
    )
}

export default Login