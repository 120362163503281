import { Grid, Typography, Box, LinearProgress, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { Colors } from '../constants/colors';
import { useState } from 'react';
import { getAllUserTransactionList, getUserDashBoardData } from '../apis';
import { commaSeparated } from '../constants/GlobalConstants';
import moment from 'moment';
import { CarDetailFunc } from './CarDetail';

// Chart
import {
    BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer
} from 'recharts';

function Dashboard() {
    const [data, setData] = useState(false)
    const [userAllTransactions, setUserAllTransactions] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [open, setOpen] = useState(false);
    const [urlArray, setUrlArray] = useState([]);
    const [detail, setDetail] = useState();
    const [chartData, setChartData] = useState()

    useEffect(() => {
        handleAllUserTransactionsList()
        handleUserDashBoardData()
    }, []);

    const handleUserDashBoardData = async () => {
        setIsLoader(true)
        try {
            const res = await getUserDashBoardData()
            let entries = Object.entries(res.data?.lastWeekSpending?.lastWeekData);
            let data = entries.map(([key, val]) => ({
                name: key,
                amount: val,
            }));
            setChartData(data)
            setData(res.data)
            setIsLoader(false)
        } catch (error) {
            console.log(error)
            setIsLoader(false)
        }
    }

    const calculateBarSize = (data) => {
        const desiredWidthPerPoint = 30;
        const barSize = Math.min(desiredWidthPerPoint, 100 / data?.length);
        return barSize + '%';
    };

    const handleAllUserTransactionsList = async () => {
        setIsLoading(true)
        try {
            const res = await getAllUserTransactionList()
            setUserAllTransactions(res?.data?.result.map((item) => {
                return {
                    id: item._id,
                    balance: commaSeparated(item?.userID?.balance),
                    fullName: item.userID?.FirstName + ' ' + item.userID?.LastName,
                    amount: commaSeparated(item?.amount),
                    type: item?.type,
                    tDetail: item?.transactionDetail,
                    date: moment(item?.createdAt).format("ll"),
                    lotNumber: item?.auctionId?.LOT,
                    aucData: item?.auctionId,
                    invData: item?.paymentId?.invoiceID
                }
            }))
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    const columns = [
        {
            field: 'date',
            headerName: 'Date',
            sortable: false,
            width: 100,
            renderCell: (params) => (
                <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                    {params?.row?.date}
                </Typography>
            ),
        },
        {
            field: 'tDetail',
            headerName: 'TXN Detail',
            sortable: false,
            width: 450,
            renderCell: ({ row }) => (
                <Box display={'flex'}>
                    <Typography sx={{ fontWeight: '500' }}>
                        {row?.aucData ? 'This Transaction Against the Lot Number' : `This Transaction Against the #:INV-`}
                    </Typography>
                    {row.aucData ?
                        <Typography
                            onClick={() => {
                                setDetail(row.aucData)
                                const urlString = row?.aucData?.IMAGES;
                                const urlParts = urlString.split('#');
                                const urlRegex = /(https?:\/\/[^\s]+)/g;
                                const urls = urlParts.flatMap(part => part.match(urlRegex));

                                if (urls) {
                                    setUrlArray(urls);
                                }
                                setOpen(true)
                            }}
                            sx={{ marginLeft: '5px', color: Colors.Primary, cursor: 'pointer', borderRadius: '5px', '&:hover': { textDecorationLine: 'underline' } }}>
                            {`${row.lotNumber}`}
                        </Typography>
                        :
                        <a className='invText' href={process.env.REACT_APP_API_URL_INVOICE + row?.invData?.file} target='_blank' rel="noreferrer">
                            {row.invData?.invoiceNumber}
                        </a>
                    }
                </Box>
            ),
        },
        {
            field: 'type',
            headerName: 'Type',
            sortable: false,
            width: 100,
            renderCell: (params) => (
                <>
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        {params?.row?.type !== "OUT" ? "Deposited" : "Spent"}
                    </Typography>
                </>
            ),
        },
        {
            field: 'amount',
            headerName: 'Amount',
            sortable: false,
            width: 100,
            renderCell: (params) => (
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        {params?.row?.amount}
                    </Typography>
                </Box>
            ),
        },
    ];

    return (
        <Grid container mt={{ xs: 7, md: 0 }} mb={{ xs: 0, md: 1 }} >
            <Grid container gap={1} marginLeft={{ xs: 0, md: -4 }} >
                <Grid item xs={12} md={2.9} sx={{ borderRadius: '5px', p: 2, borderLeft: '2px solid #6200eb', borderLeftColor: '#6200eb', backgroundColor: 'rgb(98,0,235,0.1)' }}  >
                    <Box>

                        <Box display={'flex'} alignItems={'center'}>
                            {isLoader ?
                                <Box sx={{ width: '100%' }} >
                                    <CircularProgress size={26} color={'warning'} />
                                </Box>
                                :
                                <>
                                    {!data ?
                                        <Box display={'flex'} alignItems={'center'}>
                                            <Typography sx={{ fontSize: 22, color: '#646464' }}> N/A </Typography>
                                        </Box>
                                        :
                                        <>
                                            <Typography sx={{ fontSize: 22, color: Colors.black }}>{commaSeparated(data?.totalBalance?.totalBudget)}</Typography>
                                        </>
                                    }
                                </>
                            }
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography sx={{ fontSize: 18, color: Colors.black }}>Total Balance</Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} md={2.9} sx={{ borderRadius: '5px', p: 2, borderLeft: '2px solid #ffb400', borderLeftColor: '#ffb400', backgroundColor: 'rgb(255,180,2,0.1)' }}  >
                    <Box>

                        <Box display={'flex'} alignItems={'center'}>
                            {isLoader ?
                                <Box sx={{ width: '100%' }} >
                                    <CircularProgress size={26} color={'warning'} />
                                </Box>
                                :
                                <>
                                    {!data ?
                                        <Box display={'flex'} alignItems={'center'}>
                                            <Typography sx={{ fontSize: 22, color: Colors.black }}> N/A </Typography>
                                        </Box>
                                        :
                                        <>
                                            <Typography sx={{ fontSize: 22, color: Colors.black }}>{data?.auctionStatus?.recentBidWin} <span style={{ color: Colors.Primary, fontSize: 24 }}>({data?.auctionStatus?.lot})</span></Typography>
                                        </>
                                    }
                                </>
                            }
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography sx={{ fontSize: 18, color: Colors.black }}>Recent Auction Status</Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} md={2.9} sx={{ borderRadius: '5px', p: 2, borderLeft: '2px solid #9c26b0', borderLeftColor: '#9c26b0', backgroundColor: 'rgb(156,38,176,0.1)' }}>
                    <Box>

                        {isLoader ?
                            <Box sx={{ width: '100%' }} >
                                <CircularProgress size={26} color={'warning'} />
                            </Box>
                            :
                            <>
                                {!data ?
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Typography sx={{ fontSize: 22, color: Colors.black }}> N/A </Typography>
                                    </Box>
                                    :
                                    <Box display={'flex'}>
                                        <Typography sx={{ fontSize: 22, color: Colors.black }}>{data?.recentBid?.status} <span style={{ color: Colors.Primary, fontSize: 24 }}>({data?.recentBid?.lot})</span></Typography>
                                    </Box>
                                }
                            </>
                        }
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography sx={{ fontSize: 18, color: Colors.black }}>Recent Bid</Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} md={2.9} sx={{ borderRadius: '5px', p: 2, borderLeft: '2px solid #26a59a', borderLeftColor: '#26a59a', backgroundColor: 'rgb(37,165,154,0.1)' }}  >
                    <Box>
                        {isLoader ?
                            <Box sx={{ width: '100%' }} >
                                <CircularProgress size={26} color={'warning'} />
                            </Box>
                            :
                            <>
                                {!data ?
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Typography sx={{ fontSize: 22, color: Colors.black }}> N/A </Typography>
                                    </Box>
                                    :
                                    <Box display={'flex'} justifyContent={'space-between'} mt={0.5}>
                                        <Typography sx={{ fontSize: 22, color: Colors.black }}>En Route:{data?.shipStatus.EnRoute}</Typography>
                                        <Typography sx={{ fontSize: 22, color: Colors.black }}>Shipped:{data?.shipStatus.shipped}</Typography>
                                    </Box>
                                }
                            </>
                        }
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography sx={{ fontSize: 18, color: Colors.black }}>Shipping Status</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Grid container marginTop={2} gap={2} marginLeft={{ xs: 0, md: -4 }}>
                <Grid item xs={12} sm={12} md={8.7}>
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={userAllTransactions || []}
                            columns={columns.map((column) => ({
                                ...column,
                                cellClassName: 'custom-cell-class',
                            }))}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 7,
                                    },
                                },
                            }}
                            getRowHeight={() => 'auto'}
                            pageSizeOptions={[7]}
                            slots={{
                                loadingOverlay: LinearProgress,
                            }}
                            loading={isLoading}
                            disableRowSelectionOnClick
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={3} sx={{ boxShadow: 1, borderRadius: '5px', p: 2 }} >
                    <Box>
                        <Typography sx={{ fontSize: 18, marginBottom: 3 }}>Last Week Spendings</Typography>
                    </Box>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={chartData && chartData} margin={{ left: -40 }}>
                            <XAxis dataKey="name" style={{ fontSize: '12px' }} />
                            <YAxis style={{ fontSize: '12px' }} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Bar dataKey="amount" fill={Colors.Primary} barSize={calculateBarSize(chartData)} />
                        </BarChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
            {(open && urlArray) && (
                <CarDetailFunc detail={detail} open={open} setOpen={setOpen} urlArray={urlArray} adminNavigation={true} />
            )}
        </Grid>
    )
}

export default Dashboard