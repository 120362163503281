import { Autocomplete, Avatar, Box, Button, CircularProgress, Drawer, Grid, InputAdornment, LinearProgress, List, ListItem, ListItemText, OutlinedInput, Stack, TextareaAutosize, TextField, Typography } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { FaFileAlt, FaRegEdit } from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';
import { RxCross2 } from 'react-icons/rx';
import { bidUpdateData, getTransitData } from '../../apis/adminIndex';
import ThumbnailSlider from '../../components/ThumbnailSlider';
import { Colors } from '../../constants/colors';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdOutlineCancel } from 'react-icons/md';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { CarDetailFunc } from '../CarDetail';
import { commaSeparated } from '../../constants/GlobalConstants';


const AdminTransit = () => {
    const [transitData, setTransitData] = useState()
    const [loading, setLoading] = useState()
    const [detail, setDetail] = useState({})
    const [open, setOpen] = useState(false);
    const [urlArray, setUrlArray] = useState([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [eta, setETA] = useState('');
    console.log("🚀 ~ file: AdminTransit.jsx:30 ~ AdminTransit ~ eta:", eta)
    const [etd, setETD] = useState('');
    const [note, setNote] = useState('');
    const [vesselName, setVesselName] = useState('');
    const [purchaseAt, setPurchaseAt] = useState('');
    const [bidCompleteData, setBidCompleteData] = useState(null);
    console.log("🚀 ~ file: AdminTransit.jsx:54 ~ AdminTransit ~ bidCompleteData:", bidCompleteData)
    const [paymentProof, setPaymentProof] = useState([]);
    const [addUserLoader, setAddUserLoader] = useState(false)
    const [bidCompleteURl, setbidCompleteURl] = useState([]);
    const [status, setStatus] = useState("");


    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <FiRefreshCcw style={{ fontSize: "20px", cursor: "pointer", paddingRight: "15px" }}
                    onClick={() => handleTransitList()}
                />
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        );
    }

    const data = JSON.parse(localStorage.getItem("loggedUser"))

    const Status = [
        { id: 1, label: 'Pending' },
        { id: 2, label: 'Shipped' },
        { id: 3, label: 'En Route' },
        { id: 4, label: 'Arrived' },
        { id: 5, label: 'Delivered' },
        { id: 6, label: 'Closed' },
        { id: 7, label: 'Cancelled' },
    ]

    const handleDrawerToggle = (row) => {
        console.log("🚀 ~ handleDrawerToggle ~ row:", row)
        const urlString = row?.car;
        const urlParts = urlString.split('#');
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const urls = urlParts.flatMap(part => part.match(urlRegex));

        if (urls) {
            setbidCompleteURl(urls);
        }
        setBidCompleteData(row)
        setDrawerOpen(true);
    };

    const columns = [
        {
            field: 'car',
            headerName: 'Photo',
            width: 50,
            renderCell: (params) => (
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    <Avatar variant='square' sx={{ borderRadius: '5px' }} src={params?.row?.car} />
                </Box>
            ),
        },
        {
            field: 'lotNo',
            headerName: 'Lot No.',
            width: 70,
            renderCell: ({ row }) => (
                <Typography onClick={(event) => {
                    setDetail(row.item)
                    const urlString = row.car;
                    const urlParts = urlString.split('#');
                    const urlRegex = /(https?:\/\/[^\s]+)/g;
                    const urls = urlParts.flatMap(part => part.match(urlRegex));

                    if (urls) {
                        setUrlArray(urls);
                    }
                    setOpen(true)
                }}
                    alignItems={'center'} sx={{ color: Colors.Primary, cursor: 'pointer', p: '5px', borderRadius: '5px', '&:hover': { textDecorationLine: 'underline' } }}>
                    {`${row?.lotNo}`}
                </Typography>
            ),
        },
        {
            field: 'makeModel',
            headerName: 'Make / Modal',
            width: 120,
            valueGetter: (params) =>
                `${params.row.makeModel}`,
        },
        {
            field: 'eta',
            headerName: 'ETA',
            editable: true,
            width: 110,
            renderCell: (params) => (
                <Typography
                    alignItems={'center'}
                    sx={{
                        // color: Colors.Primary, 
                        // cursor: 'pointer',
                        p: '5px',
                        borderRadius: '5px',
                        // '&:hover': { textDecorationLine: 'underline' },
                        fontWeight: '300'
                    }}>
                    {params.row.eta == 'Invalid date' ? ' ' : moment(params.row.eta, 'll').format("ll")}
                </Typography>
            )
        },
        {
            field: 'etd',
            headerName: 'ETD',
            width: 110,
            renderCell: (params) => (
                <Typography
                    alignItems={'center'}
                    sx={{
                        // color: Colors.Primary, 
                        // cursor: 'pointer',
                        p: '5px',
                        borderRadius: '5px',
                        // '&:hover': { textDecorationLine: 'underline' },
                        fontWeight: '300'
                    }}>
                    {params.row.etd == 'Invalid date' ? ' ' : moment(params.row.etd, 'll').format("ll")}
                </Typography>
                // return moment(params.row.eta, 'll').format("ll")
            )
        },
        {
            field: 'fullName',
            headerName: 'Full Name',
            width: 120,
            renderCell: ({ row }) => (
                <Typography
                    alignItems={'center'}
                    sx={{
                        // color: Colors.Primary, 
                        // cursor: 'pointer',
                        p: '5px',
                        borderRadius: '5px',
                        // '&:hover': { textDecorationLine: 'underline' },
                        fontWeight: '300'
                    }}>
                    {`${row?.fullName}`}
                </Typography>
            ),
        },
        {
            field: 'vessel',
            headerName: 'Vessel',
            width: 100,
            valueGetter: (params) =>
                `${params.row.vessel}`,
        },
        {
            field: 'dop',
            headerName: 'Date of purchase',
            width: 120,
            valueGetter: (params) =>
                `${params.row.dop}`,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            renderCell: (params) => {
                return (
                    <>
                        {params.row.status === 'Pending' &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#DC8686',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                Pending
                            </Typography>}
                        {params.row.status === "Delivered" &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#9BB8CD',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                Delivered
                            </Typography>
                        }
                        {params.row.status === "En Route" &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#d797b1',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                En Route
                            </Typography>
                        }
                        {params.row.status === "Arrived" &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#7C9D96',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                Arrived
                            </Typography>
                        }
                        {params.row.status === "Shipped" &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#E9B384',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                Shipped
                            </Typography>
                        }
                        {params.row.status === "Cancelled" &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#9f9752',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                Cancelled
                            </Typography>
                        }
                        {params.row.status === "Closed" &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#c4bab6',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                Closed
                            </Typography>
                        }
                    </>
                )
            },
        },
        {
            field: 'pStatus',
            headerName: 'Payment Status',
            width: 100,
            valueGetter: (params) =>
                `${params.row.pStatus}`,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 50,
            renderCell: ({ row }) => (
                <Box onClick={() => handleDrawerToggle(row)} sx={{ backgroundColor: Colors.Primary, p: 1, borderRadius: '5px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                    <FaRegEdit
                        style={{ cursor: 'pointer' }} color={'white'} />
                </Box>
            ),
        },
    ];


    useEffect(() => {
        handleTransitList()
    }, [])



    const handleTransitList = async () => {
        setLoading(true)
        try {
            const res = await getTransitData()
            console.log("🚀 ~ file: AdminTransit.jsx:87 ~ handleBidList ~ res:", res)
            setTransitData(res?.data?.result
                .map((item) => {
                    return {
                        id: item._id,
                        car: item.IMAGES,
                        auctionDate: moment(item.AUCTION_DATE).format('ll'),
                        lotNo: item.LOT,
                        makeModel: item.MARKA_NAME + ' ' + item.MODEL_NAME,
                        eta: moment(item?.ETA).format('DD-MM-YYYY'),
                        etd: moment(item?.EDT).format('DD-MM-YYYY'),
                        fullName: item.bids?.userId?.FirstName + ' ' + item.bids?.userId?.LastName,
                        vessel: item.VessalName,
                        dop: moment(item.ConfirmDate).format('ll'),
                        status: item.DeliveryStatus,
                        pStatus: 'Paid',
                        soldPrice: item?.SoldPrice,
                        item: item
                    }
                })
            )
            setLoading(false)
        } catch (error) {
            console.log("🚀 ~ file: AdminBid.jsx:156 ~ handleBidList ~ error:", error)
            setLoading(false)
        }

    }



    const handleCloseDrawer = () => {
        setDrawerOpen(false)
        setSelectedFile(null)
    }


    const handleBidUpdate = async () => {
        setAddUserLoader(true)
        try {
            let formData = new FormData();
            formData.append("AuctionId", bidCompleteData?.id);
            formData.append("DeliveryStatus", status);
            formData.append("ETA", !eta ? '' : moment(eta).format('YYYY-MM-DD'));
            formData.append("EDT", !etd ? '' : moment(etd).format('YYYY-MM-DD'));
            formData.append("Note", note);
            formData.append("SoldPrice", Number(bidCompleteData?.soldPrice));
            formData.append("VessalName", vesselName);
            if (selectedFile && Object.entries(selectedFile).length > 0) {
                selectedFile.forEach(file => {
                    formData.append("DocFiles", file);
                });
            }

            if (!selectedFile) {
                formData.append("DocFiles", selectedFile);
            }
            const res = await bidUpdateData(data.token, formData)
            console.log("🚀 ~ file: AdminTransit.jsx:297 ~ handleBidUpdate ~ res:", res)
            handleTransitList()
            toast.success(res?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setAddUserLoader(false)
            setSelectedFile(null)
            setTimeout(() => {
                setDrawerOpen(false);
            }, 2500);
        } catch (error) {
            console.log("🚀 ~ file: AdminBid.jsx:193 ~ handleAddUser ~ error:", error)
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setAddUserLoader(false)
            setSelectedFile(null)
        }
    }

    const handleFileChange = (event) => {
        const allowedFileTypes = ["image/jpeg", "image/png", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf", "application/zip"];

        const files = event.target.files;
        console.log("🚀 ~ file: AdminTransit.jsx:332 ~ handleFileChange ~ files:", files)
        let prevArray = [];
        let filesArray = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (allowedFileTypes.includes(file.type)) {
                filesArray.push(file);

                // Set the image preview
                const previewURL = file ? URL.createObjectURL(file) : '';

                if (previewURL) {
                    prevArray.push(previewURL);
                }
            } else {
                // File type not allowed, you can handle this case (e.g., show an error message)
                toast.error('File type not allowed', {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                console.log(`File type not allowed: ${file.type}`);
            }

        }
        setSelectedFile(filesArray.length > 0 ? filesArray : null);
        // Set the image preview
        setPaymentProof(prevArray);
    };

    const handleDeleteImage = (index) => {
        const newPaymentProof = [...paymentProof];
        newPaymentProof.splice(index, 1);

        setPaymentProof(newPaymentProof);
        if (newPaymentProof.length === 0) {
            setSelectedFile(null);
        }
    };

    useEffect(() => {
        if (bidCompleteData) {
            if (bidCompleteData.eta != "Invalid date") {
                setETA(moment(moment(bidCompleteData?.eta, 'DD/MM/YYYY').format("MM-DD-YYYY")))
            }
            if (bidCompleteData.etd != "Invalid date") {
                setETD(moment(moment(bidCompleteData?.etd, 'DD/MM/YYYY').format("MM-DD-YYYY")))
            }
            setVesselName(bidCompleteData?.vessel)
            setStatus(bidCompleteData?.status)
            setNote(bidCompleteData?.item?.Note)
            setPurchaseAt(bidCompleteData?.item?.PurchaseAt)
            console.log("🚀 ~ file: AdminTransit.jsx:476 ~ AdminTransit ~ bidCompleteData:", bidCompleteData)
        }
    }, [bidCompleteData])


    const handleInputChange = (e) => {
        // Remove existing commas from the input value
        const rawValue = e.target.value.replace(/,/g, '');
        // Set the state with the raw value
        setPurchaseAt(rawValue);
    };

    const formatWithCommas = (value) => {
        // Format the value with commas
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };


    return (
        <Grid container mt={{ xs: 7, md: 0 }}>
            <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer}>
                <List sx={{ width: { xs: '350px', md: '450px' }, height: '100%', overflowY: 'scroll', marginBottom: 2 }}>
                    <ListItem>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                            <ListItemText primary="Update Transit Detail" />
                            <RxCross2 style={{ cursor: 'pointer' }} onClick={handleCloseDrawer} />
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ width: '100%' }}>
                            <ThumbnailSlider imageData={bidCompleteURl} />
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography>Lot Number:</Typography>
                            <Typography>{bidCompleteData?.lotNo}</Typography>
                            {console.log("🚀 ~ file: AdminTransit.jsx:413 ~ AdminTransit ~ bidCompleteData:", bidCompleteData)}
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography>Brand Car:</Typography>
                            <Typography>{bidCompleteData?.makeModel}</Typography>
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box
                            // boxShadow={1}
                            bgcolor={Colors.white}
                            borderRadius={'20px'}
                            width={{ md: '95%' }}
                        >
                            {!selectedFile ?
                                <label htmlFor="fileInput">
                                    <Box sx={{ backgroundColor: '#F6F6F6', border: 1, width: '100%', px: '10px', py: '40px', borderRadius: '5px', borderStyle: 'dashed', cursor: 'pointer', textAlign: 'center' }}>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            onChange={handleFileChange}
                                            multiple
                                            style={{ display: 'none' }}
                                        />
                                        <IoCloudUploadOutline color={'#6E6E6E'} size={30} />
                                        <Typography sx={{ color: '#6E6E6E', fontSize: '12px' }}>Browse file to Upload</Typography>
                                    </Box>
                                </label>
                                :
                                <Box sx={{ backgroundColor: 'white', display: 'flex', gap: '10px', overflowX: 'scroll', py: 1 }}>
                                    {paymentProof.map((item, index) => (
                                        <Box sx={{ width: '100px', borderRadius: '5px', border: '0.1px solid grey', position: 'relative', overflow: 'hidden' }} >
                                            {/* <img key={index} src={item} style={{ width: '100px', height: '100px', position: 'relative' }} /> */}
                                            <Avatar variant='square' key={index} src={item} sx={{ backgroundColor: '#f3f3f3', width: '100px', height: '100px', position: 'relative', '& .MuiAvatar-img': { objectFit: 'contain' } }} >
                                                <FaFileAlt size={44} color={'gray'} />
                                            </Avatar>
                                            <MdOutlineCancel
                                                style={{
                                                    cursor: 'pointer',
                                                    top: '0px',
                                                    right: '0px',
                                                    position: 'absolute'
                                                }}
                                                onClick={() => handleDeleteImage(index)} />
                                        </Box>
                                    ))}
                                </Box>
                            }
                        </Box>
                    </ListItem>
                    <ListItem >
                        <Box sx={{ width: '100%' }} >
                            <Typography>ETA</Typography>
                            <DatePicker
                                selected={eta && !isNaN(new Date(eta).getTime()) ? new Date(eta) : null}
                                onSelect={(e) => setETA(e)}
                                className='etdInput'
                                dateFormat={'dd/MM/yyyy'}
                            />
                        </Box>
                    </ListItem>
                    <ListItem >
                        <Box sx={{ width: '100%' }} >
                            <Typography>ETD</Typography>
                            <DatePicker
                                selected={etd && !isNaN(new Date(etd).getTime()) ? new Date(etd) : null}
                                onSelect={(e) => setETD(e)}
                                className='etdInput'
                                dateFormat={'dd/MM/yyyy'}
                            />
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ width: '100%' }}>
                            <Typography>Vessel Name</Typography>
                            <OutlinedInput
                                onChange={(e) => setVesselName(e.target.value)}
                                sx={{ width: '100%', backgroundColor: '#F6F6F6' }} placeholder={'Enter Vessel Name'} value={vesselName} />
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ width: '100%' }}>
                            <Typography>Status</Typography>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={Status}
                                disableClearable
                                value={status && status}
                                sx={{ width: '100%', backgroundColor: '#F6F6F6', marginTop: '16px' }}
                                onChange={(e, v) => {
                                    setStatus(v?.label)
                                }}

                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ width: '100%' }}>
                            <Typography>Purchase At</Typography>
                            <OutlinedInput
                                onChange={handleInputChange}
                                value={formatWithCommas(purchaseAt)}
                                // onChange={(e) => setPurchaseAt(e.target.value)}
                                // value={purchaseAt}
                                sx={{ width: '100%', backgroundColor: '#F6F6F6' }}
                                placeholder={'Enter Purchase Amount'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Typography sx={{ fontSize: '20px', color: Colors.lightgray }}>¥</Typography>
                                    </InputAdornment>
                                }
                            />
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ width: '100%' }}>
                            <Typography>Note</Typography>
                            <TextareaAutosize
                                onChange={(e) => setNote(e.target.value)}
                                style={{ width: '95%', borderColor: 'GrayText', borderRadius: '5px', backgroundColor: '#F6F6F6', padding: '10px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '500' }}
                                minRows={4}
                                value={note && note}
                            />
                        </Box>
                    </ListItem>
                    <ListItem >
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Button
                                onClick={handleBidUpdate}
                                sx={{
                                    backgroundColor: addUserLoader ? 'white' : Colors.Primary, '&:hover': {
                                        backgroundColor: addUserLoader ? 'white' : Colors.Primary
                                    },
                                    width: '100px'
                                }}
                            >
                                {addUserLoader ? <CircularProgress size={14} color={'warning'} /> : <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Submit</Typography>}
                            </Button>
                            <Button
                                id="basic-button"
                                aria-haspopup="true"
                                sx={{
                                    backgroundColor: '#E8E9ED', '&:hover': {
                                        backgroundColor: '#E8E9ED'
                                    },
                                    width: '100px'
                                }}
                                onClick={handleCloseDrawer}
                            >
                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'#878787'} >Cancel</Typography>
                            </Button>
                        </Stack>
                    </ListItem>
                </List>
            </Drawer>
            <Grid container md={12} marginTop={2} justifyContent={'center'}>
                <Grid item xs={11} md={11} p={2} borderRadius={'5px'}>
                    <Box sx={{ height: { xs: '600px', md: '550px' }, width: '100%' }}>
                        <DataGrid
                            rows={transitData || []}
                            // columns={columns}
                            columns={columns.map((column) => ({
                                ...column,
                                cellClassName: 'custom-cell-class',
                            }))}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 20,
                                    },
                                },
                            }}
                            pageSizeOptions={[20]}
                            // checkboxSelection
                            disableRowSelectionOnClick
                            slots={{
                                loadingOverlay: LinearProgress,
                                toolbar: CustomToolbar,
                            }}
                            loading={loading}
                            // slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                },
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
            {
                (open && urlArray) && (
                    <CarDetailFunc detail={detail} open={open} setOpen={setOpen} urlArray={urlArray} adminNavigation={true} />
                )
            }

            <ToastContainer />
        </Grid >
    )
}

export default AdminTransit