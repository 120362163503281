export const appImages = {
  kmLogo1: require("../images/kmLogo1.png"),
  bell: require("../images/bell.png"),
  theme: require("../images/theme.png"),
  lang: require("../images/lang.png"),
  fbIcon: require("../images/fbIcon.png"),
  footerFb: require("../images/footerFb.png"),
  footerInsta: require("../images/footerInsta.png"),
  footerYt: require("../images/footerYt.png"),
  footerTwitter: require("../images/footerTwitter.png"),
  emailVerify: require("../images/emailVerify.png"),
  homeBack: require("../images/backImage2.png"),
  // homeBack: require("../images/homeBack.png"),
  type: require("../images/type.png"),
  calendar: require("../images/calendar.png"),
  milage: require("../images/milage.png"),
  carImg: require("../images/carImg.png"),
  map: require("../images/map.png"),
  rCar: require("../images/rCar.png"),
  pCar: require("../images/pCar.png"),
  cUS: require("../images/cUS.png"),
  blackBack: require("../images/blackBack.png"),
  otherCard: require("../images/otherCard.png"),
  adorablePrice: require("../images/adorablePrice.png"),
  CusSatis: require("../images/CusSatis.png"),
  payBack: require("../images/payBack.png"),
  auction: require("../images/auction.png"),
  carAvail: require("../images/carAvail.png"),
  sold: require("../images/sold.png"),
  wMap: require("../images/wMap.png"),
  contact: require("../images/contact.png"),
  // members: require("../images/members.png"),
  members: require("../images/member1.png"),
  customers: require("../images/customers.png"),
  graph: require("../images/graph.png"),
  briefcase: require("../images/briefcase.png"),
  profit: require("../images/profit.png"),
  tSale: require("../images/tSale.png"),
  tansaction: require("../images/tansaction.png"),
  visitor: require("../images/visitor.png"),
  visitorByDay: require("../images/visitorByDay.png"),
  tUser: require("../images/tUser.png"),
  aUser: require("../images/aUser.png"),
  pUser: require("../images/pUser.png"),
  penUser: require("../images/penUser.png"),
  client: require("../images/client.png"),
  invoice: require("../images/invoice.png"),
  paid: require("../images/paid.png"),
  unpaid: require("../images/unpaid.png"),
  thanks: require("../images/thanks.png"),
  cover: require("../images/cover.jpg"),
  honda: require("../images/honda.png"),
  toyota: require("../images/toyo.png"),
  mercedes: require("../images/merb.png"),
  mazda: require("../images/mazda.png"),
  proton: require("../images/proton.png"),
  kia: require("../images/kia.png"),
  bmw: require("../images/bmw.png"),
  mg: require("../images/mg.png"),
  nissan: require("../images/nissan.png"),
  peugeot: require("../images/peugeot.png"),
  suzuki: require("../images/suzuki-1.png"),
  daihatsu: require("../images/daihatsu.png"),
  audi: require("../images/audi.png"),
  adminBackImg: require("../images/backImage1.png"),
  cross: require("../images/cross.png"),
  tick: require("../images/tick.png"),
  japan: require("../images/japan.png"),
  lexus: require("../images/lexus.png"),
  mitsubishi: require("../images/mitsubishi.png"),
  volkswagon: require("../images/volkswagon.png"),
  subaru: require("../images/subaru.png"),
};
