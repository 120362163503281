import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import { Colors } from '../constants/colors';
import CountdownTimer, { commaSeparated, allPrice, DateDifference, removeTrailingTime, DateDifferenceCalculator } from '../constants/GlobalConstants';
import {
    Grid, Box, Breadcrumbs, Typography, Card, CardHeader, Divider, List, ListItem, Button, Autocomplete, TextField, Modal, FormControl, CircularProgress, Backdrop, InputAdornment
} from '@mui/material';

// icon
import { RxCross2 } from "react-icons/rx";
import { FaStar, FaRegStar } from "react-icons/fa"

import { useFormik } from "formik";
import * as yup from "yup";

// Component 
import ThumbnailSlider from "../components/ThumbnailSlider";

// Api
import { CreateUserBid, GetCarDetail, DownloadCarImages, MarkFavorite, DeleteFvrt } from "../apis/AuctionApis"

import moment from 'moment';

//  Toast
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//  Redux
import { useSelector } from "react-redux";
import { GetCarDetailByChassisNumber } from '../apis/AuctionApi';

const headerTypo = {
    fontSize: "1rem",
    fontWeight: 500,
}

const ListItemStyle = {
    color: Colors.lightgray,
    borderBottom: "1px solid #f3f3f3",
}

const modalstyle = {
    width: {
        lg: "60%",
        md: "60%",
        sm: "87%",
        xs: "87%"
    },
    p: {
        lg: 3.5,
        md: 3.5,
        sm: 1.5,
        xs: 1.5
    },
    height: {
        lg: 500,
        md: 500,
        sm: 550,
        xs: 550,
    },
    overflowY: "auto",
    margin: "2rem auto 0",
    bgcolor: 'white',
    border: 'white',
    boxShadow: 24,
    borderRadius: '10px'
};

const stylePaymentModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 350, md: 850 },
    bgcolor: 'white',
    border: 'white',
    boxShadow: 24,
    p: { xs: 2, md: 4 },
    borderRadius: '10px'
};

export const CarDetail = () => {
    const { code } = useParams();
    console.log("🚀 ~ CarDetail ~ code:", code)
    const [urlArray, setUrlArray] = useState([]);
    const [detail, setDetail] = useState({});
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false)
    const [bidStatus, setBidStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [countext, setCountText] = useState("");
    const [newChassisNumber, setNewChassisNumber] = useState("");
    const [spinner, setSpinner] = useState(false);
    const [chassisModal, setChassisModal] = useState(false);
    const [chassisData, setChasisData] = useState()

    useEffect(() => {
        if (code) {
            getDetail(code)
        }
    }, [code]);

    const getDetail = async (id) => {
        setLoading(true)
        try {
            const response = await GetCarDetail(id);
            const data = response.data[0]
            console.log("🚀 ~ getDetail ~ data:", data)

            const val = DateDifference(data.AUCTION_DATE);
            setShow(val)

            setDetail(data)
            const urlString = data.IMAGES;
            const urlParts = urlString.split('#');
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            const urls = urlParts.flatMap(part => part.match(urlRegex));

            let chassisValue = `${data.KUZOV}-`
            setNewChassisNumber(chassisValue)
            if (urls) {
                setUrlArray(urls);
            }
            setLoading(false)
        } catch (error) {
            console.log("🚀 ~ getDetail ~ error:", error)
            setLoading(false)
        }

    };

    useEffect(() => {
        if (bidStatus) {
            toast.success('Your bid has been submitted successfully', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            setBidStatus(false)
        }
    }, [bidStatus]);

    const downloadFile = async () => {
        console.log(code)
        setLoading(true);
        const response = await DownloadCarImages(code);
        if (response.error === true) {
            toast.error('Your Daily Limit is Reached', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            setLoading(false)
        } else {
            const data = response.data?.imagesRes[0]
            setLoading(false)
            const val = DateDifference(data.AUCTION_DATE);
            setShow(val)
            const urlString = data.IMAGES;
            if (urlString === "_need wait 1 min or more") {
                toast.error('Wait for 2 min', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            } else {
                setDetail(data);
                setCountText(`You have downloaded ${response.data?.count}/10 cars pictures`)
                const urlParts = urlString.split('#');
                const urlRegex = /(https?:\/\/[^\s]+)/g;
                const urls = urlParts.flatMap(part => part.match(urlRegex));
                if (urls) {
                    setUrlArray(urls);
                }
            }

        }




    }

    const handleChassisNumber = async () => {
        setSpinner(true)
        try {
            const res = await GetCarDetailByChassisNumber(newChassisNumber)
            setChasisData(convertAnchorsToParagraphs(res.data))
            setSpinner(false)
            setChassisModal(true)
            console.log("🚀 ~ handleChassisNumber ~ res:", res.data)
        } catch (error) {
            console.log("🚀 ~ handleChassisNumber ~ error:", error)
            setSpinner(false)
        }
    }

    function convertAnchorsToParagraphs(html) {
        // Create a temporary div element
        var tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;

        // Select all anchor tags
        var anchorTags = tempDiv.querySelectorAll('a, span > a');

        // Replace each anchor tag with a <p> tag
        for (var i = 0; i < anchorTags.length; i++) {
            var anchorTag = anchorTags[i];
            var paragraphTag = document.createElement('span');
            paragraphTag.innerHTML = anchorTag.innerHTML;
            anchorTag.parentNode.replaceChild(paragraphTag, anchorTag);
        }

        // Return the modified HTML
        return tempDiv.innerHTML;
    }

    const handleChassisModalClose = () => {
        setChassisModal(false)
    }

    const userData = JSON.parse(localStorage.getItem('loggedUser'))

    const handleMarkFavorite = async (ID) => {
        setLoading(true)
        try {
            const res = await MarkFavorite(userData?.user?.id, ID)
            toast.success(res?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            getDetail(ID)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const handleDeleteFvrt = async (ID) => {
        setLoading(true)
        try {
            const res = await DeleteFvrt(ID)
            console.log("🚀 ~ handleDeleteFvrt ~ res:", res)
            toast.success(res?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false)
            getDetail(ID)
        } catch (error) {
            console.log("🚀 ~ handleDeleteFvrt ~ error:", error)
            setLoading(false)
        }
    }

    return (
        <>
            <Grid container>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Grid item xs={12} sm={12} md={12}>
                    <Box sx={{ boxShadow: 1, borderRadius: '5px', p: 1 }}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6}>
                                <Breadcrumbs aria-label="breadcrumb" color="#000" pt={1}>
                                    <Link underline="hover" to="/user/auction" style={{ color: Colors.Primary }}>
                                        KM
                                    </Link>
                                    <Typography>{detail.MARKA_NAME} {detail.MODEL_NAME} {detail.AUCTION} {detail.YEAR}</Typography>
                                </Breadcrumbs>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <Typography sx={{ color: Colors.Primary }} pt={1}>{commaSeparated(detail.FINISH)}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                {show ? (
                                    <Button variant="contained"
                                        sx={{
                                            background: "#000",
                                            '&:hover': {
                                                background: "#000",
                                            },
                                        }}
                                        onClick={() => setOpen(true)}
                                    >
                                        Bid Now
                                    </Button>
                                ) : (
                                    <Typography pt={1}>Bidding time of this car is over</Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid container marginTop={'15px'}>
                    <Grid item xs={12} sm={12} md={4}>
                        <ThumbnailSlider imageData={urlArray} />
                        {detail?.AUCTION?.includes("USS") &&
                            <>
                                <Button variant="contained"
                                    sx={{
                                        background: "#000",
                                        '&:hover': {
                                            background: "#000",
                                        },
                                    }}
                                    onClick={downloadFile}
                                >
                                    Download Images
                                </Button>
                                &nbsp; &nbsp;
                                {countext}
                            </>
                        }

                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} py={2} px={2}>
                            <TextField
                                autoComplete="off"
                                variant="outlined"
                                size="small"
                                value={newChassisNumber}
                                onChange={(e) => {
                                    setNewChassisNumber(e.target.value);
                                }}
                                placeholder="Chassis"
                                style={{ width: '100%', backgroundColor: 'white' }}
                            />
                            {spinner ?
                                <Box
                                    sx={{
                                        width: '200px',
                                        marginLeft: '10px',
                                        textAlign: 'center'
                                    }}
                                    onClick={() => { }}
                                >
                                    <CircularProgress size={18} color={'warning'} />
                                </Box> :
                                <Button variant="contained"
                                    sx={{
                                        background: "#000",
                                        '&:hover': {
                                            background: "#000",
                                        },
                                        width: '200px',
                                        marginLeft: '10px'
                                    }}
                                    onClick={handleChassisNumber}
                                >
                                    Year of prod.
                                </Button>
                            }

                        </Box>
                        <Grid container marginTop={'15px'}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box sx={{
                                    width: {
                                        md: "90%",
                                        sm: "100%",
                                        xs: "100%"
                                    }, margin: "0 auto"
                                }}>
                                    <Card>
                                        <CardHeader
                                            sx={{
                                                px: 2,
                                                py: 1,
                                                background: Colors.gray
                                            }}
                                            title={
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <Typography style={headerTypo}>Car Detail</Typography>
                                                    {detail.favStatus == 'no' ?
                                                        <Box sx={{ cursor: 'pointer' }}>
                                                            <FaRegStar size={18} onClick={() => handleMarkFavorite(code)} />
                                                        </Box>
                                                        :
                                                        <Box sx={{ cursor: 'pointer' }}>
                                                            <FaStar size={18} color={'red'} sx={{ cursor: 'pointer' }} onClick={() => handleDeleteFvrt(code)} />
                                                        </Box>
                                                    }
                                                </Box>
                                            }
                                        />
                                        <Divider />
                                        <List
                                            w-full="true"
                                            dense
                                            component="div"
                                            role="list"
                                        >
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Lot No</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{detail.LOT}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Auction</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{detail.AUCTION}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Auction Type</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{detail.AUCTION_TYPE}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Auction Date</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        {/* <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{moment(detail.AUCTION_DATE).format("DD MMM, YYYY hh:mm A")}</Typography> */}
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{removeTrailingTime(detail.AUCTION_DATE)}</Typography>
                                                        <DateDifferenceCalculator targetDateTime={moment(detail.AUCTION_DATE).format("DD MMM, YYYY hh:mm A")} />
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Make</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{detail.MARKA_NAME}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Model</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{detail.MODEL_NAME}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Year</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{detail.YEAR}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Town</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{detail.TOWN}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Engine</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{detail.ENG_V}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>PW</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{detail.PW}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>KUZOV</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{detail.KUZOV}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                        </List>
                                    </Card>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box sx={{
                                    width: {
                                        md: "90%",
                                        sm: "100%",
                                        xs: "100%"
                                    }, margin: "0 auto"
                                }}>
                                    <Card>
                                        {/* <CardHeader
                                        sx={{
                                            px: 2,
                                            py: 1,
                                            background: Colors.gray
                                        }}
                                        title={<Typography style={headerTypo}>Detail</Typography>}
                                    /> */}
                                        <Divider />
                                        <List
                                            w-full="true"
                                            dense
                                            component="div"
                                            role="list"
                                        >
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Color</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{detail.COLOR}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>KPP</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{detail.KPP}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>KPP Type</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{detail.KPP_TYPE}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>PRIV</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{detail.PRIV}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Mileage</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{detail.MILEAGE}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Equipment</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{detail.EQUIP}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Rate</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{detail.RATE}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Status</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{detail.STATUS}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Serial</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{detail.SERIAL}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Start Price</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{commaSeparated(detail.START)}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Avg. Price</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{commaSeparated(detail.AVG_PRICE)}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem
                                                role="listitem"
                                                sx={ListItemStyle}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>End Price</Typography>
                                                    </Grid>
                                                    <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{commaSeparated(detail.FINISH)}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                        </List>
                                    </Card>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {open && (
                <CarDetailFunc detail={detail} open={open} setOpen={setOpen} urlArray={urlArray} state={code} setBidStatus={setBidStatus} />
            )}
            <div>
                <Modal
                    open={chassisModal}
                    onClose={handleChassisModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={stylePaymentModal}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={handleChassisModalClose} style={{ cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Grid container md={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={12} sx={{ width: '100%' }}>
                                    <Box sx={{ width: '100%', textAlign: 'center', }}>
                                        {/* <Typography sx={{ textAlign: 'center', marginBottom: 5 }}>Are you sure you want to mark this bid as lost ?</Typography> */}
                                        <Typography sx={{ fontSize: '24px', color: Colors.Primary, textAlign: 'center' }}>Year of Manufacture</Typography>
                                        <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
                                            <div className="chassisStyle" dangerouslySetInnerHTML={{ __html: chassisData }} />
                                        </Box>
                                        <Button variant="contained"
                                            sx={{
                                                background: Colors.Primary,
                                                '&:hover': {
                                                    background: Colors.Primary,
                                                },
                                                width: '200px',
                                                marginTop: '10px',

                                            }}
                                            onClick={handleChassisModalClose}
                                        >
                                            Close
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    </Box>
                </Modal>
            </div>
            <ToastContainer />
        </>
    )
}

const Group = [
    { id: 1, label: 'A1' },
    { id: 2, label: 'A2' },
    { id: 3, label: 'A3' },
    { id: 4, label: 'A4' },
]

export const CarDetailFunc = (props) => {
    console.log("🚀 ~ file: CarDetail.jsx:522 ~ CarDetailFunc ~ props:", props)
    const { loggedUserInfo } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false)
    const [amountStatus, setAmountStatus] = useState(false)

    const userData = JSON.parse(localStorage.getItem('loggedUser'))

    useEffect(() => {
        if (props.highestBid && props.userBid) {
            if (props.highestBid?.userId?._id === props.userBid?.userId) {
                if (props.userBid.amount >= props.highestBid.amount) {
                    setAmountStatus(true)
                }
                else {
                    setAmountStatus(false)
                }
            }
            formik.setFieldValue("bidAmount", props.userBid.amount);
        }
    }, [props.highestBid, props.userBid]);

    const formik = useFormik({
        initialValues: {
            auctionId: "",
            bidAmount: "",
            bidNote: "",
            groupName: ""
        },
        validationSchema: yup.object({
            auctionId: yup.string(),
            bidAmount: yup.string().required("This field is required"),
            // userText: yup.string().required("This field is required"),
        }),
        onSubmit: (values) => {
            console.log("🚀 ~ CarDetailFunc ~ values:", values)
            // if (Number(values.bidAmount) > Number(loggedUserInfo?.balance)) {
            //     toast.error('You cannot bid this car because of low balance', {
            //         position: 'top-right',
            //         autoClose: 5000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //         theme: 'light',
            //     });
            //     return;
            // }

            values.auctionId = props.detail.ID;
            (async () => {
                setLoading(true)
                try {
                    await CreateUserBid(values);
                    props.setBidStatus(true)
                    props.setOpen(false)
                    setLoading(false)
                } catch (error) {
                    console.log("🚀 ~ error: 660", error)
                    setLoading(false)
                }

            })();
        },
    });

    return (
        <>
            <Modal
                open={props.open}
                onClose={() => props.setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={modalstyle}>
                    <Box sx={{ width: '100%', marginBottom: "10px", textAlign: '-webkit-right', alignItems: 'flex-end' }}>
                        <RxCross2 onClick={() => props.setOpen(false)} style={{ cursor: 'pointer', alignSelf: { xs: 'flex-end' } }} />
                    </Box>

                    <Grid container gap={2} justifyContent={'center'} alignItems={'center'}>
                        <Grid item md={5.8} sm={12} xs={12}>
                            <ThumbnailSlider imageData={props.urlArray} />
                        </Grid>
                        {props.adminNavigation ? (
                            <Grid item xs={12} md={5.8}>
                                <Box sx={{ overflowX: 'hidden', maxHeight: 420, overflowY: 'scroll' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                        <Typography sx={{ fontSize: '12px' }} >Auction</Typography>
                                        <Typography sx={{ fontSize: '12px' }} >{props.detail.AUCTION}</Typography>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                        <Typography sx={{ fontSize: '12px' }} >Auction Date</Typography>
                                        <Typography sx={{ fontSize: '12px' }} >{removeTrailingTime(props.detail.AUCTION_DATE)} </Typography>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                        <Typography sx={{ fontSize: '12px' }} >Year</Typography>
                                        <Typography sx={{ fontSize: '12px' }} >{props.detail.YEAR}</Typography>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                        <Typography sx={{ fontSize: '12px' }} >Marka</Typography>
                                        <Typography sx={{ fontSize: '12px' }} >{props.detail.MARKA_NAME}</Typography>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                        <Typography sx={{ fontSize: '12px' }} >Model</Typography>
                                        <Typography sx={{ fontSize: '12px' }} >{props.detail.MODEL_NAME}</Typography>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                        <Typography sx={{ fontSize: '12px' }} >Engine_V</Typography>
                                        <Typography sx={{ fontSize: '12px' }} >{props.detail.ENG_V}</Typography>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                        <Typography sx={{ fontSize: '12px' }} >Equip</Typography>
                                        <Typography sx={{ fontSize: '12px' }} >{props.detail.EQUIP}</Typography>
                                    </Box>
                                    <Divider />

                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                        <Typography sx={{ fontSize: '12px' }} >Grade</Typography>
                                        <Typography sx={{ fontSize: '12px' }} >{props.detail.GRADE}</Typography>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                        <Typography sx={{ fontSize: '12px' }} >Rate</Typography>
                                        <Typography sx={{ fontSize: '12px' }} >{props.detail.RATE}</Typography>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                        <Typography sx={{ fontSize: '12px' }} >Milage</Typography>
                                        <Typography sx={{ fontSize: '12px' }} >{props.detail.MILEAGE} KM</Typography>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                        <Typography sx={{ fontSize: '12px' }} >Status</Typography>
                                        <Typography sx={{ fontSize: '12px' }} >{props.detail.status}</Typography>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: 1, width: '98%' }}>
                                        <Typography sx={{ fontSize: '12px' }} >Market Value</Typography>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                        <Typography sx={{ fontSize: '12px' }} >Average Price</Typography>
                                        <Typography sx={{ fontSize: '12px' }} >{commaSeparated(props.detail.AVG_PRICE)}</Typography>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                        <Typography sx={{ fontSize: '12px' }} >Start Price</Typography>
                                        <Typography sx={{ fontSize: '12px' }} >{commaSeparated(props.detail.START)}</Typography>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                        <Typography sx={{ fontSize: '12px' }} >End Price</Typography>
                                        <Typography sx={{ fontSize: '12px' }} >{commaSeparated(props.detail.FINISH)}</Typography>
                                    </Box>
                                    <Divider />
                                </Box>
                            </Grid>
                        ) :
                            (
                                <Grid item md={5.8} sm={12} xs={12}>
                                    <Box sx={{ marginTop: -1 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                            <Typography sx={{ fontSize: '18px', color: Colors.Primary }} >{props.detail.LOT}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                            <Typography sx={{ fontSize: '18px', color: Colors.lightgray }} >{props.detail.MARKA_NAME} {props.detail.YEAR} {props.detail.MODEL_NAME}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '100%' }}>
                                            <Box width={'30%'}>
                                                <Typography sx={{ fontSize: '14px', textAlign: 'left', fontWeight: '300', marginTop: '5px' }} >Location</Typography>
                                                <Typography sx={{ fontSize: '14px', backgroundColor: '#eee', padding: '5px', }} >{props.detail.AUCTION}</Typography>
                                            </Box>
                                            <Box width={'30%'}>
                                                <Typography sx={{ fontSize: '14px', textAlign: 'left', fontWeight: '300', marginTop: '5px' }} >Engine</Typography>
                                                <Typography sx={{ fontSize: '14px', backgroundColor: '#eee', padding: '5px', marginLeft: '-5px' }} >{props.detail.ENG_V}cc</Typography>
                                            </Box>
                                            <Box width={'30%'}>
                                                <Typography sx={{ fontSize: '14px', textAlign: 'left', fontWeight: '300', marginTop: '5px' }} >Color</Typography>
                                                <Typography sx={{ fontSize: '14px', backgroundColor: '#eee', padding: '5px', }} >{props.detail.COLOR ? props.detail.COLOR : 'N/A'}</Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '100%' }}>
                                            <Box width={'30%'}>
                                                <Typography sx={{ fontSize: '14px', textAlign: 'left', fontWeight: '300', marginTop: '5px' }} >Rate</Typography>
                                                <Typography sx={{ fontSize: '14px', backgroundColor: '#eee', padding: '5px', }} >{props.detail.RATE}</Typography>
                                            </Box>
                                            <Box width={'65%'}>
                                                <Typography sx={{ fontSize: '14px', textAlign: 'left', fontWeight: '300', marginTop: '5px' }} >Auction Date</Typography>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography sx={{ fontSize: '14px', backgroundColor: '#eee', padding: '5px', }} >{removeTrailingTime(props.detail.AUCTION_DATE)}  </Typography>
                                                    {/* <Typography sx={{ fontSize: '14px', backgroundColor: '#eee', padding: '5px', }} > <CountdownTimer targetTime={moment(props.detail.AUCTION_DATE).format("YYYY-MM-DDTHH:mm:ss")} /></Typography> */}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Divider />
                                        {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                            <Typography sx={{ fontSize: '12px' }} >ID</Typography>
                                            <Typography sx={{ fontSize: '12px' }} >{props.detail.ID}</Typography>
                                        </Box>
                                        <Divider /> */}
                                        {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                            <Typography sx={{ fontSize: '12px' }} >Location</Typography>
                                            <Typography sx={{ fontSize: '12px' }} >{props.detail.TOWN}</Typography>
                                        </Box>
                                        <Divider />
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                            <Typography sx={{ fontSize: '12px' }} >Date</Typography>
                                            <Typography sx={{ fontSize: '12px' }} >{moment(props.detail.AUCTION_DATE).format("DD MMM, YYYY hh:mm A")}</Typography>
                                        </Box>
                                        <Divider />
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: 1, width: '98%' }}>
                                            <Typography sx={{ fontSize: '12px' }} >Information</Typography>
                                        </Box>
                                        <Divider />
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                            <Typography sx={{ fontSize: '12px' }} >Maker</Typography>
                                            <Typography sx={{ fontSize: '12px' }} >{props.detail.MARKA_NAME}</Typography>
                                        </Box>
                                        <Divider />
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                            <Typography sx={{ fontSize: '12px' }} >Model</Typography>
                                            <Typography sx={{ fontSize: '12px' }} >{props.detail.MODEL_NAME}</Typography>
                                        </Box>
                                        <Divider />
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                            <Typography sx={{ fontSize: '12px' }} >GRADE</Typography>
                                            <Typography sx={{ fontSize: '12px' }} >{props.detail.GRADE}</Typography>
                                        </Box>
                                        <Divider />
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                            <Typography sx={{ fontSize: '12px' }} >Year</Typography>
                                            <Typography sx={{ fontSize: '12px' }} >{props.detail.YEAR}</Typography>
                                        </Box>
                                        <Divider />
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                            <Typography sx={{ fontSize: '12px' }} >CC</Typography>
                                            <Typography sx={{ fontSize: '12px' }} >{props.detail.ENG_V}</Typography>
                                        </Box>
                                        <Divider />

                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                            <Typography sx={{ fontSize: '12px' }} >Mileage</Typography>
                                            <Typography sx={{ fontSize: '12px' }} >{props.detail.MILEAGE + '( Km )'}</Typography>
                                        </Box>
                                        <Divider />
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                            <Typography sx={{ fontSize: '12px' }} >Start Price</Typography>
                                            <Typography sx={{ fontSize: '12px' }} >{commaSeparated(props.detail.START)}</Typography>
                                        </Box>
                                        <Divider />
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                            <Typography sx={{ fontSize: '12px' }} >Avg. Price</Typography>
                                            <Typography sx={{ fontSize: '12px' }} >{commaSeparated(props.detail.AVG_PRICE)}</Typography>
                                        </Box> */}
                                        {!props.adminNavigation && userData.user.bidStatus == "Enable" &&
                                            <Grid container mt={{ xs: 0, md: 0 }} justifyContent={'center'} alignItems={'center'}  >
                                                <Box sx={{ width: '100%' }}>
                                                    <Typography sx={{ color: Colors.Primary, textAlign: 'left' }}>Bid Detail</Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex' }} >
                                                    <form onSubmit={formik.handleSubmit}>
                                                        <Box className='BidAmountField' >
                                                            <Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', justifyContent: 'space-between' }}>
                                                                    <FormControl className="w-full" sx={{ width: { xs: 'auto' } }} >
                                                                        <TextField
                                                                            placeholder='Enter Bid Amount'
                                                                            sx={{ width: { lg: !props.adminNavigation ? "17vw" : "22vw", md: "20vw", sm: "28vw", xs: "54vw" }, outline: 'none' }}
                                                                            onChange={(e, data) => {
                                                                                // console.log("Bid Clicked", e.target.value);
                                                                                formik.setFieldValue("bidAmount", e.target.value || '');
                                                                            }}
                                                                            InputProps={{
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                        <Typography sx={{ fontSize: '20px', marginRight: '5px', color: Colors.lightgray }}>¥</Typography>
                                                                                    </InputAdornment>
                                                                                )
                                                                            }}
                                                                        />
                                                                        {/* <Autocomplete
                                                                    size="small"
                                                                    options={allPrice}
                                                                    disableClearable
                                                                    sx={{ width: { lg: "20vw", md: "20vw", sm: "28vw", xs: "54vw" } }}
                                                                    getOptionLabel={(option) => option.label}
                                                                    value={allPrice.find((option) => option.value === String(formik.values.bidAmount)) || null}
                                                                    onChange={(e, data) => {
                                                                        formik.setFieldValue("bidAmount", data?.value || '');
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            variant="standard"
                                                                            label="Bid Amount"
                                                                        />
                                                                    )}
                                                                    ListboxProps={
                                                                        {
                                                                            style: {
                                                                                maxHeight: '150px',
                                                                            }
                                                                        }
                                                                    }
                                                                /> */}
                                                                        {formik.touched.bidAmount && formik.errors.bidAmount ? (
                                                                            <div style={{ color: Colors.Primary }}>{formik.errors.bidAmount}</div>
                                                                        ) : null}
                                                                    </FormControl>
                                                                    <Box sx={{ width: '40%' }}>
                                                                        <Autocomplete
                                                                            disablePortal
                                                                            id="combo-box-demo"
                                                                            options={Group}
                                                                            disableClearable
                                                                            sx={{ width: '100%', backgroundColor: 'white', marginTop: '5px', marginBottom: '5px' }}
                                                                            onChange={(e, v) => formik.setFieldValue("groupName", v?.label)}
                                                                            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select Group" />}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                                {props.navigation === "userbidPage" && (
                                                                    <Typography fontSize={'12px'} fontFamily={"Roboto"} sx={{ color: amountStatus ? 'green' : Colors.Primary, marginBottom: '1%', marginTop: '-5px' }} >{amountStatus ? 'Your bid is already higher than others' : 'Your bid is lower than others'}</Typography>
                                                                )}
                                                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                                    {/* <RiText /> */}
                                                                    <FormControl className="w-full" sx={{ width: { xs: 'auto' } }} >
                                                                        <TextField
                                                                            placeholder='Bid Note'
                                                                            sx={{ width: { lg: !props.adminNavigation ? "29vw" : "22vw", md: "20vw", sm: "28vw", xs: "54vw" }, outline: 'none' }}
                                                                            onChange={(e, data) => {
                                                                                formik.setFieldValue("bidNote", e.target.value || '');
                                                                            }}

                                                                        />
                                                                    </FormControl>
                                                                </Box>

                                                            </Box>
                                                            <Button
                                                                id="basic-button"
                                                                aria-haspopup="true"
                                                                sx={{
                                                                    // marginLeft: 2,
                                                                    backgroundColor: loading ? 'white' : Colors.Primary, '&:hover': {
                                                                        backgroundColor: loading ? 'white' : Colors.Primary,
                                                                    },
                                                                    textTransform: 'capitalize',
                                                                    width: { xs: '100px' },
                                                                    height: '50px',
                                                                }}
                                                                type='submit'
                                                            >
                                                                {loading ? <CircularProgress size={16} color={'warning'} /> : <Typography fontSize={'14px'} fontFamily={"Roboto"} color={'white'} >Submit Bid</Typography>}
                                                            </Button>
                                                        </Box>
                                                    </form>
                                                </Box>
                                            </Grid>
                                        }
                                    </Box>
                                </Grid>
                            )
                        }

                    </Grid>
                </Box>
            </Modal>
            <ToastContainer />
        </>
    );
}