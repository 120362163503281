import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { AppBar, Avatar, Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemText, Menu, MenuItem, Stack, Toolbar, Typography, useMediaQuery } from '@mui/material'
import { Colors } from '../constants/colors';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoDocumentsOutline, IoDocumentTextOutline, IoHomeOutline, IoLogOutOutline, IoSettingsOutline } from 'react-icons/io5';
import { RiAuctionLine } from 'react-icons/ri';
import { BiChevronDown, BiMenu } from 'react-icons/bi';
import { appImages } from '../assets/images';
import { FaRegUser } from 'react-icons/fa';

const DrawerDashboard = (props) => {
    const { loggedUserInfo } = useSelector((state) => state.auth);
    const navigate = useNavigate()
    const location = useLocation();
    const pathname = location.pathname;
    console.log("🚀 ~ file: DrawerDashboard.jsx:14 ~ DrawerDashboard ~ pathname:", pathname)
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const matches = useMediaQuery('(max-width:820px)');
    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAddPayment = () => {
        handleClose()
        navigate('/user/payment')
    }

    const adminMenu = [
        {
            title: 'Dashboard',
            url: '/admin/dashboard',
            icon: <IoHomeOutline size={18} style={{ marginRight: '10px', color: pathname === "/admin/dashboard" && Colors.white }} />
        },
        {
            title: 'Manage Customers',
            url: '/admin/userbalance',
            icon: <FaRegUser size={18} style={{ marginRight: '10px', color: pathname === "/admin/userbalance" && Colors.white }} />
        },
        {
            title: 'Running Bids',
            url: '/admin/bid',
            icon: <IoHomeOutline size={18} style={{ marginRight: '10px', color: pathname === "/admin/bid" && Colors.white }} />
        },
        {
            title: 'In Transit',
            url: '/admin/transit',
            icon: <RiAuctionLine size={18} style={{ marginRight: '10px', color: pathname === "/admin/transit" && Colors.white }} />
        },
        {
            title: 'Settings',
            url: '/admin/settings',
            icon: <IoSettingsOutline size={18} style={{ marginRight: '10px', color: pathname === "/admin/settings" && Colors.white }} />
        },

    ]

    const userMenu = [
        {
            title: 'Dashboard',
            url: '/user/dashboard',
            icon: <IoHomeOutline size={18} style={{ marginRight: '10px', color: pathname === "/user/dashboard" && Colors.white }} />
        },
        {
            title: 'Auction',
            url: '/user/auction',
            icon: <RiAuctionLine size={18} style={{ marginRight: '10px', color: pathname === "/user/auction" && Colors.white }} />
        },
        {
            title: 'Bids',
            url: '/user/bids',
            icon: <IoHomeOutline size={18} style={{ marginRight: '10px', color: pathname === "/user/bids" && Colors.white }} />
        },
        {
            title: 'Bids History',
            url: '/user/bidshistory',
            icon: <IoHomeOutline size={18} style={{ marginRight: '10px', color: pathname === "/user/bidshistory" && Colors.white }} />
        },
        {
            title: 'Invoices',
            url: '/user/invoice',
            icon: <IoDocumentTextOutline size={18} style={{ marginRight: '10px', color: pathname === "/user/invoice" && Colors.white }} />
        },
        {
            title: 'In Transit',
            url: '/user/transit',
            icon: <IoDocumentsOutline size={18} style={{ marginRight: '10px', color: pathname === "/user/transit" && Colors.white }} />
        },
        {
            title: 'My Account',
            url: '/user/account',
            icon: <FaRegUser size={18} style={{ marginRight: '10px', color: pathname === "/user/account" && Colors.white }} />
        },
        // {
        //     title: 'Company',
        //     url: '',
        //     icon: <BsBuildings size={18} style={{ marginRight: '10px', color: pathname === "/company" && Colors.white }} />
        // },
        {
            title: 'Settings',
            url: '/user/settings',
            icon: <IoSettingsOutline size={18} style={{ marginRight: '10px', color: pathname === "/user/settings" && Colors.white }} />
        },
    ]

    return (
        <>
            {loggedUserInfo?.Role?.RoleName == 'User' ? (
                <>
                    <AppBar position="fixed">
                        <Toolbar sx={{ backgroundColor: Colors.white, justifyContent: 'space-between' }}>
                            {matches && (
                                <IconButton
                                    sx={{ width: '50px' }}
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerToggle}
                                    edge="start"
                                >
                                    <BiMenu color={Colors.Primary} />
                                </IconButton>
                            )}

                            <Avatar
                                variant="square"
                                src={appImages.kmLogo1}
                                sx={{
                                    width: 50,
                                    height: 50,
                                    '& .MuiAvatar-img': { objectFit: 'contain' },
                                    alignSelf: 'center'
                                }} />
                            <Box sx={{}}>
                                <Button
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <Avatar sx={{ width: 25, height: 25, marginRight: '5px' }} />
                                    {/* <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'black'}>
                                        {loggedUserInfo ? loggedUserInfo?.FirstName + ' ' + loggedUserInfo?.LastName : ''}
                                    </Typography> */}
                                    <BiChevronDown size={'16px'} color={'black'} />
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >

                                    <MenuItem onClick={handleClose}>
                                        {loggedUserInfo ? loggedUserInfo?.FirstName + ' ' + loggedUserInfo?.LastName : ''}</MenuItem>
                                    <Divider />
                                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                                    {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
                                    {loggedUserInfo?.Role?.RoleName === "User" ? <MenuItem onClick={handleAddPayment}>Add Payment</MenuItem> : ''}
                                    <MenuItem onClick={() => {
                                        localStorage.removeItem('loggedUser')
                                        document.location.reload(navigate("/"))
                                    }} >Logout</MenuItem>
                                </Menu>
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <Drawer open={drawerOpen} onClose={handleDrawerToggle}>
                        <List sx={{ width: '60vw' }}>
                            {(userMenu).map((item, index) => {
                                return (

                                    <ListItem
                                        key={index}
                                        style={{ fontSize: '18px', color: 'black', marginTop: '5%', marginBottom: '2%', backgroundColor: pathname === item.url && Colors.Primary }}
                                        // component={<Link to={item.url} />}
                                        component={Link} to={item.url}
                                        onClick={handleDrawerToggle}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            {item.icon}
                                            <Typography sx={{ color: pathname === item.url && "white" }}>{item.title}</Typography>
                                        </Box>
                                    </ListItem>

                                )
                            })}
                            {/* <ListItem sx={{ backgroundColor: pathname === "/admin/dashboard" && Colors.Primary }} button
                                onClick={() => {
                                    handleDrawerToggle()
                                    navigate('admin/dashboard')
                                }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <IoHomeOutline size={18} style={{ marginRight: '10px', color: pathname === "/admin/dashboard" && Colors.white }} />
                                    <Typography sx={{ color: pathname === "/admin/dashboard" && Colors.white }}>
                                        Dashboard
                                    </Typography>
                                </Box>
                            </ListItem> */}
                            <ListItem button
                                onClick={() => {
                                    localStorage.removeItem('loggedUser')
                                    document.location.reload(navigate("/"))
                                }} >
                                <Box display={'inline-flex'} alignItems={'center'}>
                                    <IoLogOutOutline size={18} style={{ marginRight: '10px' }} />
                                    <Typography>Logout</Typography>
                                </Box>
                            </ListItem>
                        </List>
                    </Drawer>
                </>
            ) : (
                <>
                    <AppBar position="fixed">
                        <Toolbar sx={{ backgroundColor: Colors.white, justifyContent: 'space-between' }}>
                            {matches && (
                                <IconButton
                                    sx={{ width: '50px' }}
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerToggle}
                                    edge="start"
                                >
                                    <BiMenu color={Colors.Primary} />
                                </IconButton>
                            )}

                            <Avatar
                                variant="square"
                                src={appImages.kmLogo1}
                                sx={{
                                    width: 50,
                                    height: 50,
                                    '& .MuiAvatar-img': { objectFit: 'contain' },
                                    alignSelf: 'center'
                                }} />
                            <Box sx={{}}>
                                <Button
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <Avatar sx={{ width: 25, height: 25, marginRight: '5px' }} />
                                    {/* <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'black'}>
                                        {loggedUserInfo ? loggedUserInfo?.FirstName + ' ' + loggedUserInfo?.LastName : ''}
                                    </Typography> */}
                                    <BiChevronDown size={'16px'} color={'black'} />
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >

                                    <MenuItem onClick={handleClose}>
                                        {loggedUserInfo ? loggedUserInfo?.FirstName + ' ' + loggedUserInfo?.LastName : ''}</MenuItem>
                                    <Divider />
                                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                                    {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
                                    {loggedUserInfo?.Role?.RoleName === "User" ? <MenuItem onClick={handleAddPayment}>Add Payment</MenuItem> : ''}
                                    <MenuItem onClick={() => {
                                        localStorage.removeItem('loggedUser')
                                        document.location.reload(navigate("/"))
                                    }} >Logout</MenuItem>
                                </Menu>
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <Drawer open={drawerOpen} onClose={handleDrawerToggle}>
                        <List sx={{ width: '60vw' }}>
                            {(adminMenu).map((item, index) => {
                                return (

                                    <ListItem
                                        key={index}
                                        style={{ fontSize: '18px', color: 'black', marginTop: '5%', marginBottom: '2%', backgroundColor: pathname === item.url && Colors.Primary }}
                                        // component={<Link to={item.url} />}
                                        component={Link} to={item.url}
                                        onClick={handleDrawerToggle}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            {item.icon}
                                            <Typography sx={{ color: pathname === item.url && "white" }}>{item.title}</Typography>
                                        </Box>
                                    </ListItem>

                                )
                            })}
                            {/* <ListItem sx={{ backgroundColor: pathname === "/admin/dashboard" && Colors.Primary }} button
                                onClick={() => {
                                    handleDrawerToggle()
                                    navigate('admin/dashboard')
                                }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <IoHomeOutline size={18} style={{ marginRight: '10px', color: pathname === "/admin/dashboard" && Colors.white }} />
                                    <Typography sx={{ color: pathname === "/admin/dashboard" && Colors.white }}>
                                        Dashboard
                                    </Typography>
                                </Box>
                            </ListItem> */}
                            <ListItem button
                                onClick={() => {
                                    localStorage.removeItem('loggedUser')
                                    document.location.reload(navigate("/"))
                                }} >
                                <Box display={'inline-flex'} alignItems={'center'}>
                                    <IoLogOutOutline size={18} style={{ marginRight: '10px' }} />
                                    <Typography>Logout</Typography>
                                </Box>
                            </ListItem>
                        </List>
                    </Drawer>
                </>
            )
            }

        </>
    )
}

export default DrawerDashboard