import React, { useEffect, useState } from "react";

//  Plugin
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { Avatar, Typography } from "@mui/material";
import { appImages } from "../assets/images";

const ThumbnailSlider = (props) => {

    const [images, setImages] = useState([]);

    useEffect(() => {
        if (props.imageData) {
            const data = props.imageData;
            const updatedImages = [];

            data?.forEach((dataItem) => {
                if (dataItem) {
                    updatedImages.push({
                        original: dataItem,
                        thumbnail: dataItem,
                    });
                }
            });
            setImages(updatedImages);
        }
    }, [props.imageData]);

    return (
        <div className="wrapper">
            {images.length > 0 ? (
                <ImageGallery
                    showPlayButton={false}
                    showFullscreenButton={true}
                    items={images}
                />
            ) : (
                <Avatar sx={{
                    width: '100%',
                    height: '300px',
                    filter: 'grayscale(0.8)',
                    '& .MuiAvatar-img': { objectFit: 'contain' }
                }}
                    variant='square'
                    src={appImages.kmLogo1} />
            )}
        </div>
    );
};

export default ThumbnailSlider;
