import React, { useState, useEffect, useMemo } from 'react'
import { Colors } from '../constants/colors';
import CountdownTimer, { ProgressBar, ScoreArray, commaSeparated, allYears, allMileage, allEngineRange, allPrices, DateDifference, removeTrailingTime, parse_ } from '../constants/GlobalConstants';
import {
    Grid, Typography, Box, Card, List, ListItem, ListItemText, ListItemIcon, Checkbox, Divider, Slider,
    RadioGroup, Radio, FormControlLabel, TextField, FormControl, CardHeader, FormLabel, Button, Stack, CircularProgress, Backdrop, Drawer, Modal
} from '@mui/material';
// Component
import AuctionTable from "../components/AuctionTable"
import { Link } from "react-router-dom";

// Api
import { GetModelByMake, GetAuctionList, DownloadCarImages, GetCarDetail, GetChasisList, MarkFavorite, DeleteFvrt } from "../apis/AuctionApis"

//  Redux
import { useSelector } from "react-redux";
import Pagination from '@mui/material/Pagination';
// Toast
import { ToastContainer, toast } from 'react-toastify';

import moment from "moment"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { RxCross2 } from 'react-icons/rx';
import { BsBox, BsCardList, BsUiRadios } from "react-icons/bs";
import { FaStar, FaRegStar } from "react-icons/fa";
import { IoWarning } from "react-icons/io5";
import ThumbnailSlider from '../components/ThumbnailSlider';
import { CarDetailFunc } from './CarDetail';
import { fetchUserDetail } from '../apis';
import { AxiosInstance } from '../apis/AxiosInstance';
import axios from 'axios';
import { GetCarDetailByChassisNumber } from '../apis/AuctionApi';

const mainHeaderTypo = {
    fontSize: "1rem",
    fontWeight: 500,
    color: Colors.Primary,
    paddingBottom: "7px"
}

const listStyle = {
    width: {
        md: 190,
        sm: "100%",
        xs: "100%"
    },
    height: 190,
    overflow: 'auto',
    // backgroundColor:'#eee'
}

const listTextStyle = {
    fontSize: "12px",
    fontWeight: 700
}

const checkboxWidth = {
    minWidth: "30px",
}

const sliderTypo = {
    textAlign: "center",
    fontSize: "12px",
    fontWeight: 400
}

const checkboxColor = {
    '& .MuiSlider-thumb': {
        color: Colors.Primary
    },
    '& .MuiSlider-track': {
        color: "#000"
    },
    '& .MuiSlider-rail': {
        color: "rgba(204, 7, 26, .6)"
    },
}

const RangeHeader = {
    fontSize: ".8rem",
    fontWeight: 500,
    color: Colors.Primary,
    paddingBottom: "3px"
}

const stylePaymentModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 350, md: 850 },
    bgcolor: 'white',
    border: 'white',
    boxShadow: 24,
    p: { xs: 2, md: 4 },
    borderRadius: '10px'
};



function Auction() {
    const { allMakesData, allColorsData, allAuctionHouses } = useSelector((state) => state.auction);
    // const [yearRange, setYearRange] = useState([1970, 2023]);
    // const [mileageRange, setMileageRange] = useState([0, 1000000]);
    // const [engineRange, setEngineRange] = useState([200, 4000]);
    // const [priceRange, setPriceRange] = useState([null, null]);
    const [minYear, setMinYear] = useState(1950);
    const [maxYear, setMaxYear] = useState(new Date().getFullYear());
    const [minMileage, setMinMileage] = useState(0);
    const [maxMileage, setMaxMileage] = useState(1000000);
    const [minEngine, setMinEngine] = useState(0);
    const [maxEngine, setMaxEngine] = useState(7000);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(10000000);
    const [selectedDay, setSelectedDay] = useState(moment().format("YYYY-MM-DD"));
    const [lotNo, setLotNo] = useState('');
    const [models, setModels] = useState([]);
    const [chasis, setChasis] = useState([]);
    const [makeName, setMakeName] = useState(null);
    const [modelName, setModelName] = useState(null);
    const [chasisName, setChasisName] = useState("");
    const [selectedAuction, setSelectedAuction] = useState([]);
    const [selectedRates, setSelectedRates] = useState([]);
    const [selectedColors, setSelectedColors] = useState([]);
    const [auctionList, setAuctionList] = useState([]);
    const [modelStatus, setModelStatus] = useState(false);
    const [chasisStatus, setChasisStatus] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [activeListView, setActiveListView] = useState(false);
    const [activeDetailView, setActiveDetailView] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [bidCompleteURl, setbidCompleteURl] = useState([]);
    const [bidCompleteData, setBidCompleteData] = useState(new Date());
    const [bidTime, setBidTime] = useState();
    const [countext, setCountText] = useState("");
    const [newChassisNumber, setNewChassisNumber] = useState("");
    const [code, setCode] = useState()
    const [detail, setDetail] = useState({});
    const [bidStatus, setBidStatus] = useState(false);
    const [urlArray, setUrlArray] = useState([]);
    const [show, setShow] = useState(false)
    const [open, setOpen] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [chassisModal, setChassisModal] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [chassisData, setChasisData] = useState()

    const userData = JSON.parse(localStorage.getItem('loggedUser'))

    const setListViewClick = () => {
        setActiveListView(true);
        setActiveDetailView(false);
    }
    const listDetailClick = () => {
        setActiveDetailView(true);
        setActiveListView(false);
    }


    const allModels = async (data) => {
        setModelStatus(true)
        setModels([])
        const query = "?ModelName=" + data.name + "&date=" + moment().format("YYYY-MM-DD") + "&specificDate=" + data.selectedDay;
        const response = await GetModelByMake(query);
        setModels(response.data)
        setModelStatus(false)
    };

    const allChasis = async (data) => {
        setChasisStatus(true)
        setChasis([])
        const response = await GetChasisList(data);
        setChasis(response.data)
        setChasisStatus(false)
    };

    useEffect(() => {
        handleUserData()
    }, [])


    const handleUserData = async () => {
        try {
            const response = await AxiosInstance.get('/UserDetail');
            setUserInfo(response.data.data)
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    const handleChassisNumber = async () => {
        setSpinner(true)
        try {
            const res = await GetCarDetailByChassisNumber(newChassisNumber)
            setChasisData(convertAnchorsToParagraphs(res.data))
            setSpinner(false)
            setChassisModal(true)
            console.log("🚀 ~ handleChassisNumber ~ res:", res.data)
        } catch (error) {
            console.log("🚀 ~ handleChassisNumber ~ error:", error)
            setSpinner(false)
        }
    }

    function convertAnchorsToParagraphs(html) {
        // Create a temporary div element
        var tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;

        // Select all anchor tags
        var anchorTags = tempDiv.querySelectorAll('a, span > a');

        // Replace each anchor tag with a <p> tag
        for (var i = 0; i < anchorTags.length; i++) {
            var anchorTag = anchorTags[i];
            var paragraphTag = document.createElement('span');
            paragraphTag.innerHTML = anchorTag.innerHTML;
            anchorTag.parentNode.replaceChild(paragraphTag, anchorTag);
        }

        // Return the modified HTML
        return tempDiv.innerHTML;
    }

    function removeAnchorTags(html) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        const anchorElements = doc.getElementsByTagName('a');
        for (let i = anchorElements.length - 1; i >= 0; i--) {
            anchorElements[i].parentNode.removeChild(anchorElements[i]);
        }
        return doc.body.innerHTML;
    }

    const handleChassisModalClose = () => {
        setChassisModal(false)
    }

    const [limit, setLimit] = useState("0,50");
    const [totalPages, setTotalPages] = useState(0);

    const SearhData = async () => {
        setLoading(true)
        try {
            const auctionString = selectedAuction?.length > 0 ? `(${selectedAuction.map((auction) => `'${auction.name}'`).join(', ')})` : '';
            const rateString = selectedRates?.length > 0 ? `(${selectedRates.map((rates) => `'${rates.name}'`).join(', ')})` : '';
            const colorString = selectedColors?.length > 0 ? `(${selectedColors.map((colors) => `'${colors.name}'`).join(', ')})` : '';

            let formData = new FormData();
            if (modelName) {
                formData.append("ModelName", `('${modelName}')`);
            }
            formData.append("minYear", minYear);
            formData.append("maxYear", maxYear);
            formData.append("minMilage", minMileage);
            formData.append("maxMilage", maxMileage);
            formData.append("minEng", minEngine);
            formData.append("maxEng", maxEngine);
            formData.append("minStart", minPrice);
            formData.append("maxStart", maxPrice);
            if (rateString !== '') {
                formData.append("Rate", rateString);
            }
            if (colorString !== '') {
                formData.append("Color", colorString);
            }
            if (auctionString !== '') {
                formData.append("auction", auctionString);
            }
            if (lotNo !== '') {
                formData.append("lot", lotNo);
            }
            formData.append("auctionDate", selectedDay);
            formData.append("status", selectedStatus);
            if (selectedDay) {
                formData.append("dateCheck", selectedDay);
            } else {
                formData.append("dateCheck", moment().format("YYYY-MM-DD"));
            }

            formData.append("Chassis", chasisName == '' ? '' : chasisName);
            formData.append("limit", limit);

            const response = await GetAuctionList(formData);
            setAuctionList(response.data)
            const totalPage = Math.ceil(response?.data?.totalCount / 50);
            setTotalPages(totalPage)
            setLoading(false)
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false)
        } finally {
            setLoading(false);
        }
    };

    const handleRatesChange = useMemo(() => (rates) => {
        setSelectedRates((prevSelectedRates) => {
            const isSelected = prevSelectedRates.some((selectedRates) => selectedRates.id === rates.id);
            if (isSelected) {
                return prevSelectedRates.filter((selectedRates) => selectedRates.id !== rates.id);
            } else {
                return [...prevSelectedRates, rates];
            }
        });
    }, []);

    const handleColorsChange = useMemo(() => (color) => {
        setSelectedColors((prevSelectedColors) => {
            const isSelected = prevSelectedColors.some((selectedColors) => selectedColors.id === color.id);
            if (isSelected) {
                return prevSelectedColors.filter((selectedColors) => selectedColors.id !== color.id);
            } else {
                return [...prevSelectedColors, color];
            }
        });
    }, []);

    const handleAuctionChange = useMemo(() => (auction) => {
        setSelectedAuction((prevSelectedAuction) => {
            const isSelected = prevSelectedAuction.some((selectedAuction) => selectedAuction.id === auction.id);
            if (isSelected) {
                return prevSelectedAuction.filter((selectedAuction) => selectedAuction.id !== auction.id);
            } else {
                return [...prevSelectedAuction, auction];
            }
        });
    }, []);

    useEffect(() => {
        if (modelName) {
            SearhData();
        }
    }, [modelName, chasisName]);

    useEffect(() => {
        if (selectedDay !== '' && modelName) {
            allModels({ name: makeName, selectedDay })
            SearhData()
        }
        if (selectedDay !== '' && makeName) {
            allModels({ name: makeName, selectedDay })
        }
    }, [selectedDay, limit]);

    const customCell = {
        fontWeight: '200',
    };

    const handlePageChange = (event, page) => {
        const newLimit = `${(page - 1) * 50},50`;
        setLimit(newLimit);
    };

    const handleDrawerToggle = (row) => {
        console.log("🚀 ~ handleDrawerToggle ~ row:", row)
        const urlString = row?.IMAGES;
        const urlParts = urlString.split('#');
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const urls = urlParts.flatMap(part => part.match(urlRegex));

        if (urls) {
            // setbidCompleteURl(urls);
            setUrlArray(urls);
        }

        setCode(row.ID)
        setBidCompleteData(row)
        let chassisValue = `${row.KUZOV}-`
        setNewChassisNumber(chassisValue)
        setBidTime(moment(row.AUCTION_DATE).format("YYYY-MM-DDTHH:mm:ss"))
        setDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false)
        setCountText(null)
    }

    const ListItemStyle = {
        color: Colors.lightgray,
        borderBottom: "1px solid #f3f3f3",
    }

    const headerTypo = {
        fontSize: "1rem",
        fontWeight: 500,
    }

    useEffect(() => {
        if (code) {
            getDetail(code)
        }
    }, [code])


    const getDetail = async (id) => {
        const response = await GetCarDetail(id);
        const data = response.data[0]

        const val = DateDifference(data.AUCTION_DATE);
        setShow(val)

        setDetail(data)
        const urlString = data.IMAGES;
        const urlParts = urlString.split('#');
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const urls = urlParts.flatMap(part => part.match(urlRegex));

        if (urls) {
            setUrlArray(urls);
        }
    };

    const handleMarkFavorite = async (data) => {
        setLoading(true)
        try {
            const res = await MarkFavorite(userData?.user?.id, data?.ID)
            toast.success(res?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            SearhData()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const handleDeleteFvrt = async (data) => {
        console.log("🚀 ~ handleDeleteFvrt ~ data:", data)
        setLoading(true)
        try {
            const res = await DeleteFvrt(data?.ID)
            console.log("🚀 ~ handleDeleteFvrt ~ res:", res)
            toast.success(res?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false)
            SearhData()
        } catch (error) {
            console.log("🚀 ~ handleDeleteFvrt ~ error:", error)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (bidStatus) {
            toast.success('Your bid has been submitted successfully', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            setBidStatus(false)
            setDrawerOpen(false)
        }
    }, [bidStatus]);

    const downloadFile = async () => {
        setLoading(true);
        const response = await DownloadCarImages(code);
        if (response.error === true) {
            toast.error('Your Daily Limit is Reached', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            setLoading(false)
        } else {
            const data = response.data?.imagesRes[0]
            setLoading(false)
            const val = DateDifference(data.AUCTION_DATE);
            setShow(val)
            const urlString = data.IMAGES;
            if (urlString === "_need wait 1 min or more") {
                toast.error('Wait for 2 min', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            } else {
                setDetail(data);
                setCountText(`${response.data?.count}`)
                const urlParts = urlString.split('#');
                const urlRegex = /(https?:\/\/[^\s]+)/g;
                const urls = urlParts.flatMap(part => part.match(urlRegex));
                if (urls) {
                    setUrlArray(urls);
                }
            }

        }
    }


    // Function to move featured companies to the top
    const shuffleArray = (array, targetNames) => {
        const shuffledArray = [...array];

        // Move the target names to the top
        targetNames.forEach(name => {
            const index = shuffledArray.findIndex(item => item.MARKA_NAME.toLowerCase() === name.toLowerCase());
            if (index !== -1) {
                const [targetItem] = shuffledArray.splice(index, 1);
                shuffledArray.unshift(targetItem);
            }
        });

        return shuffledArray;
    };

    // Target names to be moved to the top
    const targetNames = ["LEXUS", "MITSUOKA", "DAIHATSU", "ISUZU", "SUBARU", "SUZUKI", "MITSUBISHI", "MAZDA", "NISSAN", "HONDA", "TOYOTA",];

    // Shuffle the array
    const featureNamesArray = shuffleArray(allMakesData, targetNames);

    return (
        <>
            <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer}>
                <List sx={{ width: { xs: '300px', md: '500px' }, height: '100%', overflowX: { xs: 'hidden' }, overflowY: 'scroll', marginBottom: 2 }}>
                    <ListItem>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                            <ListItemText primary={`${bidCompleteData.MARKA_NAME} ${bidCompleteData.MODEL_NAME} ${bidCompleteData.AUCTION} ${bidCompleteData.YEAR}`} />
                            <RxCross2 style={{ cursor: 'pointer' }} onClick={handleCloseDrawer} />
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ width: '100%' }}>
                                    <ThumbnailSlider imageData={urlArray} />
                                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} py={2}>
                                        <TextField
                                            autoComplete="off"
                                            variant="outlined"
                                            size="small"
                                            value={newChassisNumber}
                                            onChange={(e) => {
                                                setNewChassisNumber(e.target.value);
                                            }}
                                            placeholder="Chassis"
                                            style={{ width: '100%', backgroundColor: 'white' }}
                                        />
                                        {spinner ?
                                            <Box
                                                sx={{
                                                    width: '200px',
                                                    marginLeft: '10px',
                                                    textAlign: 'center'
                                                }}
                                                onClick={() => { }}
                                            >
                                                <CircularProgress size={18} color={'warning'} />
                                            </Box> :
                                            <Button variant="contained"
                                                sx={{
                                                    background: "#000",
                                                    '&:hover': {
                                                        background: "#000",
                                                    },
                                                    width: '200px',
                                                    marginLeft: '10px'
                                                }}
                                                onClick={handleChassisNumber}
                                            >
                                                Year of prod.
                                            </Button>
                                        }

                                    </Box>
                                    {bidCompleteData?.AUCTION?.includes("USS") &&
                                        <>
                                            <Button variant="contained"
                                                sx={{
                                                    background: "#000",
                                                    '&:hover': {
                                                        background: "#000",
                                                    },
                                                    width: '100%'
                                                }}
                                                onClick={downloadFile}
                                            >
                                                Download Images
                                            </Button>
                                            &nbsp; &nbsp;
                                            {countext &&
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: -1, marginBottom: 1 }}>
                                                    <IoWarning size={25} color={'#ffcc00'} />
                                                    <Typography p={1} sx={{ fontSize: '14px', textAlign: 'center', color: Colors.lightgray }}>
                                                        You have downloaded {countext}/10 pictures
                                                    </Typography>
                                                </Box>
                                            }
                                        </>
                                    }

                                </Box>

                                <Box sx={{ borderRadius: '5px' }}>
                                    {/* <Grid container> */}
                                    {/* <Grid item xs={12} sm={12} md={12}>
                                            <Typography sx={{ color: Colors.Primary }} pt={1}>{commaSeparated(bidCompleteData.FINISH)}</Typography>
                                        </Grid> */}
                                    <Box >
                                        {show ? (
                                            <Button variant="contained"
                                                sx={{
                                                    background: "#000",
                                                    '&:hover': {
                                                        background: "#000",
                                                    },
                                                    width: '100%',
                                                }}
                                                onClick={() => setOpen(true)}
                                            >
                                                Bid Now
                                            </Button>
                                        ) : (
                                            <Typography sx={{ fontSize: '14px', backgroundColor: '#FFDEDE', textAlign: 'center', borderRadius: '5px' }} p={1}>Bidding time of this car is over</Typography>
                                        )}
                                    </Box>
                                    {/* </Grid> */}
                                </Box>
                            </Box>
                            <Box sx={{ width: '100%', marginTop: '10px' }}>
                                {/* <Typography sx={{ textDecorationLine: 'underline' }}>Car Detail</Typography>
                                <Box>
                                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography>Lot Number:</Typography>
                                        <Typography>{bidCompleteData.LOT}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography>Auction:</Typography>
                                        <Typography>{bidCompleteData.AUCTION}</Typography>
                                    </Box>
                                </Box> */}
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box sx={{
                                        width: {
                                            md: "100%",
                                            sm: "100%",
                                            xs: "100%"
                                        }, margin: "0 auto"
                                    }}>
                                        <Card>
                                            <CardHeader
                                                sx={{
                                                    px: 2,
                                                    py: 1,
                                                    background: Colors.gray
                                                }}
                                                title={<Typography style={headerTypo}>Car Detail</Typography>}
                                            />
                                            <Divider />
                                            <List
                                                w-full="true"
                                                dense
                                                component="div"
                                                role="list"
                                            >
                                                <ListItem
                                                    role="listitem"
                                                    sx={ListItemStyle}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Lot No</Typography>
                                                        </Grid>
                                                        <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{bidCompleteData.LOT}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                                <ListItem
                                                    role="listitem"
                                                    sx={ListItemStyle}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Auction</Typography>
                                                        </Grid>
                                                        <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{bidCompleteData.AUCTION}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                                <ListItem
                                                    role="listitem"
                                                    sx={ListItemStyle}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Auction Date</Typography>
                                                        </Grid>
                                                        <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                            {/* <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{moment(bidCompleteData.AUCTION_DATE).format("DD MMM, YYYY hh:mm A")}</Typography> */}
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}> {bidTime && <> {removeTrailingTime(bidCompleteData.AUCTION_DATE)}
                                                                {/* <CountdownTimer targetTime={bidTime} /> */}
                                                            </>} </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                                <ListItem
                                                    role="listitem"
                                                    sx={ListItemStyle}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Make</Typography>
                                                        </Grid>
                                                        <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{bidCompleteData.MARKA_NAME}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                                <ListItem
                                                    role="listitem"
                                                    sx={ListItemStyle}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Model</Typography>
                                                        </Grid>
                                                        <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{bidCompleteData.MODEL_NAME}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                                <ListItem
                                                    role="listitem"
                                                    sx={ListItemStyle}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Year</Typography>
                                                        </Grid>
                                                        <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{bidCompleteData.YEAR}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                                <ListItem
                                                    role="listitem"
                                                    sx={ListItemStyle}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Engine</Typography>
                                                        </Grid>
                                                        <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{bidCompleteData.ENG_V}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                                <ListItem
                                                    role="listitem"
                                                    sx={ListItemStyle}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Chassis</Typography>
                                                        </Grid>
                                                        <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{bidCompleteData.KUZOV}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                                <ListItem
                                                    role="listitem"
                                                    sx={ListItemStyle}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Color</Typography>
                                                        </Grid>
                                                        <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{bidCompleteData.COLOR}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                                <ListItem
                                                    role="listitem"
                                                    sx={ListItemStyle}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Mileage</Typography>
                                                        </Grid>
                                                        <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{bidCompleteData.MILEAGE}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                                <ListItem
                                                    role="listitem"
                                                    sx={ListItemStyle}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Equipment</Typography>
                                                        </Grid>
                                                        <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{bidCompleteData.EQUIP}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                                <ListItem
                                                    role="listitem"
                                                    sx={ListItemStyle}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Rate</Typography>
                                                        </Grid>
                                                        <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{bidCompleteData.RATE}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                                <ListItem
                                                    role="listitem"
                                                    sx={ListItemStyle}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Status</Typography>
                                                        </Grid>
                                                        <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{bidCompleteData.STATUS}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                                <ListItem
                                                    role="listitem"
                                                    sx={ListItemStyle}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Start Price</Typography>
                                                        </Grid>
                                                        <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{commaSeparated(bidCompleteData.START)}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                                <ListItem
                                                    role="listitem"
                                                    sx={ListItemStyle}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>Avg. Price</Typography>
                                                        </Grid>
                                                        <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{commaSeparated(bidCompleteData.AVG_PRICE)}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                                <ListItem
                                                    role="listitem"
                                                    sx={ListItemStyle}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={4.5} sm={4.5} md={4.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>End Price</Typography>
                                                        </Grid>
                                                        <Grid item xs={7.5} sm={7.5} md={7.5}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{commaSeparated(bidCompleteData.FINISH)}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            </List>
                                        </Card>
                                    </Box>
                                </Grid>
                            </Box>
                        </Box>
                    </ListItem>
                </List>
            </Drawer>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box sx={{
                marginLeft: { xs: 0, md: -5 },
            }}>
                <Typography sx={{ fontSize: '18px', fontWeight: '700', color: Colors.Primary, marginBottom: 2 }}>Auction Dashboard</Typography>
                <Box sx={{ borderRadius: '5px', p: 2, backgroundColor: '#EEE' }} className="auctionPage">
                    <Stack direction={{ lg: "row", md: "row", sm: "column", xs: "column" }} spacing={1} justifyContent="space-between">
                        <Box>
                            {/* <Box item md={3} xs={12}>
                            <FormControl>
                                <FormLabel style={mainHeaderTypo}>
                                    Status
                                </FormLabel>
                                <RadioGroup
                                    row
                                    name="row-radio-buttons-group"
                                    value={selectedStatus}
                                    onChange={(event) => {
                                        setSelectedStatus(event.target.value);
                                    }}
                                    sx={{
                                        fontSize: "12px",
                                        color: Colors.lightgray
                                    }}
                                >
                                    <FormControlLabel value="" control={<Radio size="small" sx={{
                                        '&.Mui-checked': {
                                            color: Colors.Primary,
                                        },
                                    }} />} label="Not Sold" />
                                    <FormControlLabel value="sold" control={<Radio size="small" sx={{
                                        '&.Mui-checked': {
                                            color: Colors.Primary,
                                        },
                                    }} />} label="Sold" />
                                </RadioGroup>
                            </FormControl>
                        </Box> */}
                            <Box item md={2} xs={12} sx={{ width: '100px' }}>
                                <FormControl>
                                    <FormLabel style={mainHeaderTypo}>
                                        Auction Day
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        name="row-radio-buttons-group"
                                        value={selectedDay}
                                        onChange={(event) => {
                                            setSelectedDay(event.target.value);
                                        }}
                                        sx={{
                                            fontSize: "12px",
                                            color: Colors.lightgray,
                                            display: 'flex'
                                        }}
                                    >
                                        <FormControlLabel value={moment().format("YYYY-MM-DD")} control={<Radio size="small" sx={{
                                            '&.Mui-checked': {
                                                color: Colors.Primary,
                                            },
                                        }} />} label={moment().format('ddd')} />
                                        <FormControlLabel value={moment().add(1, 'days').format("YYYY-MM-DD")} control={<Radio size="small" sx={{
                                            '&.Mui-checked': {
                                                color: Colors.Primary,
                                            },
                                        }} />} label={moment().add(1, 'days').format('ddd')} />
                                        {/* {(moment().format('dddd') === "Friday") ? ( */}
                                        {(moment().day() === 5) ? (
                                            <FormControlLabel
                                                value={moment().add(3, 'days').format("YYYY-MM-DD")}
                                                control={<Radio size="small" sx={{
                                                    '&.Mui-checked': {
                                                        color: Colors.Primary,
                                                    },
                                                }} />}
                                                label={moment().add(3, 'days').format('ddd')}
                                            />
                                        ) : (
                                            <FormControlLabel value={moment().add(2, 'days').format("YYYY-MM-DD")} control={<Radio size="small" sx={{
                                                '&.Mui-checked': {
                                                    color: Colors.Primary,
                                                },
                                            }} />} label={moment().add(2, 'days').format('ddd')} />
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            <Box item md={3} xs={12} mt={1} sx={{ width: '100px' }}>
                                <FormControl variant="standard">
                                    <FormLabel
                                        // style={mainHeaderTypo}
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: 500,
                                            color: Colors.Primary,
                                            paddingBottom: "7px"
                                        }}
                                    >
                                        Lot No.
                                    </FormLabel>
                                    <div style={{ alignItems: 'center', display: 'flex' }}>
                                        <TextField
                                            autoComplete="off"
                                            variant="outlined"
                                            size="small"
                                            value={lotNo}
                                            onChange={(e) => {
                                                setLotNo(e.target.value);
                                            }}
                                            style={{ width: '100px', backgroundColor: 'white' }}
                                        />
                                    </div>
                                    <Button variant="contained"
                                        sx={{
                                            background: Colors.Primary,
                                            '&:hover': {
                                                background: Colors.Primary,
                                            },
                                            marginTop: '5px',
                                            width: '70px',
                                            height: '30px'
                                        }}
                                        onClick={() => SearhData()}
                                    >
                                        Search
                                    </Button>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box>
                            <Typography style={mainHeaderTypo}>Make</Typography>
                            <Box sx={{ backgroundColor: 'white' }}>
                                <List
                                    sx={listStyle}
                                    dense
                                    component="div"
                                    role="list"
                                >
                                    {featureNamesArray?.length > 0 && (
                                        <>
                                            {featureNamesArray.map((value, index) => {
                                                return (
                                                    <ListItem
                                                        key={index}
                                                        role="listitem"
                                                        sx={{
                                                            color: makeName === value.MARKA_NAME ? Colors.Primary : Colors.lightgray,
                                                            borderBottom: "1px solid #f3f3f3",
                                                            cursor: "pointer",
                                                            backgroundColor: 'white'
                                                        }}
                                                        onClick={() => {
                                                            allModels({ name: value.MARKA_NAME, selectedDay })
                                                            setMakeName(value.MARKA_NAME)
                                                        }}
                                                    >
                                                        <ListItemText primary={
                                                            <Typography sx={listTextStyle}>{value.MARKA_NAME}</Typography>
                                                        } />
                                                    </ListItem>
                                                );
                                            })}
                                        </>
                                    )}
                                </List>
                            </Box>
                        </Box>
                        <Box>
                            <Typography style={mainHeaderTypo}>Model</Typography>
                            <Box sx={{ backgroundColor: 'white' }}>
                                <List
                                    sx={listStyle}
                                    dense
                                    component="div"
                                    role="list"
                                >
                                    {modelStatus ? (
                                        <ProgressBar />
                                    ) : (
                                        <>
                                            {models.map((value, index) => {
                                                return (
                                                    <ListItem
                                                        key={index}
                                                        role="listitem"
                                                        sx={{
                                                            color: modelName === value.MODEL_NAME ? Colors.Primary : Colors.lightgray,
                                                            borderBottom: "1px solid #f3f3f3",
                                                            cursor: "pointer",
                                                            backgroundColor: 'white'
                                                        }}
                                                        onClick={() => {
                                                            allChasis(value.MODEL_NAME)
                                                            setModelName(value.MODEL_NAME)
                                                            setChasisName('')
                                                        }}
                                                    >
                                                        <ListItemText primary={
                                                            <Typography sx={listTextStyle}>
                                                                {value.MODEL_NAME}
                                                                <span style={{ color: Colors.Primary, marginLeft: "5px" }}>
                                                                    ({value.TAG2})
                                                                </span>
                                                            </Typography>
                                                        } />
                                                    </ListItem>
                                                );
                                            })}
                                        </>
                                    )
                                    }
                                </List>
                            </Box>
                        </Box>
                        <Box>
                            <Typography style={mainHeaderTypo}>Chassis</Typography>
                            <Box sx={{ backgroundColor: 'white' }}>
                                <List
                                    sx={listStyle}
                                    dense
                                    component="div"
                                    role="list"
                                >
                                    {chasisStatus ? (
                                        <ProgressBar />
                                    ) : (
                                        <>
                                            {chasis.map((value, index) => {
                                                return (
                                                    <ListItem
                                                        key={index}
                                                        role="listitem"
                                                        sx={{
                                                            color: chasisName === value.KUZOV ? Colors.Primary : Colors.lightgray,
                                                            borderBottom: "1px solid #f3f3f3",
                                                            cursor: "pointer",
                                                            backgroundColor: 'white'
                                                        }}
                                                        onClick={() => {
                                                            setChasisName(value.KUZOV)
                                                        }}
                                                    >
                                                        <ListItemText primary={
                                                            <Typography sx={listTextStyle}>
                                                                {value.KUZOV}
                                                                {/* <span style={{ color: Colors.Primary, marginLeft: "5px" }}>
                                                                    ({value.TAG2})
                                                                </span> */}
                                                            </Typography>
                                                        } />
                                                    </ListItem>
                                                );
                                            })}
                                        </>
                                    )
                                    }
                                </List>
                            </Box>
                        </Box>
                        <Box>
                            <Typography style={mainHeaderTypo}>Auction</Typography>
                            <Box sx={{ backgroundColor: 'white' }}>
                                <List
                                    sx={listStyle}
                                    dense
                                    component="div"
                                    role="list"
                                >
                                    {allAuctionHouses?.length > 0 && (
                                        <>
                                            {allAuctionHouses.map((value, index) => {
                                                return (
                                                    <ListItem
                                                        key={index}
                                                        role="listitem"
                                                        sx={{
                                                            color: Colors.lightgray,
                                                            borderBottom: "1px solid #f3f3f3",
                                                        }}
                                                    >
                                                        <ListItemIcon style={checkboxWidth}>
                                                            <Checkbox
                                                                checked={selectedAuction.some((selectedAuction) => selectedAuction.id === index)}
                                                                onChange={() => handleAuctionChange({ id: index, name: value.AUCTION })}
                                                                sx={{
                                                                    padding: 0,
                                                                    '&.Mui-checked': {
                                                                        color: Colors.Primary,
                                                                    },
                                                                    fontSize: '12px'
                                                                }}
                                                                size="small"
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText primary={
                                                            <Typography sx={listTextStyle}>{value.AUCTION}</Typography>
                                                        } />
                                                    </ListItem>
                                                );
                                            })}
                                        </>
                                    )}
                                </List>
                            </Box>
                        </Box>
                        <Box>
                            <Typography style={mainHeaderTypo}>Colors</Typography>
                            <Box sx={{ backgroundColor: 'white' }}>
                                <List
                                    sx={{
                                        width: {
                                            md: 130,
                                            sm: "100%",
                                            xs: "100%"
                                        },
                                        height: 190,
                                        overflow: 'auto',
                                    }}
                                    dense
                                    component="div"
                                    role="list"
                                >
                                    {allColorsData?.length > 0 && (
                                        <>
                                            {allColorsData.map((value, index) => {
                                                return (
                                                    <ListItem
                                                        key={index}
                                                        role="listitem"
                                                        sx={{
                                                            color: Colors.lightgray,
                                                            borderBottom: "1px solid #f3f3f3",
                                                        }}
                                                    >
                                                        <ListItemIcon style={checkboxWidth}>
                                                            <Checkbox
                                                                checked={selectedColors.some((selectedcolor) => selectedcolor.id === index)}
                                                                onChange={() => handleColorsChange({ id: index, name: value.COLOR })}
                                                                sx={{
                                                                    padding: 0,
                                                                    '&.Mui-checked': {
                                                                        color: Colors.Primary,
                                                                    },
                                                                    fontSize: '12px'
                                                                }}
                                                                size="small"
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText primary={
                                                            <Typography sx={listTextStyle}>
                                                                {value.COLOR}
                                                            </Typography>
                                                        } />
                                                    </ListItem>
                                                );
                                            })}
                                        </>
                                    )}
                                </List>
                            </Box>
                        </Box>
                        <Box>
                            <Typography style={mainHeaderTypo}>Score</Typography>
                            <Box sx={{ backgroundColor: 'white' }}>
                                <List
                                    style={listStyle}
                                    dense
                                    component="div"
                                    role="list"
                                >
                                    {ScoreArray?.map((value, index) => {
                                        return (
                                            <ListItem
                                                key={index}
                                                role="listitem"
                                                sx={{
                                                    color: Colors.lightgray,
                                                    borderBottom: "1px solid #f3f3f3",
                                                }}
                                            >
                                                <ListItemIcon style={checkboxWidth}>
                                                    <Checkbox
                                                        checked={selectedRates.some((selectedRates) => selectedRates.id === value.value)}
                                                        onChange={() => handleRatesChange({ id: value.value, name: value.label })}
                                                        sx={{
                                                            padding: 0,
                                                            '&.Mui-checked': {
                                                                color: Colors.Primary,
                                                            },
                                                        }}
                                                        size="small"
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={
                                                    <Typography sx={listTextStyle}>
                                                        {value.label}
                                                    </Typography>
                                                } />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Box>
                        </Box>

                    </Stack>
                    <Divider sx={{ margin: ".6rem 0" }} />
                    <Grid container>
                        <Box sx={{ width: '100%', }}>
                            <Stack direction="row" alignItems={'end'} spacing={2} mt={1}>
                                <FormControl variant="standard">
                                    <FormLabel style={RangeHeader}>
                                        Min. Year
                                    </FormLabel>
                                    <select
                                        className='selectInputStyle'
                                        value={minYear}
                                        onChange={e => {
                                            setMinYear(e.target.value);
                                        }}
                                    >
                                        {allYears?.map((option, index) => (
                                            <option key={index} value={option.id}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </FormControl>
                                <FormControl variant="standard">
                                    <FormLabel style={RangeHeader}>
                                        Max. Year
                                    </FormLabel>
                                    <select
                                        className='selectInputStyle'
                                        value={maxYear}
                                        onChange={e => {
                                            setMaxYear(e.target.value);
                                        }}
                                    >
                                        {allYears?.map((option, index) => (
                                            <option key={index} value={option.id}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </FormControl>
                                <FormControl variant="standard">
                                    <FormLabel style={RangeHeader}>
                                        Min. Mileage
                                    </FormLabel>
                                    <select
                                        className='selectInputStyle'
                                        value={minMileage}
                                        onChange={e => {
                                            setMinMileage(e.target.value);
                                        }}
                                    >
                                        {allMileage?.map((option, index) => (
                                            <option key={index} value={option.id}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </FormControl>
                                <FormControl variant="standard">
                                    <FormLabel style={RangeHeader}>
                                        Max. Mileage
                                    </FormLabel>
                                    <select
                                        className='selectInputStyle'
                                        value={maxMileage}
                                        onChange={e => {
                                            setMaxMileage(e.target.value);
                                        }}
                                    >
                                        {allMileage?.map((option, index) => (
                                            <option key={index} value={option.id}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </FormControl>
                                <FormControl variant="standard">
                                    <FormLabel style={RangeHeader}>
                                        Min. Engine
                                    </FormLabel>
                                    <select
                                        className='selectInputStyle'
                                        value={minEngine}
                                        onChange={e => {
                                            setMinEngine(e.target.value);
                                        }}
                                    >
                                        {allEngineRange?.map((option, index) => (
                                            <option key={index} value={option.id}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </FormControl>
                                <FormControl variant="standard">
                                    <FormLabel style={RangeHeader}>
                                        Max. Engine
                                    </FormLabel>
                                    <select
                                        className='selectInputStyle'
                                        value={maxEngine}
                                        onChange={e => {
                                            setMaxEngine(e.target.value);
                                        }}
                                    >
                                        {allEngineRange?.map((option, index) => (
                                            <option key={index} value={option.id}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </FormControl>
                                <FormControl variant="standard">
                                    <FormLabel style={RangeHeader}>
                                        Min. Price
                                    </FormLabel>
                                    <select
                                        className='selectInputStyle'
                                        value={minPrice}
                                        onChange={e => {
                                            setMinPrice(e.target.value);
                                        }}
                                    >
                                        {allPrices?.map((option, index) => (
                                            <option key={index} value={option.id}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </FormControl>
                                <FormControl variant="standard">
                                    <FormLabel style={RangeHeader}>
                                        Max. Price
                                    </FormLabel>
                                    <select
                                        className='selectInputStyle'
                                        value={maxPrice}
                                        onChange={e => {
                                            setMaxPrice(e.target.value);
                                        }}
                                    >
                                        {allPrices?.map((option, index) => (
                                            <option key={index} value={option.id}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </FormControl>
                                <Button variant="contained"
                                    onClick={() => SearhData()}
                                    disabled={modelName === null}
                                    sx={{
                                        width: '70px',
                                        height: '30px',
                                        marginTop: 5,
                                        background: Colors.Primary,
                                        '&:hover': {
                                            background: Colors.Primary,
                                        },
                                    }}
                                >
                                    Search
                                </Button>
                            </Stack>
                            {/* <Stack direction="column" spacing={2} mt={2}>
                            <FormControl variant="standard">
                                <FormLabel style={RangeHeader}>
                                    Min. Mileage
                                </FormLabel>
                                <select
                                    className='selectInputStyle'
                                    value={minMileage}
                                    onChange={e => {
                                        setMinMileage(e.target.value);
                                    }}
                                >
                                    {allMileage?.map((option, index) => (
                                        <option key={index} value={option.id}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </FormControl>
                            <FormControl variant="standard">
                                <FormLabel style={RangeHeader}>
                                    Max. Mileage
                                </FormLabel>
                                <select
                                    className='selectInputStyle'
                                    value={maxMileage}
                                    onChange={e => {
                                        setMaxMileage(e.target.value);
                                    }}
                                >
                                    {allMileage?.map((option, index) => (
                                        <option key={index} value={option.id}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </FormControl>
                        </Stack> */}
                            {/* <Stack direction="column" spacing={2} mt={2}>
                            <FormControl variant="standard">
                                <FormLabel style={RangeHeader}>
                                    Min. Engine
                                </FormLabel>
                                <select
                                    className='selectInputStyle'
                                    value={minEngine}
                                    onChange={e => {
                                        setMinEngine(e.target.value);
                                    }}
                                >
                                    {allEngineRange?.map((option, index) => (
                                        <option key={index} value={option.id}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </FormControl>
                            <FormControl variant="standard">
                                <FormLabel style={RangeHeader}>
                                    Max. Engine
                                </FormLabel>
                                <select
                                    className='selectInputStyle'
                                    value={maxEngine}
                                    onChange={e => {
                                        setMaxEngine(e.target.value);
                                    }}
                                >
                                    {allEngineRange?.map((option, index) => (
                                        <option key={index} value={option.id}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </FormControl>
                        </Stack>
                        <Stack direction="column" spacing={2} mt={2}>
                            <FormControl variant="standard">
                                <FormLabel style={RangeHeader}>
                                    Min. Price
                                </FormLabel>
                                <select
                                    className='selectInputStyle'
                                    value={minPrice}
                                    onChange={e => {
                                        setMinPrice(e.target.value);
                                    }}
                                >
                                    {allPrices?.map((option, index) => (
                                        <option key={index} value={option.id}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </FormControl>
                            <FormControl variant="standard">
                                <FormLabel style={RangeHeader}>
                                    Max. Price
                                </FormLabel>
                                <select
                                    className='selectInputStyle'
                                    value={maxPrice}
                                    onChange={e => {
                                        setMaxPrice(e.target.value);
                                    }}
                                >
                                    {allPrices?.map((option, index) => (
                                        <option key={index} value={option.id}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </FormControl>
                        </Stack> */}
                            {/* <Button variant="contained"
                            onClick={() => SearhData()}
                            disabled={modelName === null}
                            sx={{
                                width: "90%",
                                marginTop: "20px",
                                background: Colors.Primary,
                                '&:hover': {
                                    background: Colors.Primary,
                                },
                            }}
                        >
                            Search
                        </Button> */}
                        </Box>
                        {/* <Grid item md={3} xs={12}>
                        <FormControl>
                            <FormLabel style={mainHeaderTypo}>
                                Status
                            </FormLabel>
                            <RadioGroup
                                row
                                name="row-radio-buttons-group"
                                value={selectedStatus}
                                onChange={(event) => {
                                    setSelectedStatus(event.target.value);
                                }}
                                sx={{
                                    fontSize: "12px",
                                    color: Colors.lightgray
                                }}
                            >
                                <FormControlLabel value="" control={<Radio size="small" sx={{
                                    '&.Mui-checked': {
                                        color: Colors.Primary,
                                    },
                                }} />} label="Not Sold" />
                                <FormControlLabel value="sold" control={<Radio size="small" sx={{
                                    '&.Mui-checked': {
                                        color: Colors.Primary,
                                    },
                                }} />} label="Sold" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <FormControl>
                            <FormLabel style={mainHeaderTypo}>
                                Auction Day
                            </FormLabel>
                            <RadioGroup
                                row
                                name="row-radio-buttons-group"
                                value={selectedDay}
                                onChange={(event) => {
                                    setSelectedDay(event.target.value);
                                }}
                                sx={{
                                    fontSize: "12px",
                                    color: Colors.lightgray
                                }}
                            >
                                <FormControlLabel value={moment().format("YYYY-MM-DD")} control={<Radio size="small" sx={{
                                    '&.Mui-checked': {
                                        color: Colors.Primary,
                                    },
                                }} />} label={moment().format('dddd')} />
                                <FormControlLabel value={moment().add(1, 'days').format("YYYY-MM-DD")} control={<Radio size="small" sx={{
                                    '&.Mui-checked': {
                                        color: Colors.Primary,
                                    },
                                }} />} label={moment().add(1, 'days').format('dddd')} />
                                <FormControlLabel value={moment().add(2, 'days').format("YYYY-MM-DD")} control={<Radio size="small" sx={{
                                    '&.Mui-checked': {
                                        color: Colors.Primary,
                                    },
                                }} />} label={moment().add(2, 'days').format('dddd')} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <FormControl variant="standard">
                            <FormLabel style={mainHeaderTypo}>
                                Search by Lot No
                            </FormLabel>
                            <div>
                                <TextField
                                    autoComplete="off"
                                    variant="outlined"
                                    size="small"
                                    value={lotNo}
                                    onChange={(e) => {
                                        setLotNo(e.target.value);
                                    }}
                                />
                                <Button variant="contained"
                                    sx={{
                                        background: Colors.Primary,
                                        '&:hover': {
                                            background: Colors.Primary,
                                        },
                                    }}
                                    onClick={() => SearhData()}
                                >
                                    Enter
                                </Button>
                            </div>
                        </FormControl>
                    </Grid> */}
                    </Grid>
                </Box>
                <Box sx={{ borderRadius: '5px', p: 2, marginTop: "20px", }}>
                    {/* <AuctionTable data={auctionList} /> */}
                    <Grid container spacing={2}>
                        <Grid xs={10}>
                            <Typography sx={{ fontSize: "17px", marginLeft: { xs: 0, md: 2 }, padding: "10px", color: Colors.lightgray }} >
                                Total Founds:
                                <span style={{ color: Colors.Primary }}>{auctionList?.totalCount ? auctionList?.totalCount : 0}</span>
                            </Typography>
                        </Grid>
                        <Grid xs={2} style={{ textAlign: "right", marginTop: "10px" }} justifyContent="flex-end">
                            <BsCardList onClick={(e) => { setListViewClick() }} style={{ fontSize: "22px", color: `${activeListView ? "black" : Colors.Primary}` }} /> &nbsp; &nbsp;
                            <BsUiRadios onClick={(e) => { listDetailClick() }} style={{ fontSize: "22px", color: `${activeDetailView ? "black" : Colors.Primary}` }} /> &nbsp; &nbsp;
                        </Grid>
                    </Grid>

                    {activeListView ?
                        <TableContainer component={Paper} style={{ backgroundColor: '#eee', border: '1px solid #CCC', borderStyle: 'dashed' }}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Photos</TableCell>
                                        <TableCell align="right">Lot No</TableCell>
                                        <TableCell align="right">Auction</TableCell>
                                        <TableCell align="right">Auction Date</TableCell>
                                        <TableCell align="right">YEAR</TableCell>
                                        <TableCell align="right">Chassis</TableCell>
                                        {/* <TableCell align="right">Model</TableCell>
                                        <TableCell align="right">Engine</TableCell> */}
                                        <TableCell align="right">Price</TableCell>
                                        <TableCell align="right">Mileage</TableCell>
                                        <TableCell align="right">Score</TableCell>
                                        <TableCell align="right">Color</TableCell>
                                        <TableCell align="right">Status</TableCell>
                                        <TableCell align="right">Favorite</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {auctionList?.list?.map((row, index) => {
                                        let urlString = row.IMAGES;
                                        let urlParts = urlString.split('#');
                                        let urlRegex = /(https?:\/\/[^\s]+)/g;
                                        let urls = urlParts.flatMap(part => part.match(urlRegex));
                                        let auctionDate = moment(row?.AUCTION_DATE).format('MMM, DD hh:mm A') ?? ""
                                        return (
                                            <>
                                                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                    <TableCell component="th" scope="row">
                                                        <Stack direction="row" spacing={1}>
                                                            {urls?.map((value, index) => {
                                                                return <img key={index} src={value} alt={value} width="70px" height="60px" />;
                                                            })}
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {/* <Link to={{ pathname: "/user/car-detail/" + row.ID, }} target="_blank"
                                                            style={{ cursor: "pointer", color: Colors.Primary, textDecoration: "underline", fontWeight: 600, }}>
                                                            {row.LOT}
                                                        </Link> */}
                                                        <Button
                                                            onClick={() => handleDrawerToggle(row)}
                                                            style={{ cursor: "pointer", color: Colors.Primary, textDecoration: "underline", fontWeight: 600, }}>
                                                            {row.LOT}
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell style={customCell} align="right">{row.AUCTION}</TableCell>
                                                    <TableCell style={customCell} align="right">{auctionDate}</TableCell>
                                                    <TableCell style={customCell} align="right">{row.YEAR}</TableCell>
                                                    <TableCell style={customCell} align="right">{row.KUZOV}</TableCell>
                                                    {/* <TableCell style={customCell} align="right">{row.MODEL_NAME}</TableCell>
                                                    <TableCell style={customCell} align="right">{row.ENG_V}</TableCell> */}
                                                    <TableCell style={customCell} align="right">{commaSeparated(row.START)}</TableCell>
                                                    <TableCell style={customCell} align="right">{row.MILEAGE}</TableCell>
                                                    <TableCell style={customCell} align="right">{row.RATE}</TableCell>
                                                    <TableCell style={customCell} align="right">{row.COLOR}</TableCell>
                                                    <TableCell style={customCell} align="right">{row.STATUS} {console.log("AuctionRowData:", row)}</TableCell>
                                                    <TableCell style={customCell} align="center">
                                                        {row.favStatus == 'yes' ?
                                                            <FaStar color='red' size={15} style={{ cursor: 'pointer', zIndex: 1000 }} onClick={() => {
                                                                handleDeleteFvrt(row)
                                                            }} />
                                                            :
                                                            <FaRegStar size={15} style={{ cursor: 'pointer' }} onClick={() => {
                                                                handleMarkFavorite(row)
                                                            }} />}

                                                        {/* <FaStar /> */}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        :
                        <>
                            <Grid container spacing={2} component={Paper} style={{ marginLeft: 5, padding: "10px", backgroundColor: '#eee', marginTop: 2, width: '99.5%' }}>
                                {auctionList?.list?.map((row, index) => {
                                    let urlString = row.IMAGES;
                                    let urlParts = urlString.split('#');
                                    let urlRegex = /(https?:\/\/[^\s]+)/g;
                                    let urls = urlParts.flatMap(part => part.match(urlRegex));
                                    let auctionDate = moment(row?.AUCTION_DATE).format('MMM, DD hh:mm A') ?? ""
                                    const number = row.START;
                                    const formattedNumber = number.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'JPY',
                                        currencyDisplay: 'symbol',
                                    });
                                    return (
                                        <Grid xs={6} md={6} className='gridView2'>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                <Grid xs={3} style={{ paddingLeft: "35px" }}>
                                                    <img key={index} src={urls[0]} alt={urls[0]} width="100%" />
                                                </Grid>
                                                <Grid xs={9}>
                                                    <Box display={"flex"} alignItems={'center'} justifyContent={'space-between'}>
                                                        <h5 className='gridView2h5'>{row.YEAR} {row.MARKA_NAME} {row.MODEL_NAME}</h5>
                                                        {row.favStatus == 'yes' ?
                                                            <FaStar color='red' size={15} style={{ cursor: 'pointer', zIndex: 1000 }}
                                                                onClick={() => {
                                                                    handleDeleteFvrt(row)
                                                                }} />
                                                            :
                                                            <FaRegStar size={15} style={{ cursor: 'pointer', zIndex: 1000 }}
                                                                onClick={() => {
                                                                    handleMarkFavorite(row)
                                                                }}
                                                            />}

                                                    </Box>
                                                    <p className='gridView2p'> Lot No:
                                                        {/* <Link to={{ pathname: "/user/car-detail/" + row.ID, }} target="_blank"
                                                            style={{ cursor: "pointer", color: Colors.Primary, fontWeight: 600, marginRight: "5px", textDecoration: "none" }}>
                                                            {row.LOT}
                                                        </Link> */}
                                                        <Button
                                                            onClick={() => handleDrawerToggle(row)}
                                                            style={{ cursor: "pointer", color: Colors.Primary, fontWeight: 600, marginRight: "5px", textDecoration: "none" }}>
                                                            {row.LOT}
                                                        </Button>
                                                        | Location: <span style={{ cursor: "pointer", color: Colors.Primary, fontWeight: 600, marginRight: "5px" }}>{row.AUCTION}</span>
                                                        | Price <span style={{ cursor: "pointer", color: Colors.Primary, fontWeight: 600, marginRight: "5px" }}>{commaSeparated(number)}</span>
                                                    </p>
                                                    <Grid container rowSpacing={1}>
                                                        {/* <Grid xs={4} className='p-10'>
                                                            <Grid container rowSpacing={1}>
                                                                <Grid xs={3} className='text'>
                                                                    Model
                                                                </Grid>
                                                                <Grid xs={9} className='bgGray p-10' >
                                                                    {row.MODEL_NAME}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid> */}
                                                        <Grid xs={4} className='p-10'>
                                                            <Grid container rowSpacing={1}>
                                                                <Grid xs={5}>
                                                                    Mileage
                                                                </Grid>
                                                                <Grid xs={7} className='bgGray p-10' >
                                                                    {row.MILEAGE}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid xs={4} className='p-10'>
                                                            <Grid container rowSpacing={1}>
                                                                <Grid xs={5}>
                                                                    Engine
                                                                </Grid>
                                                                <Grid xs={7} className='bgGray p-10' >
                                                                    {row.ENG_V}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid xs={4} className='p-10'>
                                                            <Grid container rowSpacing={1}>
                                                                <Grid xs={5} className='text'>
                                                                    Year
                                                                </Grid>
                                                                <Grid xs={7} className='bgGray p-10' >
                                                                    {row.YEAR}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container rowSpacing={1} style={{ marginTop: "11px" }}>

                                                        <Grid xs={4} className='p-10'>
                                                            <Grid container rowSpacing={1}>
                                                                <Grid xs={5}>
                                                                    Color
                                                                </Grid>
                                                                <Grid xs={7} className='bgGray p-10' >
                                                                    {row.COLOR}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid xs={4} className='p-10'>
                                                            <Grid container rowSpacing={1}>
                                                                <Grid xs={5} className='text'>
                                                                    Chassis
                                                                </Grid>
                                                                <Grid xs={7} className='bgGray p-10' >
                                                                    {row.KUZOV}
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>

                                                        <Grid xs={4} className='p-10'>
                                                            <Grid container rowSpacing={1}>
                                                                <Grid xs={5}>
                                                                    Rate
                                                                </Grid>
                                                                <Grid xs={7} className='bgGray p-10' >
                                                                    {row.RATE}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>


                                                        <Grid xs={12} style={{ textAlign: "right", marginTop: "10px" }} justifyContent="flex-end">
                                                            <Link className='DetailBtn'
                                                                to={{ pathname: "/user/car-detail/" + row.ID, }} target="_blank"
                                                                sx={{
                                                                    background: Colors.Primary,
                                                                    '&:hover': {
                                                                        background: Colors.Primary,
                                                                    },
                                                                    color: 'white',
                                                                    marginTop: 2,
                                                                }}
                                                                onClick={() => SearhData()}
                                                            // onClick={() => handleDrawerToggle(row)}
                                                            >
                                                                Detail
                                                            </Link>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </>
                    }


                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ textAlign: "right", marginTop: "10px" }} justifyContent="flex-end">
                            {auctionList?.list &&
                                <Box sx={{ display: "flex", alignItems: "center", float: "right" }}>
                                    <span>Show {auctionList?.list?.length} </span> <Pagination style={{ float: "right" }} count={totalPages} onChange={handlePageChange} />
                                </Box>
                            }
                        </Grid>
                    </Grid>




                </Box>
            </Box>
            {open && (
                <CarDetailFunc detail={detail} open={open} setOpen={setOpen} urlArray={urlArray} state={code} setBidStatus={setBidStatus} />
            )}
            <div>
                <Modal
                    open={chassisModal}
                    onClose={handleChassisModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={stylePaymentModal}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={handleChassisModalClose} style={{ cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Grid container md={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={12} sx={{ width: '100%' }}>
                                    <Box sx={{ width: '100%', textAlign: 'center', }}>
                                        {/* <Typography sx={{ textAlign: 'center', marginBottom: 5 }}>Are you sure you want to mark this bid as lost ?</Typography> */}
                                        <Typography sx={{ fontSize: '24px', color: Colors.Primary, textAlign: 'center' }}>Year of Manufacture</Typography>
                                        <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
                                            <div className="chassisStyle" dangerouslySetInnerHTML={{ __html: chassisData }} />
                                        </Box>
                                        <Button variant="contained"
                                            sx={{
                                                background: Colors.Primary,
                                                '&:hover': {
                                                    background: Colors.Primary,
                                                },
                                                width: '200px',
                                                marginTop: '10px',

                                            }}
                                            onClick={handleChassisModalClose}
                                        >
                                            Close
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    </Box>
                </Modal>
            </div>
            <ToastContainer />
        </>
    )
}

export default Auction;