import React, { useCallback, useEffect, useState } from 'react'
import { Avatar, Box, Drawer, Grid, LinearProgress, List, ListItem, ListItemText, Typography } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import moment from 'moment';
import { getBidsHistoryData } from '../apis';
import { FiRefreshCcw } from 'react-icons/fi';
import { Colors } from '../constants/colors';
import { CarDetailFunc } from './CarDetail';
import { commaSeparated } from '../constants/GlobalConstants';

const BidHistory = () => {
    const [bidHistory, setBidHistory] = useState()
    const [loading, setLoading] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [detail, setDetail] = useState();
    const [urlArray, setUrlArray] = useState();
    const [totalRecords, setTotalRecords] = useState(0);
    const [open, setOpen] = useState(false)

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <FiRefreshCcw style={{ fontSize: "20px", cursor: "pointer", paddingRight: "15px" }}
                    onClick={() => handleBidsHistory()}
                />
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        );
    }


    const handleBidsHistory = useCallback(() => {
        (async () => {
            setLoading(true)
            const query = "?limit=" + pageSize + "&page=" + currentPage;
            const res = await getBidsHistoryData(query);
            setTotalRecords(res.data.totalRecords);
            setBidHistory(res.data.result.map((item) => {
                console.log("🚀 ~ file: BidHistory.jsx:40 ~ setBidHistory ~ item:", item)
                return {
                    id: item.auctionData._id,
                    car: item.auctionData.IMAGES,
                    auctionDate: moment(item.auctionData.AUCTION_DATE).format('ll'),
                    lotNo: item.auctionData.LOT,
                    makeModel: item.auctionData.MARKA_NAME + ' ' + item.auctionData.MODEL_NAME,
                    bidAmount: commaSeparated(item.amount),
                    status: item.status,
                    shippingStatus: item.status === "Won" ? item.auctionData.DeliveryStatus : '',
                    item: item.auctionData
                }
            }))
            setLoading(false)
        })();
    }, [currentPage, pageSize]);

    useEffect(() => {
        handleBidsHistory()
    }, [handleBidsHistory])

    const handlePageChange = (params) => {
        setCurrentPage(params + 1);
    };

    const handlePageSizeChange = (params) => {
        setPageSize(params);
    };

    const getRowId = (bidshistory) => {
        return bidshistory?.id
    }
    const columns = [
        {
            field: 'lotNo',
            headerName: 'Lot No.',
            width: 170,
            renderCell: (params) => (
                <Typography
                    onClick={(event) => {
                        setDetail(params.row.item)
                        const urlString = params.row.car;
                        const urlParts = urlString.split('#');
                        const urlRegex = /(https?:\/\/[^\s]+)/g;
                        const urls = urlParts.flatMap(part => part.match(urlRegex));

                        if (urls) {
                            setUrlArray(urls);
                        }
                        setOpen(true)
                    }}
                    alignItems={'center'} sx={{ color: Colors.Primary, cursor: 'pointer', p: '5px', borderRadius: '5px', '&:hover': { textDecorationLine: 'underline' } }}>
                    {`${params.row?.lotNo}`}
                    {console.log("🚀 ~ file: BidHistory.jsx:93 ~ BidHistory ~ params:", params)}
                </Typography>
            ),
        },
        {
            field: 'makeModel',
            headerName: 'Make / Modal',
            width: 250,
            renderCell: (params) => {
                return (
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        {params.row.makeModel}
                    </Typography>
                )
            }
        },
        {
            field: 'auctionDate',
            headerName: 'Auction Date',
            width: 170,
            renderCell: (params) => {
                return (
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        {params.row.auctionDate}
                    </Typography>
                )
            }
        },
        {
            field: 'bidAmount',
            headerName: 'Bid Amount',
            width: 170,
            renderCell: (params) => {
                return (
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        {params.row.bidAmount}
                    </Typography>
                )
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 120,
            renderCell: (params) => {
                return (
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        {params.row.status}
                    </Typography>
                )
            }
        },
        {
            field: 'shippingStatus',
            headerName: 'Shipping Status',
            width: 170,
            renderCell: (params) => {
                return (
                    <>
                        {params.row.shippingStatus === 'Pending' &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#DC8686',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                Pending
                            </Typography>}
                        {params.row.shippingStatus === "Delivered" &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#9BB8CD',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                Delivered
                            </Typography>
                        }
                        {params.row.shippingStatus === "En Route" &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#d797b1',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                En Route
                            </Typography>
                        }
                        {params.row.shippingStatus === "Arrived" &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#7C9D96',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                Arrived
                            </Typography>
                        }
                        {params.row.shippingStatus === "Shipped" &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#E9B384',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                Shipped
                            </Typography>
                        }
                        {params.row.shippingStatus === "Cancelled" &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#9f9752',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                Cancelled
                            </Typography>
                        }
                        {params.row.shippingStatus === "Closed" &&
                            <Typography sx={{
                                fontSize: '12px',
                                py: 0.2,
                                px: 1,
                                borderRadius: '5px',
                                backgroundColor: '#c4bab6',
                                padding: '2px',
                                color: 'white',
                                width: '70px',
                                textAlign: 'center'
                            }}>
                                Closed
                            </Typography>
                        }
                    </>
                )
            },
        },
    ];
    return (
        <Box sx={{ margin: '0 auto', width: { xs: '90%', sm: '90%', md: '100%', lg: '80' } }} p={2} borderRadius={'5px'}>
            <Box sx={{ height: { xs: '600px', md: '550px' }, width: '100%', marginLeft:{ xs:0, md:-5} }}>
                <DataGrid
                    rows={bidHistory || []}
                    columns={columns.map((column) => ({
                        ...column,
                        cellClassName: 'custom-cell-class',
                    }))}
                    pagination
                    pageSize={pageSize}
                    rowsPerPageOptions={[10, 25, 50]}
                    rowCount={totalRecords}
                    paginationMode="server"
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    getRowHeight={() => 'auto'}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar,
                    }}
                    loading={loading}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                    getRowId={getRowId}
                />
            </Box>
            {(open && urlArray.length > 0 && detail) && (
                <CarDetailFunc adminNavigation={true} detail={detail} open={open} setOpen={setOpen} urlArray={urlArray} />
            )}
        </Box>
    )
}

export default BidHistory