import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { appImages } from '../assets/images';
import HeaderUser from '../components/HeaderUser';
import { Colors } from '../constants/colors';

const ThankYou = () => {
    const navigate = useNavigate()
    const { state } = useLocation();
    const location = useLocation();
    const pathname = location.pathname;
    console.log("🚀 ~ file: ThankYou.jsx:11 ~ ThankYou ~ pathname:", pathname)
    console.log("🚀 ~ file: ThankYou.jsx:6 ~ ThankYou ~ state:", state)
    return (
        <div>
            {pathname == '/thankyou' ? <HeaderUser /> : ''}
            <Grid
                container
                md={12}
                justifyContent={{ xs: 'center', md: '' }}
                alignItems={{ xs: 'center', md: 'normal' }}
            >
                <Grid item md={12} textAlign={'-webkit-center'} py={5} marginTop={3}>

                    <Box
                        boxShadow={1}
                        // height={'100%'}
                        bgcolor={Colors.white}
                        // p={'20px'}
                        borderRadius={'20px'}
                        width={{ md: '30%' }}
                        overflow={'hidden'}
                    >
                        <Box sx={{ backgroundColor: Colors.Primary, height: '35vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Avatar src={appImages.thanks} sx={{ width: 100, height: 100 }} />
                        </Box>
                        <Box p={'20px'}>
                            <Typography sx={{ color: Colors.black, fontSize: 28, fontWeight: 'bold', lineHeight: 0.8 }}>
                                Thank You
                            </Typography>
                            <Typography sx={{ color: Colors.black, fontSize: 18, fontWeight: '500' }}>
                                for providing your payment proof!
                            </Typography>
                            <Typography sx={{ color: Colors.black, marginTop: 1 }}>
                                {state.des} {state.info && <span style={{ textDecorationLine: 'underline', cursor: 'pointer' }}>{state.info}</span>}
                            </Typography>
                        </Box>
                        <Button
                            onClick={() => pathname == '/thankyou' ? navigate('/login') : navigate('/user/dashboard')}
                            id="basic-button"
                            aria-haspopup="true"
                            sx={{
                                width: 200,
                                marginBottom: '25px',
                                backgroundColor:
                                    // loading ? 'white'
                                    //  :
                                    '#232628', '&:hover': {
                                        backgroundColor:
                                            // loading ? 'white'
                                            //  : 
                                            '#232628',
                                    },
                                textTransform: 'capitalize'
                            }}
                        >
                            {/* {loading ? <CircularProgress size={12} color={'warning'} />  */}
                            {/* : */}
                            <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Back to Home</Typography>
                            {/* } */}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

export default ThankYou