import { AppBar, Avatar, Box, Button, CircularProgress, Divider, Grid, IconButton, Menu, MenuItem, Modal, Stack, Toolbar, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { appImages } from '../assets/images';
import { BiChevronDown, BiMenu } from 'react-icons/bi';

//  Redux
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Colors } from '../constants/colors';
import DrawerDashboard from './DrawerDashboard';
import { getAllNotifications, readNotification } from '../apis';
import { RxCross2 } from 'react-icons/rx';
import { MdOutlinePayments } from 'react-icons/md';
import { IoLogOutOutline } from 'react-icons/io5';
import { LuBellRing } from 'react-icons/lu';
import { BsBox2 } from 'react-icons/bs';

const stylePaymentModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 350, md: 400 },
    bgcolor: 'white',
    border: 'white',
    boxShadow: 24,
    p: { xs: 2, md: 4 },
    borderRadius: '10px'
};

const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
}

const formatJapanTime = () => {
    const now = new Date().toLocaleString("en-US", { timeZone: "Asia/Tokyo" });
    const date = new Date(now);

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear().toString().slice(-2);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const suffix = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12;

    return `${day}${getOrdinalSuffix(day)} ${month}, ${year} ${hours}:${minutes}${suffix}`;
}

const HeaderDashboard = () => {
    const navigate = useNavigate()
    const { loggedUserInfo } = useSelector((state) => state.auth);

    const matches = useMediaQuery('(max-width:820px)');
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [notificationModal, setNotificationModal] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [notify, setNotify] = useState(null);
    const [readID, setReadID] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [japanTime, setJapanTime] = useState(formatJapanTime());

    const openNotify = Boolean(notify)
    const handleNotify = (event) => {
        setNotify(event.currentTarget);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setJapanTime(formatJapanTime());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const handleNotifyClose = () => {
        setNotify(null);
    };

    useEffect(() => {
        if (loggedUserInfo?.Role?.RoleName === "User") {
            setInterval(() => {
                handleAllNotifications()
            }, 60000);
        }
    }, [])

    const handleNotificationModal = async (data) => {
        setReadID(data)
        try {
            const res = await readNotification(readID?._id)
            console.log("🚀 ~ file: HeaderDashboard.jsx:88 ~ handleReadNotification ~ res:", res)
            setNotificationModal(!notificationModal)
            handleAllNotifications()
        } catch (error) {
            console.log(error)
        }

    }

    const handleCloseNotification = () => {
        setNotificationModal(false)
    }


    const handleAllNotifications = async () => {
        try {
            const res = await getAllNotifications()
            console.log("🚀 ~ file: HeaderDashboard.jsx:36 ~ handleAllNotifications ~ res:", res)
            setNotifications(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAddPayment = () => {
        handleClose()
        navigate('/user/payment')
    }

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <>
            {matches ? (
                <>
                    <AppBar position="fixed">
                        <Toolbar sx={{ backgroundColor: Colors.white, justifyContent: 'space-between' }}>
                            {matches && (
                                <IconButton
                                    sx={{ width: '50px' }}
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerToggle}
                                    edge="start"
                                >
                                    <BiMenu color={Colors.Primary} />
                                </IconButton>
                            )}

                            <Avatar
                                variant="square"
                                src={appImages.kmLogo1}
                                sx={{
                                    width: 50,
                                    height: 50,
                                    '& .MuiAvatar-img': { objectFit: 'contain' },
                                    alignSelf: 'center'
                                }} />
                            <Box sx={{}}>
                                <Button
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <Avatar sx={{ width: 25, height: 25, marginRight: '5px' }} />
                                    <BiChevronDown size={'16px'} color={'black'} />
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >

                                    <MenuItem onClick={handleClose}>
                                        {loggedUserInfo ? loggedUserInfo?.FirstName + ' ' + loggedUserInfo?.LastName : ''}</MenuItem>
                                    <Divider />
                                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                                    {loggedUserInfo?.Role?.RoleName === "User" ? <MenuItem onClick={handleAddPayment}>Add Payment</MenuItem> : ''}
                                    <MenuItem onClick={() => {
                                        localStorage.removeItem('loggedUser')
                                        document.location.reload(navigate("/"))
                                    }} >Logout</MenuItem>
                                </Menu>
                            </Box>
                        </Toolbar>
                    </AppBar>

                    <DrawerDashboard />
                </>
            ) : (
                <Box sx={{ marginLeft: loggedUserInfo?.Role?.RoleName === 'Admin' ? '' : '-5%', backgroundColor: loggedUserInfo?.Role?.RoleName === 'Admin' ? 'rgb(49,54, 63,0.7)' : '', marginBottom: loggedUserInfo?.Role?.RoleName === 'Admin' ? '' : 2 }}>
                    {/* <Stack width={'100%'}> */}
                    <Stack height={'65px'} direction={"row"} display={'flex'} justifyContent={'space-between'} alignItems={"center"} >

                        <Typography sx={{ color: Colors.white, fontSize: '28px', fontWeight: 'bold', letterSpacing: '24px', textTransform: 'uppercase', opacity: '1', marginLeft: { xs: 0, md: 5 } }}>{loggedUserInfo?.Role?.RoleName === 'Admin' && 'Kashmir Motors Admin'}</Typography>
                        {loggedUserInfo?.Role?.RoleName !== 'Admin' &&
                            <Stack direction={'row'} alignItems={'center'}>
                                <Typography sx={{}}>Tokyo Time: {japanTime}</Typography>
                                <Button
                                    id="basic-button"
                                    aria-controls={openNotify ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openNotify ? 'true' : undefined}
                                    onClick={handleNotify}
                                >

                                    <LuBellRing size={30} color={loggedUserInfo?.Role?.RoleName === 'Admin' ? 'white' : Colors.lightgray} />
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={notify}
                                    open={openNotify}
                                    onClose={handleNotifyClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    sx={{ maxHeight: '400px', left: '-150px', top: '5px' }}
                                >
                                    <Typography sx={{ fontSize: '16px', padding: "5px 10px", color: Colors.Primary }}>
                                        Notifications
                                    </Typography>
                                    <Divider />
                                    {notifications?.length == 0 ?
                                        (
                                            <Typography sx={{ fontSize: '16px', padding: '5px 10px', fontWeight: '300' }}>
                                                No notification found
                                            </Typography>
                                        )
                                        :
                                        (
                                            <>
                                                {notifications.map((item, index) => {
                                                    return (
                                                        <MenuItem onClick={() => {
                                                            handleNotificationModal(item)
                                                            handleNotifyClose()
                                                        }
                                                        }>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', width: '200px' }}>
                                                                <Avatar
                                                                    variant="circular"
                                                                    sx={{
                                                                        width: 35,
                                                                        height: 35,
                                                                        '& .MuiAvatar-img': { objectFit: 'contain' }
                                                                    }} />
                                                                <Box sx={{ marginLeft: '5px' }}>
                                                                    <Typography sx={{ fontSize: '16px' }}>
                                                                        {item.notificationTitle}
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        fontSize: '12px', width: '150px', fontWeight: '300',
                                                                        whiteSpace: "nowrap",
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis",
                                                                        // padding: "5px 10px",
                                                                    }}>
                                                                        {item.notificationDescription}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </MenuItem>
                                                    )
                                                })}
                                            </>
                                        )}

                                </Menu>
                                <Button
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <Avatar sx={{ width: 35, height: 35, marginRight: '5px' }} />
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    sx={{ width: '300px' }}
                                >
                                    <MenuItem onClick={handleClose}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Avatar sx={{ width: 35, height: 35, marginRight: '5px' }} />
                                            <Typography>
                                                {loggedUserInfo ? loggedUserInfo?.FirstName + ' ' + loggedUserInfo?.LastName : ''}
                                            </Typography>
                                        </Box>
                                    </MenuItem>

                                    <Divider />

                                    {loggedUserInfo?.Role?.RoleName === "User" ? <MenuItem onClick={handleAddPayment}><Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <MdOutlinePayments size={25} />
                                        <Typography sx={{ marginLeft: '5px' }}>
                                            Add Payment
                                        </Typography>
                                    </Box></MenuItem> : ''}

                                    <MenuItem onClick={() => {
                                        localStorage.removeItem('loggedUser')
                                        document.location.reload(navigate("/"))
                                    }} ><Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <IoLogOutOutline size={25} style={{ marginRight: '5px' }} />
                                            <Typography>
                                                Logout
                                            </Typography>
                                        </Box></MenuItem>
                                </Menu>
                            </Stack>
                        }

                    </Stack>
                    {/* </Stack> */}
                </Box>
            )
            }
            <div>
                <Modal
                    open={notificationModal}
                    onClose={handleNotificationModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={stylePaymentModal}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={handleCloseNotification} style={{ cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Grid container gap={2} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}>
                                        <Avatar
                                            variant="circular"
                                            sx={{
                                                width: 55,
                                                height: 55,
                                                marginBottom: 1,
                                                '& .MuiAvatar-img': { objectFit: 'contain' }
                                            }} />
                                    </Box>
                                    <Typography sx={{ textAlign: 'center', marginBottom: 1 }}>{readID?.notificationTitle}</Typography>
                                    <Typography sx={{ textAlign: 'left', marginBottom: 5, fontWeight: '300', }}>{readID?.notificationDescription}</Typography>

                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    </Box>
                </Modal>
            </div>
        </>

    )
}

export default HeaderDashboard