import { Autocomplete, Avatar, Button, Divider, Drawer, Grid, List, ListItem, ListItemText, Menu, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import { appImages } from '../../assets/images';
// import SideBarView from '../../components/SideBar';
import { IoEyeOutline } from "react-icons/io5";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { HiOutlineDotsVertical, HiOutlinePlusSm } from "react-icons/hi";
import { Colors } from '../../constants/colors';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarQuickFilter />
        </GridToolbarContainer>
    );
}

function AdminInvoice() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'fullName',
            headerName: 'User',
            flex: 1,
            editable: false,
            renderCell: (params) => (
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    {/* <Avatar sx={{ width: 25, height: 25, '& .MuiAvatar-img': { objectFit: 'fill' }, }} src={appImages.homeBack} /> */}
                    {params?.row?.fullName}
                </Box>
            ),
        },
        {
            field: 'date',
            headerName: 'Date',
            flex: 1,
            editable: false,
        },
        // {
        //     field: 'email',
        //     headerName: 'Email',
        //     flex: 1,
        //     editable: false,
        // },
        {
            field: 'total',
            headerName: 'Total',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            flex: 1,
            valueGetter: (params) =>
                `${params.row.total}`,
        },
        {
            field: 'balance',
            headerName: 'Balance',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            flex: 1,
            valueGetter: (params) =>
                `${params.row.balance}`,
            renderCell: (params) => (
                <>
                    {params.row.balance == 'paid' ?
                        <Box>
                            <Typography sx={{ fontSize: '12px', backgroundColor: 'rgba(103, 187, 47, 0.2)', py: 0.2, px: 1, borderRadius: '5px', color: '#67BB2F' }}>
                                Paid
                            </Typography>
                        </Box>
                        :
                        params.row.balance == 'unpaid' ?
                            <Box>
                                <Typography sx={{ fontSize: '12px', backgroundColor: 'rgba(253, 181, 40, 0.2)', py: 0.2, px: 1, borderRadius: '5px', color: '#FDB528' }}>
                                    UnPaid
                                </Typography>
                            </Box>
                            :
                            <Box>
                                <Typography sx={{ fontSize: '12px', borderRadius: '5px' }}>
                                    #4027
                                </Typography>
                            </Box>}


                </>
            ),
        },
        // {
        //     field: 'role',
        //     headerName: 'Role',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: false,
        //     flex: 1,
        //     valueGetter: (params) =>
        //         `${params.row.role}`,
        // },
        {
            field: 'action',
            headerName: 'Action',
            type: 'number',
            width: 110,
            editable: false,
            renderCell: (params) => (
                <Box display={'flex'} gap={0} alignItems={'center'}>
                    <IoEyeOutline />  <HiOutlineDotsVertical sx={{ width: 25, height: 25 }} />
                </Box>
            ),
        },
    ];

    const rows = [
        { id: '#5089', date: '23 Oct 2023', fullName: 'John Doe', total: '$4027', balance: 'paid', role: 'Admin', action: 35, },
        { id: '#5090', date: '22 Oct 2023', fullName: 'Breena Gallemore', total: '$4027', balance: 'paid', role: 'Editor', action: 42, },
        { id: '#5091', date: '21 Oct 2023', fullName: 'Beverlie Krabbe', total: '$4027', balance: 'unpaid', role: 'Subscriber', action: 45, },
        { id: '#5092', date: '20 Oct 2023', fullName: 'Brockie Myles', total: '$4027', balance: '$4027', role: 'Maintainer', action: 16, },
        { id: '#5093', date: '19 Oct 2023', fullName: 'Candice Pinyon', total: '$4027', balance: 'paid', role: 'Editor', action: 26, },
        { id: '#5094', date: '18 Oct 2023', fullName: 'Bree Kilday', total: '$4027', balance: 'unpaid', role: 'Author', action: 15, },
        { id: '#5095', date: '17 Oct 2023', fullName: 'Bertha Biner', total: '$4027', balance: '$4027', role: 'Subscriber', action: 44, },
    ];

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <div>
            <Grid container md={12} >
                <Grid item xs={12} sm={11} md={12}>
                    <Grid container md={12} gap={2} marginTop={2} justifyContent={{ xs: 'center' }} >
                        <Grid item xs={11} md={11} sx={{ boxShadow: 1, borderRadius: '5px', p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} gap={2} >
                            <Box display={'flex'} width={'20%'} justifyContent={'space-between'} alignItems={'center'}>
                                <Box >
                                    <Typography sx={{ fontSize: 18 }}>38</Typography>
                                    <Typography sx={{ fontSize: 12, color: '#646464' }}>Clients</Typography>
                                </Box>
                                <Avatar variant='square' src={appImages.client} sx={{ width: 22, height: 22 }} />
                            </Box>
                            <Divider sx={{ marginTop: 3, height: 50, backgroundColor: '#DFDFDF', display: { xs: 'none', md: 'block' } }} orientation={'vertical'} />
                            <Box display={'flex'} width={'20%'} justifyContent={'space-between'} alignItems={'center'}>
                                <Box >
                                    <Typography sx={{ fontSize: 18 }}>165</Typography>
                                    <Typography sx={{ fontSize: 12, color: '#646464' }}>Invoice</Typography>
                                </Box>
                                <Avatar variant='square' src={appImages.invoice} sx={{ width: 22, height: 22 }} />
                            </Box>
                            <Divider sx={{ marginTop: 3, height: 50, backgroundColor: '#DFDFDF', display: { xs: 'none', md: 'block' } }} orientation={'vertical'} />
                            <Box display={'flex'} width={'20%'} justifyContent={'space-between'} alignItems={'center'}>
                                <Box  >
                                    <Typography sx={{ fontSize: 18 }}>$8.14K</Typography>
                                    <Typography sx={{ fontSize: 12, color: '#646464' }}>Paid</Typography>
                                </Box>
                                <Avatar variant='square' src={appImages.paid} sx={{ width: 22, height: 22 }} />
                            </Box>
                            <Divider sx={{ marginTop: 3, height: 50, backgroundColor: '#DFDFDF', display: { xs: 'none', md: 'block' } }} orientation={'vertical'} />
                            <Box display={'flex'} width={'20%'} justifyContent={'space-between'} alignItems={'center'}>
                                <Box>
                                    <Typography sx={{ fontSize: 18 }}>$1.87K</Typography>
                                    <Typography sx={{ fontSize: 12, color: '#646464' }}>Unpaid</Typography>
                                </Box>
                                <Avatar variant='square' src={appImages.unpaid} sx={{ width: 22, height: 22 }} />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container md={12} marginTop={2} gap={2} justifyContent={'center'}>
                        <Grid item xs={11} md={11} p={2} boxShadow={1} borderRadius={'5px'}>
                            <Box sx={{ height: 'auto', width: '100%' }}>
                                <Box sx={{ textAlign: 'right', my: '10px' }} >
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        sx={{
                                            backgroundColor: Colors.Primary, '&:hover': {
                                                backgroundColor: Colors.Primary, // Change the text color when hovered over
                                                // You can add other styles here for the hover state
                                            },
                                            alignItems: 'center',
                                            marginLeft: '5px'
                                        }}
                                    >
                                        <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} > <HiOutlinePlusSm /> Create Invoice</Typography>
                                    </Button>
                                </Box>
                                <DataGrid
                                    rows={rows}
                                    // columns={columns}
                                    columns={columns.map((column) => ({
                                        ...column,
                                        cellClassName: 'custom-cell-class',
                                    }))}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 20,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[20]}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                    slots={{
                                        toolbar: CustomToolbar,
                                    }}
                                    // slots={{ toolbar: GridToolbar }}
                                    slotProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                        },
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

        </div>
    )
}

export default AdminInvoice