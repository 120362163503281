
import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./apis/index";
import auctionReducer from "./apis/AuctionApis";

const reducer = {
    auth: authReducer,
    auction: auctionReducer,
}

const store = configureStore({
    reducer: reducer,
    devTools: true,
})

export default store;