import { AxiosInstance } from './AxiosInstance';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchAllMakes = createAsyncThunk(
    "auction/fetchAllMakes",
    async () => {
        try {
            const response = await AxiosInstance.get(`/Global/GetAllMakes`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const fetchAllColors = createAsyncThunk(
    "auction/fetchAllColors",
    async () => {
        try {
            const response = await AxiosInstance.get(`/Global/GetAllColors`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const fetchAllAuctionHouses = createAsyncThunk(
    "auction/fetchAllAuctionHouses",
    async () => {
        try {
            const response = await AxiosInstance.get(`/Global/GetAllAuctionHouses`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const GetModelByMake = async (query) => {
    const response = await AxiosInstance.get(`/Global/GetAllModels` + query);
    return response.data;
};

export const GetChasisList = async (data) => {
    const response = await AxiosInstance.get(`Global/GetAllchasisByModel/` + data);
    return response.data;
};
export const GetAuctionList = async (data) => {
    const response = await AxiosInstance.post(`Global/GetAuctionList`, data);
    return response.data;
};

export const GetModelCount = async (name) => {
    const response = await AxiosInstance.get(`Global/GetModelCount/` + name);
    return response.data;
};

export const GetCarDetail = async (id) => {
    const response = await AxiosInstance.get(`Global/GetCarDetail/` + id);
    return response.data;
};

export const MarkFavorite = async (id, aucId) => {
    const response = await AxiosInstance.get(`addToFavourite/` + `${id}/`+ `${aucId}`);
    return response.data;
}
;
export const AllFavorite = async () => {
    const response = await AxiosInstance.get(`getAllFav/`);
    return response.data;
};

export const DeleteFvrt = async (id) => {
    const response = await AxiosInstance.get(`deleteFav/` + id);
    console.log("🚀 ~ DeleteFvrt ~ response:", response)
    return response.data;
};

export const getCancelBidbyUser = async (data) => {
    const response = await AxiosInstance.post(`CanceledUserBid/`, data);
    return response.data;
};
export const GetBidDetail = async (id) => {
    const response = await AxiosInstance.get(`GetSingleBidUser/` + id);
    return response.data;
};

export const CreateUserBid = async (data) => {
    const response = await AxiosInstance.post(`UserBid`, data);
    return response.data;
};

export const GetUserBidList = async (query) => {
    const response = await AxiosInstance.get(`UserBidList` + query);
    return response.data;
};

const user = JSON.parse(localStorage.getItem("loggedUser"));
const allMakesData = [];
const allColorsData = {};
const allAuctionHouses = {};

const initialState = user
    ? { allMakesData, allColorsData, allAuctionHouses }
    : { allMakesData: null, allColorsData: null, allAuctionHouses: null };

const auctionSlice = createSlice({
    name: "auction",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllMakes.fulfilled, (state, action) => {
                state.allMakesData = action.payload.data;
            })
            .addCase(fetchAllMakes.rejected, (state) => {
                state.allMakesData = null;
            })
            .addCase(fetchAllColors.fulfilled, (state, action) => {
                state.allColorsData = action.payload.data;
            })
            .addCase(fetchAllColors.rejected, (state) => {
                state.allColorsData = null;
            })
            .addCase(fetchAllAuctionHouses.fulfilled, (state, action) => {
                state.allAuctionHouses = action.payload.data;
            })
            .addCase(fetchAllAuctionHouses.rejected, (state) => {
                state.allAuctionHouses = null;
            });

            
    },
});

export const DownloadCarImages = async (id) => {
    const response = await AxiosInstance.get(`Global/GetCarDetailImages/` + id);
    return response.data;
};

export default auctionSlice.reducer;