import { Autocomplete, Avatar, Button, CircularProgress, Divider, Drawer, Grid, InputAdornment, LinearProgress, List, ListItem, ListItemText, Menu, MenuItem, Modal, OutlinedInput, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { appImages } from '../../assets/images';
// import SideBarView from '../../components/SideBar';
import { GoEye } from "react-icons/go";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { HiOutlineDotsVertical, HiOutlinePlusSm } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineDeleteOutline, MdOutlineEdit, MdOutlinePayments, MdBlock } from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";
import { Colors } from '../../constants/colors';
import { activeUsersWidget, blockedUser, detailedUser, GetAllUsers, getDeleteUser, getUserBidStatus, paidUsersWidget, pendingUsersWidget, totalUsersWidget, verifyUser } from '../../apis/adminIndex';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { addUserValidationSchema, balanceSchema, paymentSchema } from '../../constants/formikValidation';
import { addBalancePayment, addUser, verifyingPayment } from '../../apis';
import { TiArrowDown, TiArrowUp } from "react-icons/ti";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { commaSeparated } from '../../constants/GlobalConstants';
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom';
import { AiOutlineTransaction } from 'react-icons/ai';



const Role = [
    { id: 1, label: 'User' },
    { id: 2, label: 'Admin' },
]
const Bank = [
    { id: 1, label: 'UBL' },
    { id: 2, label: 'Meezan' },
    { id: 3, label: 'Allied' },
]
const bidingStatus = [
    { id: 1, label: 'Enable' },
    { id: 2, label: 'Disable' },
]

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 350, md: 500 },
    height: { xs: 450, md: 450 },
    bgcolor: 'white',
    border: 'white',
    boxShadow: 24,
    p: { xs: 1, md: 4 },
    borderRadius: '10px'
};

const addBalanceStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 350, md: 500 },
    height: { xs: 450, md: 'auto' },
    bgcolor: 'white',
    border: 'white',
    boxShadow: 24,
    p: { xs: 1, md: 4 },
    borderRadius: '10px'
};

const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 350, md: 500 },
    height: { xs: 450, md: 350 },
    bgcolor: 'white',
    border: 'white',
    boxShadow: 24,
    p: { xs: 1, md: 4 },
    borderRadius: '10px'
};
const stylePaymentModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 350, md: 400 },
    bgcolor: 'white',
    border: 'white',
    boxShadow: 24,
    p: { xs: 2, md: 4 },
    borderRadius: '10px'
};

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarQuickFilter />
        </GridToolbarContainer>
    );
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function AdminUserBalance() {
    const navigate = useNavigate()
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [biderStatusChangesModal, setBiderStatusChangesModal] = useState(false);
    const [deleteUserModal, setDeleteUserModal] = useState(false);
    const [blockUserModal, setBlockUserModal] = useState(false);
    const [spinner, setSpinner] = useState(false)
    const [getAllUsersData, setGetAllUsersData] = useState([]);
    const [userBidStatus, setUserBidStatus] = useState();
    const [getDetailedUser, setGetDetailedUser] = useState([]);
    const [selectedUser, setSelectedUser] = useState()
    const [totalUsers, setTotalUsers] = useState()
    const [activeUsers, setActiveUsers] = useState()
    const [paidUsers, setPaidUsers] = useState()
    const [pendingUsers, setPendingUsers] = useState()
    const [loading, setLoading] = useState(true);
    const [loader, setLoader] = useState(false)
    const [addUserLoader, setAddUserLoader] = useState(false)
    const [addBalanceModal, setAddBalanceModal] = useState(false)
    const [addPaymentDetail, setAddPaymentDetail] = useState(null)
    const open = Boolean(anchorEl);
    const handleClick = (event, rowData) => {
        setSelectedUser(rowData?.row)
        setAnchorEl(event.currentTarget);
    };

    const handleAddBalance = () => {
        setAddBalanceModal(true)
    }

    const handleBlockUserModal = () => {
        setBlockUserModal(true)
    }

    const handleUserSelect = (data) => {
        const url = "/admin/customer-detail";
        const state = { data: data.id };
        localStorage.setItem('customerDetailData', JSON.stringify(state));
        window.open(url, '_blank');
    }

    const handleBiderStatusModalClick = () => {
        setBiderStatusChangesModal(true)
    };

    const handleDeleteUserModalClick = () => {
        setDeleteUserModal(true)
    };


    const handleBiderStatusModalClose = () => {
        setBiderStatusChangesModal(false)
    }
    const handleDeleteUserModalClose = () => {
        setDeleteUserModal(false)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [paymentModal, setPaymentModal] = useState(false);
    const handlePaymentModalOpen = () => {
        handleGetDetailedUser()
        setModal(false)
        setPaymentModal(true)
    }
    const [modal, setModal] = useState(false);
    const handleModalOpen = () => {
        handleGetDetailedUser()
        setModal(true)
    }
    const handleModalClose = () => setModal(false);
    const handlePaymentModalClose = () => {
        setPaymentModal(false)
        setModal(true)
    };

    const columns = [
        // { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'fullName',
            headerName: 'User',
            // flex: 1,
            width: 150,
            editable: false,
            renderCell: (params) => (
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    {params?.row?.fullName}
                </Box>
            ),
        },
        {
            field: 'email',
            headerName: 'Email',
            // flex: 1,
            width: 200,
            editable: false,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            // flex: 1,
            width: 150,
            editable: false,
            sortable: false,
        },
        {
            field: 'balance',
            headerName: 'Balance',
            sortable: false,
            // flex: 1,
            width: 150,
            renderCell: ({ row }) => (
                <Typography sx={{
                    color: row.balance == 0 ? 'blue' : row.balance > 0 ? 'green' : row.balance < 0 ? Colors.Primary : '',
                    fontWeight: '300'
                }} >{commaSeparated(row.balance)}</Typography>
            )
            // valueGetter: (params) =>
            //     `${params.row.balance}`,
        },
        {
            field: 'role',
            headerName: 'Role',
            sortable: false,
            // flex: 1,
            width: 80,
            valueGetter: (params) =>
                `${params.row.role}`,
        },
        {
            field: 'bidding',
            headerName: 'Bidding',
            sortable: false,
            // flex: 1,
            width: 100,
            renderCell: (params) => (
                <Box
                    textAlign={'center'}
                    justifyContent={'center'}
                    p={'5px'}
                    borderRadius={'5px'}
                    bg={Colors.Primary}
                >
                    {params?.row?.bidStatus == 'Enable' ? <Avatar src={appImages.tick} sx={{ width: '20px', height: '20px' }} /> : <Avatar src={appImages.cross} sx={{ width: '20px', height: '20px' }} />}
                </Box>
            ),
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            // flex: 1,
            width: 120,
            renderCell: (params) => (
                <Box
                    width={"50px"}
                    textAlign={'center'}
                    px={1}
                    py={'2px'}
                    borderRadius={'5px'}
                    bgcolor={params?.row?.status == "1" ? '#e1f1d6' : '#fff0d6'}>
                    <Typography sx={{ fontSize: '12px', color: params?.row?.status == "1" ? '#67bb2e' : '#fdb529', fontWeight: '500' }}>
                        {params?.row?.status == "1" ? "Active" : "Pending"}
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            // type: 'number',
            width: 120,
            editable: false,
            sortable: false,
            renderCell: (rowData) => {
                const { row } = rowData;
                console.log("🚀 ~ AdminUserBalance ~ rowData:", rowData)
                return (
                    <>
                        <HiOutlineDotsVertical onClick={(event) => { handleClick(event, rowData) }} style={{ width: 15, height: 15, cursor: 'pointer' }} />
                        <Menu
                            sx={{
                                boxShadow: 'none', '& .MuiPaper-elevation': {
                                    boxShadow: '.2px .2px .2px .2px'
                                }
                            }}
                            id={rowData.id}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => {
                                handleModalOpen();
                                handleClose()
                            }}> <GoEye style={{ marginRight: '5px' }} /> View</MenuItem>
                            <MenuItem onClick={() => {
                                handleClose()
                                handleAddBalance()
                            }}> <MdOutlinePayments style={{ marginRight: '5px' }} /> Add Balance</MenuItem>
                            <MenuItem onClick={() => {
                                handleUserSelect(selectedUser);
                                handleClose()
                            }}> <AiOutlineTransaction style={{ marginRight: '5px' }} /> Transactions
                            </MenuItem>
                            <MenuItem onClick={(event) => {
                                handleBlockUserModal()
                                handleClose()
                            }}> <MdBlock style={{ marginRight: '5px' }} /> Block</MenuItem>
                            <MenuItem onClick={() => {
                                handleDeleteUserModalClick()
                                handleClose()
                            }}> <MdOutlineDeleteOutline style={{ marginRight: '5px' }} /> Delete</MenuItem>
                            <MenuItem onClick={() => {
                                handleBiderStatusModalClick()
                                handleClose()
                            }}> <MdOutlineEdit style={{ marginRight: '5px' }} /> Biding </MenuItem>
                        </Menu>
                    </>
                )
            },
        },
    ];

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleGetAllUsers = async () => {
        const res = await GetAllUsers()
        console.log("🚀 ~ file: AdminUserBalance.jsx:227 ~ handleGetAllUsers ~ res:", res)
        if (res) {
            setLoading(false)
        }
        setGetAllUsersData(res?.data?.result.map((item) => {
            setUserBidStatus(item.bidStatus)
            return {
                id: item._id,
                email: item.Email,
                phone: item.Phone,
                fullName: item.FirstName + ' ' + item.LastName,
                balance: item.balance,
                role: item.Role,
                status: item.AdminVrify,
                bidStatus: item.bidStatus,
                action: 35,
            }
        }))
    }

    const handleGetDetailedUser = async () => {
        const res = await detailedUser(selectedUser?.id)
        console.log("🚀 ~ file: AdminUserBalance.jsx:238 ~ handleGetDetailedUser ~ res:", res)
        setGetDetailedUser(res?.data)
    }

    const handleBlockUser = async () => {
        setSpinner(true)
        try {
            const res = await blockedUser(selectedUser?.id)
            console.log("🚀 ~ handleBlockUser ~ res:", res)
            toast.success(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setSpinner(false)
            setBlockUserModal(false)
        } catch (error) {
            console.log("🚀 ~ handleBlockUser ~ error:", error)
            setSpinner(false)
            setBlockUserModal(false)
        }
    }

    const handleTotalUserWidgets = async () => {
        const res = await totalUsersWidget()
        setTotalUsers(res.data)
    }
    const handleActiveUserWidgets = async () => {
        const res = await activeUsersWidget()
        setActiveUsers(res.data)
    }

    const handlePaidUserWidgets = async () => {
        const res = await paidUsersWidget()
        setPaidUsers(res.data)
    }

    const handlePendingUserWidgets = async () => {
        const res = await pendingUsersWidget()
        setPendingUsers(res.data)
    }

    const handleVerifyUser = async () => {
        setLoader(true)
        try {
            const res = await verifyUser(selectedUser?.id)
            toast.success(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoader(false)
            setModal(false)
        } catch (error) {
            console.log("🚀 ~ file: AdminUserBalance.jsx:229 ~ handleVerifyUser ~ error:", error.res)
        }

    }

    const addUserValues = { FirstName: '', LastName: '', Email: '', Phone: '', UserRole: '' }

    const handleAddUser = async (values) => {
        console.log("🚀 ~ file: AdminUserBalance.jsx:277 ~ handleAddUser ~ values:", values)
        setAddUserLoader(true)
        try {
            const res = await addUser(values.FirstName, values.LastName, values.Email, values.Phone, values.UserRole)
            console.log("🚀 ~ file: AdminUserBalance.jsx:283 ~ handleAddUser ~ res:", res)
            toast.success(res?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            handleGetAllUsers()
            handleDrawerToggle()
            setAddUserLoader(false)
        } catch (error) {
            console.log("🚀 ~ file: AdminUserBalance.jsx:281 ~ handleAddUser ~ error:", error)
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setAddUserLoader(false)
        }
    }

    const handleChangeBidingStatus = async () => {
        setSpinner(true)
        try {
            let formData = new FormData();
            formData.append("userId", selectedUser.id);
            if (selectedUser.bidStatus == "Enable") {
                formData.append("bidStatus", "Disable");
            }
            if (selectedUser.bidStatus == "Disable") {
                formData.append("bidStatus", "Enable");
            }
            const response = await getUserBidStatus(formData);
            handleGetAllUsers()
            setSpinner(false)
            toast.success(response?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setBiderStatusChangesModal(false)
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setSpinner(false)
        } finally {
            setSpinner(false);
        }
    }

    const handleDeleteUser = async () => {
        setSpinner(true)
        try {
            const response = await getDeleteUser(selectedUser.id);
            console.log("🚀 ~ handleDeleteUser ~ response:", response)
            handleGetAllUsers()
            setSpinner(false)
            toast.success(response?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setDeleteUserModal(false)
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setDeleteUserModal(false)
            setSpinner(false)
        } finally {
            setDeleteUserModal(false)
            setSpinner(false);
        }
    }

    useEffect(() => {
        handleGetAllUsers()
        handleTotalUserWidgets()
        handleActiveUserWidgets()
        handlePaidUserWidgets()
        handlePendingUserWidgets()
    }, [])

    const initialValues = { payment: '', bankName: '' }

    const handlePayment = async (values) => {
        console.log("🚀 ~ file: AdminUserBalance.jsx:324 ~ handlePayment ~ values:", values)
        setLoading(true)
        try {
            const res = await verifyingPayment(addPaymentDetail?.userID, addPaymentDetail?._id, values.payment, values.bankName)
            console.log("🚀 ~ file: AdminUserBalance.jsx:328 ~ handlePayment ~ res:", res)
            toast.success(res?.data?.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            handleGetAllUsers()
            setTimeout(() => {
                setPaymentModal(false)
                setLoading(false)
            }, 2500);
        } catch (error) {
            console.log("🚀 ~ file: Login.jsx:30 ~ handleLogin ~ error:", error)
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setPaymentModal(false)
            setModal(true)
            setLoading(false)
        }
    }

    const handleAddPayment = async (values) => {
        console.log("🚀 ~ handleAddPayment ~ values:", values)
        setLoading(true)
        try {
            const res = await addBalancePayment(selectedUser.id, values.payment, values.bankName)
            console.log("🚀 ~ handleAddPayment ~ res:", res)
            toast.success(res?.data?.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            handleGetAllUsers()
            setTimeout(() => {
                setAddBalanceModal(false)
                setLoading(false)
            }, 2500);
        } catch (error) {
            console.log("🚀 ~ file: Login.jsx:30 ~ handleLogin ~ error:", error)
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setPaymentModal(false)
            // setModal(false)
            setLoading(false)
        }
    }

    return (
        <div>
            <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
                <List sx={{ width: '300px' }}>
                    <ListItem >
                        <ListItemText primary="Add User" /> <RxCross2 style={{ cursor: 'pointer' }} onClick={handleDrawerToggle} />
                    </ListItem>
                    <Formik
                        initialValues={addUserValues}
                        validationSchema={addUserValidationSchema}
                        onSubmit={handleAddUser}
                    >
                        {({ errors, touched, validateForm, handleSubmit, setFieldValue, }) => (
                            <Form>
                                <ListItem >
                                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }} >
                                        <Field name="FirstName">
                                            {({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    sx={{ backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' }, marginTop: { xs: 1, md: 2 }, }}
                                                    placeholder="First Name"
                                                    id="outlined-adornment-weight"
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="FirstName">
                                            {(msg) => (
                                                <Typography variant="caption" color="error">
                                                    {msg}
                                                </Typography>
                                            )}
                                        </ErrorMessage>
                                    </Box>
                                </ListItem>
                                <ListItem >
                                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }} >
                                        <Field name="LastName">
                                            {({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    sx={{ backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' }, marginTop: { xs: 1, md: 2 }, }}
                                                    placeholder="Last Name"
                                                    id="outlined-adornment-weight"
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="LastName">
                                            {(msg) => (
                                                <Typography variant="caption" color="error">
                                                    {msg}
                                                </Typography>
                                            )}
                                        </ErrorMessage>
                                    </Box>
                                </ListItem>
                                <ListItem >
                                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }} >
                                        <Field name="Email">
                                            {({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    sx={{ backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' }, marginTop: { xs: 1, md: 2 }, }}
                                                    placeholder="Email"
                                                    id="outlined-adornment-weight"
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="Email">
                                            {(msg) => (
                                                <Typography variant="caption" color="error">
                                                    {msg}
                                                </Typography>
                                            )}
                                        </ErrorMessage>
                                    </Box>
                                </ListItem>
                                <ListItem >
                                    <PhoneInput
                                        country={'jp'}
                                        value={addUserValues.Phone}
                                        placeholder={'Phone Number'}
                                        onChange={(val) =>
                                            setFieldValue("Phone", val)}
                                        inputProps={{
                                            name: 'Phone',
                                            required: true,
                                            autoFocus: true
                                        }}
                                        containerStyle={{ backgroundColor: '#F6F6F6', marginTop: '17px', height: '55px' }}
                                        inputStyle={{ width: '100%', height: '55px', backgroundColor: '#F6F6F6' }}
                                        buttonStyle={{ backgroundColor: 'rgba(173, 173, 173, 0.5)' }}
                                    />
                                </ListItem>
                                <ListItem >
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={Role}
                                        disableClearable
                                        sx={{ width: '100%', backgroundColor: '#F6F6F6', marginTop: '16px' }}
                                        onChange={(e, v) => setFieldValue("UserRole", v?.label)}
                                        renderInput={(params) => <TextField {...params} variant="outlined" placeholder="User Role" />}
                                    />
                                </ListItem>
                                <ListItem >
                                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                        <Button
                                            onClick={handleSubmit}
                                            id="basic-button"
                                            aria-haspopup="true"
                                            sx={{
                                                backgroundColor: addUserLoader ? 'white' : Colors.Primary, '&:hover': {
                                                    backgroundColor: addUserLoader ? 'white' : Colors.Primary
                                                }
                                            }}
                                        >
                                            {addUserLoader ? <CircularProgress size={14} color={'warning'} /> : <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Submit</Typography>}
                                        </Button>
                                        <Button
                                            id="basic-button"
                                            aria-haspopup="true"
                                            sx={{
                                                backgroundColor: '#E8E9ED', '&:hover': {
                                                    backgroundColor: '#E8E9ED'
                                                }
                                            }}
                                            onClick={handleDrawerToggle}
                                        >
                                            <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'#878787'} >Cancel</Typography>
                                        </Button>
                                    </Stack>
                                </ListItem>
                            </Form>
                        )}
                    </Formik>
                </List>
            </Drawer>

            <Grid container md={12} mt={{ xs: 7, md: 0 }} >
                <Grid item xs={12} sm={11} md={12}>
                    {/* <Grid container md={12} gap={2} marginTop={2} justifyContent={{ xs: 'center' }} >
                        <Grid item xs={11} md={2.65} sx={{ borderRadius: '5px', p: 2, borderLeft: '2px solid #6200eb', borderLeftColor: '#6200eb', backgroundColor: 'rgb(98,0,235,0.1)' }}  >
                            <Box>
                                <Box display={'flex'} justifyContent={'space-between'}>
                                    <Typography sx={{ fontSize: 18 }}>Total Users</Typography>
                                    <Avatar variant='square' src={appImages.tUser} sx={{ width: 22, height: 22 }} />
                                </Box>
                                {!totalUsers ?
                                    <CircularProgress size={14} color='warning' />
                                    :
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: 18, color: '#646464' }}>{totalUsers?.UsersCounts}<span style={{ marginLeft: '10px', color: totalUsers?.weeklyPercentage < 0 ? Colors.Primary : '#39B34A' }}>({totalUsers?.weeklyPercentage.toFixed(2)}%)</span> </Typography>
                                        {totalUsers?.weeklyPercentage < 0 ? <TiArrowDown size={20} color={Colors.Primary} /> : <TiArrowUp size={20} color={'#39B34A'} />}
                                    </Box>
                                }

                                <Typography sx={{ fontSize: 16 }}>Last week analytics</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={11} md={2.65} sx={{ borderRadius: '5px', p: 2, borderLeft: '2px solid #ffb400', borderLeftColor: '#ffb400', backgroundColor: 'rgb(255,180,2,0.1)' }}  >
                            <Box>
                                <Box display={'flex'} justifyContent={'space-between'}>
                                    <Typography sx={{ fontSize: 18 }}>Active Users</Typography>
                                    <Avatar variant='square' src={appImages.aUser} sx={{ width: 22, height: 22 }} />
                                </Box>
                                {!activeUsers ?
                                    <CircularProgress size={14} color='warning' />
                                    :
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: 18, color: '#646464' }}>{activeUsers?.UsersCounts}<span style={{ marginLeft: '10px', color: activeUsers?.weeklyPercentage < 0 ? Colors.Primary : '#39B34A' }}>({activeUsers?.weeklyPercentage.toFixed(2)}%)</span> </Typography>
                                        {activeUsers?.weeklyPercentage < 0 ? <TiArrowDown size={20} color={Colors.Primary} /> : <TiArrowUp size={20} color={'#39B34A'} />}
                                    </Box>
                                }
                                <Typography sx={{ fontSize: 16 }}>Last week analytics</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={11} md={2.65} sx={{ borderRadius: '5px', p: 2, borderLeft: '2px solid #9c26b0', borderLeftColor: '#9c26b0', backgroundColor: 'rgb(156,38,176,0.1)' }}  >
                            <Box>
                                <Box display={'flex'} justifyContent={'space-between'}>
                                    <Typography sx={{ fontSize: 18 }}>Paid Users</Typography>
                                    <Avatar variant='square' src={appImages.pUser} sx={{ width: 22, height: 22 }} />
                                </Box>
                                {!paidUsers ?
                                    <CircularProgress size={14} color='warning' />
                                    :
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: 18, color: '#646464' }}>{paidUsers?.UsersCounts}<span style={{ marginLeft: '10px', color: paidUsers?.weeklyPercentage < 0 ? Colors.Primary : '#39B34A' }}>({paidUsers?.weeklyPercentage?.toFixed(2)}%)</span> </Typography>
                                        {paidUsers?.weeklyPercentage < 0 ? <TiArrowDown size={20} color={Colors.Primary} /> : <TiArrowUp size={20} color={'#39B34A'} />}
                                    </Box>
                                }
                                <Typography sx={{ fontSize: 16 }}>Last week analytics</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={11} md={2.65} sx={{ borderRadius: '5px', p: 2, borderLeft: '2px solid #26a59a', borderLeftColor: '#26a59a', backgroundColor: 'rgb(37,165,154,0.1)' }}  >
                            <Box>
                                <Box display={'flex'} justifyContent={'space-between'}>
                                    <Typography sx={{ fontSize: 18 }}>Pending Users</Typography>
                                    <Avatar variant='square' src={appImages.penUser} sx={{ width: 22, height: 22 }} />
                                </Box>
                                {!pendingUsers ?
                                    <CircularProgress size={14} color='warning' />
                                    :
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: 18, color: '#646464' }}>{pendingUsers?.UsersCounts}<span style={{ marginLeft: '10px', color: pendingUsers?.weeklyPercentage < 0 ? Colors.Primary : '#39B34A' }}>({pendingUsers?.weeklyPercentage?.toFixed(2)}%)</span> </Typography>
                                        {pendingUsers?.weeklyPercentage < 0 ? <TiArrowDown size={20} color={Colors.Primary} /> : <TiArrowUp size={20} color={'#39B34A'} />}
                                    </Box>
                                }
                                <Typography sx={{ fontSize: 16 }}>Last week analytics</Typography>
                            </Box>
                        </Grid>
                    </Grid> */}

                    <Grid container md={12} marginTop={2} marginBottom={2} gap={2} justifyContent={'center'}>
                        <Grid item xs={11} md={11} borderRadius={'5px'}>
                            <Box sx={{ height: 'auto', width: '100%' }}>

                                <Box sx={{ my: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: 18 }}>Total Users:</Typography>
                                        <Typography sx={{ fontSize: 18, color: '#646464', marginLeft: '5px' }}>{totalUsers?.UsersCounts}</Typography>
                                    </Box>
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        sx={{
                                            backgroundColor: Colors.Primary, '&:hover': {
                                                backgroundColor: Colors.Primary, // Change the text color when hovered over
                                                // You can add other styles here for the hover state
                                            },
                                            alignItems: 'center',
                                            marginLeft: '5px'
                                        }}
                                        onClick={handleDrawerToggle}
                                    >
                                        <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} > <HiOutlinePlusSm /> Add User</Typography>
                                    </Button>
                                </Box>
                                {/* {loading ?
                                    <Box sx={{ width: '100%', textAlign: '-webkit-center' }} >
                                        <CircularProgress size={26} color={'warning'} />
                                    </Box>
                                    : */}
                                <Box sx={{ height: 500, width: '100%', overflowX: 'auto' }}>
                                    <DataGrid
                                        rows={getAllUsersData || []}
                                        // columns={columns}
                                        columns={columns.map((column) => ({
                                            ...column,
                                            cellClassName: 'custom-cell-class',
                                        }))}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 200,
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[200]}
                                        rowsPerPageOptions={[200]}
                                        // checkboxSelection
                                        disableRowSelectionOnClick
                                        slots={{
                                            loadingOverlay: LinearProgress,
                                            toolbar: CustomToolbar,
                                        }}
                                        loading={loading}
                                        // slots={{ toolbar: GridToolbar }}
                                        slotProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                            },
                                        }}
                                    />
                                </Box>
                                {/* } */}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

            <div>
                <Modal
                    open={modal}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={value == 0 ? style1 : style}>
                        <RxCross2 onClick={handleModalClose} style={{ float: 'right', cursor: 'pointer' }} />
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{}}>
                                <Tabs variant="standard" value={value} onChange={handleChange} indicatorColor={'secondary'} textColor="secondary">
                                    <Tab sx={{ width: '50%' }} label="User Detail" {...a11yProps(0)} />
                                    <Tab sx={{ width: '50%' }} label="Payment History" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <Box display={'flex'} justifyContent={'space-between'}>
                                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{ width: '40%' }}>
                                        <Box alignItems={'center'}>
                                            <Avatar variant={'rounded'} sx={{ width: 100, height: 100 }} />
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: '50%' }}>
                                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} lineHeight={1.5}>
                                            <Box sx={{ textAlign: 'left', my: 2, width: '100%' }}>
                                                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} mb={2}>
                                                    <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px' }} >Name</Typography>
                                                    <Typography sx={{ marginTop: '5px', fontSize: '14px' }} >{getDetailedUser?.User?.FirstName + ' ' + getDetailedUser?.User?.LastName}</Typography>
                                                </Box>
                                                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} mb={2}>
                                                    <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px' }} >Email</Typography>
                                                    <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px' }}>{getDetailedUser?.User?.Email}</Typography>
                                                    {/* <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            backgroundColor: 'rgba(195, 195, 195, 0.2)',
                                                            padding: 1,
                                                            borderRadius: '5px',
                                                            borderColor: '#C3C3C3',
                                                            border: 0.4
                                                        }}>
                                                       
                                                        <MdOutlineMail size={14} />
                                                    </Box> */}
                                                </Box>
                                                <Box display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'} mb={2}>
                                                    <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px' }} >Phone</Typography>
                                                    <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px' }}>{getDetailedUser?.User?.Phone}</Typography>
                                                    {/* <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            backgroundColor: 'rgba(195, 195, 195, 0.2)',
                                                            padding: 1,
                                                            borderRadius: '5px',
                                                            borderColor: '#C3C3C3',
                                                            border: 0.4
                                                        }}>
                                                        
                                                        <FaPhoneAlt size={14} />
                                                    </Box> */}
                                                </Box>
                                                <Box display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'} mb={2}>
                                                    <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', my: '5px' }} >Last Payment</Typography>
                                                    <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px' }}>{moment(getDetailedUser?.User?.createdAt).format("ll")}</Typography>
                                                    {/* <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            backgroundColor: 'rgba(195, 195, 195, 0.2)',
                                                            padding: 1,
                                                            borderRadius: '5px',
                                                            borderColor: '#C3C3C3',
                                                            border: 0.4
                                                        }}>
                                                        
                                                        <BsCurrencyDollar size={14} />
                                                    </Box> */}
                                                </Box>
                                            </Box>
                                        </Box>
                                        {/* <motion.div whileHover={{ scale: 1.02 }}>
                                            <Button
                                                onClick={handleVerifyUser}
                                                id="basic-button"
                                                sx={{
                                                    backgroundColor: loader ? 'white' : Colors.Primary, '&:hover': {
                                                        backgroundColor: loader ? 'white' : Colors.Primary,
                                                    },
                                                    width: '100%',
                                                    my: 1
                                                }}
                                            >
                                                {loader ? <CircularProgress size={16} color={'warning'} /> : <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Verify Account</Typography>}
                                            </Button>
                                        </motion.div> */}
                                        {/* <Box sx={{ display: 'flex', gap: 2 }}>
                                            <Button
                                                onClick={() => { }}
                                                id="basic-button"
                                                sx={{
                                                    backgroundColor: '#232628', '&:hover': {
                                                        backgroundColor: '#232628',
                                                    },
                                                    width: '90%',
                                                    my: 1
                                                }}
                                            >
                                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Click to see transactions</Typography>
                                            </Button>
                                            <Button
                                                onClick={() => { }}
                                                id="basic-button"
                                                sx={{
                                                    backgroundColor: '#232628', '&:hover': {
                                                        backgroundColor: '#232628',
                                                    },
                                                    width: '90%',
                                                    my: 1
                                                }}
                                            >
                                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Click to see the purchases


                                                </Typography>
                                            </Button>
                                        </Box> */}
                                    </Box>

                                </Box>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1 }}>
                                    <Typography sx={{ marginTop: '-15px' }} >Date</Typography>
                                    <Typography sx={{ marginTop: '-15px' }} >Amount</Typography>
                                    <Typography sx={{ marginTop: '-15px' }} >File</Typography>
                                </Box>
                                <Divider />
                                <Box sx={{ overflowY: 'scroll', overflowX: 'hidden', maxHeight: 320 }}>
                                    {getDetailedUser?.paymentHistory?.map((item, index) => {
                                        const time = moment(item?.createdAt).format("ll");
                                        return (
                                            <>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1 }}>
                                                    <Typography sx={{ fontSize: '12px' }} >{time}</Typography>
                                                    <Typography sx={{ fontSize: '12px' }} marginLeft={-5} >{item.amount} Rs</Typography>
                                                    {/* <Avatar src={item.fileSrc} /> */}
                                                    <IoDocumentTextOutline onClick={() => {
                                                        setAddPaymentDetail(item)
                                                        handlePaymentModalOpen()
                                                    }} size={20} style={{ cursor: 'pointer' }} />
                                                </Box>
                                                <Divider />
                                            </>
                                        )
                                    })}
                                </Box>
                            </CustomTabPanel>
                        </Box>
                    </Box>
                </Modal>
            </div>
            <div>
                <Modal
                    open={paymentModal}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <RxCross2 onClick={handlePaymentModalClose} style={{ float: 'right', cursor: 'pointer' }} />
                        <Box sx={{ width: '100%' }}>
                            <Typography sx={{ fontSize: '18px', fontFamily: 'Roboto', fontWeight: '500', textAlign: 'center', color: Colors.Primary, marginBottom: 2 }} >Verifying Payment</Typography>
                            {/* 
                            <Tabs variant="standard" value={value} onChange={handleChange} indicatorColor={'secondary'} textColor="secondary">
                                <Tab sx={{ width: '100%' }} label="Payment History" {...a11yProps(0)} />
                            </Tabs> */}

                            {/* <CustomTabPanel value={value} index={1}> */}
                            {/* <Divider /> */}
                            <Box sx={{ maxHeight: 320 }}>
                                <Box sx={{ width: '100%', height: 200 }}>
                                    <img src={process.env.REACT_APP_API_IMAGE_URL + addPaymentDetail?.fileSrc} style={{ width: '100%', height: '100%', borderRadius: '5px' }} />
                                </Box>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={paymentSchema}
                                    onSubmit={handlePayment}
                                >
                                    {({ errors, touched, validateForm, handleSubmit, setFieldValue }) => (
                                        <Form>
                                            <Stack mt={4}>
                                                <Field name="payment">
                                                    {({ field }) => (
                                                        <OutlinedInput
                                                            {...field}
                                                            sx={{ backgroundColor: '#F6F6F6' }}
                                                            placeholder={addPaymentDetail.amount}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="payment">
                                                    {(msg) => (
                                                        <Typography variant="caption" color="error">
                                                            {msg}
                                                        </Typography>
                                                    )}
                                                </ErrorMessage>
                                            </Stack>

                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={Bank}
                                                disableClearable
                                                sx={{ width: '100%', backgroundColor: '#F6F6F6', marginTop: '16px' }}
                                                onChange={(e, v) => setFieldValue("bankName", v?.label)}
                                                renderInput={(params) => <TextField {...params} variant="outlined" placeholder={addPaymentDetail?.bankName} />}
                                            />

                                            {/* <Stack mt={4}>
                                                <Field name="bankName">
                                                    {({ field }) => (
                                                        <OutlinedInput
                                                            {...field}
                                                            sx={{ backgroundColor: '#F6F6F6' }}
                                                            placeholder={'Enter Bank Name'}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="bankName">
                                                    {(msg) => (
                                                        <Typography variant="caption" color="error">
                                                            {msg}
                                                        </Typography>
                                                    )}
                                                </ErrorMessage>
                                            </Stack> */}

                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Button
                                                    onClick={handleSubmit}
                                                    id="basic-button"
                                                    aria-haspopup="true"
                                                    sx={{
                                                        marginTop: '25px',
                                                        backgroundColor: loading ? 'white' : Colors.Primary, '&:hover': {
                                                            backgroundColor: loading ? 'white' : Colors.Primary,
                                                        },
                                                        textTransform: 'capitalize',
                                                        width: '200px'
                                                    }}
                                                >
                                                    {loading ? <CircularProgress size={12} color={'warning'} /> : <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Submit</Typography>}
                                                </Button>
                                            </Box>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                            {/* </CustomTabPanel> */}
                        </Box>
                    </Box>
                </Modal>
            </div>

            <div>
                <Modal
                    open={addBalanceModal}
                    onClose={() => setAddBalanceModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={addBalanceStyle}>
                        <RxCross2 onClick={() => setAddBalanceModal(false)} style={{ float: 'right', cursor: 'pointer' }} />
                        <Box sx={{ width: '100%' }}>
                            <Typography sx={{ fontSize: '18px', fontFamily: 'Roboto', fontWeight: '500', textAlign: 'center', color: Colors.Primary, marginBottom: 2 }} >Add Balance</Typography>
                            {/* 
                            <Tabs variant="standard" value={value} onChange={handleChange} indicatorColor={'secondary'} textColor="secondary">
                                <Tab sx={{ width: '100%' }} label="Payment History" {...a11yProps(0)} />
                            </Tabs> */}

                            {/* <CustomTabPanel value={value} index={1}> */}
                            {/* <Divider /> */}
                            <Box sx={{ maxHeight: 320 }}>
                                {/* <Box sx={{ width: '100%', height: 200 }}>
                                    <img src={process.env.REACT_APP_API_IMAGE_URL + addPaymentDetail?.fileSrc} style={{ width: '100%', height: '100%', borderRadius: '5px' }} />
                                </Box> */}
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={balanceSchema}
                                    onSubmit={handleAddPayment}
                                >
                                    {({ errors, touched, validateForm, handleSubmit, setFieldValue }) => (
                                        <Form>
                                            <Stack mt={4}>
                                                <Field name="payment">
                                                    {({ field }) => (
                                                        <OutlinedInput
                                                            {...field}
                                                            sx={{ backgroundColor: '#F6F6F6' }}
                                                            placeholder={"Amount"}
                                                            startAdornment={<InputAdornment position="start">¥</InputAdornment>}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="payment">
                                                    {(msg) => (
                                                        <Typography variant="caption" color="error">
                                                            {msg}
                                                        </Typography>
                                                    )}
                                                </ErrorMessage>
                                            </Stack>

                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={Bank}
                                                disableClearable
                                                sx={{ width: '100%', backgroundColor: '#F6F6F6', marginTop: '16px' }}
                                                onChange={(e, v) => setFieldValue("bankName", v?.label)}
                                                renderInput={(params) => <TextField {...params} variant="outlined" placeholder={"Select Bank"} />}
                                            />

                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                {loading ?
                                                    <Button
                                                        onClick={() => { }}
                                                        id="basic-button"
                                                        aria-haspopup="true"
                                                        sx={{
                                                            marginTop: '25px',
                                                            backgroundColor: loading ? 'white' : Colors.Primary, '&:hover': {
                                                                backgroundColor: loading ? 'white' : Colors.Primary,
                                                            },
                                                            textTransform: 'capitalize',
                                                            width: '200px'
                                                        }}
                                                    >
                                                        <CircularProgress size={12} color={'warning'} />
                                                    </Button> :
                                                    <Button
                                                        onClick={handleSubmit}
                                                        id="basic-button"
                                                        aria-haspopup="true"
                                                        sx={{
                                                            marginTop: '25px',
                                                            backgroundColor: loading ? 'white' : Colors.Primary, '&:hover': {
                                                                backgroundColor: loading ? 'white' : Colors.Primary,
                                                            },
                                                            textTransform: 'capitalize',
                                                            width: '200px'
                                                        }}
                                                    >
                                                        <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Submit</Typography>
                                                    </Button>
                                                }
                                            </Box>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                            {/* </CustomTabPanel> */}
                        </Box>
                    </Box>
                </Modal>
            </div>

            <div>
                <Modal
                    open={biderStatusChangesModal}
                    onClose={handleBiderStatusModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={stylePaymentModal}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={handleBiderStatusModalClose} style={{ cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Grid container md={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={12}>
                                    <Typography sx={{ textAlign: 'center', marginBottom: 3, textTransform: 'capitalize' }}>Are you sure you want to {userBidStatus == "Disable" ? "Enable" : "Disable"} bidding option for this user?</Typography>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        {spinner ?
                                            <Box sx={{ width: '90%', display: 'flex', justifyContent: 'center', height: 50, alignItems: 'center' }} >
                                                <CircularProgress size={26} color={'warning'} />
                                            </Box>
                                            :
                                            <Button
                                                onClick={handleChangeBidingStatus}
                                                id="basic-button"
                                                sx={{
                                                    backgroundColor: '#232628', '&:hover': {
                                                        backgroundColor: '#232628',
                                                    },
                                                    width: '90%',
                                                    my: 1
                                                }}
                                            >
                                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Yes</Typography>
                                            </Button>
                                        }
                                        <Button
                                            onClick={handleBiderStatusModalClose}
                                            id="basic-button"
                                            sx={{
                                                backgroundColor: Colors.Primary, '&:hover': {
                                                    backgroundColor: Colors.Primary
                                                },
                                                width: '90%',
                                                my: 1
                                            }}
                                        >
                                            <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >No</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    </Box>
                </Modal>
            </div>

            <div>
                <Modal
                    open={deleteUserModal}
                    onClose={handleDeleteUserModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={stylePaymentModal}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={handleDeleteUserModalClose} style={{ cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Grid container md={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={12}>
                                    <Typography sx={{ textAlign: 'center', marginBottom: 3 }}>Are you sure you want to delete this user?</Typography>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        {spinner ?
                                            <Box sx={{ width: '90%', display: 'flex', justifyContent: 'center', height: 50, alignItems: 'center' }} >
                                                <CircularProgress size={26} color={'warning'} />
                                            </Box>
                                            :
                                            <Button
                                                onClick={handleDeleteUser}
                                                id="basic-button"
                                                sx={{
                                                    backgroundColor: '#232628', '&:hover': {
                                                        backgroundColor: '#232628',
                                                    },
                                                    width: '90%',
                                                    my: 1
                                                }}
                                            >
                                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Yes</Typography>
                                            </Button>
                                        }
                                        <Button
                                            onClick={handleBiderStatusModalClose}
                                            id="basic-button"
                                            sx={{
                                                backgroundColor: Colors.Primary, '&:hover': {
                                                    backgroundColor: Colors.Primary
                                                },
                                                width: '90%',
                                                my: 1
                                            }}
                                        >
                                            <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >No</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    </Box>
                </Modal>
            </div>

            <div>
                <Modal
                    open={blockUserModal}
                    onClose={() => setBlockUserModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={stylePaymentModal}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={() => setBlockUserModal(false)} style={{ cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Grid container md={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={12}>
                                    <Typography sx={{ textAlign: 'center', marginBottom: 3 }}>Are you sure you want to block this user?</Typography>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        {spinner ?
                                            <Box sx={{ width: '90%', display: 'flex', justifyContent: 'center', height: 50, alignItems: 'center' }} >
                                                <CircularProgress size={26} color={'warning'} />
                                            </Box>
                                            :
                                            <Button
                                                onClick={handleBlockUser}
                                                id="basic-button"
                                                sx={{
                                                    backgroundColor: '#232628', '&:hover': {
                                                        backgroundColor: '#232628',
                                                    },
                                                    width: '90%',
                                                    my: 1
                                                }}
                                            >
                                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Yes</Typography>
                                            </Button>
                                        }
                                        <Button
                                            onClick={() => setBlockUserModal(false)}
                                            id="basic-button"
                                            sx={{
                                                backgroundColor: Colors.Primary, '&:hover': {
                                                    backgroundColor: Colors.Primary
                                                },
                                                width: '90%',
                                                my: 1
                                            }}
                                        >
                                            <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >No</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    </Box>
                </Modal>
            </div>
            <ToastContainer />
        </div>
    )
}

export default AdminUserBalance