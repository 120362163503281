import { Avatar, Box, Button, CircularProgress, Grid, LinearProgress, Typography } from '@mui/material'
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { GetOneMonthReport } from '../../apis/adminIndex'
import { Colors } from '../../constants/colors'
import { commaSeparated } from '../../constants/GlobalConstants'
import { CarDetailFunc } from '../CarDetail'
import DatePicker from "react-datepicker";

function ReportPage() {
    const [startDate, setStartDate] = useState(moment(new Date()).subtract(1, 'months').format("YYYY-MM-DD"))
    const [lastDate, setLastDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [loading, setLoading] = useState(true);
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [urlArray, setUrlArray] = useState([]);
    const [detail, setDetail] = useState({})

    const [reportData, setReportData] = useState()

    useEffect(() => {
        handleGetOneMonthReport()
    }, [])


    const handleGetOneMonthReport = async () => {

        let formData = new FormData()
        formData.append("startDate", startDate)
        formData.append("endDate", lastDate)
        const res = await GetOneMonthReport(formData)
        console.log("🚀 ~ handleGetOneMonthReport ~ res:", res)
        if (res) {
            setLoading(false)
            setLoader(false)
        }
        setReportData(res?.data?.map((item) => {
            return {
                id: item._id,
                car: item.IMAGES,
                lotNo: item.LOT,
                purchaseDate: moment(item.purchaseDate).format('DD-MMM'),
                makeModel: item.MARKA_NAME + ' ' + item.MODEL_NAME,
                price: commaSeparated(item.PurchaseAt),
                auctionHouse: item.AUCTION,
                soldTo: item.SoldTo.FirstName + ' ' + item.SoldTo.LastName,
                bidAmount: commaSeparated(item?.bids[0]?.amount),
                item: item
            }
        }))
    }

    const columns = [
        {
            field: 'car',
            headerName: 'Photo',
            width: 100,
            renderCell: (params) => (
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    <Avatar variant='square' sx={{ borderRadius: '5px' }} src={params?.row?.car} />
                </Box>
            ),
        },
        {
            field: 'lotNo',
            headerName: 'Lot No.',
            width: 100,
            renderCell: ({ row }) => (
                <Typography
                    onClick={(event) => {
                        console.log("Select Row:", row);
                        setDetail(row.item)
                        const urlString = row.item.IMAGES;
                        const urlParts = urlString.split('#');
                        const urlRegex = /(https?:\/\/[^\s]+)/g;
                        const urls = urlParts.flatMap(part => part.match(urlRegex));

                        if (urls) {
                            setUrlArray(urls);
                        }
                        setOpen(true)
                    }}
                    alignItems={'center'} sx={{ color: Colors.Primary, cursor: 'pointer', p: '5px', borderRadius: '5px', '&:hover': { textDecorationLine: 'underline' } }}>
                    {`${row?.lotNo}`}
                </Typography>
            ),
        },
        {
            field: 'purchaseDate',
            headerName: 'Purchase Date',
            width: 130,
            valueGetter: (params) =>
                `${params.row.purchaseDate}`,
        },
        {
            field: 'makeModel',
            headerName: 'Make / Modal',
            width: 130,
            valueGetter: (params) =>
                `${params.row.makeModel}`,
        },
        {
            field: 'purchaseAt',
            headerName: 'Purchased Price',
            width: 130,
            valueGetter: (params) =>
                `${params.row.price}`,
        },
        {
            field: 'auctionHouse',
            headerName: 'Auction House',
            width: 130,
            valueGetter: (params) =>
                `${params.row.auctionHouse}`,
        },
        {
            field: 'soldTo',
            headerName: 'Sold to',
            width: 130,
            valueGetter: (params) =>
                `${params.row.soldTo}`,
        },
        {
            field: 'bidAmount',
            headerName: 'Bid Amount',
            width: 130,
            valueGetter: (params) =>
                `${params.row.bidAmount}`,
        },
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        );
    }

    return (
        <>
            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'flex' }, width: '90%', marginLeft: { xs: 4, sm: 4, md: 5 }, marginTop: { xs: '15%', sm: '15%', md: 2 } }}>
                <Box sx={{ display: 'flex', width: { xs: '100%', sm: '100%', md: '50%' }, alignItems: 'center' }}>
                    <Grid container sx={{ marginTop: '10px' }}>
                        <Grid item xs={1.5} sm={3}>
                            <Typography sx={{ marginRight: '10px', marginTop: '15px' }}> From </Typography>
                        </Grid>
                        <Grid item xs={10.5} sm={9}>
                            <DatePicker
                                minDate={startDate}
                                selected={startDate && new Date(startDate)}
                                onChange={(e) => setStartDate(moment(e).format('YYYY-MM-DD'))}
                                className='reportDateInput'
                                dateFormat={'dd/MM/yyyy'}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ display: 'flex', width: { xs: '100%', sm: '100%', md: '50%' }, alignItems: 'center' }}>
                    <Grid container sx={{ marginTop: '10px' }}>
                        <Grid item xs={1.5} sm={3}>
                            <Typography sx={{ marginRight: '10px', marginTop: '15px' }}> To </Typography>
                        </Grid>
                        <Grid item xs={10.5} sm={9} sx={{}}>
                            <DatePicker
                                minDate={lastDate}
                                selected={lastDate && new Date(lastDate)}
                                onChange={(e) => setLastDate(moment(e).format('YYYY-MM-DD'))}
                                className='reportDateInput'
                                dateFormat={'dd/MM/yyyy'}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Button sx={{
                    backgroundColor: loader ? 'white' : Colors.Primary, '&:hover': {
                        backgroundColor: loader ? 'white' : Colors.Primary
                    },
                    color: 'white',
                    width: { xs: '100%', sm: '100%', md: '150px' },
                    marginLeft: { xs: '0%', sm: '0%', md: '4%' },
                    height: '50px',
                    marginTop: { md: '10px', xs: '10px', sm: '10px' }
                }} onClick={() => {
                    setLoader(true)
                    handleGetOneMonthReport()
                }} > {loader ? <CircularProgress size={18} color={'warning'} /> : 'Search'} </Button>
            </Box>
            <Box sx={{ height: { xs: '600px', md: '500px' }, width: '90%', marginLeft: { xs: 4, sm: 4, md: 5 }, marginTop: 2 }}>
                <DataGrid
                    rows={reportData || []}
                    // columns={columns}
                    columns={columns.map((column) => ({
                        ...column,
                        cellClassName: 'custom-cell-class',
                    }))}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 20,
                            },
                        },
                    }}
                    pageSizeOptions={[20]}
                    // checkboxSelection
                    disableRowSelectionOnClick
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar,
                    }}
                    loading={loading}
                    // slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                />
            </Box>
            {(open && urlArray.length > 0 && detail) && (
                <CarDetailFunc adminNavigation={true} detail={detail} open={open} setOpen={setOpen} urlArray={urlArray} />
            )}
        </>
    )
}

export default ReportPage