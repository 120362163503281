import { Autocomplete, Avatar, Button, CircularProgress, Divider, Drawer, Grid, InputAdornment, LinearProgress, List, ListItem, ListItemText, Modal, OutlinedInput, Stack, Tab, Tabs, TextareaAutosize, TextField, Typography } from '@mui/material';
import { Box, Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useState, useRef } from 'react'

import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { FaCarSide, FaCrown } from "react-icons/fa";
import { AiOutlinePrinter } from "react-icons/ai";
import { RxCross2, RxRowSpacing } from "react-icons/rx";
import { BsCalendar2Event } from "react-icons/bs";
import { MdOutlineCancel, MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp, MdAssignmentInd } from "react-icons/md";
import { Colors } from '../../constants/colors';
import moment from 'moment';
import { bidCompleteAPI, downloadPrintPdf, getActivetoPending, getAdminBidList, getCancelUserBid, getMarkBidInLose, getMarkBidInProcess, getSingleBidDetail } from '../../apis/adminIndex';
import { useEffect } from 'react';
import { commaSeparated, DateDifference } from '../../constants/GlobalConstants';
import ThumbnailSlider from '../../components/ThumbnailSlider';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { IoArrowRedoSharp, IoCloudUploadOutline } from 'react-icons/io5'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaFileAlt, FaInfoCircle } from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';
import { CarDetailFunc } from '../CarDetail';
import { useNavigate } from 'react-router-dom';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    // height: 450,
    bgcolor: 'white',
    border: 'white',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px'
};

const stylePaymentModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 350, md: 400 },
    bgcolor: 'white',
    border: 'white',
    boxShadow: 24,
    p: { xs: 2, md: 4 },
    borderRadius: '10px'
};
const styleInfoModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'white',
    border: 'white',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px'
};

const styleBidderModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: 'white',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px'
};



function AdminBid2(props) {
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [bidListData, setBidListData] = useState([]);
    const [allBidListData, setAllBidListData] = useState()
    const [userSelectedId, setUserSelectedId] = useState(null)
    const [detail, setDetail] = useState(null)

    const [pendingStatusId, setPendingStatusId] = useState(null)
    const [selectedUserDetail, setSelectedUserDetail] = useState()
    const [urlArray, setUrlArray] = useState([]);
    const [bidCompleteURl, setbidCompleteURl] = useState([]);

    const [open, setOpen] = useState(false);
    const [bidderModal, setBidderModal] = useState(false);
    const [progressModal, setProgressModal] = useState(false);
    const [pendingModal, setPendingModal] = useState(false);
    const [pendingData, setPendingData] = useState(false);
    const [completeBidingModal, setCompleteBidingModal] = useState(false);
    const [cancelUserBidModal, setCancelUserBidModal] = useState(false);
    const [loseBidingModal, setLoseBidingModal] = useState(false);
    const [addUserLoader, setAddUserLoader] = useState(false)
    const [bidCompleteData, setBidCompleteData] = useState(new Date());
    const [paymentProof, setPaymentProof] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [eta, setETA] = useState('');
    const [etd, setETD] = useState('');
    const [note, setNote] = useState('');
    const [vesselName, setVesselName] = useState('');
    const [purchaseAt, setPurchaseAt] = useState('');
    const [defaultDate, setDefaultDate] = useState(moment().format('YYYY-MM-DD'));
    const [value, setValue] = useState(2);
    const [status, setStatus] = useState();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState(allBidListData?.result || []);

    const dateChangeRef = useRef(defaultDate);

    const data = JSON.parse(localStorage.getItem("loggedUser"))

    const Status = [
        { id: 1, label: 'Pending' },
        { id: 2, label: 'Shipped' },
        { id: 3, label: 'En Route' },
        { id: 4, label: 'Arrived' },
        { id: 5, label: 'Delivered' },
        { id: 6, label: 'Closed' },
        { id: 7, label: 'Cancelled' },
    ]

    function CustomToolbar() {
        const [infoModal, setInfoModal] = useState(false);

        const handleInfoModal = () => {
            setInfoModal(true)
        }

        const handleInfoModalClose = () => {
            setInfoModal(false)
        }
        return (
            <>
                <GridToolbarContainer>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: { xs: 'column-reverse', md: 'row' } }} >
                        <Box sx={{ width: '100%' }} >
                            <Grid container gap={1.5}>
                                <Grid item xs={12} md={3.5} boxShadow={1} p={1}>
                                    <Box sx={{ width: '100%' }}>
                                        <Typography sx={{ fontSize: '16px', fontFamily: 'Roboto' }}>
                                            Total cars for auction today
                                        </Typography>
                                        <Typography sx={{ fontSize: '22px', fontFamily: 'Roboto' }}>
                                            {allBidListData?.countTodayBids}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3.5} boxShadow={1} p={1}>
                                    <Box sx={{ width: '100%' }}>
                                        <Typography sx={{ fontSize: '16px', fontFamily: 'Roboto' }}>
                                            Total won today
                                        </Typography>
                                        <Typography sx={{ fontSize: '22px', fontFamily: 'Roboto' }}>
                                            {allBidListData?.countWinToday}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3.5} boxShadow={1} p={1}>
                                    <Box sx={{ width: '100%' }}>
                                        <Typography sx={{ fontSize: '16px', fontFamily: 'Roboto' }}>
                                            Total lost today
                                        </Typography>
                                        <Typography sx={{ fontSize: '22px', fontFamily: 'Roboto' }}>
                                            {allBidListData?.countLoseToday}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} >
                            <FiRefreshCcw style={{ fontSize: "20px", cursor: "pointer", paddingRight: "15px" }}
                                onClick={() => handleBidList()}
                            />
                            <FaInfoCircle size={22} color={Colors.Primary} style={{ cursor: 'pointer' }} onClick={handleInfoModal} />
                            <GridToolbarQuickFilter />
                        </Box>
                    </Box>
                </GridToolbarContainer>
                <div>
                    <Modal
                        open={infoModal}
                        onClose={handleInfoModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >

                        <Box sx={styleInfoModal}>
                            <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                                <RxCross2 onClick={handleInfoModalClose} style={{ cursor: 'pointer' }} />
                            </Box>
                            <Grid container gap={3} my={1.5}>
                                <Grid item md={1.5}>
                                    <Typography sx={{
                                        fontSize: '12px',
                                        py: 1,
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        color: '#FDB528',
                                        backgroundColor: 'rgba(253, 181, 40, 0.2)'
                                    }}>
                                        Active
                                    </Typography>
                                </Grid>
                                <Grid item md={9}>
                                    <Typography sx={{
                                        fontSize: '12px',
                                        py: 0.2,
                                        borderRadius: '5px',
                                        color: Colors.lightgray,
                                    }}>
                                        The local bidding for this car is still open. It will automatically close 4 hours before the bidding date/time
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container gap={3} my={1.5}>
                                <Grid item md={1.5}>
                                    <Typography sx={{
                                        fontSize: '12px',
                                        py: 1,
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        color: Colors.Primary,
                                        backgroundColor: 'rgba( 204, 7, 26, 0.2)',
                                    }}>
                                        Pending
                                    </Typography>
                                </Grid>
                                <Grid item md={9}>
                                    <Typography sx={{
                                        fontSize: '12px',
                                        borderRadius: '5px',
                                        color: Colors.lightgray,
                                    }}>
                                        The local bidding time is over for this car but it has not been started bidding on 3rd party platform.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container gap={3} >
                                <Grid item md={1.5}>
                                    <Typography sx={{
                                        fontSize: '12px',
                                        py: 1,
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        // color: '#FDB528',
                                        backgroundColor: '#D5EBFE',
                                        color: '#56AEF7'
                                    }}>
                                        In Biding
                                    </Typography>
                                </Grid>
                                <Grid item md={9}>
                                    <Typography sx={{
                                        fontSize: '12px',
                                        py: 0.2,
                                        borderRadius: '5px',
                                        color: Colors.lightgray,
                                    }}>
                                        The admin has started bidding this car on 3rd party platform. This car is ready for either win or loose from the other platform.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Modal>
                </div>
            </>
        );
    }


    const handleModalClose = () => {
        setOpen(false)
    }

    const handleSearch = (query) => {
        setSearchQuery(query);
        if (query.trim() === '') {
            setFilteredData(allBidListData?.result || []);
        } else {
            const filtered = allBidListData?.result.filter((row) => {
                const lotNumber = row.LOT.toString().toLowerCase();
                const searchValue = query.toString().toLowerCase();
                return lotNumber.includes(searchValue);
            });
            setFilteredData(filtered);
        }
    };

    // const handleSearch = (query) => {
    //     console.log("🚀 ~ handleSearch ~ query:", query)
    //     if (query.trim() === '') {
    //         // If search query is empty, render all data
    //         setFilteredData(allBidListData?.result || []);
    //     } else {
    //         // If search query is not empty, filter data based on the query
    //         const filtered = allBidListData?.result.filter((row) => {
    //             const lotNumber = row.LOT.toString().toLowerCase();
    //             const searchValue = query.toString().toLowerCase();
    //             return lotNumber.includes(searchValue);
    //         });
    //         setFilteredData(filtered);
    //     }
    // };

    const handleBidderModalClose = () => {
        setBidderModal(false)
    }
    const handleProgressModalClose = () => {
        setProgressModal(false)

    }
    const handleActiveToPendingModalClose = () => {
        setPendingModal(false)
        setProgressModal(false)
    }
    const handleCompleteModalClose = () => {
        setCompleteBidingModal(false)
    }

    const handleCancelUserBidModalClose = () => {
        setCancelUserBidModal(false)
    }

    const handleLoseModalClose = () => {
        setLoseBidingModal(false)
    }

    useEffect(() => {
        handleBidList()
    }, [])

    useEffect(() => {
        handleSingleBidDetail(userSelectedId)
    }, [userSelectedId]);

    const handleBidList = async () => {
        setLoading(true)
        try {
            const res = await getAdminBidList(`?date=${dateChangeRef.current}`)
            setAllBidListData(res.data)
            console.log("🚀 ~ handleBidList ~ res:", res)
            TableCollapsible(res?.data?.result)
            setBidListData(res?.data?.result.map((item) => {
                return {
                    id: item._id,
                    car: item.IMAGES,
                    auctionDate: moment(item.AUCTION_DATE).format('DD/MM/YYYY'),
                    lotNo: item.LOT,
                    makeModel: item.MARKA_NAME + ' ' + item.MODEL_NAME,
                    balance: commaSeparated(item?.bids?.userId?.balance),
                    currentBid: commaSeparated(item?.bids?.amount),
                    bidderName: item.bids?.userId?.FirstName + ' ' + item.bids?.userId?.LastName,
                    auctionName: item.AUCTION,
                    status: item.status,
                    date: DateDifference(item.AUCTION_DATE),
                    amount: commaSeparated(item?.bids?.amount),
                    soldPrice: item?.bids?.amount,
                    item: item
                }
            }))
            setLoading(false)
        } catch (error) {
            console.log("🚀 ~ file: AdminBid.jsx:156 ~ handleBidList ~ error:", error)
            setLoading(false)
        }

    }


    const handleTabClick = (newDate) => {
        console.log("🚀 ~ handleTabClick ~ newDate:", newDate)
        setDefaultDate(newDate);
        dateChangeRef.current = newDate; // Update the ref with the new value
    };

    const handleSingleBidDetail = async (userData) => {
        setLoader(true)
        try {
            const res = await getSingleBidDetail(userData?.auctionId?._id)
            console.log("🚀 ~ handleSingleBidDetail ~ res:", res)
            var data = res?.data

            setSelectedUserDetail(data)
            const urlString = data?.auctionData?.IMAGES;
            const urlParts = urlString.split('#');
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            const urls = urlParts.flatMap(part => part.match(urlRegex));

            if (urls) {
                setUrlArray(urls);
            }
            setLoader(false)
        } catch (error) {
            console.log("🚀 ~ file: AdminBid.jsx:173 ~ handleSingleBidDetail ~ error:", error)
        }
    }

    const handleProgressModal = (row) => {
        setPendingStatusId(row)
        console.log("🚀 ~ 390 handleProgressModal ~ id:", row)
        setProgressModal(true)
    }

    const handleInProgressLoseModal = (row) => {
        setPendingStatusId(row?.auctionId?._id)
        console.log("🚀 ~ file: AdminBid.jsx:388 ~ handleInProgressLoseModal ~ id:", row)
        setLoseBidingModal(true)
    }

    const handleActiveToPendingModal = (row) => {
        console.log("🚀 ~ handleActiveToPendingModal ~ row:", row)
        setPendingData(row)
        setPendingModal(true)
    }

    const handleChangeStatus = async () => {
        setSpinner(true)
        try {
            const res = await getMarkBidInProcess(pendingStatusId?.auctionId?._id, pendingStatusId?._id)
            console.log("🚀 ~ file: AdminBid.jsx:171 ~ handleChangeStatus ~ res:", res)
            handleBidList()
            setSpinner(false)
            setProgressModal(false)
            setPendingModal(false)
        } catch (error) {
            console.log("🚀 ~ file: AdminBid.jsx:177 ~ handleChangeStatus ~ error:", error)
            setSpinner(false)
        }

    }

    const handleLoseStatus = async () => {
        setSpinner(true)
        try {
            const res = await getMarkBidInLose(pendingStatusId)
            handleBidList()
            setSpinner(false)
            setLoseBidingModal(false)
        } catch (error) {
            console.log("🚀 ~ file: AdminBid.jsx:177 ~ handleChangeStatus ~ error:", error)
            setSpinner(false)
        }

    }

    const handleCancelUserBidModal = (row) => {
        setPendingStatusId(row._id)
        console.log("🚀 ~ handleCancelUserBidModal ~ row:", row)
        setCancelUserBidModal(true)
    }

    const handleCancelUserBid = async () => {
        console.log("CancelUserBid:", pendingStatusId);
        setSpinner(true)
        try {
            const res = await getCancelUserBid(pendingStatusId)
            handleBidList()
            setSpinner(false)
            setCancelUserBidModal(false)
        } catch (error) {
            console.log("🚀 ~ file: AdminBid.jsx:177 ~ handleChangeStatus ~ error:", error)
            setSpinner(false)
        }

    }

    const handleChangeStatusActive = async () => {
        setSpinner(true)
        try {
            const res = await getActivetoPending(pendingData?.auctionId?._id, pendingData?._id)
            console.log("🚀 ~ file: AdminBid.jsx:171 ~ handleChangeStatus ~ res:", res)
            toast.success(res?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            handleBidList()
            setSpinner(false)
            setProgressModal(false)
            setPendingModal(false)

        } catch (error) {
            console.log("🚀 ~ file: AdminBid.jsx:177 ~ handleChangeStatus ~ error:", error)
            setSpinner(false)
        }

    }

    const handleDrawerToggle = (row) => {
        console.log("🚀 ~ handleDrawerToggle ~ row:", row)
        setBidCompleteData(row)
        const urlString = row?.auctionId?.IMAGES;
        const urlParts = urlString.split('#');
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const urls = urlParts.flatMap(part => part.match(urlRegex));

        if (urls) {
            setbidCompleteURl(urls);
        }
        setDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false)
    }

    const handleBidComplete = async () => {
        setAddUserLoader(true)
        try {
            let formData = new FormData();
            formData.append("AuctionId", bidCompleteData?.auctionId?._id);
            formData.append("ConfirmDate", moment().format('YYYY-MM-DD'));
            formData.append("Status", 'Completed');
            formData.append("DeliveryStatus", status);
            formData.append("ETA", !eta ? '' : moment(eta).format('YYYY-MM-DD'));
            formData.append("EDT", !etd ? '' : moment(etd).format('YYYY-MM-DD'));
            formData.append("Note", note);
            formData.append("SoldPrice", Number(bidCompleteData?.amount));
            formData.append("VessalName", vesselName);
            formData.append("PurchaseAt", purchaseAt);
            if (selectedFile) {
                selectedFile.forEach(file => {
                    formData.append("DocFiles", file);
                });
            }
            if (!selectedFile) {
                formData.append("DocFiles", selectedFile);
            }
            const res = await bidCompleteAPI(data.token, formData)
            console.log("🚀 ~ file: AdminBid.jsx:381 ~ handleBidComplete ~ res:", res)
            handleBidList()
            toast.success(res?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setAddUserLoader(false)
            setSelectedFile(null)
            setTimeout(() => {
                setDrawerOpen(false);
            }, 2500);
        } catch (error) {
            console.log("🚀 ~ file: AdminBid.jsx:193 ~ handleAddUser ~ error:", error)
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setAddUserLoader(false)
        }
    }

    const today = moment();

    const handleFileChange = (event) => {
        const allowedFileTypes = ["image/jpeg", "image/png", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf", "application/zip"];

        const files = event.target.files;
        console.log("🚀 ~ file: AdminBid.jsx:373 ~ handleFileChange ~ file:", files);
        let prevArray = [];
        let filesArray = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (allowedFileTypes.includes(file.type)) {
                filesArray.push(file);

                // Set the image preview
                const previewURL = file ? URL.createObjectURL(file) : '';

                if (previewURL) {
                    prevArray.push(previewURL);
                }
            } else {
                // File type not allowed, you can handle this case (e.g., show an error message)
                toast.error('File type not allowed', {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                console.log(`File type not allowed: ${file.type}`);
            }

        }
        setSelectedFile(filesArray.length > 0 ? filesArray : null);
        // Set the image preview
        setPaymentProof(prevArray);
    };

    const handleDeleteImage = (index) => {
        const newPaymentProof = [...paymentProof];
        newPaymentProof.splice(index, 1);

        setPaymentProof(newPaymentProof);
        if (newPaymentProof.length === 0) {
            setSelectedFile(null);
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (defaultDate) {
            setLoading(true)
            handleBidList()
            // setLoading(false)
        }
    }, [defaultDate]);

    //TABLE WORK    

    const TableCollapsible = () => {
        return (
            // <MainCard content={false}>
            <>
                <TableContainer>
                    <Table aria-label="collapsible table">
                        {loading ?
                            <Box sx={{ width: '100%', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <CircularProgress size={30} color={'warning'} />
                            </Box>
                            :
                            <>
                                <TableHead sx={{ backgroundColor: Colors.gray, }}>
                                    <TableRow>
                                        <TableCell sx={{ pl: 3 }} />
                                        <TableCell>Car Image</TableCell>
                                        <TableCell>Lot No.</TableCell>
                                        <TableCell align="center">Make / Modal</TableCell>
                                        <TableCell align="right">Auction House</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {searchQuery !== '' ? filteredData.map((row) => (
                                        <Row key={row.name} row={row} />
                                    )) : allBidListData?.result.map((row) => {
                                        return (
                                            <Row key={row.name} row={row} />
                                        )
                                    })}
                                    {/* {filteredData?.map((row) => (
                                <>
                                    <Row key={row.name} row={row} />
                                    {console.log("🚀 ~ TableCollapsible ~ row:", row)}
                                </>
                            ))} */}
                                </TableBody>
                            </>
                        }
                    </Table>
                </TableContainer>

            </>
            // </MainCard>
        );
    }

    const Row = ({ row }) => {
        // const theme = useTheme();
        const [openMenu, setMenuOpen] = useState(true);
        const backColor = Colors.Primary;

        let date = DateDifference(row.AUCTION_DATE)
        let id = row._id

        return (
            <>
                <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell sx={{ pl: 3 }}>
                        <Button aria-label="expand row" size="small" onClick={() => setMenuOpen(!openMenu)}>
                            {openMenu ? <MdOutlineKeyboardArrowUp size={25} /> : <MdOutlineKeyboardArrowDown size={25} />}
                        </Button>
                    </TableCell>
                    <TableCell align="left"><Avatar variant='square' sx={{ borderRadius: '5px' }} src={row?.IMAGES} /></TableCell>
                    <TableCell align="left" component="th" scope="row">
                        <Typography
                            onClick={(event) => {
                                setDetail(row)
                                const urlString = row.IMAGES;
                                const urlParts = urlString.split('#');
                                const urlRegex = /(https?:\/\/[^\s]+)/g;
                                const urls = urlParts.flatMap(part => part.match(urlRegex));

                                if (urls) {
                                    setUrlArray(urls);
                                }
                                setOpen(true)
                            }}
                            // onClick={(event) => {
                            //     setUserSelectedId(row.id)
                            //     setModal(true)
                            // }}
                            alignItems={'center'} sx={{ color: Colors.Primary, cursor: 'pointer', p: '5px', borderRadius: '5px', '&:hover': { textDecorationLine: 'underline' } }}>
                            {row.LOT}
                        </Typography>

                    </TableCell>
                    <TableCell align="center">{row.MARKA_NAME}{' '}{row.MODEL_NAME} </TableCell>
                    <TableCell align="right">{row.AUCTION}</TableCell>

                </TableRow>
                <TableRow sx={{ bgcolor: Colors.white, '&:hover': { bgcolor: `${Colors.white} !important` } }}>
                    <TableCell sx={{ py: 0 }} colSpan={6}>
                        <Collapse in={openMenu} timeout="auto" unmountOnExit>
                            {openMenu && (
                                <Box sx={{ py: 3, pl: { xs: 3, sm: 5, md: 6, lg: 10, xl: 12 }, width: '80%', float: 'right' }}>
                                    <TableContainer>
                                        {/* <MainCard content={false}> */}
                                        <Table size="small" aria-label="purchases">
                                            <TableHead>
                                                <TableRow sx={{ backgroundColor: Colors.gray, borderRadius: '50px', }} >
                                                    <TableCell>Bidder Name</TableCell>
                                                    <TableCell align="right">Bid Amount</TableCell>
                                                    <TableCell align="right">Grouped</TableCell>
                                                    <TableCell align="center">Bid Status</TableCell>
                                                    <TableCell align="right">Status</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {row.bids?.map((item, index) => (
                                                    <TableRow hover key={index}>
                                                        <TableCell component="th" scope="row">
                                                            <Typography onClick={(event) => {
                                                                console.log("TableLog", item);
                                                                // setUserSelectedId(item?.userId?._id)
                                                                setUserSelectedId(item)
                                                                setBidderModal(true)
                                                            }}
                                                                alignItems={'center'} sx={{ color: Colors.Primary, cursor: 'pointer', p: '5px', borderRadius: '5px', '&:hover': { textDecorationLine: 'underline' } }}>
                                                                {item.status == "Approved" && <FaCrown />} {item?.userId?.FirstName}
                                                            </Typography>
                                                            <Typography onClick={(event) => {
                                                                console.log("TableLog", item);
                                                                // setUserSelectedId(item?.userId?._id)
                                                                setUserSelectedId(item)
                                                                setBidderModal(true)
                                                            }}
                                                                alignItems={'center'} sx={{ color: Colors.black, p: '5px', borderRadius: '5px', fontWeight: '300', fontSize: '11px', fontStyle: 'italic', width: '200px' }}>
                                                                {item?.bidNote}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right">{commaSeparated(item.amount)}</TableCell>
                                                        <TableCell align="right">{item.bidGroup ? "YES" : ""}</TableCell>
                                                        <TableCell align="center"> <Typography sx={{ color: item.status == "Deleted" ? Colors.Primary : item.status == "Canceled" ? Colors.white : '', backgroundColor: item.status == "Deleted" ? Colors.gray : item.status == "Canceled" ? Colors.lightgray : '', borderRadius: '3px' }}> {item.status}</Typography></TableCell>
                                                        <TableCell align="right">
                                                            <>
                                                                {item.status != 'Rejected' && item.status != 'cancelled' &&
                                                                    <>
                                                                        {item.status === "Approved" ?
                                                                            <Box display={'flex'} alignItems={'right'} justifyContent={'right'} gap={'10px'}>
                                                                                {/* <Typography sx={{ alignSelf: 'center', cursor: 'pointer', fontSize: '12px', backgroundColor: '#D5EBFE', py: 0.2, px: 1, borderRadius: '5px', color: '#56AEF7' }}>
                                                                                    In Biding
                                                                                </Typography> */}
                                                                                <Box
                                                                                    onClick={() => handleInProgressLoseModal(item)}
                                                                                    sx={{ padding: '3px', borderRadius: '6px', cursor: 'pointer', width: '45px', height: '35px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                                                                    {/* <MdOutlineCancel color={'white'} size={20} /> */}
                                                                                    <Typography sx={{ fontSize: '12px', textDecorationLine: 'underline', color: Colors.black }} >Cancel</Typography>
                                                                                </Box>
                                                                                <Box
                                                                                    onClick={() => handleDrawerToggle(item)}
                                                                                    sx={{
                                                                                        backgroundColor: '#24CF36',
                                                                                        padding: '3px',
                                                                                        borderRadius: '6px',
                                                                                        cursor: 'pointer',
                                                                                        width: '45px',
                                                                                        height: '35px',
                                                                                        justifyContent: 'center',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center'
                                                                                    }}>
                                                                                    <FaCarSide color={'white'} size={25} />
                                                                                    {/* <FaThumbsUp color={'white'} size={20} /> */}
                                                                                </Box>

                                                                            </Box>
                                                                            :
                                                                            <Box
                                                                                onClick={() => {
                                                                                    if (!date) {
                                                                                        handleProgressModal(item)
                                                                                    }
                                                                                }}
                                                                                sx={{ display: 'flex', gap: 1, justifyContent: 'right' }}
                                                                            >

                                                                                {date ?
                                                                                    <Box
                                                                                        onClick={() => { handleCancelUserBidModal(item) }}
                                                                                        sx={{
                                                                                            backgroundColor: 'red',
                                                                                            padding: '3px',
                                                                                            borderRadius: '6px',
                                                                                            cursor: 'pointer',
                                                                                            width: '45px',
                                                                                            height: '35px',
                                                                                            justifyContent: 'center',
                                                                                            display: 'flex',
                                                                                            alignItems: 'center'
                                                                                        }}>
                                                                                        <MdOutlineCancel color={'white'} size={20} />
                                                                                    </Box>
                                                                                    :
                                                                                    <>
                                                                                        {/* <Typography sx={{
                                                                                            cursor: 'pointer',
                                                                                            fontSize: '12px',
                                                                                            py: 0.2,
                                                                                            px: 1,
                                                                                            borderRadius: '5px',
                                                                                            // backgroundColor: !date ? 'rgba( 204, 7, 26, 0.2)' : 'rgba(253, 181, 40, 0.2)',
                                                                                            color: !date ? Colors.Primary : '#FDB528',
                                                                                            alignItems: 'center',
                                                                                            display: 'flex',
                                                                                            justifyContent: 'center'
                                                                                        }}>
                                                                                            {date ? 'Active' : 'Pending'}
                                                                                        </Typography> */}
                                                                                        <Box
                                                                                            onClick={() => handleActiveToPendingModal(item)}
                                                                                            sx={{
                                                                                                backgroundColor: '#e07619',
                                                                                                padding: '3px',
                                                                                                borderRadius: '6px',
                                                                                                cursor: 'pointer',
                                                                                                width: '45px',
                                                                                                height: '35px',
                                                                                                justifyContent: 'center',
                                                                                                display: 'flex',
                                                                                                alignItems: 'center',
                                                                                                // px: 1,
                                                                                            }}>
                                                                                            <MdAssignmentInd color={'white'} size={20} />
                                                                                            {/* <Typography sx={{ backgroundColor: !date ? 'rgba( 204, 7, 26, 0.2)' : 'rgba(253, 181, 40, 0.2)', color: Colors.white }}>Assign</Typography> */}
                                                                                        </Box>
                                                                                    </>
                                                                                }
                                                                            </Box>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        {/* </MainCard> */}
                                    </TableContainer>
                                </Box>
                            )}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    }

    const handlePrintData = () => {
        const recordsWithHighestPendingBid = allBidListData.result.map(record => {

            // Check if the record has bids
            if (record.bids && record.bids.length > 0) {
                // Find the highest bid amount among pending bids
                const highestPendingBidAmount = Math.max(...record.bids
                    .filter(bid => bid.status === "Pending")
                    .map(bid => bid.amount));

                // Filter bids to include only the highest bid amount with status "Pending"
                const highestPendingBid = record.bids.find(bid => bid.amount === highestPendingBidAmount && bid.status === "Pending");

                // If the record has a bid with highest amount and status is "Pending", return the record with only that bid
                if (highestPendingBid !== undefined) {
                    return {
                        ...record,
                        bids: [highestPendingBid] // Keep only the highest bid with status "Pending"
                    };
                }
            }
            return null; // If no bid meets the criteria, return null
        }).filter(record => record !== null); // Filter out the null values
        console.log("🚀 ~ recordsWithHighestPendingBid ~ recordsWithHighestPendingBid:", recordsWithHighestPendingBid)
        navigate('/admin/print', { state: { param: recordsWithHighestPendingBid } })
    }

    const handlePrintPdf = async () => {
        const res = await downloadPrintPdf(`?date=${dateChangeRef.current}`)
        window.open(`${process.env.REACT_APP_API_URL_PDF + res.data}`, '_blank');
        console.log("🚀 ~ handlePrintPdf ~ res:", res)
    }

    const navigate = useNavigate()

    const handleInputChange = (e) => {
        // Remove existing commas from the input value
        const rawValue = e.target.value.replace(/,/g, '');
        // Set the state with the raw value
        setPurchaseAt(rawValue);
    };

    const formatWithCommas = (value) => {
        // Format the value with commas
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    return (
        <div>
            <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer}>
                <List sx={{ width: '450px', height: '100%', overflowY: 'scroll', marginBottom: 2 }}>
                    <ListItem>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                            <Typography sx={{ color: Colors.Primary, fontSize: '20px', fontFamily: 'Roboto' }}>Congratulations! You have won this bid</Typography>
                            <RxCross2 style={{ cursor: 'pointer' }} onClick={handleCloseDrawer} />
                        </Box>
                    </ListItem>
                    <ListItem><ListItemText primary="Car Delivery Status" /></ListItem>
                    <ListItem>
                        <Box sx={{ width: '100%' }}>
                            <ThumbnailSlider imageData={bidCompleteURl} />
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography>Lot Number:</Typography>
                            <Typography>{bidCompleteData.lotNo}</Typography>
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography>Brand Car:</Typography>
                            <Typography>{bidCompleteData.makeModel}</Typography>
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box
                            boxShadow={1}
                            bgcolor={Colors.white}
                            borderRadius={'20px'}
                            width={{ md: '95%' }}
                        >
                            {!selectedFile ?
                                <label htmlFor="fileInput">
                                    <Box sx={{ backgroundColor: '#F6F6F6', border: 1, width: '100%', px: '10px', py: '40px', borderRadius: '5px', borderStyle: 'dashed', cursor: 'pointer', textAlign: 'center' }}>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            onChange={handleFileChange}
                                            multiple
                                            style={{ display: 'none' }}
                                        />
                                        <IoCloudUploadOutline color={'#6E6E6E'} size={30} />
                                        <Typography sx={{ color: '#6E6E6E', fontSize: '12px' }}>Browse file to Upload</Typography>
                                    </Box>
                                </label>
                                :
                                <Box sx={{ backgroundColor: 'white', display: 'flex', gap: '10px', overflowX: 'scroll', py: 1 }}>
                                    {paymentProof.map((item, index) => (
                                        <Box sx={{ width: '100px', borderRadius: '5px', boxShadow: 1, border: '0.1px solid grey', position: 'relative' }} >
                                            <Avatar variant='square' key={index} src={item} sx={{ backgroundColor: '#f3f3f3', width: '100px', height: '100px', position: 'relative', '& .MuiAvatar-img': { objectFit: 'contain' } }} >
                                                <FaFileAlt size={44} color={'gray'} />
                                            </Avatar>
                                            <MdOutlineCancel
                                                style={{
                                                    cursor: 'pointer',
                                                    top: '0px',
                                                    right: '0px',
                                                    position: 'absolute'
                                                }}
                                                onClick={() => handleDeleteImage(index)} />
                                        </Box>
                                    ))}
                                </Box>
                            }
                        </Box>
                    </ListItem>
                    <ListItem >
                        <Box sx={{ width: '100%' }} >
                            <Typography>ETA</Typography>
                            <DatePicker
                                minDate={today}
                                selected={eta && new Date(eta)}
                                onChange={(e) => setETA(e)}
                                className='etdInput'
                                dateFormat={'dd/MM/yyyy'}
                            />
                        </Box>
                    </ListItem>
                    <ListItem >
                        <Box sx={{ width: '100%' }} >
                            <Typography>ETD</Typography>
                            <DatePicker
                                minDate={today}
                                selected={etd && new Date(etd)}
                                onSelect={(e) => setETD(e)}
                                className='etdInput'
                                dateFormat={'dd/MM/yyyy'}
                            />
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ width: '100%' }}>
                            <Typography>Vessel Name</Typography>
                            <OutlinedInput
                                onChange={(e) => setVesselName(e.target.value)}
                                sx={{ width: '100%', backgroundColor: '#F6F6F6' }} placeholder="Enter Vessel Name" />
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ width: '100%' }}>
                            <Typography>Status</Typography>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={Status}
                                disableClearable
                                sx={{ width: '100%', backgroundColor: '#F6F6F6', marginTop: '16px' }}
                                onChange={(e, v) => setStatus(v?.label)}
                                renderInput={(params) => <TextField {...params} variant="outlined" placeholder={"Enter Status"} />}
                            />
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ width: '100%' }}>
                            <Typography>Purchase at</Typography>
                            <OutlinedInput
                                onChange={handleInputChange}
                                value={formatWithCommas(purchaseAt)}
                                // onChange={(e) => setPurchaseAt(e.target.value)}
                                sx={{ width: '100%', backgroundColor: '#F6F6F6' }}
                                placeholder="Enter Purchase Amount"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Typography sx={{ fontSize: '20px', marginRight: '5px', color: Colors.lightgray }}>¥</Typography>
                                    </InputAdornment>
                                }
                            />
                        </Box>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ width: '100%' }}>
                            <Typography>Note</Typography>
                            <TextareaAutosize
                                onChange={(e) => setNote(e.target.value)}
                                style={{
                                    width: '95%',
                                    borderColor: 'GrayText',
                                    borderRadius: '5px',
                                    backgroundColor: '#F6F6F6',
                                    padding: '10px',
                                    fontSize: '14px',
                                    fontFamily: 'Roboto',
                                    fontWeight: '500'
                                }}
                                minRows={4}
                            />
                        </Box>
                    </ListItem>
                    <ListItem >
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Button
                                onClick={handleBidComplete}
                                id="basic-button"
                                aria-haspopup="true"
                                sx={{
                                    backgroundColor: addUserLoader ? 'white' : Colors.Primary, '&:hover': {
                                        backgroundColor: addUserLoader ? 'white' : Colors.Primary
                                    },
                                    width: '100px'
                                }}
                            >
                                {addUserLoader ? <CircularProgress size={14} color={'warning'} /> : <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Submit</Typography>}
                            </Button>
                            <Button
                                id="basic-button"
                                aria-haspopup="true"
                                sx={{
                                    backgroundColor: '#E8E9ED', '&:hover': {
                                        backgroundColor: '#E8E9ED'
                                    },
                                    width: '100px'
                                }}
                                onClick={handleCloseDrawer}
                            >
                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'#878787'} >Cancel</Typography>
                            </Button>
                        </Stack>
                    </ListItem>
                </List>
            </Drawer>
            {/* <Grid container marginTop={{ xs: 7, md: 2 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={12} sm={8} md={8}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab label={moment().subtract(2, 'days').format('dddd')} sx={{ textTransform: "capitalize" }}
                                    onClick={() => handleTabClick(moment().subtract(2, 'days').format('YYYY-MM-DD'))}
                                />
                                <Tab label={moment().subtract(1, 'days').format('dddd')} sx={{ textTransform: "capitalize" }}
                                    onClick={() => handleTabClick(moment().subtract(1, 'days').format('YYYY-MM-DD'))}
                                />
                                <Tab label={moment().isoWeekday() === 7 ? moment().add(1, 'days').format('dddd') : moment().format('dddd')} sx={{ textTransform: "capitalize" }}
                                    onClick={() => handleTabClick(moment().isoWeekday() === 7 ? moment().add(1, 'days').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'))}
                                />
                                <Tab label={moment().isoWeekday() === 7 ? moment().add(2, 'days').format('dddd') : moment().add(1, 'days').format('dddd')} sx={{ textTransform: "capitalize" }}
                                    onClick={() => handleTabClick(moment().isoWeekday() === 7 ? moment().add(2, 'days').format('YYYY-MM-DD') : moment().add(1, 'days').format('YYYY-MM-DD'))}
                                />
                                <Tab label={moment().isoWeekday() === 7 ? moment().add(3, 'days').format('dddd') : moment().add(2, 'days').format('dddd')} sx={{ textTransform: "capitalize" }}
                                    onClick={() => handleTabClick(moment().isoWeekday() === 7 ? moment().add(3, 'days').format('YYYY-MM-DD') : moment().add(2, 'days').format('YYYY-MM-DD'))}
                                />
                            </Tabs>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}
            <Grid container md={12} >
                <Grid container md={12} marginTop={{ xs: 10, md: 2 }} gap={2} justifyContent={'center'}>
                    <Grid item xs={11} md={11} p={2} boxShadow={1} borderRadius={'5px'}>
                        <Box sx={{ height: { xs: '600px', md: '100%' }, width: '100%' }}>
                            <Box sx={{ display: { xs: 'block', md: 'flex' }, alignItmes: 'enter', justifyContent: 'space-between', marginBottom: '5px', }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <BsCalendar2Event size={30} style={{ marginRight: '5px' }} />
                                        <DatePicker
                                            // minDate={today}
                                            selected={defaultDate && new Date(defaultDate)}
                                            // onChange={(e) => setDefaultDate(e)}
                                            onChange={(e) => handleTabClick(moment(e).format('YYYY-MM-DD'))}
                                            className='dateInput'
                                            dateFormat={'dd/MM/yyyy'}
                                        />
                                    </Box>
                                    {/* <Button sx={{
                                        backgroundColor: Colors.Primary, '&:hover': {
                                            backgroundColor: Colors.Primary
                                        },
                                        color: 'white',
                                        width: { xs: '200px', md: '250px' },
                                        marginLeft: '4%',
                                        height: '50px'
                                    }} onClick={handlePrintPdf} ><AiOutlinePrinter color='white' size={25} style={{ marginRight: '5px' }} /> Print Report</Button> */}
                                    <Button sx={{
                                        backgroundColor: Colors.Primary, '&:hover': {
                                            backgroundColor: Colors.Primary
                                        },
                                        color: 'white',
                                        width: { xs: '200px', md: '170px' },
                                        marginLeft: '5%',
                                        height: '50px'
                                    }} onClick={() => navigate("/admin/view-reports", { state: { date: defaultDate } })} > View Report</Button>
                                </Box>
                                <TextField
                                    label="Search By Lot #."
                                    value={searchQuery}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    size={'small'}
                                    sx={{ width: { xs: '100%', md: '20%' }, marginTop: { xs: '10px' } }}
                                    variant="outlined"
                                />
                            </Box>
                            <TableCollapsible />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

            <div>
                <Modal
                    open={bidderModal}
                    onClose={handleBidderModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={styleBidderModal}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={handleBidderModalClose} style={{ cursor: 'pointer' }} />
                        </Box>
                        {loader ?
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', height: 400, alignItems: 'center' }} >
                                <CircularProgress size={26} color={'warning'} />
                            </Box>
                            :
                            <Box sx={{ width: '100%', display: 'flex' }}>
                                <Grid container md={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                    <Grid item md={12}>
                                        <Box sx={{ overflowX: 'hidden', maxHeight: 420 }}>
                                            {/* {selectedUserDetail && Object.entries(selectedUserDetail?.auctionData).map(([key, value]) => {
                                                return (
                                                    <>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                            <Typography sx={{ fontSize: '12px' }} >{key}</Typography>
                                                            <Typography sx={{ fontSize: '12px' }} >{value}</Typography>
                                                        </Box>
                                                        <Divider />
                                                    </>
                                                )
                                            })
                                            } */}
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Name</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.higestBidUser?.userId?.FirstName + ' ' + selectedUserDetail?.higestBidUser?.userId?.LastName}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Email</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.higestBidUser?.userId?.Email}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Country</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.higestBidUser?.userId?.County}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Phone</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.higestBidUser?.userId?.Phone}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Zip Code</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.higestBidUser?.userId?.ZipCode}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Status</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{selectedUserDetail?.higestBidUser?.status}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Balance</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{commaSeparated(selectedUserDetail?.higestBidUser?.userId?.balance)}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1, width: '98%' }}>
                                                <Typography sx={{ fontSize: '12px' }} >Bid Amount</Typography>
                                                <Typography sx={{ fontSize: '12px' }} >{commaSeparated(selectedUserDetail?.higestBidUser?.amount)}</Typography>
                                            </Box>
                                            <Divider />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Box>
                        }
                    </Box>
                </Modal>
            </div>
            <div>
                <Modal
                    open={progressModal}
                    onClose={handleProgressModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={stylePaymentModal}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={handleProgressModalClose} style={{ cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Grid container md={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={12}>
                                    <Typography sx={{ textAlign: 'center', marginBottom: 5 }}>Are you sure you want change the status ?</Typography>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        {spinner ?
                                            <Box sx={{ width: '90%', display: 'flex', justifyContent: 'center', height: 50, alignItems: 'center' }} >
                                                <CircularProgress size={26} color={'warning'} />
                                            </Box>
                                            :
                                            <Button
                                                onClick={handleChangeStatus}
                                                id="basic-button"
                                                sx={{
                                                    backgroundColor: '#232628', '&:hover': {
                                                        backgroundColor: '#232628',
                                                    },
                                                    width: '90%',
                                                    my: 1
                                                }}
                                            >
                                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Yes</Typography>
                                            </Button>
                                        }
                                        <Button
                                            onClick={handleProgressModalClose}
                                            id="basic-button"
                                            sx={{
                                                backgroundColor: Colors.Primary, '&:hover': {
                                                    backgroundColor: Colors.Primary
                                                },
                                                width: '90%',
                                                my: 1
                                            }}
                                        >
                                            <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >No</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    </Box>
                </Modal>
            </div>

            <div>
                <Modal
                    open={pendingModal}
                    onClose={handleActiveToPendingModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={stylePaymentModal}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={handleActiveToPendingModalClose} style={{ cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Grid container md={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={12}>
                                    <Typography sx={{ textAlign: 'center', marginBottom: 5 }}>
                                        The Car {pendingData?.auctionId?.MARKA_NAME} {pendingData?.auctionId?.MODEL_NAME} Lot No {pendingData?.auctionId?.LOT} will be sold to {pendingData?.userId?.FirstName} {pendingData?.userId?.LastName}.
                                        Are you sure you want to proceed?</Typography>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        {spinner ?
                                            <Box sx={{ width: '90%', display: 'flex', justifyContent: 'center', height: 50, alignItems: 'center' }} >
                                                <CircularProgress size={26} color={'warning'} />
                                            </Box>
                                            :
                                            <Button
                                                onClick={handleChangeStatusActive}
                                                id="basic-button"
                                                sx={{
                                                    backgroundColor: '#232628', '&:hover': {
                                                        backgroundColor: '#232628',
                                                    },
                                                    width: '90%',
                                                    my: 1
                                                }}
                                            >
                                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Yes</Typography>
                                            </Button>
                                        }
                                        <Button
                                            onClick={handleActiveToPendingModalClose}
                                            id="basic-button"
                                            sx={{
                                                backgroundColor: Colors.Primary, '&:hover': {
                                                    backgroundColor: Colors.Primary
                                                },
                                                width: '90%',
                                                my: 1
                                            }}
                                        >
                                            <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >No</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    </Box>
                </Modal>
            </div>

            <div>
                <Modal
                    open={completeBidingModal}
                    onClose={handleCompleteModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={stylePaymentModal}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={handleCompleteModalClose} style={{ cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Grid container md={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={12}>
                                    <Typography sx={{ textAlign: 'center', marginBottom: 5 }}>Are you sure you want change the status ?</Typography>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        {spinner ?
                                            <Box sx={{ width: '90%', display: 'flex', justifyContent: 'center', height: 50, alignItems: 'center' }} >
                                                <CircularProgress size={26} color={'warning'} />
                                            </Box>
                                            :
                                            <Button
                                                onClick={handleChangeStatus}
                                                id="basic-button"
                                                sx={{
                                                    backgroundColor: '#232628', '&:hover': {
                                                        backgroundColor: '#232628',
                                                    },
                                                    width: '90%',
                                                    my: 1
                                                }}
                                            >
                                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Yes</Typography>
                                            </Button>
                                        }
                                        <Button
                                            onClick={handleCompleteModalClose}
                                            id="basic-button"
                                            sx={{
                                                backgroundColor: Colors.Primary, '&:hover': {
                                                    backgroundColor: Colors.Primary
                                                },
                                                width: '90%',
                                                my: 1
                                            }}
                                        >
                                            <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >No</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    </Box>
                </Modal>
            </div>

            <div>
                <Modal
                    open={cancelUserBidModal}
                    onClose={handleCancelUserBidModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={stylePaymentModal}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={handleCancelUserBidModalClose} style={{ cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Grid container md={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={12}>
                                    <Typography sx={{ textAlign: 'center', marginBottom: 5 }}>Are you sure you want cancel this user bid?</Typography>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        {spinner ?
                                            <Box sx={{ width: '90%', display: 'flex', justifyContent: 'center', height: 50, alignItems: 'center' }} >
                                                <CircularProgress size={26} color={'warning'} />
                                            </Box>
                                            :
                                            <Button
                                                onClick={handleCancelUserBid}
                                                id="basic-button"
                                                sx={{
                                                    backgroundColor: '#232628', '&:hover': {
                                                        backgroundColor: '#232628',
                                                    },
                                                    width: '90%',
                                                    my: 1
                                                }}
                                            >
                                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Yes</Typography>
                                            </Button>
                                        }
                                        <Button
                                            onClick={handleCancelUserBidModalClose}
                                            id="basic-button"
                                            sx={{
                                                backgroundColor: Colors.Primary, '&:hover': {
                                                    backgroundColor: Colors.Primary
                                                },
                                                width: '90%',
                                                my: 1
                                            }}
                                        >
                                            <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >No</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    </Box>
                </Modal>
            </div>

            <div>
                <Modal
                    open={loseBidingModal}
                    onClose={handleLoseModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={stylePaymentModal}>
                        <Box sx={{ width: '100%', textAlign: '-webkit-right' }}>
                            <RxCross2 onClick={handleLoseModalClose} style={{ cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Grid container md={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={12}>
                                    <Typography sx={{ textAlign: 'center', marginBottom: 5 }}>Are you sure you want to mark this bid as lost ?</Typography>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        {spinner ?
                                            <Box sx={{ width: '90%', display: 'flex', justifyContent: 'center', height: 50, alignItems: 'center' }} >
                                                <CircularProgress size={26} color={'warning'} />
                                            </Box>
                                            :
                                            <Button
                                                onClick={handleLoseStatus}
                                                id="basic-button"
                                                sx={{
                                                    backgroundColor: '#232628', '&:hover': {
                                                        backgroundColor: '#232628',
                                                    },
                                                    width: '90%',
                                                    my: 1
                                                }}
                                            >
                                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Yes</Typography>
                                            </Button>
                                        }
                                        <Button
                                            onClick={handleLoseModalClose}
                                            id="basic-button"
                                            sx={{
                                                backgroundColor: Colors.Primary, '&:hover': {
                                                    backgroundColor: Colors.Primary
                                                },
                                                width: '90%',
                                                my: 1
                                            }}
                                        >
                                            <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >No</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    </Box>
                </Modal>
            </div>
            {
                (open && urlArray) && (
                    <CarDetailFunc detail={detail} open={open} setOpen={setOpen} urlArray={urlArray} adminNavigation={true} />
                )
            }
            <ToastContainer />
        </div>
    )


}

function createData(name, calories, fat, carbs, protein, price) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
        price,
        history: [
            { date: '2020-01-05', customerId: '11091700', amount: 3 },
            { date: '2020-01-02', customerId: 'Anonymous', amount: 1 }
        ]
    };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
    createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
    createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
    createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5)
];

// ==============================|| MUI TABLE - COLLAPSIBLE ||============================== //



export default AdminBid2


