import React, { useState } from 'react'
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoHomeOutline, IoDocumentTextOutline, IoDocumentsOutline, IoSettingsOutline, IoLogOutOutline } from "react-icons/io5";
import { RiAuctionLine } from "react-icons/ri";
import { FaRegUser, FaStarHalfAlt } from "react-icons/fa";
import { CgDetailsMore } from "react-icons/cg";
import { BiCommentDetail } from "react-icons/bi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { Typography, Avatar, Divider } from '@mui/material';
import { BsBuildings } from "react-icons/bs";
import { Box } from '@mui/system';
import { Colors } from '../constants/colors';
import { useMediaQuery } from 'react-responsive';
import { appImages } from '../assets/images';

export const UserSideBarView = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;

    const userMenu = [
        {
            title: 'Dashboard',
            url: '/user/dashboard',
            icon: <IoHomeOutline size={18} style={{ marginRight: '10px', color: pathname === "/user/dashboard" && Colors.Primary }} />
        },
        {
            title: 'Auction',
            url: '/user/auction',
            icon: <RiAuctionLine size={18} style={{ marginRight: '10px', color: pathname === "/user/auction" && Colors.Primary }} />
        },
        {
            title: 'Bids',
            url: '/user/bids',
            icon: <CgDetailsMore size={18} style={{ marginRight: '10px', color: pathname === "/user/bids" && Colors.Primary }} />
        },
        {
            title: 'My Favorite',
            url: '/user/favorite',
            icon: <FaStarHalfAlt size={18} style={{ marginRight: '10px', color: pathname === "/user/favorite" && Colors.Primary }} />
        },
        {
            title: 'Bids History',
            url: '/user/bidshistory',
            icon: <BiCommentDetail size={18} style={{ marginRight: '10px', color: pathname === "/user/bidshistory" && Colors.Primary }} />
        },
        {
            title: 'Invoices',
            url: '/user/invoice',
            icon: <IoDocumentTextOutline size={18} style={{ marginRight: '10px', color: pathname === "/user/invoice" && Colors.Primary }} />
        },
        {
            title: 'In Transit',
            url: '/user/transit',
            icon: <IoDocumentsOutline size={18} style={{ marginRight: '10px', color: pathname === "/user/transit" && Colors.Primary }} />
        },
        // {
        //     title: 'My Account',
        //     url: '/user/account',
        //     icon: <FaRegUser size={18} style={{ marginRight: '10px', color: pathname === "/user/account" && Colors.white }} />
        // },
        // {
        //     title: 'Company',
        //     url: '',
        //     icon: <BsBuildings size={18} style={{ marginRight: '10px', color: pathname === "/company" && Colors.white }} />
        // },
        {
            title: 'Settings',
            url: '/user/settings',
            icon: <IoSettingsOutline size={18} style={{ marginRight: '10px', color: pathname === "/user/settings" && Colors.Primary }} />
        },
    ]
    const [collapsed, setCollapsed] = useState(true);

    const handleMouseEnter = () => {
        setCollapsed(false);
    };

    const handleMouseLeave = () => {
        setCollapsed(true);
    };

    // const isMobile = useMediaQuery({ maxWidth: 900 });
    return (
        <Sidebar collapsed={false}
            // onMouseEnter={handleMouseEnter} 
            // onMouseLeave={handleMouseLeave} 
            collapsedWidth={"70px"}
            // style={{  }}
            style={{
                position: 'fixed', // Fix sidebar position
                top: 0,            // Align at the top
                bottom: 0,         // Stretch to the bottom
                zIndex: 1000,      // Ensure it's above other content
                backgroundColor: 'white',
                overflowY: 'auto', // Add scrollbar if needed
            }}
        >
            {/* <Sidebar collapsed={isMobile} collapsedWidth={"70px"} > */}

            <Menu
                sx={{ backgroundColor: 'white', border: 'none' }}
                menuItemStyles={{
                    button: ({ level, active, disabled }) => {
                        if (level === 0)
                            return {
                                color: disabled ? Colors.white : '#d359ff',
                                backgroundColor: active ? Colors.Primary : '#EEEEF1',
                            };
                    },
                }}
            >
                <Avatar
                    variant="square"
                    src={appImages.kmLogo1}
                    sx={{
                        width: "100%",
                        height: 120,
                        '& .MuiAvatar-img': { objectFit: 'contain' },
                        // marginLeft: 4
                    }} />
                {(userMenu).map((item, i) => {
                    return (
                        <MenuItem
                            key={i}
                            style={{ fontSize: '18px', color: '#636578', marginTop: '1%', marginLeft: '5%', marginBottom: '2%', borderRadius: '5px', backgroundColor: pathname === item.url && Colors.Primary }}
                            component={<Link to={item.url} />}
                        >
                            <Box display={'inline-flex'} alignItems={'center'} >
                                {item.icon}
                                <Typography sx={{ color: pathname === item.url && Colors.Primary }}>{item.title}</Typography>
                            </Box>
                            <Divider sx={{ borderStyle: 'dashed' }} />
                        </MenuItem>
                    )
                })}
                <MenuItem
                    onClick={() => {
                        localStorage.removeItem('loggedUser')
                        document.location.reload(navigate("/"))
                    }}
                    style={{ fontSize: '18px', color: '#636578', marginTop: '1%', marginLeft: '5%', marginBottom: '2%', borderRadius: '5px' }}
                >
                    <Box display={'inline-flex'} alignItems={'center'}>
                        <IoLogOutOutline size={18} style={{ marginRight: '10px' }} />
                        <Typography>Logout</Typography>
                    </Box>
                </MenuItem>
            </Menu>
        </Sidebar>
    )
}

export const AdminSideBarView = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;

    const adminMenu = [
        {
            title: 'Dashboard',
            url: '/admin/dashboard',
            icon: <IoHomeOutline size={18} style={{ marginRight: '10px', color: pathname === "/admin/dashboard" && Colors.Primary }} />
        },
        {
            title: 'Manage Customers',
            url: '/admin/userbalance',
            icon: <FaRegUser size={18} style={{ marginRight: '10px', color: pathname === "/admin/userbalance" && Colors.Primary }} />
        },
        {
            title: 'Running Bids',
            url: '/admin/bid',
            icon: <CgDetailsMore size={18} style={{ marginRight: '10px', color: pathname === "/admin/bid" && Colors.Primary }} />
        },
        {
            title: 'In Transit',
            url: '/admin/transit',
            icon: <RiAuctionLine size={18} style={{ marginRight: '10px', color: pathname === "/admin/transit" && Colors.Primary }} />
        },
        {
            title: 'Sales Report',
            url: '/admin/report',
            icon: <HiOutlineDocumentReport size={18} style={{ marginRight: '10px', color: pathname === "/admin/report" && Colors.Primary }} />
        },
        {
            title: 'Settings',
            url: '/admin/settings',
            icon: <IoSettingsOutline size={18} style={{ marginRight: '10px', color: pathname === "/admin/settings" && Colors.Primary }} />
        },


        // {
        //     title: 'Invoices',
        //     url: '/admin/invoice',
        //     icon: <IoDocumentTextOutline size={18} style={{ marginRight: '10px', color: pathname === "/admin/invoice" && Colors.white }} />
        // },
        // {
        //     title: 'Status Report',
        //     url: '',
        //     icon: <IoDocumentsOutline size={18} style={{ marginRight: '10px', color: pathname === "/admin/adminstatusreport" && Colors.white }} />
        // },

        // {
        //     title: 'Company',
        //     url: '',
        //     icon: <BsBuildings size={18} style={{ marginRight: '10px', color: pathname === "/admin/admincompany" && Colors.white }} />
        // },

    ]

    const [collapsed, setCollapsed] = useState(true);

    const handleMouseEnter = () => {
        setCollapsed(false);
    };

    const handleMouseLeave = () => {
        setCollapsed(true);
    };

    // const isMobile = useMediaQuery({ maxWidth: 900 });
    return (
        <div>
            <Sidebar collapsed={false}
                // onMouseEnter={handleMouseEnter} 
                // onMouseLeave={handleMouseLeave} 
                collapsedWidth={"70px"}
                style={{
                    position: 'fixed', // Fix sidebar position
                    top: 0,            // Align at the top
                    bottom: 0,         // Stretch to the bottom
                    zIndex: 1000,      // Ensure it's above other content
                    backgroundColor: 'white',
                    overflowY: 'auto', // Add scrollbar if needed
                }}
            >
                {/* <Sidebar collapsed={isMobile} collapsedWidth={"70px"} > */}
                <Menu
                    sx={{ backgroundColor: 'white', border: 'none' }}
                    menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            if (level === 0)
                                return {
                                    color: disabled ? Colors.white : '#d359ff',
                                    backgroundColor: active ? Colors.Primary : '#EEEEF1',
                                };
                        },
                    }}
                >
                    <Avatar
                        variant="square"
                        src={appImages.kmLogo1}
                        sx={{
                            width: 120,
                            height: 120,
                            '& .MuiAvatar-img': { objectFit: 'contain' },
                            marginLeft: 4
                        }} />
                    {(adminMenu).map((item, index) => {
                        return (
                            <MenuItem
                                key={index}
                                style={{ fontSize: '18px', color: '#636578', marginTop: '1%', marginLeft: '5%', marginBottom: '2%', borderRadius: '5px', backgroundColor: pathname === item.url && Colors.Primary }}
                                component={<Link to={item.url} />}
                            >
                                <Box display={'inline-flex'} alignItems={'center'} >
                                    {item.icon}
                                    <Typography sx={{ color: pathname === item.url && Colors.Primary }}>{item.title}</Typography>
                                </Box>
                                <Divider sx={{ borderStyle: 'dashed' }} />
                            </MenuItem>
                        )
                    })}
                    <MenuItem
                        onClick={() => {
                            localStorage.removeItem('loggedUser')
                            document.location.reload(navigate("/"))
                        }}
                        style={{ fontSize: '18px', color: '#636578', marginTop: '1%', marginLeft: '5%', marginBottom: '2%', borderRadius: '5px' }}
                    >
                        <Box display={'inline-flex'} alignItems={'center'}>
                            <IoLogOutOutline size={18} style={{ marginRight: '10px' }} />
                            <Typography>Logout</Typography>
                        </Box>
                    </MenuItem>
                </Menu>
            </Sidebar>
        </div>
    )
}
