import { Autocomplete, Avatar, Box, Button, CircularProgress, Grid, OutlinedInput, TextareaAutosize, TextField, Typography } from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import Footer from '../components/Footer'
import HeaderUser from '../components/HeaderUser'
import { Colors } from '../constants/colors'
import { IoCloudUploadOutline } from 'react-icons/io5'
import { RxCross2 } from "react-icons/rx";
import { payment } from '../apis'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeaderDashboard from '../components/HeaderDashboard'

function SignUpPayment() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [paymentProof, setPaymentProof] = useState('');
  const [loading, setLoading] = useState(false);
  const [bankName, setBankName] = useState(false);

  const Bank = [
    { id: 1, label: 'UBL' },
    { id: 2, label: 'Meezan' },
    { id: 3, label: 'Allied' },
  ]

  const initialValues = { email: '', phone: '', fName: '', lName: '', message: '' }
  const handlePayment = async () => {
    try {
      setLoading(true)
      const formData = new FormData();
      formData.append(`paymentProof`, selectedFile);
      formData.append(`bankName`, bankName);
      const res = await payment(state.token, formData)
      toast.success(res.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setSelectedFile(null)
      setLoading(false)
      setTimeout(() => {
        // navigate('/login')
        navigate("/thankyou", {
          state: {
            des: "We will get back to you shortly",
          }
        })
      }, 2000);
    } catch (error) {
      console.log("🚀 ~ file: Payment.jsx:22 ~ handlePayment ~ error:", error)
      toast.error(`${error?.response?.data?.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false)
    }

  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    // Set the image preview
    const previewURL = file ? URL.createObjectURL(file) : '';
    setPaymentProof(previewURL);
  };

  return (
    <div>
      <HeaderUser />
      <Grid
        container
        md={12}
        justifyContent={{ xs: 'center', md: '' }}
        alignItems={{ xs: 'center', md: 'normal' }}
      >
        <Grid item md={12} textAlign={'-webkit-center'} py={5} marginTop={5}>
          {/* <Box
            boxShadow={1}
            height={'100%'}
            bgcolor={Colors.white}
            p={'20px'}
            // marginTop={'50px'}
            borderRadius={'5px'}
            width={{ xs: '100%', md: '50%' }}
          >
            <Typography>Payment</Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={paymentValidationSchema}
              onSubmit={handlePayment}
            >
              {({ errors, touched, validateForm, handleSubmit }) => (
                <Form>
                  <Box sx={{ display: { xs: 'block', md: "flex" }, flexDirection: "row", mt: 1 }} >
                    <Box sx={{ display: { xs: 'block', md: "flex" }, flexDirection: "column", width: { xs: '100%', md: "49%" } }} >
                      <Field name="fName">
                        {({ field }) => (
                          <>
                            <Typography sx={{ textAlign: 'left', fontSize: '14px', color: '#6E6E6E' }}>
                              Payment for
                            </Typography>
                            <OutlinedInput
                              {...field}
                              sx={{
                                backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' },
                                marginTop: { xs: 1, md: 0 },
                              }}
                              id="outlined-adornment-weight"
                            />
                          </>
                        )}
                      </Field>
                      <ErrorMessage name="fName" float={'left'}>
                        {(msg) => (
                          <Typography variant="caption" color="error" marginRight={'auto'} >
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Box>

                    <Box sx={{
                      display: "flex", flexDirection: "column",
                      width: { xs: '100%', md: '49%' },
                      marginLeft: { xs: 0, md: 2 },
                      marginTop: { xs: 1, md: 0 },
                    }} >
                      <Field name="lName">
                        {({ field }) => (
                          <>
                            <Typography sx={{ textAlign: 'left', fontSize: '14px', color: '#6E6E6E' }}>
                              Payment made on
                            </Typography>
                            <OutlinedInput
                              {...field}
                              sx={{ backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' }, marginTop: { xs: 1, md: 0 }, }}
                              id="outlined-adornment-weight"
                            />
                          </>
                        )}
                      </Field>
                      <ErrorMessage name="lName">
                        {(msg) => (
                          <Typography variant="caption" color="error" marginRight={'auto'}>
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }} >
                    <Field name="email">
                      {({ field }) => (
                        <>
                          <Typography sx={{ textAlign: 'left', fontSize: '14px', color: '#6E6E6E', mt: 1 }}>
                            Sender name
                          </Typography>
                          <OutlinedInput
                            {...field}
                            sx={{
                              backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' },
                              marginTop: { xs: 1, md: 0 },
                            }}
                            id="outlined-adornment-weight"
                          />
                        </>
                      )}
                    </Field>
                    <ErrorMessage name="email">
                      {(msg) => (
                        <Typography variant="caption" color="error" marginRight={'auto'}>
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }} >
                    <Field name="phone">
                      {({ field }) => (
                        <>
                          <Typography sx={{ textAlign: 'left', fontSize: '14px', color: '#6E6E6E', mt: 1 }}>
                            Payment account
                          </Typography>
                          <OutlinedInput
                            {...field}
                            sx={{
                              backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' },
                              marginTop: { xs: 1, md: 0 },
                            }}
                            id="outlined-adornment-weight"
                          />
                        </>
                      )}
                    </Field>
                    <ErrorMessage name="phone">
                      {(msg) => (
                        <Typography variant="caption" color="error" marginRight={'auto'}>
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }} >
                    <Field name="phone">
                      {({ field }) => (
                        <>
                          <Typography sx={{ textAlign: 'left', fontSize: '14px', color: '#6E6E6E', mt: 1 }}>
                            Currency
                          </Typography>
                          <OutlinedInput
                            {...field}
                            sx={{
                              backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' },
                              marginTop: { xs: 1, md: 0 },
                            }}
                            id="outlined-adornment-weight"
                          />
                        </>
                      )}
                    </Field>
                    <ErrorMessage name="phone">
                      {(msg) => (
                        <Typography variant="caption" color="error" marginRight={'auto'}>
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }} >
                    <Field name="message">
                      {({ field }) => (
                        <>
                          <Typography sx={{ textAlign: 'left', fontSize: '14px', color: '#6E6E6E', mt: 1 }}>
                            Other information
                          </Typography>
                          <TextareaAutosize
                            {...field}
                            sx={{
                              backgroundColor: '#F6F6F6', width: { xs: '100%', md: '100%' },
                              marginTop: { xs: 1, md: 0 },
                            }}
                            minRows={5}
                            id="outlined-adornment-weight"
                          />
                        </>
                      )}
                    </Field>
                    <ErrorMessage name="message">
                      {(msg) => (
                        <Typography variant="caption" color="error" marginRight={'auto'}>
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Box>
                  
                  <Button
                    onClick={handleSubmit}
                    id="basic-button"
                    aria-haspopup="true"
                    sx={{
                      mt: '15px',
                      backgroundColor: Colors.Primary, '&:hover': {
                        backgroundColor: Colors.Primary,
                      },
                      textTransform: 'capitalize',
                      width: { xs: '100%', md: 100 }
                    }}
                  >
                    <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Submit</Typography>
                  </Button>
                </Form>
              )}
            </Formik>
          </Box> */}
          {/* <Box
            boxShadow={1}
            height={'100%'}
            bgcolor={Colors.white}
            p={'20px'}
            borderRadius={'5px'}
            width={{ xs: '100%', md: '40%' }}
          >
            <Typography sx={{ color: Colors.Primary }}>
              Thank you for payment
            </Typography>
            <Typography sx={{ color: Colors.Primary, marginBottom: 2 }}>
              Please Submit Your payment proof
            </Typography>
            <Box sx={{ border: 1, width: '70%', px: '10px', py: '40px', borderRadius: '5px', borderStyle: 'dashed', cursor:'pointer' }}>
              <IoCloudUploadOutline color={'#6E6E6E'} size={30} />
              <Typography sx={{ color: '#6E6E6E', fontSize: '12px' }}>Browse file to Upload</Typography>
            </Box>
          </Box> */}

          <Box
            boxShadow={1}
            height={'100%'}
            bgcolor={Colors.white}
            p={'20px'}
            borderRadius={'20px'}
            width={{ md: '40%' }}
          >
            <Typography sx={{ color: Colors.Primary }}>
              Thank you for payment
            </Typography>
            <Typography sx={{ color: Colors.Primary, marginBottom: 2 }}>
              Please submit your payment proof
            </Typography>
            {!selectedFile ?
              <label htmlFor="fileInput">
                <Box sx={{ border: 1, width: '70%', px: '10px', py: '40px', borderRadius: '5px', borderStyle: 'dashed', cursor: 'pointer' }}>
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  <IoCloudUploadOutline color={'#6E6E6E'} size={30} />
                  <Typography sx={{ color: '#6E6E6E', fontSize: '12px' }}>Browse file to Upload</Typography>
                </Box>
              </label>
              :
              <Box>
                <Box sx={{ width: '200px', position: 'relative', }}>
                  <img
                    src={paymentProof}
                    alt="Selected Image"
                    style={{ maxWidth: '200px' }}
                  />
                  <RxCross2
                    style={{ position: 'absolute', right: '0px', top: '-5px', right: '-5px', cursor: 'pointer' }}
                    onClick={() => setSelectedFile(null)} />
                </Box>
              </Box>
            }
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={Bank}
              disableClearable
              sx={{ width: '75%', backgroundColor: '#F6F6F6', marginTop: '16px' }}
              onChange={(e, v) => setBankName(v?.label)}
              renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Bank" />}
            />
          </Box>
          <Button
            onClick={handlePayment}
            id="basic-button"
            aria-haspopup="true"
            sx={{
              marginTop: '15px',
              backgroundColor: loading ? 'white' : Colors.Primary, '&:hover': {
                backgroundColor: loading ? 'white' : Colors.Primary,
              },
              textTransform: 'capitalize'
            }}
          >
            {loading ? <CircularProgress size={12} color={'warning'} /> : <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Submit</Typography>}
          </Button>
        </Grid>
      </Grid>
      <Footer />
      <ToastContainer />
    </div>
  )
}

export default SignUpPayment