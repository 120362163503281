
import React from "react";
import { Outlet } from "react-router-dom";

//  Components
import { UserSideBarView, AdminSideBarView } from "../components/SideBar";
import HeaderDashboard from "../components/HeaderDashboard";

import { Grid, Box, Avatar } from '@mui/material';
import { appImages } from "../assets/images";

export function UserLayoutStructure() {

    return (
        <>

            <Grid container justifyContent={'space-between'}>
                <Grid item md={1}>
                    <Box sx={{
                        display: {
                            md: "block",
                            sm: "none",
                            xs: "none"
                        }
                    }} >
                        <UserSideBarView />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={9.5}>
                    <HeaderDashboard />
                    <Box sx={{ p: "15px", mt: { xs: 7, md: 0 } }}>
                        {/* <Box sx={{ p: "15px", height: '100%', mt: { xs: 7, md: 0 } }}> */}
                        <Outlet />
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export function AdminLayoutStructure() {

    return (
        <>

            <Grid container justifyContent={'space-between'}>
                <Grid item md={1}>
                    <Box sx={{
                        display: {
                            md: "block",
                            sm: "none",
                            xs: "none"
                        }
                    }} >
                        <AdminSideBarView />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={10}>
                    <HeaderDashboard />
                    <Outlet />
                </Grid>
            </Grid>
        </>
    )
}
