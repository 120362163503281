export const Colors = {
    Primary: '#CC071A',
    // Primary: '#37a647',
    // Primary: 'black',
    fbColor: '#1877F2',
    twitterColor: '#3ec9d6',
    iosColor: '#212529',
    ytColor: '#dc2626',
    white: '#ffffff',
    black: '#000000',
    borderColor: '#C3C3C3',
    available: '#047D32',
    gray: '#f3f3f3',
    lightgray: 'rgb(99, 101, 120)',
}